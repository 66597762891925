import React from "react";
import "./Content.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route, useParams } from "react-router-dom";

import Header from "../App/Header/Header";
import NoMatch from "../App/NoMatch/NoMatch";
import ContentDetails from "./ContentDetails/ContentDetails";

import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";

const Content = () => {
  const userCategory = UserCategories();

  return (
    <>
      <Header title="" />
      <DataLayer />
      <Container id={userCategory} className="mb-5 mt-3 MainContent">
        <Row>
          <Col xs={12} md={12} className="mt-3 mb-3 py-3">
            <Routes>
              <Route path="/:id" element={<ContentWrapper />} />
              <Route component={NoMatch} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
};
/** Middleware for content page. */

const ContentWrapper = () => {
  const { id } = useParams();
  return <ContentDetails contentId={id} view={"full"} />;
};

Content.propTypes = {};

export default Content;
