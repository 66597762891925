import { StyleSheet, Font } from "@react-pdf/renderer";

// Create styles
const ExportStyles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 45,
    paddingHorizontal: 25,
  },

  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  column: {
    display: "flex",
    flexDirection: "column",
  },

  heading: {
    marginBottom: 12,
    paddingBottom: 12,
    borderBottom: "1px solid #007a5f",
  },

  logoText: {
    fontSize: 24,
    color: "#007a5f",
    fontFamily: "Source Sans Pro",
    paddingRight: "8px",
    borderRight: "2px solid #007a5f",
  },

  logoImage: {
    width: 300,
    paddingLeft: "0px",
  },

  title: {
    marginTop: 6,
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
  },

  subtitle: {
    fontSize: 14,
    textAlign: "center",
    color: "#2e384d",
    marginTop: 6,
  },

  paragraph: {
    fontSize: 12,
    textAlign: "center",
    color: "#2e384d",
    fontFamily: "Source Sans Pro",
    marginTop: 6,
  },

  staticPara: {
    fontSize: 12,
    color: "#2e384d",
    fontFamily: "Source Sans Pro",
  },

  footer: {
    marginTop: 12,
    paddingTop: 12,
    borderTop: "1px solid #007a5f",
  },

  footerText: {
    marginBottom: 6,
    fontSize: 10,
    textAlign: "center",
  },

  section: {
    marginTop: 6,
    marginBottom: 6,
  },

  newSection: {
    marginTop: 2,
    marginBottom: 2,
  },

  siteSection: {
    width: "60%",
    marginTop: 12,
    marginBottom: 12,
    display: "flex",
    flexDirection: "column",
  },

  carbonSection: {
    marginTop: 8,
    marginBottom: 8,
    display: "flex",
    flexDirection: "column",
  },

  baselineSection: {
    display: "flex",
    flexDirection: "row",
  },

  boldTextCell: {
    fontWeight: "bold",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 10,
    fontFamily: "Source Sans Pro",
    textAlign: "left",
  },

  sectionHeading: {
    fontSize: 16,
    fontFamily: "Source Sans Pro",
  },

  chartHeading: {
    fontSize: 16,
    fontFamily: "Source Sans Pro",
    paddingBottom: 6,
  },

  totalHeading: {
    fontSize: 13,
    fontFamily: "Source Sans Pro",
    paddingTop: 5,
    paddingBottom: 5,
  },

  totalNumber: {
    fontSize: 13,
    fontFamily: "Source Sans Pro",
    marginLeft: 16,
    backgroundColor: "#F5F5F5",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: "bold",
  },

  totalSection: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
    marginTop: 5,
  },

  scopeHeadSection: {
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
    marginBottom: 16,
  },

  scopeNumber: {
    fontSize: 13,
    fontFamily: "Source Sans Pro",
    backgroundColor: "#F5F5F5",
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: "bold",
    margin: "0 auto",
  },

  scopeThree: {
    fontSize: 13,
    fontFamily: "Source Sans Pro",
    backgroundColor: "#F5F5F5",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: "bold",
    margin: "0 auto",
  },

  scopeSection: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
    marginTop: 6,
  },

  scopeHeading: {
    fontSize: 10,
    fontFamily: "Source Sans Pro",
    margin: "0 auto",
    marginTop: 5,
  },

  financedCol: {
    display: "flex",
    flexDirection: "column",
  },

  valueCol: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 16,
  },

  sectionSubHeading: {
    fontSize: 14,
    fontFamily: "Source Sans Pro",
  },

  sectionText: {
    marginTop: 6,
    fontSize: 14,
    fontFamily: "Source Sans Pro",
  },

  chartSection: { padding: 12, border: "1px solid #dee2e6" },

  emptyState: {
    padding: 12,
    border: "1px solid #dee2e6",
    marginTop: 6,
    marginBottom: 6,
    textAlign: "center",
    color: "#2e384d",
    fontFamily: "Source Sans Pro",
    fontSize: 12,
  },

  contentSection: { marginTop: 8, marginBottom: 8 },

  chartTitle: {
    fontSize: 12,
    textAlign: "center",
    color: "#2e384d",
    fontFamily: "Source Sans Pro",
  },

  chart: {
    width: 540,
    height: "auto",

    marginBottom: 10,
    marginTop: 10,
  },

  abatementChart: {
    width: 540,
    height: "auto",

    marginBottom: 6,
    marginTop: 2,
  },

  siteChart: {
    margin: "0 auto",
    marginBottom: 8,
    marginTop: 8,
  },

  carbonChart: {
    width: "auto",
  },

  forecastingChart: {
    height: 250,
    marginBottom: 1,
    marginTop: 1,
  },

  tableSection: {
    paddingTop: 8,
    paddingBottom: 8,
  },

  tableTitle: {
    marginTop: 6,
    fontSize: 14,
    color: "#2e384d",
    fontFamily: "Source Sans Pro",
  },

  table: {
    display: "table",
    width: "auto",
    fontSize: 14,
    fontFamily: "Source Sans Pro",
    marginBottom: 6,
    marginTop: 6,
  },

  tableRow: {
    display: "flex",
    padding: 2,
    flexDirection: "row",
    backgroundColor: "#F5F5F5",
    borderBottom: "1px solid #fff",
  },

  tableRowTotal: {
    display: "flex",
    padding: 2,
    flexDirection: "row",
    backgroundColor: "#F5F5F5",
    borderTop: "1px solid black",
    borderBottom: "1px solid #fff",
  },

  tableRowStripe: {
    flexDirection: "row",
    borderBottom: "1px solid #f6f6f6",
  },

  tableCol: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: 0,
  },

  tableColC: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 10,
    flexBasis: 0,
  },

  tableCell: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 10,
    fontFamily: "Source Sans Pro",
    textAlign: "left",
  },

  tableCellC: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 8,
    fontFamily: "ZCool",
    textAlign: "left",
  },

  tableSmallCell: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 9,
    fontFamily: "Source Sans Pro",
    textAlign: "left",
  },

  tableSmallCellBold: {
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 9,
    fontFamily: "Source Sans Pro",
    textAlign: "left",
  },

  tableCellRight: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 10,
    fontFamily: "Source Sans Pro",
    textAlign: "right",
  },

  tableCellHeader: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    fontFamily: "Source Sans Pro",
    textAlign: "left",
    fontWeight: "bold",
    backgroundColor: "#d3d3d3",
  },

  tableCellHeaderRight: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    fontFamily: "Source Sans Pro",
    textAlign: "right",
    fontWeight: "bold",
    backgroundColor: "#d3d3d3",
  },

  disclaimerHeading: {
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 2,
    fontSize: 10,
    fontFamily: "Source Sans Pro",
  },

  disclaimerText: {
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 8,
    color: "grey",
  },

  disclaimerLink: {
    margin: 6,
    fontSize: 10,
    color: "#007a5f",
    fontFamily: "Source Sans Pro",
  },

  timeStamp: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 25,
    right: 0,
    textAlign: "left",
    color: "grey",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 25,
    textAlign: "right",
    color: "grey",
  },

  subscript: {
    fontSize: "10px",
  },

  subscriptTable: {
    fontSize: "7px",
  },

  contentText: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 12,
    color: "#2e384d",
  },

  contentLink: {
    margin: 3,
    fontSize: 12,
    color: "#007a5f",
    fontFamily: "Source Sans Pro",
  },

  list: {
    display: "block",
    paddingLeft: 12,
  },

  listItem: {
    flexDirection: "row",
  },

  listText: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 12,
    fontFamily: "Source Sans Pro",
  },

  link: {
    margin: 6,
    fontSize: 10,
    color: "#007a5f",
  },
});

Font.register({
  family: "Source Sans Pro",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7gujVj9w.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkA.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "ZCool",
  src: "https://fonts.gstatic.com/s/zcoolkuaile/v7/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf",
});

export default ExportStyles;
