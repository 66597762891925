import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { get } from "utils/BeeApi";
import { Link } from "react-router-dom";
import { useContentContext } from "contexts/ContentContext";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import "./ContentDetails.scss";

const ContentDetails = ({ contentId, view, onContentFetched }) => {
  const {
    allFilteredContent,
    bookmarkableIds,
    isLoading,
    error,
    markBookmark,
  } = useContentContext();

  const subscribedPromises = useRef([]);

  const [content, setContent] = useState();

  const [isContentLoading, setContentLoading] = useState(false);
  const [contentError, setContentError] = useState();

  //First Id is for sandbox second is for production
  const contentGroupId = ["64cc0923eabf3", "62a0c2bbe0861"];

  const isWhatsNew = content?.categories?.find((category) => {
    return category?.groups?.some((group) =>
      contentGroupId.includes(group?.groupId)
    );
  });

  useEffect(() => {
    if (isWhatsNew) {
      markBookmark(contentId);
    }
  }, [contentId, markBookmark, isWhatsNew]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    function fetchContent(id) {
      setContentLoading(true);
      setContentError("");

      const contentPromise = get(`contents/${id}`, {});
      contentPromise.promise
        .then((response) => {
          setContent(response.data);
          setContentLoading(false);
          onContentFetched && onContentFetched(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setContentError(error);
            setContentLoading(false);
          }
        });

      subscribedPromises.current.push(contentPromise);
    }
    fetchContent(contentId);
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, [contentId]);

  const setResponsiveness = useCallback((bodyNode) => {
    if (bodyNode !== null) {
      let aTags = bodyNode.getElementsByTagName("a");
      for (let aTag of aTags) {
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("rel", "noopener noreferrer");
      }

      let imgTags = bodyNode.getElementsByTagName("img");
      for (let img of imgTags) {
        img.setAttribute("class", "img-fluid");
      }

      let iframeTags = bodyNode.getElementsByTagName("iframe");
      for (let iframe of iframeTags) {
        iframe.removeAttribute("height");
        iframe.removeAttribute("width");
        let wrapper = document.createElement("div");
        wrapper.className = "ratio ratio-16x9";
        // insert wrapper before el in the DOM tree
        iframe.parentNode.insertBefore(wrapper, iframe);
        wrapper.appendChild(iframe);
      }

      let figureTags = bodyNode.getElementsByTagName("figureTags");
      for (let figure of figureTags) {
        figure.setAttribute("class", "img-fluid");
      }
    }
  }, []);

  if (contentError) return <ErrorHandler error={contentError} />;
  if (!content) return <span />;

  return (
    <>
      <Col xs={10} sm={6} md={12}>
        {isWhatsNew && (
          <div className="ContentDetails">
            <div className="float-end whatsnew ms-3 border border-2 rounded-2 border-primary">
              <Link
                to="/all-updates"
                className="float-end text-decoration-none "
              >
                <small>View all updates</small>
              </Link>
              <h3 className="lh-1">What's New</h3>
              <hr className="divider" />
              {error && <ErrorHandler error={error} />}
              {isLoading ? (
                <Loader />
              ) : (
                <div>
                  {allFilteredContent.length !== 0 ? (
                    <div className="mt-3">
                      {allFilteredContent?.slice(0, 4)?.map((item, index) => (
                        <div className="mb-2" key={item.contentId}>
                          <div>
                            <span className="gray">
                              <small>
                                {new Date(item.createdAt).toLocaleString(
                                  "default",
                                  {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  }
                                )}
                              </small>
                            </span>
                            <Link
                              to={`/contents/${item.contentId}`}
                              className="w-max d-flex p-0 text-decoration-none"
                            >
                              {" "}
                              <h4>
                                {item.title}
                                {!bookmarkableIds?.includes(item.contentId) && (
                                  <sup>
                                    <span className="fs-5 ms-1 text-orange">
                                      &#9679;
                                    </span>
                                  </sup>
                                )}
                              </h4>
                            </Link>
                          </div>
                          <span className="gray w-25">
                            <small>
                              {item?.description &&
                              item?.description?.length > 150
                                ? item?.description?.substring(0, 157) + "..."
                                : item?.description}
                            </small>
                          </span>
                          {index <
                            allFilteredContent.slice(0, 4).length - 1 && (
                            <hr className="divider" />
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        )}
      </Col>

      {isWhatsNew ? (
        <Col xs={10} sm={6} md={8}>
          {isContentLoading ? (
            <Loader />
          ) : (
            <div>
              {view === "full" && (
                <>
                  <h1>{content.title}</h1>
                  <hr />
                </>
              )}

              <div
                className="ContentDetails "
                ref={setResponsiveness}
                dangerouslySetInnerHTML={{ __html: content.contentBody }}
              />
            </div>
          )}
        </Col>
      ) : isContentLoading ? (
        <Loader />
      ) : (
        <div>
          {view === "full" && (
            <>
              <h1>{content.title}</h1>
              <hr />
            </>
          )}

          <div
            className="ContentDetails "
            ref={setResponsiveness}
            dangerouslySetInnerHTML={{ __html: content.contentBody }}
          />
        </div>
      )}
    </>
  );
};

ContentDetails.propTypes = {
  contentId: PropTypes.string.isRequired,
  view: PropTypes.string,
};

export default ContentDetails;
