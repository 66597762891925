import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Alert, Table } from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field } from "formik";

import { post, get, destroy } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import SelectInputFormik from "components/SelectInput/SelectInput";

import { AccountContext } from "contexts/AccountProvider";
import { getAllYearsBetweenDates } from "utils/dateUtils";
import moment from "moment";

const PortfolioOrganizationUpdate = ({
  portfolio,
  portfolioOrganizations,
  onPortfolioOrganizationUpdated,
  emissionYear,
}) => {
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [organizationTenures, setOrganizationTenures] = useState([]);
  const [startDates, setStartDate] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const initialValues = {
    portfolio: "",
    equityOwnership: "",
    addedAt: "",
    exitedAt: "",
  };
  const [nearestEndDate, setNearestEndDate] = useState();

  const account = useContext(AccountContext);

  const handleClose = () => {
    setShow(false);
    setOrganizationTenures([]);
    // isDeleted && onPortfolioOrganizationUpdated();
    // setIsDeleted(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    organization: yup.string().required(),
    equityOwnership: yup
      .number()
      .min(0, "Attribution factor cannot be less than 0!")
      .max(100, "Attribution factor cannot be greater than 100!")
      .required(),
    addedAt: yup.string().required(),
    exitedAt: yup.string(),
  });

  function endDatesArr(addedAt) {
    const datesToBeChecked = organizationTenures?.map((org) =>
      moment(org?.addedAt).format("YYYY-MM-DD")
    );
    const dateToCheckFor = moment(addedAt).format("YYYY-MM-DD");
    let nearestDate;
    datesToBeChecked.forEach((date) => {
      let diff = moment(date).diff(moment(dateToCheckFor), "years");
      if (diff > 0) {
        if (nearestDate) {
          if (moment(date).diff(moment(nearestDate), "years") < 0) {
            nearestDate = date;
          }
        } else {
          nearestDate = date;
        }
      }
    });
    setNearestEndDate(nearestDate);

    const endDates = getAllYearsBetweenDates().filter((data) =>
      addedAt && !nearestDate?.length
        ? data >= moment(addedAt, "YYYY-MM-DD").year()
        : addedAt && !!nearestDate?.length
        ? data >= moment(addedAt, "YYYY-MM-DD").year() &&
          data <= moment(nearestDate, "YYYY-MM-DD").subtract(1, "years").year()
        : data >= emissionYear
    );
    return endDates;
  }

  const updatePortfolioOrganization = ({
    organization,
    equityOwnership,
    addedAt,
    exitedAt,
  }) => {
    setError(null);
    setIsLoading(true);

    let portfolioOrganizationPromise;

    portfolioOrganizationPromise = post(
      `/portfolios/${portfolio.id}/organizations`,
      {
        organizations: [
          {
            id: organization,
            equityOwnership: equityOwnership,
            addedAt: addedAt,
            exitedAt: exitedAt,
          },
        ],
      }
    );

    portfolioOrganizationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onPortfolioOrganizationUpdated(response.data);
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const deleteOrganizationFromPortfolio = (organization, id) => {
    setError(null);
    setIsLoading(true);
    const portfolioOrganizationPromise = destroy(
      `portfolios/${portfolio.id}/organizations/${id}`,
      {
        pivotId: organization.pivotId,
        deleteAll: 0,
        deletingYear: emissionYear,
      }
    );
    portfolioOrganizationPromise.promise
      .then((response) => {
        setIsDeleted(true);
        fetchOrganizationTenure(id);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(portfolioOrganizationPromise);
  };

  const fetchOrganizationTenure = (organization, setFieldValue) => {
    setError(null);
    setIsLoading(true);

    const organizationTenurePromise = get(
      `/portfolios/${portfolio.id}/organizations/${organization}/tenures`
    );
    organizationTenurePromise.promise
      .then((response) => {
        setOrganizationTenures(response.data);
        !response?.data?.length &&
          setFieldValue &&
          setFieldValue("addedAt", emissionYear + "-01-01");
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationTenurePromise);
  };

  // function getTenureYears(addedAt, exitedAt) {
  //   const start = moment(addedAt);
  //   const end = moment(exitedAt);
  //   let yearList = [];
  //   for (let current = start; current <= end; current.add(1, "y")) {
  //     yearList.push(current.format("YYYY"));
  //   }
  //   return yearList;
  // }

  // function getTenures() {
  //   let remainingYears = [];
  //   if (!!organizationTenures?.length) {
  //     const tenures = organizationTenures?.map((org) => ({
  //       addedAt: moment(org?.addedAt || "2010-01-01").format("YYYY-MM-DD"),
  //       exitedAt: org?.exitedAt
  //         ? moment(org?.exitedAt).format("YYYY-MM-DD")
  //         : moment(org?.addedAt || "2010-01-01").format("YYYY-MM-DD"),
  //     }));
  //     const tenureYears = tenures.map((ten) =>
  //       getTenureYears(ten?.addedAt, ten?.exitedAt)
  //     );
  //     remainingYears = getAllYearsBetweenDates()
  //       .filter((data) => data > Math.max(...tenureYears.flat()))
  //       .sort((a, b) => b - a);
  //   }
  //   return remainingYears;
  // }

  useEffect(() => {
    const tenureYears = organizationTenures?.map((data) =>
      moment(data?.addedAt, "YYYY")?.year()?.toString()
    );
    const filteredYears = getAllYearsBetweenDates()?.filter(
      (data) => data !== "2009"
    );

    setStartDate(
      !!organizationTenures?.length
        ? filteredYears?.filter(function (obj) {
            return tenureYears?.indexOf(obj) === -1;
          })
        : [emissionYear]
    );
  }, [organizationTenures]);

  const fetchOrganizations = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const organizationsPromise = get("/organizations/list", {
      params: { accountId: account.id },
    });

    organizationsPromise.promise
      .then((response) => {
        setError(null);
        setOrganizations([...response.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationsPromise);
  }, [account.id]);

  useEffect(() => {
    fetchOrganizations();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrganizations]);

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm">
        Add Organizations
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Add organizations in{" "}
            <b>
              <i>{portfolio.name}</i>
            </b>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updatePortfolioOrganization(values)}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group
                  controlId="portfolioOrganizations"
                  className="my-3"
                  key={values.organization}
                >
                  <Form.Label>Select Organization.</Form.Label>
                  <Field
                    name="organization"
                    value={values.organization}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.organization}
                  >
                    {({ field, form }) => (
                      <SelectInputFormik
                        name="organization"
                        values={values}
                        options={organizations.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                        onChange={(option) => {
                          if (option) {
                            form.setFieldValue(field.name, option.value);
                            setFieldValue("addedAt", "");
                            fetchOrganizationTenure(
                              option.value,
                              setFieldValue
                            );
                          } else {
                            form.setFieldValue(field.name, "");
                          }
                          return option;
                        }}
                        form={form}
                        field={field}
                      />
                    )}
                  </Field>
                  <Form.Control.Feedback type="invalid">
                    {errors.organization}
                  </Form.Control.Feedback>
                </Form.Group>
                {values.organization && !startDates?.length && (
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block mb-2"
                  >
                    This organization is already added for all years and
                    effective since{" "}
                    {moment(organizationTenures?.[0]?.addedAt, "YYYY")?.year()}.
                  </Form.Control.Feedback>
                )}
                {values.organization && (
                  <>
                    <Form.Group controlId="addedAt">
                      <Form.Label>Reporting Year</Form.Label>
                      <Form.Select
                        aria-label="year-filter"
                        name="addedAt"
                        value={values.addedAt}
                        onChange={(ev) => {
                          setFieldValue("addedAt", ev.target.value);
                          if (
                            new Date(ev.target.value) >
                            new Date(values.exitedAt)
                          ) {
                            setFieldValue("exitedAt", "");
                          }
                        }}
                        onBlur={handleBlur}
                        isValid={values.addedAt}
                        isInvalid={errors.addedAt && touched.addedAt}
                        disabled={
                          !startDates?.length ||
                          (startDates?.length === 1 &&
                            startDates?.includes(emissionYear))
                        }
                      >
                        <option disabled={values?.addedAt}>
                          Select Reporting Year
                        </option>
                        {!!startDates?.length ? (
                          startDates?.map((year) => (
                            <option key={year} value={year + "-01-01"}>
                              {year}
                            </option>
                          ))
                        ) : (
                          <option value={emissionYear + "-01-01"}>
                            {emissionYear}
                          </option>
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.addedAt && "Effective From is a required filed"}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="equityOwnership" className="my-3">
                      <Form.Label>
                        Enter Attribution Factor (%).
                        <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min="0"
                        name="equityOwnership"
                        placeholder="Enter attribution factor (%)"
                        value={values.equityOwnership}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.equityOwnership}
                        isInvalid={
                          errors.equityOwnership && touched.equityOwnership
                        }
                        disabled={!startDates?.length}
                        className="md-6"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.equityOwnership &&
                          "Attribution Factor is a required field"}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
                {!!organizationTenures?.length && (
                  <Alert variant="dark" className="bg-light">
                    <h4>Attribution Factor by Year</h4>
                    <div className="table-scroller-resp">
                      <Table size="sm" className="table-head-fixed">
                        <thead>
                          <tr>
                            <th>Reporting Year</th>
                            <th className="text-end">Attribution Factor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {organizationTenures.map((organization) => {
                            return (
                              <tr className="mb-0" key={organization.pivotId}>
                                <td>
                                  {organization.addedAt
                                    ? moment(
                                        organization.addedAt,
                                        "YYYY-MM-DD"
                                      ).year()
                                    : "2010"}{" "}
                                </td>
                                <td className="text-end">
                                  {organization.equityOwnership}%
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Alert>
                )}
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                  Add
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

PortfolioOrganizationUpdate.propTypes = {
  portfolio: PropTypes.object.isRequired,
  portfolioOrganizations: PropTypes.array.isRequired,
  onPortfolioOrganizationUpdated: PropTypes.func.isRequired,
};

export default PortfolioOrganizationUpdate;
