import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post } from "utils/DeApi";
import { AccountContext } from "contexts/AccountProvider";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

function PortfolioCreate({ onPortfolioCreated, variant }) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const subscribedPromises = useRef([]);

  const account = useContext(AccountContext);

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Portfolio name is required"),
    description: yup.string().min(2, "Too Short!").max(256, "Too Long!"),
  });

  const initialValues = {
    name: "",
    description: "",
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createPortfolio = ({ name, description }) => {
    setError(null);
    setIsLoading(true);

    const organizationPromise = post(`portfolios`, {
      name: name,
      description: description,
      accountId: account.id,
    });

    organizationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onPortfolioCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  return (
    <>
      <Button variant={variant} className="px-3" onClick={handleShow} size="sm">
        New Portfolio
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Portfolio</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => createPortfolio(values)}
          validateOnMount
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="portfolioName">
                  <Form.Label>
                    Name
                    <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter portfolio's name"
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="portfolioDescription" className="my-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Describe the portfolio"
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={!isValid}>
                  Create Portfolio
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

PortfolioCreate.defaultProps = {
  variant: "outline-primary",
};

PortfolioCreate.propTypes = {
  onPortfolioCreated: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default PortfolioCreate;
