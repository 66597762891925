import React, { useState, useEffect } from "react";
import { Form, Button, Collapse, Alert } from "react-bootstrap";
import { Field } from "formik";
import SelectInputFormik from "components/SelectInput/SelectInput";

import { getFormula } from "./CurrencyDataset";

import NumberFormat from "react-number-format";
import { get } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const CurrencyConverter = ({
  emissionUnit,
  reportingYear,
  values,
  setFieldValue,
  selectedOption,
  module,
}) => {
  const [show, setShow] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyAvailYears, setCurrencyAvailYears] = useState([]);
  const [exchangeRates, setExchangeRates] = useState([]);

  const [amount, setAmount] = useState(values?.rawAmount);
  const [amountCurrency, setAmountCurrency] = useState(values?.rawUnit);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setError(null);
    setIsLoading(true);
    const getUnitConversions = get(`/conversions?unit=${emissionUnit}`);
    const getExchangeRate = get(`/conversions?unit=EUR`);
    Promise.all([getUnitConversions.promise, getExchangeRate.promise])
      .then((responses) => {
        setError(null);
        setIsLoading(false);
        const convertedCurrencyArray = responses?.[0]?.data?.reduce(
          (result, item) => {
            const existingCurrency = result.find(
              (currency) => currency.currency === item.fromUnitName
            );

            if (existingCurrency) {
              existingCurrency.conversionFactors.push({
                rate: 1 / parseFloat(item.factor),
                year: item.year.toString(),
              });
            } else {
              result.push({
                currency: item.fromUnitName,
                currencyName: item.fromUnitLabel,
                conversionFactors: [
                  {
                    rate: 1 / parseFloat(item.factor),
                    year: item.year.toString(),
                  },
                ],
              });
            }

            return result;
          },
          []
        );
        const availableCurrencyYears =
          convertedCurrencyArray?.[0]?.conversionFactors?.map(
            (data) => data?.year
          );
        const currencies = convertedCurrencyArray?.map((data) => ({
          label: data?.currency + ` (${data?.currencyName})`,
          value: data?.currency + ` (${data?.currencyName})`,
        }));
        setCurrencyData(convertedCurrencyArray);
        setCurrencyAvailYears(availableCurrencyYears);
        setCurrencyList(!!currencies?.length ? currencies : ["No Data Found"]);
        setExchangeRates(responses?.[1]?.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  }, [emissionUnit]);

  const availableExchangeRates = exchangeRates?.map((data) => data?.year);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : (
        <>
          {(module === "topdown" || module === "exiobase") &&
            availableExchangeRates?.includes(Number(reportingYear)) && (
              <Form.Text>
                Exchange rate from USD to EUR:{" "}
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 8,
                  minimumFractionDigits: 2,
                }).format(
                  exchangeRates?.filter(
                    (data) =>
                      data?.fromUnitName === "USD" &&
                      data?.year === Number(reportingYear)
                  )?.[0]?.factor
                )}
              </Form.Text>
            )}
          {currencyAvailYears?.includes(reportingYear) ? (
            <div className="pt-3">
              <Button
                variant="link"
                onClick={() => {
                  setShow(!show);
                }}
                className="p-0 mt-n3"
              >
                Convert amount to{module === "carbonintensity" && ` MM`}{" "}
                {emissionUnit}
              </Button>
              <Collapse in={show}>
                <div>
                  <Alert
                    variant="dark"
                    className="bg-light"
                    dismissible
                    onClose={() => setShow(!show)}
                  >
                    <Form.Group controlId="currency" className="my-3">
                      <Form.Label>
                        Select the currency to convert from
                      </Form.Label>

                      <Field name="rawUnit" value={values.rawUnit}>
                        {({ field, form }) => (
                          <SelectInputFormik
                            name="rawUnit"
                            values={values}
                            options={currencyList}
                            onChange={(ev) => {
                              setAmountCurrency(ev.value);
                              setFieldValue("rawUnit", ev.value);
                            }}
                            selectedOption={selectedOption}
                            form={form}
                            field={field}
                          />
                        )}
                      </Field>
                    </Form.Group>
                    {amountCurrency && (
                      <Form.Group controlId="resource-amount">
                        <Form.Label>
                          Provide the amount of resource used in
                          {module === "carbonintensity" && ` MM`}{" "}
                          {amountCurrency.slice(0, 3)}.
                        </Form.Label>

                        <NumberFormat
                          customInput={Form.Control}
                          name="amount"
                          value={amount}
                          thousandSeparator={true}
                          onValueChange={(numberItem) => {
                            setAmount(numberItem.value);
                            setFieldValue("rawAmount", numberItem.value);
                          }}
                          placeholder={"Enter amount of resource"}
                          onBlur={() => {}}
                        />
                      </Form.Group>
                    )}
                    {(amount || amount === 0) && (
                      <p className="my-3">
                        {amount}
                        {module === "carbonintensity" && ` MM`}{" "}
                        {amountCurrency?.slice(0, 3)} is equivalent to{" "}
                        <big>
                          {Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 6,
                            minimumFractionDigits: 2,
                          }).format(
                            getFormula(
                              currencyData,
                              amountCurrency?.slice(0, 3),
                              amount,
                              reportingYear
                            ).toFixed(2)
                          )}
                        </big>
                        {module === "carbonintensity" && ` MM`} {emissionUnit}
                      </p>
                    )}

                    <div className="my-3 text-end">
                      <>
                        <Button
                          size="sm"
                          variant="link"
                          onClick={(e) => {
                            e.preventDefault();
                            setAmount();
                            setAmountCurrency();
                            setShow(!show);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          size="sm"
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(
                              "amount",
                              getFormula(
                                currencyData,
                                amountCurrency?.slice(0, 3),
                                amount,
                                reportingYear
                              ).toFixed(2)
                            );
                            setFieldValue(
                              "aum",
                              getFormula(
                                currencyData,
                                amountCurrency?.slice(0, 3),
                                amount,
                                reportingYear
                              ).toFixed(2)
                            );
                            setFieldValue(
                              "revenue",
                              getFormula(
                                currencyData,
                                amountCurrency?.slice(0, 3),
                                amount,
                                reportingYear
                              ).toFixed(2)
                            );
                            setAmount();
                            setAmountCurrency();
                            setShow(!show);
                          }}
                          disabled={!amount || !amountCurrency}
                        >
                          Use{" "}
                          {Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 6,
                            minimumFractionDigits: 2,
                          }).format(
                            amount
                              ? getFormula(
                                  currencyData,
                                  amountCurrency?.slice(0, 3),
                                  amount,
                                  reportingYear
                                ).toFixed(2)
                              : 0
                          )}
                          {module === "carbonintensity" && ` MM`} {emissionUnit}
                        </Button>
                      </>
                    </div>
                  </Alert>
                </div>
              </Collapse>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default CurrencyConverter;
