import { AccountDispatchContext } from "contexts/AccountProvider";
import { AccountContext } from "contexts/AccountProvider";
import React, { useContext, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { post } from "utils/DeApi";
import { put } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const PowerBILink = () => {
  const accountContext = useContext(AccountContext);
  const setAccount = useContext(AccountDispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState();

  const schema = yup.object().shape({
    link: yup.string().url().required(),
  });

  const addLink = ({ link }) => {
    setError(null);
    setIsLoading(true);
    let saveLink = accountContext?.dataAnalyticsLink?.length
      ? put(
          `/accounts/${accountContext.id}/data-analytics-links/${accountContext?.dataAnalyticsLink?.[0]?.id}`,
          {
            link: link,
          }
        )
      : post(`/accounts/${accountContext.id}/data-analytics-links`, {
          link: link,
        });

    saveLink.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
        setAccount({
          ...accountContext,
          dataAnalyticsLink: [response.data],
        });
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };
  return (
    <div>
      {error && <ErrorHandler error={error} />}
      <Formik
        validationSchema={schema}
        onSubmit={(values) => addLink(values)}
        initialValues={{
          link: accountContext?.dataAnalyticsLink?.[0]?.link || "",
        }}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          isValid,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="link">
              <Form.Control
                type="text"
                name="link"
                value={values.link}
                placeholder="Enter Power BI link"
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={values.link}
                isInvalid={errors.link && touched.link}
              />
              <Form.Control.Feedback type="invalid">
                {errors.link}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className="my-3"
              onClick={handleSubmit}
              size="sm"
              disabled={isLoading || !isValid}
            >
              {isLoading && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              Save
            </Button>
            {isSuccess && (
              <Alert key={"success"} variant={"success"}>
                Link Saved Successfully.
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PowerBILink;
