import React from "react";
import "./Logo.scss";

const Logo = () => (
  <div className="Logo">
    <div className="d-flex align-items-center justify-content-center">
      <div className="p-4">
        <a href="/">
          <img
            className="img-responsive"
            style={{ width: "320px" }}
            src="/logo-green.png"
            alt="Logo"
          />{" "}
        </a>
      </div>
    </div>
  </div>
);

export default Logo;
