import { Formik } from "formik";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as yup from "yup";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import FileUploadField, {
  createFile,
} from "components/Organization/Site/SiteDetail/FileUpload/FileUpload";
import { post } from "utils/DeApi";

const DocumentUpdate = ({ organization, gwpModels, onDocumentUploaded }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setError(null);
    setIsLoading(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    file: yup.mixed().required("Document is required"),
    comment: yup.string(),
  });

  const CreateDocument = useCallback(
    ({ file, comment }) => {
      setError(null);
      setIsLoading(true);

      const createOrgAttachment = createFile(
        file,
        "organizations",
        organization.id
      );

      createOrgAttachment.promise
        .then((response) => {
          if (comment) {
            const createOrgAttachmentComment = post(
              `attachments/${response.data.id}/comments`,
              {
                body: comment,
              }
            );
            createOrgAttachmentComment.promise
              .then((res) => {
                onDocumentUploaded(response.data);
                setError(null);
                setIsLoading(true);
                handleClose();
              })
              .catch((err) => {
                if (!err.isCanceled) {
                  setError(err);
                  setIsLoading(false);
                }
                console.error(err);
              });
          } else {
            onDocumentUploaded(response.data);
            setError(null);
            setIsLoading(true);
            handleClose();
          }
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
    },
    [organization, onDocumentUploaded]
  );

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Add document</Tooltip>}>
        <Button variant="primary" onClick={handleShow} size="sm">
          <span className="material-icons md-18 material-icons-outlined">
            add
          </span>
        </Button>
      </OverlayTrigger>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Add document for <i>{organization.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => CreateDocument(values)}
          initialValues={{ file: "", comment: "" }}
          validateOnMount
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <Modal.Body>
                <FileUploadField
                  fieldName="file"
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={(event) => {
                    const _file = event.currentTarget.files[0];
                    setFieldValue("file", _file);
                  }}
                  attachments={[]}
                  handleDeleteAttachment={() => console.log("Deleted")}
                />

                <Form.Group className="mt-3">
                  <Form.Label controlId="comment">Comment</Form.Label>
                  <Form.Control
                    type="text"
                    name="comment"
                    aria-label="comment"
                    size="md"
                    className="py-1"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Add comment for this document"
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.comment}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} size="sm" disabled={!isValid}>
                  Add document{" "}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

DocumentUpdate.propTypes = {
  organization: PropTypes.object.isRequired,
  gwpModels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDocumentUploaded: PropTypes.func.isRequired,
};

export default DocumentUpdate;
