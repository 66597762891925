import { useContext, useRef, useState } from "react";

import PropTypes from "prop-types";

import { Alert, Button, Form, Modal, Table } from "react-bootstrap";

import { Formik } from "formik";
import * as yup from "yup";

import { upload } from "utils/DeApi";

import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import { AccountContext } from "contexts/AccountProvider";

import "./OrganizationsUpload.scss";

const OrganizationsUpload = ({ onOrganizationsUploaded }) => {
  const subscribedPromises = useRef([]);
  const attachmentRef = useRef();

  const [show, setShow] = useState(false);

  const account = useContext(AccountContext);

  const [fileData, setFileData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setIsLoading();
    setError();
    setSuccess();
    setFileData();
    attachmentRef.current.reset();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const uploadOrganizations = (file) => {
    setError();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("accountId", account.id);

    const filePromise = upload(`organizations/import`, formData);

    filePromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        setSuccess(true);
        attachmentRef.current.reset();
        setFileData();
        onOrganizationsUploaded();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setSuccess();
        }
      });
    subscribedPromises.current.push(filePromise);
  };

  const previewOrganizations = (file) => {
    setError();
    setIsLoading(true);
    setSuccess(false);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("accountId", account.id);
    formData.append("preview", 1);

    const previewPromise = upload(`organizations/import`, formData);

    previewPromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        const blankRow = response?.data.map((item) => item.name).includes("");
        const txtType = "text/plain".includes(file?.type);
        const shortName = response?.data.find((item) => item.name.length < 2);
        const blankLocation = response?.data
          .map((item) => item.primary_location)
          .includes("");

        if (txtType || file?.type !== "text/csv") {
          setError({
            data: {
              message: "The file should be in CSV format (.csv extension only)",
            },
          });
          attachmentRef.current.reset();
        } else if (blankRow) {
          setError({
            data: {
              message:
                "The file has organizations without names. Please provide the missing organization names and reupload.",
            },
          });
          attachmentRef.current.reset();
        } else if (shortName) {
          setError({
            data: {
              message:
                "The file has shorter organization names. Please make sure all organization names have at least two characters in the CSV and try again.",
            },
          });
          attachmentRef.current.reset();
        } else if (blankLocation) {
          setError({
            data: {
              message:
                "The file has missing locations. Please make sure all primary location are filled out in the CSV and try again.",
            },
          });
          attachmentRef.current.reset();
        } else {
          setFileData(response.data);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setFileData();
        }
      });
    subscribedPromises.current.push(previewPromise);
  };

  const schema = yup.object().shape({
    file: yup.mixed().required("A file is required"),
  });

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow} size="sm">
        Import Organizations
      </Button>

      <Modal
        className="OrganizationsUpload"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Organizations</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            uploadOrganizations(values.file);
          }}
          initialValues={{ file: "" }}
        >
          {({
            handleSubmit,
            handleBlur,
            setFieldValue,
            touched,
            errors,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} ref={attachmentRef}>
              <Modal.Body>
                <ContentDetails contentId={"65e57ae5f1fcf"} />
                <Form.Group controlId="files" className="mb-3">
                  <Form.Label>Please attach csv file.</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    disabled={fileData}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("file", file);
                      previewOrganizations(file);
                    }}
                    onBlur={handleBlur}
                    isValid={touched.file && !errors.file}
                    accept=".csv"
                    multiple
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.files}
                  </Form.Control.Feedback>
                </Form.Group>

                {isLoading && <Loader />}
                {error && (
                  <ErrorHandler message={error.data.message} error={error} />
                )}
                {success && (
                  <Alert variant="info">
                    Organizations uploaded successfully!
                  </Alert>
                )}
                {fileData && (
                  <div className="table-scroll mt-4">
                    <Table responsive striped size="sm">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Primary Location</th>
                          <th>Primary Industry Sector</th>
                          <th>Fiscal Year Start</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fileData?.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.name}</td>
                            <td>{data.description || "N/A"}</td>
                            <td>{data.primary_location}</td>
                            <td>{data.industry_sector || "N/A"}</td>
                            <td>
                              {data.start_of_year
                                ? new Date(
                                    null,
                                    data.start_of_year - 1
                                  ).toLocaleString("en", { month: "long" })
                                : "N/A"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                {fileData && (
                  <Button
                    size="sm"
                    variant="outline-primary"
                    disabled={error}
                    onClick={() => {
                      setFileData();
                      attachmentRef.current.reset();
                    }}
                  >
                    Discard
                  </Button>
                )}
                <Button
                  type="submit"
                  size="sm"
                  disabled={!isValid || isLoading || !fileData || error}
                >
                  Import
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

OrganizationsUpload.propTypes = {
  onOrganizationsUploaded: PropTypes.func.isRequired,
};

export default OrganizationsUpload;
