import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Modal, Button, Table, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { put, get, destroy } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import moment from "moment";
// import { getAllYearsBetweenDates } from "utils/dateUtils";
import "./PortfolioOrganizationEdit.scss";
import PortfolioOrganizationDelete from "../PortfolioOrganizationDelete/PortfolioOrganizationDelete";

function PortfilioOrganizationEdit({
  organization,
  emissionYear,
  portfolio,
  onOrganizationDeleted,
  portfolioId,
  onOrganizationUpdated,
}) {
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [organizationTenures, setOrganizationTenures] = useState([]);
  const [startDates, setStartDate] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);

  const handleClose = () => {
    setShow(false);
    isDeleted && onOrganizationDeleted();
    setIsDeleted(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    equityOwnership: yup
      .number()
      .min(0, "Attribution factor cannot be less than 0!")
      .max(100, "Attribution factor cannot be greater than 100!")
      .required("Attribution Factor is a required field"),
  });

  const initialValues = {
    name: organization?.name,
    equityOwnership: organization?.equityOwnership,
    addedAt: emissionYear,
    pivotId: organization?.pivotId,
    aum: organization?.aum,
    rawAmount: organization?.rawAmount,
    rawUnit: organization?.rawUnit,
  };

  const fetchOrganizationTenure = (setFieldValue) => {
    setError(null);
    setIsLoading(true);

    const organizationTenurePromise = get(
      `/portfolios/${portfolioId}/organizations/${organization.id}/tenures`
    );
    organizationTenurePromise.promise
      .then((response) => {
        setOrganizationTenures(response.data);
        if (setFieldValue && !!response?.data?.length) {
          const currentYear = response?.data?.filter(
            (data) =>
              moment(data?.addedAt, "YYYY").year() === Number(emissionYear)
          );
          setFieldValue(
            "addedAt",
            (!!currentYear?.length &&
              moment(currentYear?.[0]?.addedAt, "YYYY").year()) ||
              moment(response.data?.[0]?.addedAt, "YYYY").year()
          );
          filterTenureData(
            (!!currentYear?.length &&
              moment(currentYear?.[0]?.addedAt, "YYYY").year()) ||
              moment(response.data?.[0]?.addedAt, "YYYY").year(),
            setFieldValue
          );
        }
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationTenurePromise);
  };

  // function getTenureYears(addedAt, exitedAt) {
  //   const start = moment(addedAt);
  //   const end = moment(exitedAt);
  //   let yearList = [];
  //   for (let current = start; current <= end; current.add(1, "y")) {
  //     yearList.push(current.format("YYYY"));
  //   }
  //   return yearList;
  // }

  // function getTenures() {
  //   let remainingYears = [];
  //   if (!!organizationTenures?.length) {
  //     const tenures = organizationTenures?.map((org) => ({
  //       addedAt: moment(org?.addedAt || "2010-01-01").format("YYYY-MM-DD"),
  //       exitedAt: org?.exitedAt
  //         ? moment(org?.exitedAt).format("YYYY-MM-DD")
  //         : moment(org?.addedAt || "2010-01-01").format("YYYY-MM-DD"),
  //     }));
  //     const tenureYears = tenures.map((ten) =>
  //       getTenureYears(ten?.addedAt, ten?.exitedAt)
  //     );
  //     remainingYears = getAllYearsBetweenDates()
  //       .filter((data) => data > Math.max(...tenureYears.flat()))
  //       .sort((a, b) => b - a);
  //   }
  //   return remainingYears;
  // }

  useEffect(() => {
    setStartDate(
      !!organizationTenures?.length
        ? organizationTenures?.map((data) =>
            moment(data?.addedAt, "YYYY").year()
          )
        : []
    );
  }, [organizationTenures]);

  const filterTenureData = (year, setFieldValue) => {
    const filteredData = organizationTenures?.find(
      (data) => moment(data?.addedAt, "YYYY").year() === Number(year)
    );
    setFieldValue("pivotId", filteredData?.pivotId);
    setFieldValue("equityOwnership", filteredData?.equityOwnership);
    setFieldValue("aum", filteredData?.aum);
    setFieldValue("rawAmount", filteredData?.rawAmount);
    setFieldValue("rawUnit", filteredData?.rawUnit);
  };

  const updatePortfolioOrganization = ({
    equityOwnership,
    pivotId,
    aum,
    rawAmount,
    rawUnit,
  }) => {
    setError(null);
    setIsLoading(true);

    const organizationPortfolioPromise = put(
      `/portfolios/${portfolioId}/organizations/${organization.id}`,
      {
        equityOwnership: equityOwnership,
        pivotId: pivotId,
        aum: aum,
        rawAmount: rawAmount,
        rawUnit: rawUnit,
      }
    );

    organizationPortfolioPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onOrganizationUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const deleteOrganizationFromPortfolio = (org, id, setFieldValue) => {
    setError(null);
    setIsLoading(true);
    const portfolioOrganizationPromise = destroy(
      `portfolios/${portfolio.id}/organizations/${id}`,
      {
        pivotId: org.pivotId,
        deletingYear: emissionYear,
        deleteAll: 0,
      }
    );
    portfolioOrganizationPromise.promise
      .then((response) => {
        organizationTenures?.length === 1 && onOrganizationDeleted();
        setIsDeleted(true);
        fetchOrganizationTenure(setFieldValue);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(portfolioOrganizationPromise);
  };

  return (
    <>
      <Button
        variant="outline-primary"
        className="right py-0"
        size="sm"
        onClick={() => {
          handleShow();
          fetchOrganizationTenure();
        }}
      >
        <span className="material-icons-outlined md-18">tune</span>
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update organization in{" "}
            <b>
              <i>{portfolio?.name}</i>
            </b>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updatePortfolioOrganization(values)}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="organizationName" className="my-3">
                  <Form.Label>Organization Name.</Form.Label>
                  <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                    className="md-6"
                    disabled
                    readOnly
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Reporting Year</Form.Label>
                  <Form.Select
                    aria-label="year-filter"
                    name="addedAt"
                    value={values?.addedAt}
                    onChange={(ev) => {
                      setFieldValue("addedAt", ev.target.value);
                      filterTenureData(ev.target.value, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    isValid={values?.addedAt}
                    isInvalid={errors?.addedAt && touched?.addedAt}
                  >
                    <option disabled={values?.addedAt}>
                      Select Reporting Year
                    </option>
                    {startDates?.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors?.addedAt && "Effective From is a required filed"}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="equityOwnership" className="my-3">
                  <Form.Label>Update Attribution Factor (%).</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    name="equityOwnership"
                    placeholder="Enter attribution factor"
                    value={values?.equityOwnership}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values?.equityOwnership}
                    isInvalid={
                      errors.equityOwnership && touched.equityOwnership
                    }
                    className="md-6"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.equityOwnership}
                  </Form.Control.Feedback>
                </Form.Group>
                {!!organizationTenures?.length && (
                  <Alert variant="dark" className="bg-light">
                    <h4>Attribution Factor by Year</h4>
                    <div className="table-scroller-resp">
                      <Table size="sm" className="table-head-fixed">
                        <thead>
                          <tr>
                            <th>Reporting Year</th>
                            <th>Attribution Factor</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {organizationTenures.map((org) => {
                            return (
                              <tr className="mb-0" key={org.pivotId}>
                                <td>
                                  {org.addedAt
                                    ? moment(org.addedAt, "YYYY-MM-DD").year()
                                    : "2010"}{" "}
                                </td>
                                <td>{org.equityOwnership}%</td>
                                <td className="text-end">
                                  <PortfolioOrganizationDelete
                                    organization={org}
                                    emissionYear={emissionYear}
                                    portfolio={portfolio}
                                    onOrganizationDeleted={() =>
                                      onOrganizationDeleted()
                                    }
                                    organizationTenures={organizationTenures}
                                    setIsDeleted={setIsDeleted}
                                    fetchOrganizationTenure={
                                      fetchOrganizationTenure
                                    }
                                    setFieldValue={setFieldValue}
                                    organizationDetail={organization}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Alert>
                )}
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  disabled={!values?.equityOwnership || !isValid}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

PortfilioOrganizationEdit.propTypes = {
  organization: PropTypes.object.isRequired,
  portfolioId: PropTypes.string.isRequired,
  onOrganizationUpdated: PropTypes.func.isRequired,
};

export default PortfilioOrganizationEdit;
