import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { upload } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import CsvDownloader from "components/CsvDownloader/CsvDownloader";
import "./AbatementProjectsUpload.scss";

const AbatementProjectsUpload = ({
  onProjectsUploaded,
  organization,
  allMACCData,
}) => {
  const subscribedPromises = useRef([]);
  const attachmentRef = useRef();

  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setIsLoading();
    setError();
    setSuccess();
    attachmentRef.current.reset();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const uploadProjects = ({ file, type }) => {
    setError();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    const filePromise = upload(
      `organizations/${organization.id}/import-project`,
      formData
    );

    filePromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        setSuccess(true);
        attachmentRef.current.reset();
        onProjectsUploaded();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setSuccess();
        }
      });
    subscribedPromises.current.push(filePromise);
  };

  const schema = yup.object().shape({
    file: yup
      .mixed()
      .required("A file is required")
      .test(
        "fileFormat",
        "The file should be in CSV format (.csv extension only)",
        (value) => {
          return value && ["text/csv"].includes(value.type);
        }
      ),
  });

  return (
    <>
      <Button variant="primary" onClick={handleShow} size="sm" className="me-1">
        Import / Update Projects
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Import / Update Projects</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            uploadProjects(values);
          }}
          initialValues={{ file: "", type: "" }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            handleChange,
            touched,
            errors,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} ref={attachmentRef}>
              <Modal.Body>
                <ContentDetails contentId={"65a6ac10598f7"} />
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label className="d-flex justify-content-between">
                    <span>Please attach csv file.</span>
                    {!!allMACCData?.length && (
                      <CsvDownloader
                        data={allMACCData}
                        fileName={`${organization?.name} - Project List.csv`}
                        className="downloadProjectsFile"
                        title="Download Projects File (.csv)"
                        iconText="Download existing projects"
                      />
                    )}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("file", file);
                    }}
                    onBlur={handleBlur}
                    isValid={touched.file && !errors.file}
                    accept=".csv"
                    multiple
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.file}
                  </Form.Control.Feedback>
                </Form.Group>

                {isLoading && <Loader />}
                {error && (
                  <Form.Text className="text-danger">
                    {error.data.message}
                  </Form.Text>
                )}
                {success && (
                  <Alert variant="info">Projects uploaded successfully!</Alert>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>

                <Button
                  type="submit"
                  size="sm"
                  disabled={
                    !isValid ||
                    isLoading ||
                    !attachmentRef.current ||
                    !values.file
                  }
                >
                  Import / Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AbatementProjectsUpload.propTypes = {
  onProjectsUploaded: PropTypes.func.isRequired,
};

export default AbatementProjectsUpload;
