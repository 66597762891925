import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import PortfolioDelete from "../PortfolioDelete/PortfolioDelete";
import PortfolioUpdate from "../PortfolioUpdate/PortfolioUpdate";

import { getFormattedEmission } from "utils/StringUtils";

import { TableSortArrows } from "components/Shared/TableComponents";

import "./PortfolioList.scss";

const PortfolioList = ({ portfolios, fetchPortfolios, setSelectedScope }) => {
  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "totalFinancedEmissions",
    totalFinancedEmissions: true,
    sortAsc: false,
  });

  useEffect(() => {
    setSelectedScope("Scope 1+2+3");
  }, []);

  useEffect(() => {
    const data = portfolios?.map((portfolio) => {
      return {
        ...portfolio,
        ...{
          numberOfOrganizations: portfolio?.organizationsCount,
          percentFinancedEmissions: portfolio?.financedEmissionsPercentage,
        },
      };
    });
    data?.sort((a, b) => b.financedEmissions - a.financedEmissions);
    setTableData(data);
  }, [portfolios]);

  const sortTable = (column) => {
    tableData?.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  return (
    <div className="portfolioList">
      <Table
        hover
        size="sm"
        className="my-3 first-column-fixed"
        responsive
        id="portfolioList"
      >
        <thead>
          <tr>
            <th className="table-sorter" onClick={() => sortTable("name")}>
              Name
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"name"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="table-sorter text-end"
              onClick={() => sortTable("numberOfOrganizations")}
            >
              Number of <br />
              Organizations
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"numberOfOrganizations"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="table-sorter text-end"
              onClick={() => sortTable("topDownEmissions")}
            >
              Top-Down <br /> Estimation <br />
              <small>
                (tCO<sub>2</sub>e)
              </small>
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"topDownEmissions"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="table-sorter text-end"
              onClick={() => sortTable("bottomUpEmissions")}
            >
              Bottom-Up GHG <br /> Inventory{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"bottomUpEmissions"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="table-sorter text-end"
              onClick={() => sortTable("financedEmissions")}
            >
              Financed
              <br /> Emissions
              <small>
                (tCO<sub>2</sub>e)
              </small>
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"financedEmissions"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="table-sorter text-end"
              onClick={() => sortTable("totalFinancedEmissions")}
            >
              % of Total <br />
              Financed
              <br />
              Emissions
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"totalFinancedEmissions"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th className="table-sorter" onClick={() => sortTable("updatedAt")}>
              Updated At
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"updatedAt"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((portfolio) => {
            return (
              <tr key={portfolio.id}>
                <td
                  className="first-column fixedTd text-nowrap align-middle text-truncate"
                  title={portfolio?.name}
                >
                  <Link
                    className="text-decoration-none"
                    to={`/portfolios/${portfolio.id}`}
                  >
                    {portfolio.name}
                  </Link>
                </td>
                <td className="text-end text-nowrap align-middle">
                  {portfolio.numberOfOrganizations}
                </td>
                <td className="text-end text-nowrap align-middle">
                  {getFormattedEmission(portfolio.topDownEmissions || 0)}
                </td>
                <td className="text-end text-nowrap align-middle">
                  {getFormattedEmission(portfolio.bottomUpEmissions)}
                </td>

                <td className="text-end text-nowrap align-middle">
                  {getFormattedEmission(portfolio.financedEmissions)}
                </td>
                <td className="text-end text-nowrap align-middle">
                  {portfolio.percentFinancedEmissions
                    ? Intl.NumberFormat("en-us", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(portfolio.percentFinancedEmissions)
                    : 0}
                  %
                </td>
                <td className="ps-3 text-nowrap align-middle">
                  <small>
                    {new Date(portfolio.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </td>

                <td className="text-end text-nowrap align-middle">
                  <PortfolioUpdate
                    portfolio={portfolio}
                    onPortfolioUpdated={() => fetchPortfolios()}
                  />{" "}
                  <PortfolioDelete
                    portfolio={portfolio}
                    onPortfolioDeleted={() => fetchPortfolios()}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

PortfolioList.propTypes = {
  portfolios: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchPortfolios: PropTypes.func.isRequired,
};

export default PortfolioList;
