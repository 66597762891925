import React, { useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";

import { Modal, Button, Form } from "react-bootstrap";
import { destroy } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const CustomGroupsDelete = ({ fetchCustomGroups, groupId, name }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setShow(false);
    setError(null);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup
      .string()
      .oneOf([name], "Must match custom group name")
      .required("Must match custom group name"),
  });

  const deleteGroup = () => {
    setError(null);
    setIsLoading(true);
    const groupPromise = destroy(`opportunity-groups/${groupId}`);
    groupPromise.promise
      .then(() => {
        setError(null);
        setIsLoading(false);
        fetchCustomGroups();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        className="py-0"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-16">delete</span>
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Delete opportunity grouping <i>{name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={{ name: "" }}
          onSubmit={(_) => deleteGroup()}
          validateOnMount
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <p>
                  Are you sure you want to delete this opportunity grouping?
                </p>
                <Form.Group controlId="name" className="mt-3 mb-3">
                  <Form.Label>
                    Please type the name of the project to confirm.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name === name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && (
                  <ErrorHandler error={error} message={error?.data?.message} />
                )}{" "}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  variant="danger"
                  disabled={!isValid}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CustomGroupsDelete;
