import {
  convertNegativeToZero,
  formatNumber,
} from "../../../Forecasting/helper";
import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const ReductionPotentialChart = ({
  data,
  decarbEmissions,
  orgActions,
  showOpportunities,
  organization,
  emission,
  selectedScope = "Scope 1+2+3",
  setAllYearsData,
  scenarioName,
}) => {
  const el = useRef(null);
  const userDefinedColors = [
    "#436D5D",
    "#82A78D",
    "#B7B2AA",
    "#82887E",
    "#19FFBC",
    "#4682B4",
    "#87CEEB",
    "#132B15",
  ];

  const currentPreferences =
    emission?.sbtiConfig &&
    JSON.parse(emission?.sbtiConfig)?.filter((data) => data?.show);

  const targets = currentPreferences?.map((data) =>
    data?.type === "sbti" ? data?.label || data?.title : data?.type
  );

  const filteredScope =
    selectedScope === "Scope 1+2+3"
      ? "allScopes"
      : selectedScope === "Scope 1+2"
      ? "scopeOneAndTwo"
      : selectedScope === "Scope 1"
      ? "scopeOne"
      : selectedScope === "Scope 2"
      ? "scopeTwo"
      : "scopeThree";

  let firstLineData;
  let secondLineData;

  if (selectedScope === "Scope 3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_three_no_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "scope_three_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else if (selectedScope === "Scope 1+2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_and_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1+2+3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "all_scope_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "all_scope_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else {
    firstLineData = "";
    secondLineData = "";
  }

  const filteredTarget = "netZero";

  const filteredSbtiData = emission?.sbtiData?.[filteredTarget]?.calculations;

  const filterNewDataScopeOne = filteredSbtiData?.filter(
    (item) => item?.[firstLineData]
  );

  const filterNewDataScopeTwo = filteredSbtiData?.filter(
    (item) => item?.[secondLineData]
  );

  const scopeOneData = filterNewDataScopeOne?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[firstLineData]),
  ]);
  const scopeTwoData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[secondLineData]),
  ]);

  const filteredUserDefinedData = emission?.settings?.filter((data) =>
    targets?.includes(data?.type)
  );

  const filteredUserDefinedDataMap = filteredUserDefinedData?.map(
    (data) => data?.calculations?.[filteredScope]?.["netZero"]
  );

  const userDefinedNames =
    filteredUserDefinedData?.map((data) => data?.type) || [];

  const userDefinedValues =
    filteredUserDefinedDataMap?.map(
      (data) =>
        data?.map((inData) => [
          inData?.year?.toString(),
          inData?.emission_reduction,
        ]) || []
    ) || [];

  useEffect(() => {
    const _data = data.reduce(
      (
        prev,
        {
          year,
          bau,
          emissionsPathway,
          emissionsScopeOne,
          emissionsScopeTwo,
          emissionsScopeThree,
          emissionsScopeOneReduction,
          emissionsScopeTwoReduction,
          emissionsScopeThreeReduction,
        }
      ) => ({
        year: [...prev.year, year],
        bau: [...prev.bau, bau],
        emissionsPathway: [...prev.emissionsPathway, emissionsPathway],
        emissionsScopeOne: [...prev.emissionsScopeOne, emissionsScopeOne],
        emissionsScopeTwo: [...prev.emissionsScopeTwo, emissionsScopeTwo],
        emissionsScopeThree: [...prev.emissionsScopeThree, emissionsScopeThree],
        emissionsScopeOneReduction: [
          ...prev.emissionsScopeOneReduction,
          emissionsScopeOneReduction,
        ],
        emissionsScopeTwoReduction: [
          ...prev.emissionsScopeTwoReduction,
          emissionsScopeTwoReduction,
        ],
        emissionsScopeThreeReduction: [
          ...prev.emissionsScopeThreeReduction,
          emissionsScopeThreeReduction,
        ],
      }),
      {
        year: [],
        bau: [],
        emissionsPathway: [],
        emissionsScopeOne: [],
        emissionsScopeTwo: [],
        emissionsScopeThree: [],
        emissionsScopeOneReduction: [],
        emissionsScopeTwoReduction: [],
        emissionsScopeThreeReduction: [],
      }
    );

    let option = {
      title: {
        show: false,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "10%",
        bottom: "10%",
        right: 220,
        left: "8%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => {
          return formatNumber((value || 0)?.toFixed(), 0);
        },
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {},
      toolbox: {
        feature: {
          saveAsImage: { name: `${scenarioName} - ${organization?.name}` },
        },
      },
      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: _data.year,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },
      series: [
        ...(scopeOneData && scopeOneData?.length !== 0
          ? [
              {
                name: currentPreferences?.[0]?.title || "SBTi 1.5C Aligned",
                data: scopeOneData,
                type: "line",
                color: "#32CD32",

                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),
        ...(scopeTwoData && scopeTwoData?.length !== 0
          ? [
              {
                name:
                  currentPreferences?.[1]?.title || "SBTi 1.5C S1+2 & WB2C S3",
                data: scopeTwoData,
                type: "line",
                color: "#90EE90",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),
        ...(userDefinedValues.length !== 0
          ? userDefinedValues
              .filter((filteredData) => filteredData)
              .map((data, i) => ({
                name: userDefinedNames[i],
                data: data,
                type: "line",
                color: userDefinedColors[i],
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              }))
          : []),
        {
          name: "BAU Trend",
          data: _data.bau,
          type: "line",
          color: "#FFDC60",
          symbolSize: 7,
          z: 1000,
          lineStyle: {
            width: 5,
          },
        },
        {
          name: "Emissions Pathway",
          data: _data.emissionsPathway,
          type: "line",
          color: "#5470c6",
          symbolSize: 7,
          z: 1000,
          lineStyle: {
            width: 5,
          },
        },
        ...([1, 2, 3].length
          ? [1, 2, 3].reverse().reduce((prev, curr) => {
              return [
                ...prev,
                {
                  name:
                    curr === 1 ? "Scope 1" : curr === 2 ? "Scope 2" : "Scope 3",
                  data: _data[
                    curr === 1
                      ? "emissionsScopeOne"
                      : curr === 2
                      ? "emissionsScopeTwo"
                      : "emissionsScopeThree"
                  ],
                  type: "line",
                  symbolSize: 0,
                  stack: "x",
                  lineStyle: {
                    width: 0,
                  },
                  areaStyle: {
                    type: "default",
                    opacity: 1,
                  },
                  ...{
                    color:
                      curr === 1
                        ? "#C3C5C9"
                        : curr === 2
                        ? "#B7B2AA"
                        : "#858A7F",
                  },
                },
              ];
            }, [])
          : []),
        ...(showOpportunities
          ? orgActions.length
            ? orgActions.reduce((prev, curr) => {
                return [
                  ...prev,
                  {
                    name: curr.name,
                    data: data.reduce(
                      (acc, val) => [...acc, val[`${curr.name}`]],
                      []
                    ),
                    type: "line",
                    symbolSize: 0,
                    stack: "x",
                    ...(curr?.color
                      ? {
                          color: curr?.color,
                        }
                      : prev?.length
                      ? {}
                      : { color: "#ADDFFF" }),
                    lineStyle: {
                      width: 1,
                      ...(curr?.color
                        ? {
                            color: curr?.color,
                          }
                        : prev?.length
                        ? {}
                        : { color: "#ADDFFF" }),
                    },
                    areaStyle: {
                      type: "default",
                      opacity: 1,
                      ...(curr?.color
                        ? {
                            color: curr?.color,
                          }
                        : prev?.length
                        ? {}
                        : { color: "#ADDFFF" }),
                    },
                  },
                ];
              }, [])
            : []
          : decarbEmissions.length
          ? decarbEmissions.reduce((prev, curr) => {
              return curr.scope
                ? [
                    ...prev,
                    {
                      name:
                        curr?.activityType === null
                          ? curr?.scope === 1
                            ? "Scope 1 Reduction"
                            : curr?.scope === 2
                            ? "Scope 2 Reduction"
                            : "Scope 3 Reduction"
                          : curr?.activityType?.label,
                      data: _data[
                        curr?.activityType === null
                          ? curr?.scope === 1
                            ? "emissionsScopeOneReduction"
                            : curr?.scope === 2
                            ? "emissionsScopeTwoReduction"
                            : "emissionsScopeThreeReduction"
                          : `emissions${curr?.activityType?.label.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            )}Reduction`
                      ],
                      type: "line",
                      symbolSize: 0,
                      stack: "x",
                      ...(curr?.activityType === null
                        ? {
                            color:
                              curr?.scope === 1
                                ? "#82A78D"
                                : curr?.scope === 2
                                ? "#5BBE00"
                                : "#446D5D",
                          }
                        : {}),
                      lineStyle: {
                        width: 1,
                        ...(curr?.activityType === null
                          ? {
                              color:
                                curr?.scope === 1
                                  ? "#82A78D"
                                  : curr?.scope === 2
                                  ? "#5BBE00"
                                  : "#446D5D",
                            }
                          : {}),
                      },
                      areaStyle: {
                        type: "default",
                        opacity: 1,
                        ...(curr?.activityType === null
                          ? {
                              color:
                                curr?.scope === 1
                                  ? "#82A78D"
                                  : curr?.scope === 2
                                  ? "#5BBE00"
                                  : "#446D5D",
                            }
                          : {}),
                      },
                    },
                  ]
                : [...prev];
            }, [])
          : []),
      ],
    };
    option.legend = {
      orient: "vertical",
      top: "center",
      selectedMode: false,
      right: 0,
      formatter: (value) =>
        value.length > 25 ? value.substring(0, 25) + "..." : value,
      tooltip: { show: true },
      data: [
        ...option.series
          .filter((item) => !item.name.includes("Scope"))
          .map((item, index) => ({
            name: item.name,
            icon:
              item.name === "BAU Trend" || item.name === "Emissions Pathway"
                ? ""
                : "rect",

            textStyle: {
              fontWeight:
                item.name === "BAU Trend" || item.name === "Emissions Pathway"
                  ? 600
                  : 400,
            },
          })),
        ...option.series
          .filter((item) => item.name.includes("Scope"))
          .reverse()
          .map((item, index) => ({
            name: item.name,
            icon: "rect",

            textStyle: {
              fontWeight: 400,
            },
          })),
      ],
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 1.8,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 1.8,
      });
    }

    window.addEventListener("resize", handleResize);

    chart.on("finished", (params) => {
      const tableData = option?.legend?.data?.map((val) => {
        const series = option?.series?.find((item) => val.name === item.name);
        return {
          name: series?.name,
          data: series?.data,
        };
      });
      const allYearsTableData = [
        ["", ..._data.year],
        ...tableData.map((item) => {
          if (Array.isArray(item.data[0])) {
            return [
              item.name,
              ..._data.year.map((year) => {
                const entry = item.data.find(
                  (entry) => entry && entry[0] === year.toString()
                );
                return entry
                  ? entry[1] === null ||
                    entry[1] === undefined ||
                    Number.isNaN(entry[1])
                    ? "N/A"
                    : entry[1]
                  : "N/A";
              }),
            ];
          } else {
            return [
              item.name,
              ...item.data.map((value) =>
                value === null || value === undefined || Number.isNaN(value)
                  ? "N/A"
                  : value
              ),
            ];
          }
        }),
      ];
      // if (
      //   setAllYearsData &&
      //   JSON.stringify(allYearsTableData) !== JSON.stringify(allYearsData)
      // ) {
      setAllYearsData(allYearsTableData);
      // }
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [data, decarbEmissions, showOpportunities, orgActions, scenarioName]);
  return (
    <div className="ReductionPotentialChart" style={{ marginTop: 20 }}>
      <div className="w-100" ref={el} />
    </div>
  );
};

export default ReductionPotentialChart;
