import React, { useState, useEffect } from "react";
import { Form, Button, Collapse, Alert } from "react-bootstrap";

import { getFormula, UnitsDataSet } from "./UnitDataset";
import { filter } from "lodash";

import NumberFormat from "react-number-format";

const UnitConverter = ({ setFieldValue, emissionUnit, values }) => {
  const [show, setShow] = useState(false);
  const [unitList, setUnitList] = useState([]);

  const [amount, setAmount] = useState(values?.rawAmount);
  const [amountUnit, setAmountUnit] = useState(
    ["t", "metric ton"].includes(values?.rawUnit)
      ? "tonne"
      : ["short ton"].includes(values?.rawUnit)
      ? "ton (US)"
      : ["m3"].includes(values?.rawUnit)
      ? "cubic metre"
      : ["gallon"].includes(values?.rawUnit)
      ? "US gallon"
      : ["litres", "Litres"].includes(values?.rawUnit)
      ? "litre"
      : values?.rawUnit
  );

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  function getUnitDatasetByEmissionUnit(unitsDataSet, emissionUnit) {
    const lowercaseKey = emissionUnit.toLowerCase();
    for (let i = 0; i < unitsDataSet.length; i++) {
      const objectKeys = Object.keys(unitsDataSet[i]);
      const foundKey = objectKeys.find((objKey) =>
        new RegExp(`^${escapeRegExp(objKey)}$`, "i").test(lowercaseKey)
      );
      if (foundKey) {
        return unitsDataSet[i];
        // if (lowercaseKey !== "gj" && i === 0 && "TJ" in unitsDataSet[i]) {
        //   const { TJ, ...rest } = unitsDataSet[i];
        //   return { ...rest };
        // } else {
        //   return unitsDataSet[i];
        // }
      }
    }
    return null;
  }

  useEffect(() => {
    if (emissionUnit === amountUnit) {
      setAmountUnit();
      setAmount();
    }
    let checkForEmissionUnit = ["cubic metre", "cubic metres"].includes(
      emissionUnit
    )
      ? "cubic metre"
      : ["kWh (Net CV)", "kWh (Gross CV)"].includes(emissionUnit)
      ? "kWh"
      : ["passenger.km", "person.km"].includes(emissionUnit)
      ? "km"
      : [
          "vehicle-mile",
          "ton-mile",
          "passenger-mile",
          "vehicle.mile",
          "ton (US).mile",
          "passenger.mile",
        ].includes(emissionUnit)
      ? "mile"
      : ["litres"].includes(emissionUnit)
      ? "litre"
      : ["t", "Tonne", "tonnes", "metric ton"].includes(emissionUnit)
      ? "tonne"
      : ["short ton"].includes(emissionUnit)
      ? "ton (US)"
      : ["gallon"].includes(emissionUnit)
      ? "US gallon"
      : emissionUnit?.toLowerCase();
    let getDataSet = [
      getUnitDatasetByEmissionUnit(UnitsDataSet, checkForEmissionUnit),
    ];
    let newData = [];
    if (getDataSet?.length !== 0) {
      let key = emissionUnit;
      newData = getDataSet?.map(({ [key]: value, ...item }) => {
        return item;
      });
    }
    setUnitList(
      newData?.length !== 0
        ? Object.values(newData?.[0]).filter((unitData) =>
            ["cubic metre", "cubic metres"].includes(emissionUnit)
              ? unitData !== "cubic metre"
              : ["kWh (Net CV)", "kWh (Gross CV)"].includes(emissionUnit)
              ? unitData !== "kWh"
              : ["passenger.km", "person.km"].includes(emissionUnit)
              ? unitData !== "km"
              : [
                  "vehicle-mile",
                  "ton-mile",
                  "passenger-mile",
                  "vehicle.mile",
                  "ton (US).mile",
                  "passenger.mile",
                ].includes(emissionUnit)
              ? unitData !== "mile"
              : ["Litre", "litres"].includes(emissionUnit)
              ? unitData !== "litre"
              : ["Tonne", "tonnes", "metric ton"].includes(emissionUnit)
              ? unitData !== "tonne"
              : ["short ton"].includes(emissionUnit)
              ? unitData !== "ton (US)"
              : ["gallon"].includes(emissionUnit)
              ? unitData !== "US gallon"
              : unitData
          )
        : ["No Data Found"]
    );
  }, [emissionUnit]);

  return (
    <div className="pt-3">
      <Button
        variant="link"
        onClick={() => {
          setShow(!show);
        }}
        className="p-0 mt-n3"
      >
        Need help converting quantity to {emissionUnit}
      </Button>
      <Collapse in={show}>
        <div>
          <Alert
            variant="dark"
            className="bg-light"
            dismissible
            onClose={() => {
              setAmount();
              setAmountUnit();
              setShow(!show);
              (!amount || amount !== 0) && setFieldValue("rawUnit", "");
            }}
          >
            <Form.Group controlId="unit" className="my-3">
              <Form.Label>Select the unit to convert from</Form.Label>

              <Form.Select
                name="amountUnit"
                value={
                  ["cubic metre", "cubic metres"].includes(amountUnit)
                    ? "cubic metre"
                    : ["t", "metric ton"].includes(amountUnit)
                    ? "tonne"
                    : ["short ton"].includes(amountUnit)
                    ? "ton (US)"
                    : ["gallon"].includes(amountUnit)
                    ? "US gallon"
                    : amountUnit
                }
                onChange={(ev) => {
                  setAmountUnit(ev.target.value);
                  setFieldValue("rawUnit", ev.target.value);
                }}
              >
                <option>Select unit of measurement</option>
                {unitList?.map((item) => (
                  <option
                    key={
                      ["t", "metric ton"].includes(item)
                        ? "tonne"
                        : ["short ton"].includes(item)
                        ? "ton (US)"
                        : ["gallon"].includes(item)
                        ? "US gallon"
                        : item
                    }
                    value={
                      ["t", "metric ton"].includes(item)
                        ? "tonne"
                        : ["short ton"].includes(item)
                        ? "ton (US)"
                        : ["gallon"].includes(item)
                        ? "US gallon"
                        : item
                    }
                  >
                    {["t", "metric ton"].includes(item)
                      ? "tonne"
                      : ["short ton"].includes(item)
                      ? "ton (US)"
                      : ["gallon"].includes(item)
                      ? "US gallon"
                      : item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {amountUnit && (
              <Form.Group controlId="resource-amount">
                <Form.Label>
                  Provide the quantity of resource used in {amountUnit}
                </Form.Label>

                <NumberFormat
                  customInput={Form.Control}
                  name="amount"
                  value={amount}
                  thousandSeparator={true}
                  onValueChange={(numberItem) => {
                    setAmount(numberItem.value);
                    setFieldValue("rawAmount", numberItem.value);
                  }}
                  placeholder={"Enter amount of resource"}
                  onBlur={() => {}}
                />
              </Form.Group>
            )}
            {(amount || amount === 0) && (
              <p className="my-3">
                {amount} {amountUnit} is equivalent to{" "}
                <big>
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 10,
                    minimumFractionDigits: 2,
                  }).format(
                    getFormula(
                      ["cubic metre", "cubic metres"].includes(amountUnit)
                        ? "cubic metre"
                        : ["t", "metric ton"].includes(amountUnit)
                        ? "tonne"
                        : ["short ton"].includes(amountUnit)
                        ? "ton (US)"
                        : ["gallon"].includes(amountUnit)
                        ? "US gallon"
                        : amountUnit,
                      emissionUnit,
                      amount
                    )
                  )}
                </big>{" "}
                {emissionUnit}
              </p>
            )}

            <div className="my-3 text-end">
              <>
                <Button
                  size="sm"
                  variant="link"
                  onClick={(e) => {
                    e.preventDefault();
                    setAmount();
                    setAmountUnit();
                    setShow(!show);
                    (!amount || amount !== 0) && setFieldValue("rawUnit", "");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setFieldValue(
                      "amount",
                      getFormula(
                        ["cubic metre", "cubic metres"].includes(amountUnit)
                          ? "cubic metre"
                          : ["t", "metric ton"].includes(amountUnit)
                          ? "tonne"
                          : ["short ton"].includes(amountUnit)
                          ? "ton (US)"
                          : ["gallon"].includes(amountUnit)
                          ? "US gallon"
                          : amountUnit,
                        emissionUnit,
                        amount
                      )
                    );
                    setAmount();
                    setAmountUnit();
                    setShow(!show);
                  }}
                  disabled={!amount || !amountUnit}
                >
                  Use{" "}
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 10,
                    minimumFractionDigits: 2,
                  }).format(
                    amount
                      ? getFormula(
                          ["cubic metre", "cubic metres"].includes(amountUnit)
                            ? "cubic metre"
                            : ["t", "metric ton"].includes(amountUnit)
                            ? "tonne"
                            : ["short ton"].includes(amountUnit)
                            ? "ton (US)"
                            : ["gallon"].includes(amountUnit)
                            ? "US gallon"
                            : amountUnit,
                          emissionUnit,
                          amount
                        )
                      : 0
                  )}{" "}
                  {emissionUnit}
                </Button>
              </>
            </div>
          </Alert>
        </div>
      </Collapse>
    </div>
  );
};

export default UnitConverter;
