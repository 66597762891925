import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Modal,
  Button,
  Form,
  Tabs,
  Tab,
  Table,
  Collapse,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as yup from "yup";
import { Formik, Field } from "formik";
import NumberFormat from "react-number-format";
import moment from "moment";
import { post, get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import {
  projectTypesMap,
  projectDataConfidenceMap,
  projectCustomTypesMap,
  projectCombinedStatusMap,
  projectScopeMap,
} from "../constants";
import "../Abatement.scss";
import SelectInputFormik from "components/SelectInput/SelectInput";

const AbatementProjectCreate = ({ sites, onAbatementProjectCreated }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const { organizationId } = useParams();
  const [emissions, setEmissions] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("step-1");
  const [error, setError] = useState();
  const [isYearly, setIsYearly] = useState(false);
  const [startYear, setStartYear] = useState("");
  const [projectLifetime, setProjectLifetime] = useState("");
  const [yearlyEmissions, setYearlyEmissions] = useState([]);
  const [oppurtunityGroups, setOppurtunityGroups] = useState([]);
  const [selectedOppurtunityGroup, setSelectedOppurtunityGroup] = useState();
  const [isCustom, setIsCustom] = useState(false);
  const [useCustom, setUseCustom] = useState(false);
  const [annualCarbonEmissionReduction, setAnnualCarbonEmissionReduction] =
    useState();
  const [annualSavings, setAnnualSavings] = useState();
  const [customField, setCustomField] = useState({
    label: "",
    type: "",
    value: "",
  });
  const [customFields, setCustomFields] = useState([]);
  const [existingCustomFields, setExistingCustomFields] = useState([]);
  const [useCustomFields, setUseCustomFields] = useState({
    label: "",
    type: "",
    value: "",
  });
  const [selectedSite, setSelectedSite] = useState([]);
  const [showCustomLocation, setShowCustomLocation] = useState(false);
  const [customLocations, setCustomLocations] = useState([]);
  const [customLocation, setCustomLocation] = useState("");
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setIsCustom(false);
    setUseCustom(false);
    setIsYearly(false);
    setCustomFields([]);
    setShowCustomLocation(false);
    setCustomLocation("");
    setCustomLocations([]);
    setStep("step-1");
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    //General Info
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    location: yup
      .string()
      .min(1, "Location name should be more than 1 characters!")
      .max(255, "Location name is too long!")
      .required(),
    status: yup
      .number()
      .oneOf([1, 2, 3, 4, 5, 6, 7], "Status is a required field")
      .required(),
    oppurtunityGroup: yup.string(),
    projectSize: yup.number(),
    unit: yup.string(),
    maxSize: yup.number(),
    type: yup.string().label("Abatement Opportunity Sub-Group"),

    //NPV Attributes
    initialInvestment: yup
      .number()
      .label("Initial Investment")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    annualSavings: yup
      .number()
      .label("Annual Cashflow")
      .min(-9999999999999)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    discountRate: yup
      .number()
      .label("Discount Rate")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    disposalCost: yup
      .number()
      .label("Discount Cost")
      .min(0)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .required(),
    emissionSavingsStartDate: yup
      .date()
      .label("Emission Savings Start Date")
      .required(),
    projectLifetime: yup
      .number()
      .label("Project Lifetime")
      .min(1)
      .max(
        9999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(9999999999999)}.`
      )
      .integer()
      .required(),
    annualCarbonEmissionReduction: yup
      .number()
      .label("Annual Carbon Emission Reduction")
      .min(0)
      .required(),
  });

  const checkAnnualCarbonEmissionReduction = (value, scope) => {
    let maxEmission = 9999999999999;

    if (scope === 1 || scope === "1") {
      maxEmission = emissions?.emissionsScopeOne || 0;
    } else if (scope === 2 || scope === "2") {
      maxEmission = emissions?.emissionsScopeTwo || 0;
    } else if (scope === 3 || scope === "3") {
      maxEmission = emissions?.emissionsScopeThree || 0;
    } else {
      maxEmission =
        (emissions?.emissionsScopeOne || 0) +
        (emissions?.emissionsScopeTwo || 0) +
        (emissions?.emissionsScopeThree || 0);
    }
    return !value || value <= maxEmission;
  };

  const createProject = ({
    name,
    location,
    status,
    oppurtunityGroup,
    maxSize,
    type,
    description,
    annualSavings,
    projectLifetime,
    discountRate,
    disposalCost,
    initialInvestment,
    annualCarbonEmissionReduction,
    emissionSavingsStartDate,
    autoRenew,
    site,
    dataConfidence,
    projectSize,
    unit,
    scope,
  }) => {
    setError(null);
    setIsLoading(true);

    const organizationPromise = post(
      `organizations/${organizationId}/abatement-projects`,
      {
        name: name,
        location: location,
        data_confidence: parseInt(dataConfidence),
        project_status: parseInt(status),
        opportunity_group_id: oppurtunityGroup,
        projectMaxSize: maxSize,
        ...(type?.toString().length === 1
          ? {
              project_type: parseInt(type),
            }
          : {
              decarb_action_category_id: type,
            }),
        description: description,
        initial_investment: initialInvestment,
        annual_savings: annualSavings,
        project_lifetime: projectLifetime,
        discount_rate: discountRate,
        disposal_cost: disposalCost,
        annual_carbon_emission_reduction: annualCarbonEmissionReduction,
        emission_savings_start_date: moment(emissionSavingsStartDate).format(
          "MM/DD/YYYY"
        ),
        auto_renew: autoRenew,
        site_id: site,
        projectSize,
        unit,
        customFields: customFields?.map((data) => ({
          ...data,
          type: data.type.toLowerCase(),
        })),
        projectYearData: isYearly ? yearlyEmissions : [],
        scope,
      }
    );

    organizationPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onAbatementProjectCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  const addCustomLocation = () => {
    setError(null);
    setIsLocationLoading(true);
    const addTagPromise = post(
      `organizations/${organizationId}/site-custom-locations`,
      {
        name: customLocation,
      }
    );
    addTagPromise.promise
      .then((response) => {
        setCustomLocations(response?.data);
        setIsLocationLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLocationLoading(false);
        }
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  useEffect(() => {
    setIsLocationLoading(true);
    const emissionsPromise = get(`organizations/${organizationId}/emissions`);
    const groupPromise = get(`organizations/${organizationId}/groups`);
    const customFieldPromise = get(
      `organizations/${organizationId}/custom-fields`
    );
    const customLocationPromise = get(
      `organizations/${organizationId}/site-custom-locations`
    );

    Promise.all([
      emissionsPromise.promise,
      groupPromise.promise,
      customFieldPromise.promise,
      customLocationPromise.promise,
    ])
      .then((responses) => {
        setError(null);
        setIsLoading(false);
        setIsLocationLoading(false);
        setEmissions(responses[0].data[0] || null);
        setOppurtunityGroups(responses[1].data);
        setExistingCustomFields(responses[2].data);
        setCustomLocations(responses[3].data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setIsLocationLoading(false);
        }
      });
    subscribedPromises.current.push(emissionsPromise);
  }, [organizationId]);

  useEffect(() => {
    setStartYear(new Date()?.getFullYear() + 1);
    setProjectLifetime(10);
  }, [show]);

  useEffect(() => {
    if (isYearly) {
      let totalYears = Array.from(
        { length: Number(projectLifetime) },
        (_, index) => Number(startYear) + index
      );
      const emissionsByYear = totalYears?.map((data) => ({
        year: data,
        annualSavings: annualSavings || 0,
        annualCarbonEmissionReduction: annualCarbonEmissionReduction || 0,
      }));
      setYearlyEmissions(emissionsByYear);
    }
  }, [isYearly, startYear, projectLifetime, show]);

  useEffect(() => {
    setCustomFields(existingCustomFields);
  }, [existingCustomFields]);

  const deleteModule = (index) => {
    const data = [...customFields];
    data.splice(index, 1);
    setCustomFields([...data]);
  };

  return (
    <>
      <Button size="sm" onClick={handleShow} className="float-end me-1">
        Add New Project
      </Button>
      <Modal show={show} backdrop={"static"} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Add Abatement Project</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createProject(values)}
          initialValues={{
            name: "",
            description: "",
            location: "",
            status: 6,
            oppurtunityGroup: "",
            projectSize: "",
            unit: "",
            maxSize: "",
            type: "",
            site: "",
            dataConfidence: "",
            initialInvestment: "",
            annualSavings: "",
            discountRate: 8,
            disposalCost: 0,
            emissionSavingsStartDate: `${new Date().getFullYear() + 1}-01-01`,
            projectLifetime: 10,
            annualCarbonEmissionReduction: "",
            autoRenew: 1,
            scope: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={step}
                  onSelect={(k) => setStep(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="step-1"
                    title={
                      <>
                        General Information{" "}
                        {((errors.name && touched.name) ||
                          (errors.location && touched.location) ||
                          (errors.status && touched.status) ||
                          (errors.type && touched.type)) && (
                          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        )}
                      </>
                    }
                  >
                    <Form.Group controlId="projectName">
                      <Form.Label>
                        Name
                        <sup className="text-danger ps-1 fs-3 top-0">
                          *
                        </sup>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.name}
                        isInvalid={errors.name && touched.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="oppurtunityGroup" className="my-3">
                      <Form.Label>Abatement Opportunity Grouping</Form.Label>
                      <Form.Select
                        name="oppurtunityGroup"
                        value={values.oppurtunityGroup}
                        onChange={(ev) => {
                          setFieldValue("oppurtunityGroup", ev.target.value);
                          setSelectedOppurtunityGroup(ev.target.value);
                          if (values?.type) {
                            setFieldValue(
                              "type",
                              oppurtunityGroups
                                ?.filter(
                                  (oppurtunityGroup) =>
                                    oppurtunityGroup?.id === ev.target.value
                                )?.[0]
                                ?.categories?.sort((a, b) =>
                                  a?.name
                                    ?.toLowerCase()
                                    .localeCompare(b?.name?.toLowerCase())
                                )?.[0]?.id
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        isValid={values.oppurtunityGroup}
                        isInvalid={
                          errors.oppurtunityGroup && touched.oppurtunityGroup
                        }
                      >
                        <option value={""} disabled={values.oppurtunityGroup}>
                          Select the opportunity group
                        </option>
                        {oppurtunityGroups
                          ?.sort((a, b) =>
                            a?.name
                              ?.toLowerCase()
                              .localeCompare(b?.name?.toLowerCase())
                          )
                          ?.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.oppurtunityGroup}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="projectType" className="my-3">
                      <Form.Label>Abatement Opportunity Sub-Group</Form.Label>
                      <Form.Select
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.type}
                        isInvalid={errors.type && touched.type}
                      >
                        <option value={""} disabled={values.type}>
                          Select the abatement opportunity sub-group
                        </option>
                        {values?.oppurtunityGroup
                          ? oppurtunityGroups
                              ?.filter(
                                (oppurtunityGroup) =>
                                  oppurtunityGroup?.id ===
                                  selectedOppurtunityGroup
                              )?.[0]
                              ?.categories?.sort((a, b) =>
                                a?.name
                                  ?.toLowerCase()
                                  .localeCompare(b?.name?.toLowerCase())
                              )
                              ?.map(({ id, name }) => (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ))
                          : projectTypesMap
                              ?.sort((a, b) =>
                                a?.value
                                  ?.toLowerCase()
                                  .localeCompare(b?.value?.toLowerCase())
                              )
                              ?.map(({ key, value }) => (
                                <option key={key} value={key}>
                                  {value}
                                </option>
                              ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.type}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {isLocationLoading ? (
                      <Loader />
                    ) : error ? (
                      <ErrorHandler error={error} />
                    ) : (
                      <>
                        <Form.Group
                          controlId="projectLocation"
                          className="mb-2"
                        >
                          <Form.Label>
                            Location{" "}
                            <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                          </Form.Label>
                          <Field
                            name="location"
                            value={values.location}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {({ field, form }) => (
                              <SelectInputFormik
                                name={field.name}
                                values={values}
                                options={customLocations
                                  ?.sort((a, b) => a.localeCompare(b))
                                  ?.map((loc) => ({
                                    label: loc,
                                    value: loc,
                                  }))}
                                isLoading={isLoading}
                                placeholder="Select the project location"
                                form={form}
                                field={field}
                              />
                            )}
                          </Field>
                          {values.location &&
                          values.site &&
                          !errors.location &&
                          selectedSite?.[0]?.address !== values?.location ? (
                            <Form.Text type="invalid" className="text-danger">
                              This location is different from the location of
                              selected site.
                            </Form.Text>
                          ) : (
                            <Form.Text type="invalid" className="text-danger">
                              {touched.location && errors.location}
                            </Form.Text>
                          )}
                        </Form.Group>
                        <div className="py-1">
                          <Button
                            variant="link"
                            onClick={() => {
                              setShowCustomLocation(!showCustomLocation);
                            }}
                            className="p-0 mt-n3"
                          >
                            Create a custom location
                          </Button>
                          <Collapse in={showCustomLocation}>
                            <div>
                              <Alert
                                variant="dark"
                                className="bg-light"
                                dismissible
                                onClose={() =>
                                  setShowCustomLocation(!showCustomLocation)
                                }
                              >
                                <Form.Control
                                  type="text"
                                  name="tag"
                                  className="mt-4"
                                  value={customLocation}
                                  placeholder="Enter location"
                                  onChange={(e) =>
                                    setCustomLocation(e.target.value)
                                  }
                                />
                                <div className="my-3 text-end">
                                  <>
                                    <Button
                                      size="sm"
                                      variant="link"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setCustomLocation("");
                                        setShowCustomLocation(
                                          !showCustomLocation
                                        );
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type="submit"
                                      size="sm"
                                      disabled={!customLocation}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        addCustomLocation();
                                        setCustomLocation("");
                                        setShowCustomLocation(
                                          !showCustomLocation
                                        );
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </>
                                </div>
                              </Alert>
                            </div>
                          </Collapse>
                        </div>
                      </>
                    )}
                    <Form.Group controlId="site" className="mt-2 mb-3">
                      <Form.Label>
                        Site
                        <span className="text-muted ms-2">(Optional)</span>
                      </Form.Label>
                      <Field
                        name="site"
                        value={values.site}
                        isValid={values.site}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {({ field, form }) => (
                          <SelectInputFormik
                            name={field.name}
                            values={values}
                            options={sites
                              ?.sort((a, b) =>
                                a?.name
                                  ?.toLowerCase()
                                  .localeCompare(b?.name?.toLowerCase())
                              )
                              ?.map(({ id, name }) => ({
                                label: name,
                                value: id,
                              }))}
                            onChange={(option) => {
                              if (option) {
                                setFieldValue("site", option.value);
                                setSelectedSite(
                                  sites?.filter(
                                    (site) => site.id === option.value
                                  )
                                );
                              } else {
                                setFieldValue("site", "");
                                setSelectedSite([]);
                              }
                              return option;
                            }}
                            isLoading={isLoading}
                            placeholder="Select the project site"
                            form={form}
                            field={field}
                          />
                        )}
                      </Field>
                    </Form.Group>

                    <Form.Group controlId="projectScope" className="my-3">
                      <Form.Label>
                        Scope
                        <span className="text-muted ms-2">(Optional)</span>
                      </Form.Label>
                      <Form.Select
                        name="scope"
                        value={values.scope}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.scope}
                        isInvalid={errors.scope && touched.scope}
                      >
                        <option value={""} disabled={values.scope}>
                          Select the scope
                        </option>
                        {projectScopeMap.map(({ key, value }) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.scope}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="projectStatus" className="my-3">
                      <Form.Label>
                        Status
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <Form.Select
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.status}
                        isInvalid={errors.status && touched.status}
                      >
                        <option value={""} disabled={values.status}>
                          Select the project evalution status
                        </option>
                        {projectCombinedStatusMap
                          .sort((a, b) => a.order - b.order)
                          .map(({ key, value }) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="projectDescription" className="my-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.description}
                        isInvalid={errors.description && touched.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="projectLifetime" className="my-3">
                      <Form.Label>
                        Project Lifetime (Yrs)
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="projectLifetime"
                        value={values.projectLifetime}
                        onChange={(ev) => {
                          setFieldValue("projectLifetime", ev.target.value);
                          setProjectLifetime(ev.target.value);
                        }}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.projectLifetime}
                        isInvalid={
                          errors.projectLifetime && touched.projectLifetime
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.projectLifetime}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="projectDataConfidence"
                      className="my-3"
                    >
                      <Form.Label>Data Confidence</Form.Label>
                      <Form.Select
                        name="dataConfidence"
                        value={values.dataConfidence}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.dataConfidence}
                        isInvalid={
                          errors.dataConfidence && touched.dataConfidence
                        }
                      >
                        <option value={""} disabled={values.dataConfidence}>
                          Select the project data confidence
                        </option>
                        {projectDataConfidenceMap.map(({ key, value }) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.dataConfidence}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="emissionSavingsStartDate"
                      className="my-3"
                    >
                      <Form.Label>
                        Emission Savings Start Date
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="emissionSavingsStartDate"
                        value={values.emissionSavingsStartDate}
                        onChange={(ev) => {
                          setFieldValue(
                            "emissionSavingsStartDate",
                            ev.target.value
                          );
                          setStartYear(new Date(ev.target.value).getFullYear());
                        }}
                        onBlur={handleBlur}
                        isValid={values.emissionSavingsStartDate}
                        isInvalid={
                          errors.emissionSavingsStartDate &&
                          touched.emissionSavingsStartDate
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionSavingsStartDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="projectSize" className="my-3">
                      <Form.Label>
                        Size of the Project (Scaling Unit)
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="projectSize"
                        value={values.projectSize}
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.projectSize}
                        isInvalid={errors.projectSize && touched.projectSize}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.projectSize}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="projectLifetime" className="my-3">
                      <Form.Label>Unit of measurement</Form.Label>
                      <Form.Control
                        type="text"
                        name="unit"
                        value={values.unit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.unit}
                        isInvalid={errors.unit && touched.unit}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.unit}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="maxSize" className="my-3">
                      <Form.Label>Max Size</Form.Label>
                      <Form.Control
                        type="number"
                        name="maxSize"
                        value={values.maxSize}
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.maxSize}
                        isInvalid={errors.maxSize && touched.maxSize}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.maxSize}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-3" controlId="autoRenew">
                      <Form.Check
                        type="checkbox"
                        label="Auto renew project"
                        name="autoRenew"
                        value={values.autoRenew}
                        checked={!!values.autoRenew}
                        onChange={() => {
                          if (!!values.autoRenew) setFieldValue("autoRenew", 0);
                          else setFieldValue("autoRenew", 1);
                        }}
                      />
                    </Form.Group>
                  </Tab>
                  <Tab
                    eventKey="step-2"
                    title={
                      <>
                        NPV (Net Present Value)
                        {((errors.projectLifetime && touched.projectLifetime) ||
                          (errors.discountRate && touched.discountRate) ||
                          (errors.disposalCost && touched.disposalCost) ||
                          (errors.annualSavings && touched.annualSavings) ||
                          (errors.initialInvestment &&
                            touched.initialInvestment)) && (
                          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        )}
                      </>
                    }
                  >
                    <Form.Group controlId="initialInvestment" className="my-3">
                      <Form.Label>
                        Initial Investment ($)
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="initialInvestment"
                        value={values.initialInvestment}
                        prefix={"$"}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("initialInvestment", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.initialInvestment}
                        isInvalid={
                          errors.initialInvestment && touched.initialInvestment
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.initialInvestment}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="annualSavings" className="mt-3">
                      <Form.Label>
                        Annual Cashflow ($)
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip>
                              This can be negative or positive. Negative
                              represents net cost and positive net saving.
                            </Tooltip>
                          }
                        >
                          <span className="material-icons-outlined md-18">
                            info
                          </span>
                        </OverlayTrigger>
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="annualSavings"
                        value={values.annualSavings}
                        prefix={"$"}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("annualSavings", numberItem.value);
                          setAnnualSavings(numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.annualSavings}
                        isInvalid={
                          errors.annualSavings && touched.annualSavings
                        }
                        disabled={isYearly}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.annualSavings}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mt-1">
                      <Form.Check
                        type="checkbox"
                        label={
                          isYearly ? (
                            <span
                              onClick={() => setStep("step-3")}
                              className="yearLabel"
                              tabIndex={0}
                              role="button"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  setStep("step-3");
                                }
                              }}
                            >
                              Click here to enter year by year cashflow
                            </span>
                          ) : (
                            <span>Year by year cashflow</span>
                          )
                        }
                        value={isYearly}
                        checked={!!isYearly}
                        onChange={() => {
                          if (!!isYearly) setIsYearly(false);
                          else setIsYearly(true);
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="discountRate" className="my-3">
                      <Form.Label>
                        Discount Rate (%)
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="discountRate"
                        value={values.discountRate}
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        onBlur={handleBlur}
                        isValid={values.discountRate}
                        isInvalid={errors.discountRate && touched.discountRate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.discountRate}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="disposalCost" className="my-3">
                      <Form.Label>
                        Disposal Cost ($)
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="disposalCost"
                        value={values.disposalCost}
                        prefix={"$"}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("disposalCost", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={!errors.disposalCost}
                        isInvalid={errors.disposalCost && touched.disposalCost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.disposalCost}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Tab>
                  <Tab
                    eventKey="step-3"
                    title={
                      <>
                        Lifetime Emissions Reduction
                        {errors.annualCarbonEmissionReduction &&
                          touched.annualCarbonEmissionReduction && (
                            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                          )}
                      </>
                    }
                  >
                    <Form.Group
                      controlId="annualCarbonEmissionReduction"
                      className="my-3"
                    >
                      <Form.Label>
                        Annual Emissions Reduction (tCO<sub>2</sub>e)
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="annualCarbonEmissionReduction"
                        value={values.annualCarbonEmissionReduction}
                        customInput={Form.Control}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue(
                            "annualCarbonEmissionReduction",
                            numberItem.value
                          );
                          setAnnualCarbonEmissionReduction(numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.annualCarbonEmissionReduction}
                        isInvalid={
                          errors.annualCarbonEmissionReduction &&
                          touched.annualCarbonEmissionReduction
                        }
                        disabled={isYearly}
                      />
                      {errors?.annualCarbonEmissionReduction ? (
                        <Form.Text type="invalid" className="text-danger">
                          {errors?.annualCarbonEmissionReduction}
                        </Form.Text>
                      ) : (
                        values?.annualCarbonEmissionReduction &&
                        !checkAnnualCarbonEmissionReduction(
                          values?.annualCarbonEmissionReduction,
                          values?.scope
                        ) && (
                          <Form.Text type="invalid" className="text-danger">
                            The emissions reduction entered might have exceeded
                            the reduction potential
                          </Form.Text>
                        )
                      )}
                    </Form.Group>
                    <Form.Group className="my-1" controlId="isYearly">
                      <Form.Check
                        type="checkbox"
                        label="Year by year emissions reduction"
                        name="isYearly"
                        value={isYearly}
                        checked={!!isYearly}
                        onChange={() => {
                          if (!!isYearly) setIsYearly(false);
                          else setIsYearly(true);
                        }}
                      />
                    </Form.Group>
                    {isYearly &&
                      values?.projectLifetime &&
                      values?.emissionSavingsStartDate && (
                        <Table size="sm" responsive>
                          <thead>
                            <tr>
                              <th>Year</th>
                              <th>Net Cashflow ($)</th>
                              <th>
                                Emissions Reduction (tCO<sub>2</sub>e)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {yearlyEmissions?.map((data) => (
                              <tr key={data?.year}>
                                <td>{data?.year}</td>
                                <td>
                                  <NumberFormat
                                    name="annualCashflow"
                                    value={data?.annualSavings}
                                    prefix={"$"}
                                    customInput={Form.Control}
                                    thousandSeparator={true}
                                    onValueChange={(numberItem) => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualSavings: numberItem.value,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                      setFieldValue(
                                        "annualSavings",
                                        yearlyEmissions
                                          .map((item) => {
                                            if (item.year === data?.year) {
                                              return Number(numberItem.value);
                                            } else {
                                              return item?.annualSavings;
                                            }
                                          })
                                          ?.reduce(
                                            (accumulator, currentValue) =>
                                              Number(accumulator) +
                                              Number(currentValue),
                                            0
                                          ) / values?.projectLifetime
                                      );
                                    }}
                                    onBlur={() => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualSavings:
                                                item.annualSavings === ""
                                                  ? 0
                                                  : item.annualSavings,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                    }}
                                    isValid={
                                      data?.annualSavings === 0 ||
                                      data?.annualSavings
                                    }
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    name="annualReductions"
                                    value={data?.annualCarbonEmissionReduction}
                                    customInput={Form.Control}
                                    thousandSeparator={true}
                                    onValueChange={(numberItem) => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualCarbonEmissionReduction:
                                                numberItem.value,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                      setFieldValue(
                                        "annualCarbonEmissionReduction",
                                        yearlyEmissions
                                          .map((item) => {
                                            if (item.year === data?.year) {
                                              return Number(numberItem.value);
                                            } else {
                                              return item?.annualCarbonEmissionReduction;
                                            }
                                          })
                                          ?.reduce(
                                            (accumulator, currentValue) =>
                                              Number(accumulator) +
                                              Number(currentValue),
                                            0
                                          ) / values?.projectLifetime
                                      );
                                    }}
                                    onBlur={() => {
                                      setYearlyEmissions(
                                        yearlyEmissions.map((item) => {
                                          if (item.year === data?.year) {
                                            return {
                                              ...item,
                                              annualCarbonEmissionReduction:
                                                item.annualCarbonEmissionReduction ===
                                                ""
                                                  ? 0
                                                  : item.annualCarbonEmissionReduction,
                                            };
                                          } else {
                                            return item;
                                          }
                                        })
                                      );
                                    }}
                                    isValid={
                                      data?.annualCarbonEmissionReduction ===
                                        0 || data?.annualCarbonEmissionReduction
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                  </Tab>
                  <Tab eventKey="step-4" title={<>User Defined Data Fields</>}>
                    <>
                      <Button
                        variant="link"
                        onClick={() => {
                          setIsCustom(!isCustom);
                        }}
                        className="p-0 my-2"
                      >
                        Add New Custom Field
                      </Button>
                      <Collapse in={isCustom}>
                        <div>
                          <Alert
                            variant="dark"
                            className="bg-light"
                            dismissible
                            onClose={() => {
                              setIsCustom(!isCustom);
                              setCustomField({
                                label: "",
                                type: "",
                                value: "",
                              });
                            }}
                          >
                            <Form.Group
                              controlId="customLabel"
                              className="my-2"
                            >
                              <Form.Label>
                                Label
                                <sup className="text-danger ps-1 fs-3 top-0">
                                  *
                                </sup>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="customLabel"
                                value={customField?.label}
                                onChange={(ev) =>
                                  setCustomField({
                                    ...customField,
                                    label: ev.target.value,
                                  })
                                }
                                onBlur={handleBlur}
                                isValid={customField?.label}
                              />
                            </Form.Group>
                            <Form.Group controlId="customType" className="my-2">
                              <Form.Label>
                                Type
                                <sup className="text-danger ps-1 fs-3 top-0">
                                  *
                                </sup>
                              </Form.Label>
                              <Form.Select
                                name="customType"
                                value={
                                  customField?.type === "number"
                                    ? "Number"
                                    : customField?.type === "textarea"
                                    ? "TextArea"
                                    : customField?.type === "date"
                                    ? "Date"
                                    : customField?.type
                                }
                                onChange={(ev) =>
                                  setCustomField({
                                    ...customField,
                                    type: ev.target.value.toLowerCase(),
                                    value: "",
                                  })
                                }
                                onBlur={handleBlur}
                                isValid={customField?.type}
                              >
                                <option value={""} disabled={customField?.type}>
                                  Select the type
                                </option>
                                {projectCustomTypesMap.map(({ key, value }) => (
                                  <option key={key} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            {customField?.type && (
                              <Form.Group
                                controlId="customValue"
                                className="my-2"
                              >
                                <Form.Label>Value</Form.Label>
                                {customField?.type?.toLowerCase() !==
                                "textarea" ? (
                                  <Form.Control
                                    type={customField?.type?.toLowerCase()}
                                    name="customValue"
                                    value={customField?.value}
                                    onChange={(ev) => {
                                      setCustomField({
                                        ...customField,
                                        value: ev.target.value,
                                      });
                                    }}
                                    onBlur={handleBlur}
                                    isValid={customField?.value}
                                  />
                                ) : (
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="customValue"
                                    value={customField?.value}
                                    onChange={(ev) =>
                                      setCustomField({
                                        ...customField,
                                        value: ev.target.value,
                                      })
                                    }
                                    onBlur={handleBlur}
                                    isValid={customField?.value}
                                  />
                                )}
                              </Form.Group>
                            )}
                            {customFields.some(
                              (item) =>
                                item.label === customField.label &&
                                item.type === customField.type
                            ) && (
                              <Form.Text type="invalid" className="text-danger">
                                Custom field is already added with this label
                                and type.
                              </Form.Text>
                            )}
                            <div className="mt-4 text-end">
                              <>
                                <Button
                                  size="sm"
                                  variant="link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsCustom(!isCustom);
                                    setCustomField({
                                      label: "",
                                      type: "",
                                      value: "",
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  size="sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsCustom(!isCustom);
                                    setCustomFields((prev) => [
                                      ...prev,
                                      {
                                        ...customField,
                                        fe_key: customFields?.length + 1,
                                      },
                                    ]);
                                    setCustomField({
                                      label: "",
                                      type: "",
                                      value: "",
                                    });
                                  }}
                                  disabled={
                                    !customField?.label ||
                                    !customField?.type ||
                                    customFields.some(
                                      (item) =>
                                        item.label === customField.label &&
                                        item.type === customField.type
                                    )
                                  }
                                >
                                  Save
                                </Button>
                              </>
                            </div>
                          </Alert>
                        </div>
                      </Collapse>
                      {!!customFields?.length &&
                        customFields?.map((field, index) => (
                          <>
                            <Form.Group
                              controlId={`customField${
                                field?.fe_key || field?.id
                              }`}
                              className="my-2"
                              key={field?.fe_key || field?.id}
                            >
                              <div>
                                <Form.Label>{field?.label}</Form.Label>
                                {!existingCustomFields?.some(
                                  (item) =>
                                    item?.label === field?.label &&
                                    item?.type?.toLowerCase() ===
                                      field?.type?.toLowerCase()
                                ) && (
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="py-0 float-end"
                                    onClick={() => deleteModule(index)}
                                  >
                                    <span className="material-icons-outlined md-18">
                                      delete
                                    </span>
                                  </Button>
                                )}
                              </div>
                              {field?.type?.toLowerCase() !== "textarea" ? (
                                <Form.Control
                                  type={field?.type?.toLowerCase()}
                                  name={`customField${
                                    field?.fe_key || field?.id
                                  }`}
                                  value={field?.value}
                                  onChange={(ev) => {
                                    setCustomFields(
                                      customFields.map((item) => {
                                        if (
                                          item?.id && field?.id
                                            ? item?.id === field?.id
                                            : item?.fe_key === field?.fe_key
                                        ) {
                                          return {
                                            ...item,
                                            value: ev.target.value,
                                          };
                                        } else {
                                          return item;
                                        }
                                      })
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isValid={field?.value}
                                />
                              ) : (
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  name={`customField${
                                    field?.fe_key || field?.id
                                  }`}
                                  value={field?.value}
                                  onChange={(ev) => {
                                    setCustomFields(
                                      customFields.map((item) => {
                                        if (
                                          item?.id && field?.id
                                            ? item?.id === field?.id
                                            : item?.fe_key === field?.fe_key
                                        ) {
                                          return {
                                            ...item,
                                            value: ev.target.value,
                                          };
                                        } else {
                                          return item;
                                        }
                                      })
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  isValid={field?.value}
                                />
                              )}
                            </Form.Group>
                          </>
                        ))}
                    </>
                  </Tab>
                </Tabs>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer className="">
                {step === "step-1" && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    className="px-4"
                    onClick={() => setStep("step-2")}
                  >
                    Next
                  </Button>
                )}

                {step === "step-2" && (
                  <>
                    <Button
                      variant="outline-dark"
                      size="sm"
                      onClick={() => setStep("step-1")}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outline-dark"
                      size="sm"
                      className="px-4"
                      onClick={() => setStep("step-3")}
                    >
                      Next
                    </Button>
                  </>
                )}

                {(step === "step-3" || step === "step-4") && (
                  <>
                    <Button
                      variant="outline-dark"
                      className="float-start px-4"
                      size="sm"
                      onClick={() =>
                        setStep(step === "step-3" ? "step-2" : "step-3")
                      }
                    >
                      Previous
                    </Button>
                    <Button type="submit" size="sm" disabled={!isValid}>
                      Create Project
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AbatementProjectCreate.propTypes = {
  onAbatementProjectCreated: PropTypes.func.isRequired,
};

export default AbatementProjectCreate;
