import PropTypes from "prop-types";
import { Alert, Button, Form, Table } from "react-bootstrap";

import { destroy, download, get, upload } from "utils/DeApi";
import { REPORTING_METHODS } from "../ReportActivity/constants";

const SUPPORTED_FILE_FORMATS = [
  "image/*",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xlsm", // Include .xlsm here
  ".docx",
  ".doc",
  ".ppt",
  ".pptx",
];

const createFile = (file, entity, entityId) => {
  const formData = new FormData();
  formData.append("file", file);

  const fileUploadPromise = upload(
    `${entity}/${entityId}/attachments`,
    formData
  );

  return fileUploadPromise;
};

const getFile = (entity, entityId, fileId) => {
  return get(`${entity}/${entityId}/attachments/${fileId}`);
};

const downloadFile = (fileUrl) => {
  return download(fileUrl);
};

const deleteFile = (entity, entityId, fileId) => {
  return destroy(`${entity}/${entityId}/attachments/${fileId}`);
};

const FileUploadField = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  fieldName,
  multiple,
  attachments,
  handleDeleteAttachment,
}) => {
  return (
    <Form.Group controlId="file" className="mb-3">
      <Form.Label>Upload File Attachment</Form.Label>
      <Form.Control
        type="file"
        name={fieldName}
        multiple={multiple}
        accept={SUPPORTED_FILE_FORMATS.join(",")}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={values.file && !errors.file}
      />
      <Form.Text>
        The file must be a file of type: jpg, jpeg, png, gif, pdf, docx, doc,
        csv, xlsx, xls, xlsm, ppt, pptx|max~5mb.
      </Form.Text>
      <Form.Control.Feedback type="invalid">
        {errors.file && touched.file ? <small>{errors.file}</small> : null}
      </Form.Control.Feedback>
      <br />
      <Form.Text className="text-danger">
        {[
          REPORTING_METHODS.customEF,
          REPORTING_METHODS.directUserInput,
        ].includes(values.reportingMethod) &&
          !values.file &&
          attachments.length === 0 && (
            <strong>Please attach supporting documents</strong>
          )}
      </Form.Text>
      {!!attachments.length && (
        <Alert variant="dark" className="bg-light mt-2">
          <h4>Activity attachments</h4>
          <Table size="sm">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {attachments.map((attachment, index) => {
                return (
                  <tr className="mb-0" key={attachment.id}>
                    <td>
                      {" "}
                      <a
                        href={attachment.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        {attachment.name || `Attachment ${index + 1}`}
                        <span className="material-icons md-18 material-icons-outlined">
                          open_in_new
                        </span>
                      </a>{" "}
                    </td>
                    {!!handleDeleteAttachment && (
                      <td className="text-end">
                        <Button
                          size="sm"
                          variant="light"
                          className="btn-sm py-0"
                          title="Remove attachment from portfolio"
                          onClick={() => handleDeleteAttachment(attachment.id)}
                        >
                          {" "}
                          <span className="material-icons-outlined md-18">
                            delete
                          </span>
                        </Button>{" "}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Alert>
      )}
    </Form.Group>
  );
};

export default FileUploadField;

export { createFile, deleteFile, downloadFile, getFile };

FileUploadField.defaultProps = {
  multiple: false,
  attachments: [],
  handleDeleteAttachment: null,
};

FileUploadField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  handleDeleteAttachment: PropTypes.func,
  multiple: PropTypes.bool,
  attachments: PropTypes.array,
};
