import React, { useEffect, useRef, useState, useCallback } from "react";
import { Alert, Button, Spinner, Form, Row, Col } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import ReportedActivityList from "./ReportedActivityList";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import UserCategories from "utils/userCategories";

import { getAllYearsBetweenDates } from "utils/dateUtils";
import { get, download, put } from "utils/DeApi";
import DataLayer from "utils/DataLayer";

const AllReportedActivity = ({ site, breadcrumbs }) => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [activities, setActivities] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [isExporting, setIsExporting] = useState(false);
  const [exportingError, setExportingError] = useState();

  const [isExpanding, setIsExpanding] = useState(false);
  const [expandingError, setExpandingError] = useState();

  const [showingAllActivities, setShowingAllActivities] = useState(false);

  const [offset, setOffset] = useState(0);
  const [scopeTwoPreference, setScopeTwoPreference] = useState(
    site.scopeTwoPreference
  );

  const handleSetScopeTwoPreference = (choice) => {
    setError(null);
    setIsLoading(true);
    const scopeTwoPreferencePromise = put(
      `/sites/${site.id}/scope-two-preference`,
      {
        scopeTwoPreference: choice,
      }
    );
    scopeTwoPreferencePromise.promise
      .then((response) => {
        setScopeTwoPreference(response.data.scopeTwoPreference);
        fetchActivities();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });
  const recentYear = new Date().getFullYear() - 1;
  const [year, setYear] = useState(recentYear.toString());

  const [scope, setScope] = useState();

  const fetchActivities = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const allScopesParams = {
      params: {
        offset: 0,
        limit: 10,
        sort: "-updatedAt",
        "filter[scope]": scope,
        "filter[yearEnded]": year,
        "filter[preference]": scopeTwoPreference,
      },
    };

    const activitiesPromise = get(
      `/sites/${site.id}/activities`,
      allScopesParams
    );

    activitiesPromise.promise
      .then((response) => {
        setActivities(response.data);
        setError(null);
        setIsLoading(false);
        if (response.data.length < 10) {
          setShowingAllActivities(true);
        } else {
          setShowingAllActivities(false);
        }
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(activitiesPromise);
  }, [site.id, scope, year, scopeTwoPreference]);

  const exportActivities = () => {
    setIsExporting(true);

    const exportPromise = download(
      `/organizations/${site.organization.id}/activities/export`,
      {
        params: {
          sites: [site.id],
          "filter[scope]": scope,
          "filter[yearEnded]": year,
          "filter[preference]": scopeTwoPreference,
        },
      }
    );

    exportPromise.promise
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `${site.name} - Activities.xlsx`);
        document.body.appendChild(link);

        link.click();
        setIsExporting(false);
      })
      .catch((error) => {
        setIsExporting(false);
        setExportingError(error);
        console.warn(error);
      });

    subscribedPromises.current.push(exportPromise);
  };

  const showMoreActivities = useCallback(
    (offset) => {
      setExpandingError(null);
      setIsExpanding(true);

      const params = {
        params: {
          offset: offset,
          limit: 10,
          sort: "-updatedAt",
          "filter[scope]": scope,
          "filter[yearEnded]": year,
          "filter[preference]": scopeTwoPreference,
        },
      };

      const activitiesPromise = get(`/sites/${site.id}/activities`, params);

      activitiesPromise.promise
        .then((response) => {
          setActivities((prevState) => [...prevState, ...response.data]);
          setOffset((prevState) => prevState + response.data.length);
          setExpandingError(null);
          setIsExpanding(false);
          if (response.data.length < 10) {
            setShowingAllActivities(true);
          } else {
            setShowingAllActivities(false);
          }
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setExpandingError(error);
            setIsExpanding(false);
          }
        });
      subscribedPromises.current.push(activitiesPromise);
    },
    [site.id, scope, year, scopeTwoPreference]
  );

  const sortSiteActivities = (sortingvalue) => {
    setError(null);
    setIsLoading(true);
    setTableSortState({
      sortAsc: !tableSortState.sortAsc,
      sortColumn: sortingvalue,
    });

    const allScopesParams = {
      params: {
        sites: [site.id],
        offset: 0,
        limit: activities.length,
        sort: !tableSortState.sortAsc ? sortingvalue : `-${sortingvalue}`,
        "filter[scope]": scope,
        "filter[yearEnded]": year,
        "filter[preference]": scopeTwoPreference,
      },
    };

    const activitiesPromise = get(
      `/organizations/${site.organization.id}/activities`,
      allScopesParams
    );

    activitiesPromise.promise
      .then((response) => {
        setActivities(response.data);
        setError(null);
        setIsLoading(false);
        if (response.data.length < 10) {
          setShowingAllActivities(true);
        }
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(activitiesPromise);
  };
  useEffect(() => {
    fetchActivities();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActivities]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!activities) return <span />;

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: `Reported Activities`,
              link: `/organizations/${site.organizationId}/sites/${site.id}/reported-activities`,
              active: true,
            },
          ]}
        />
      </div>
      <Row>
        <Col xs={12} md={4}>
          <h3 className="my-3">Reported Activities</h3>
        </Col>
        <Col xs={12} md={8}>
          <div className="text-end">
            <ScopeTwoPreferenceToggler
              className="d-inline-flex mx-1 py-1"
              scopeTwoPreference={scopeTwoPreference}
              setScopeTwoPreference={handleSetScopeTwoPreference}
            />
            <div className="my-3 ms-2 d-inline-flex">
              <Form.Select
                aria-label="scope-filter"
                size="sm"
                value={scope || "Scope"}
                onChange={(ev) => {
                  let selectedScope = ev.target.value;
                  setScope(selectedScope);
                }}
              >
                <option value="">All Scopes</option>
                {[1, 2, 3].map((scope) => (
                  <option key={scope} value={scope}>
                    {scope}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="my-3 ms-2 d-inline-flex">
              <Form.Select
                aria-label="year-filter"
                size="sm"
                value={year || "All Years"}
                onChange={(ev) => {
                  let selectedYear = ev.target.value;
                  setYear(selectedYear);
                }}
              >
                <option value="">All Years</option>
                {getAllYearsBetweenDates().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="my-3 ms-2 d-inline-flex">
              <Button
                className="px-3"
                size="sm"
                variant="primary"
                onClick={exportActivities}
                disabled={isExporting}
              >
                Export Filtered Activities{"  "}
                {isExporting && (
                  <Spinner
                    animation="border"
                    variant="white"
                    size="sm"
                    className="ms-3"
                  />
                )}
                {exportingError && (
                  <span className="material-icons-outlined md-18 text-danger">
                    error
                  </span>
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        {!!activities.length && (
          <>
            <ReportedActivityList
              activities={activities}
              sortSiteActivities={sortSiteActivities}
              tableSortState={tableSortState}
              isLoading={isLoading}
            />
            <div className="my-3">
              {isExpanding && <Loader />}
              {expandingError && <ErrorHandler error={expandingError} />}
              {!showingAllActivities && (
                <p className="text-center">
                  <Button
                    size="sm"
                    variant="primary"
                    className="px-3 my-2 bg-opacity-25"
                    onClick={() => showMoreActivities(offset)}
                    disabled={isExpanding}
                  >
                    Show More
                  </Button>
                </p>
              )}
            </div>
          </>
        )}
        {!activities.length && (
          <Alert variant="info">There is currently nothing to show here.</Alert>
        )}
      </div>
    </div>
  );
};

export default AllReportedActivity;
