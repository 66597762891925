import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import {
  Form,
  Button,
  Modal,
  Alert,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Field } from "formik";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Select, { components } from "react-select";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import { trim } from "lodash";

import SelectInputFormik from "components/SelectInput/SelectInput";

import {
  getEmissionFactorFromId,
  isInflationFactorAppliedOnActivity,
  getInflationRateFromYear,
  validateDatasetReferenceField,
  getEmissionFactorOptions,
  isUSEEIO2018Dataset,
  removeDuplicateOptions,
  removeUnitsToDuplicateEmissionFactors,
} from "utils/emissionFactors";
import {
  getAllYearsBetweenDates,
  getYearMonths,
  getYearQuarters,
  getFiscalQuarter,
  getFiscalYearMonths,
} from "utils/dateUtils";

import { get } from "utils/DeApi";

import EmissionResourceUnits from "components/Organization/DataModels/EmissionResourceUnits.json";
import EmissionResourceAreaUnits from "components/Organization/DataModels/EmissionResourceAreaUnits.json";
import PcafContentArticles from "components/Organization/DataModels/PcafContentArticles.json";

import FileUploadField from "components/Organization/Site/SiteDetail/FileUpload/FileUpload";
import UnitConverter from "components/Converter/UnitConverter/UnitConverter";
import CurrencyConverter from "components/Converter/CurrencyConverter/CurrencyConverter";
import { REPORTING_METHODS } from "./constants";
import { AccountContext } from "contexts/AccountProvider";
import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import "./ReportActivity.scss";

export function EmissionYearField(props) {
  const {
    fieldValue,
    handleBlur,
    handleChange,
    setFieldError,
    setFieldTouched,
    selectedActivityTypeDataset,
    siteYearsUnderReview,
    setIsYearLocked,
    isUpdate,
    organizationFiscalStartMonth,
    organizationFiscalEndMonth,
  } = props;

  useEffect(() => {
    setIsYearLocked(siteYearsUnderReview?.includes(Number(fieldValue)));
  }, [siteYearsUnderReview, fieldValue, setIsYearLocked]);

  const fiscalYearStartMonthLabel = getYearMonths().find(
    (month) => month.value === organizationFiscalStartMonth
  )?.label;
  const fiscalYearEndMonthLabel = getYearMonths().find(
    (month) => month.value === organizationFiscalEndMonth
  )?.label;

  return (
    <Form.Group controlId="activity-year" className="my-3">
      <Form.Label>Reporting year</Form.Label>
      <Field
        as={Form.Select}
        name="year"
        value={fieldValue}
        onChange={(e) => {
          handleChange(e);
          setFieldError(
            "reference",
            validateDatasetReferenceField(
              e.target.value,
              selectedActivityTypeDataset
            )
          );
          setFieldTouched("reference", true);
          setIsYearLocked(
            siteYearsUnderReview?.includes(Number(e.target.value))
          );
        }}
        onBlur={handleBlur}
        isValid={fieldValue}
      >
        {getAllYearsBetweenDates().map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Field>
      {siteYearsUnderReview?.includes(Number(fieldValue)) ? (
        <Form.Text className="text-danger">
          Inventory for this year is locked. Unlocking is required to{" "}
          {isUpdate ? "update the " : "add new "}
          activity.
          <br />
        </Form.Text>
      ) : null}
      <Form.Text>
        Reporting period: {fiscalYearStartMonthLabel}{" "}
        {organizationFiscalStartMonth === 1
          ? fieldValue
          : Number.parseInt(fieldValue) - 1}{" "}
        to {fiscalYearEndMonthLabel} {fieldValue}
      </Form.Text>
    </Form.Group>
  );
}

EmissionYearField.defaultProps = {
  selectedActivityTypeDataset: {
    name: "",
    id: "",
  },
};

EmissionYearField.propTypes = {
  fieldValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  selectedActivityTypeDataset: PropTypes.object,
  organizationFiscalStartMonth: PropTypes.number.isRequired,
  organizationFiscalEndMonth: PropTypes.number.isRequired,
};

export function ReportingMonthField(props) {
  const {
    fieldValue,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    organizationFiscalStartMonth,
    selectedYear,
  } = props;

  return (
    <Form.Group controlId="activity-month" className="my-3">
      <Form.Label>Date of activity</Form.Label>
      <Field
        as={Form.Select}
        name="month"
        value={fieldValue}
        onChange={(e) => {
          handleChange(e);
          setFieldValue(
            "quarter",
            getFiscalQuarter(e.target.value, organizationFiscalStartMonth)
          );
        }}
        onBlur={handleBlur}
      >
        <option value="" disabled>
          Select from options
        </option>
        {getFiscalYearMonths(organizationFiscalStartMonth, selectedYear).map(
          (month) => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          )
        )}
      </Field>
      <Form.Text className="text-danger">
        {touched.month && errors.month}
      </Form.Text>
    </Form.Group>
  );
}

ReportingMonthField.propTypes = {
  fieldValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  organizationFiscalStartMonth: PropTypes.number.isRequired,
  selectedYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export function ReportingQuarterField(props) {
  const { fieldValue, handleBlur, handleChange, errors, touched } = props;

  return (
    <Form.Group controlId="activity-quarter" className="my-3">
      <Form.Label>Reporting Quarter</Form.Label>
      <Field
        as={Form.Select}
        name="quarter"
        value={fieldValue}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled
      >
        <option value="" disabled></option>
        {getYearQuarters().map((quarter) => (
          <option key={quarter.value} value={quarter.value}>
            {quarter.label}
          </option>
        ))}
      </Field>
      <Form.Text className="text-danger">
        {touched.quarter && errors.quarter}
      </Form.Text>
    </Form.Group>
  );
}

ReportingMonthField.propTypes = {
  fieldValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export function EmissionAmountField(props) {
  const {
    fieldValue,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    isInvestments,
    emissionUnit,
    isUpdate,
    isYearLocked,
    selectedActivityTypeDataset,
  } = props;

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <Form.Group controlId="resource-amount">
      <Form.Label>
        {isInvestments
          ? "Provide Revenue"
          : `Provide the quantity of resource used `}
        (
        {selectedActivityTypeDataset?.type === "Financial" &&
        selectedActivityTypeDataset?.name === "Exiobase 2019"
          ? "USD"
          : emissionUnit}
        )<sup className="text-danger ps-1 fs-3 top-0">*</sup>
      </Form.Label>

      <NumberFormat
        customInput={Form.Control}
        value={fieldValue}
        thousandSeparator={true}
        onValueChange={(numberItem) => {
          setFieldValue("amount", numberItem.value);
        }}
        name="amount"
        placeholder={
          isInvestments ? "Enter value of revenue" : "Enter amount of resource"
        }
        onBlur={handleBlur}
        isValid={fieldValue}
        isInvalid={errors.amount && touched.amount}
        disabled={checkYearLock}
      />

      <Form.Control.Feedback type="invalid">
        {errors.amount}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

EmissionAmountField.defaultProps = {
  isInvestments: false,
};

EmissionAmountField.propTypes = {
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isInvestments: PropTypes.bool,
  emissionUnit: PropTypes.string.isRequired,
};

export function EmissionCommentField(props) {
  const {
    fieldValue,
    handleBlur,
    handleChange,
    errors,
    touched,
    isUpdate,
    isYearLocked,
  } = props;

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <Form.Group controlId="summary" className="mt-3 mb-3">
      <Form.Label>Comments</Form.Label>
      <Form.Control
        as="textarea"
        name="comment"
        placeholder="You may enter comments or notes here."
        value={fieldValue}
        onChange={handleChange}
        onBlur={handleBlur}
        rows={3}
        isValid={fieldValue}
        isInvalid={errors.comment && touched.comment}
        disabled={checkYearLock}
      />
      <Form.Control.Feedback type="invalid">
        {errors.comment}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

EmissionCommentField.propTypes = {
  fieldValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export function ReportActivityModalToggleButton(props) {
  const { handleShow, isUpdate } = props;

  if (isUpdate) {
    return (
      <Button
        variant="outline-primary"
        className="right py-0"
        onClick={handleShow}
        size="sm"
        title="Update Emission"
      >
        <span className="material-icons-outlined md-18">tune</span>
      </Button>
    );
  } else {
    return (
      <Button variant="primary" onClick={handleShow} size="sm">
        Report Emissions
      </Button>
    );
  }
}

ReportActivityModalToggleButton.propTypes = {
  handleShow: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
};

export function ActivityTypeField(props) {
  const {
    fieldValue,
    handleChange,
    handleBlur,
    activityTypes,
    values,
    handleOnChange,
    isDisabled,
    isUpdate,
    isYearLocked,
    setError,
    setFieldValue,
    errors,
    touched,
    activity,
  } = props;

  const [projectTypeOptions] = useState({
    5.4: [
      { label: "Office", value: "Office" },
      { label: "Retail", value: "Retail" },
      { label: "Residential", value: "Residential" },
    ],
    5.5: [
      { label: "Apartment", value: "Apartment" },
      { label: "Detached house", value: "Detached house" },
      { label: "Semi Detached house", value: "Semi Detached house" },
      { label: "Terrace", value: "Terrace" },
      { label: "Other", value: "Other" },
    ],
    5.6: [
      { label: "Van", value: "Van" },
      { label: "Car", value: "Car" },
      { label: "HGV", value: "HGV" },
      { label: "Motorcycle", value: "Motorcycle" },
    ],
  });

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <>
      <Form.Group controlId="activity-type">
        <Form.Label>Activity Type</Form.Label>
        <Field
          name="activityCategory"
          value={fieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={fieldValue}
        >
          {({ field, form }) => (
            <SelectInputFormik
              name="activityCategory"
              values={values}
              options={activityTypes.map((activityType) => ({
                label: activityType.label,
                value: activityType.id,
              }))}
              form={form}
              field={field}
              isDisabled={true}
              onChange={
                handleOnChange &&
                ((option) => handleOnChange(option, form, field))
              }
            />
          )}
        </Field>
      </Form.Group>
      <Form.Group
        controlId="activity-type"
        className="mt-2"
        hidden={activityTypes.length <= 1}
      >
        <Form.Label>
          Activity Subcategory{" "}
          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
        </Form.Label>
        <Field
          name="activityType"
          value={fieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={fieldValue}
        >
          {({ field, form }) => (
            <SelectInputFormik
              name="activityType"
              values={values}
              options={activityTypes.map((activityType) => ({
                label: activityType.title,
                value: activityType.id,
              }))}
              form={form}
              field={field}
              isDisabled={isDisabled || checkYearLock}
              onChange={
                handleOnChange &&
                ((option) => handleOnChange(option, form, field))
              }
            />
          )}
        </Field>
      </Form.Group>
      {values.activityType &&
        activityTypes.find((aType) => aType.id === values.activityType)
          ?.subActivityTypes?.length > 0 && (
          <Form.Group controlId="activity-subCategory" className="mt-3">
            <Form.Label>
              Investment Type{" "}
              <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
            </Form.Label>
            <Field
              name="subActivityTypeId"
              value={fieldValue}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={fieldValue}
            >
              {({ field, form }) => (
                <SelectInputFormik
                  name="subActivityTypeId"
                  values={values}
                  options={activityTypes
                    .find((aType) => aType.id === values.activityType)
                    .subActivityTypes.map((activityType) => ({
                      label: activityType.title,
                      value: activityType.id,
                    }))}
                  form={form}
                  field={field}
                  isDisabled={isDisabled}
                />
              )}
            </Field>
            <Form.Text className="text-danger">
              {errors.subActivityTypeId}
            </Form.Text>
          </Form.Group>
        )}
      {values.activityType &&
        activityTypes
          .find((aType) => aType.id === values.activityType)
          ?.title?.includes("5.7") && (
          <CountryField
            values={values}
            fieldValue={values.country}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setError={setError}
            setFieldValue={setFieldValue}
            selectedOption={{
              label: activity.emissionFactorCountry?.name,
              value: activity.emissionFactorCountry?.id,
            }}
            errors={errors}
            fieldLabel={"Country of Debt Origination"}
          >
            {touched.country && errors.country && (
              <Form.Text className="text-danger">{errors.country}</Form.Text>
            )}
          </CountryField>
        )}
      {values.activityType &&
        ["5.3", "5.4", "5.5"].map((prefix) => {
          if (
            activityTypes
              .find((aType) => aType.id === values.activityType)
              ?.title?.includes(prefix)
          ) {
            return (
              <Form.Group
                controlId="activity-projectType"
                className="mt-3"
                key={prefix}
              >
                <Form.Label>
                  {["5.4", "5.5"].includes(prefix) ? "Property" : "Project"}{" "}
                  Type
                  <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                </Form.Label>
                {projectTypeOptions[prefix] ? (
                  <Field
                    name="projectType"
                    value={fieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={fieldValue}
                  >
                    {({ field, form }) => (
                      <SelectInputFormik
                        name="projectType"
                        values={values}
                        options={projectTypeOptions[prefix]}
                        form={form}
                        field={field}
                      />
                    )}
                  </Field>
                ) : (
                  <Form.Control
                    type="text"
                    name="projectType"
                    value={values.projectType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter project type"
                  />
                )}
                <Form.Text className="text-danger">
                  {errors.projectType}
                </Form.Text>
              </Form.Group>
            );
          }
          return null;
        })}
      {values.activityType &&
        activityTypes
          .find((aType) => aType.id === values.activityType)
          ?.title?.includes("5.6") && (
          <Form.Group controlId="activity-vehicleType" className="mt-3">
            <Form.Label>Vehicle Type</Form.Label>
            <Field
              name="vehicleType"
              value={fieldValue}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={fieldValue}
            >
              {({ field, form }) => (
                <SelectInputFormik
                  name="vehicleType"
                  values={values}
                  options={projectTypeOptions["5.6"]}
                  form={form}
                  field={field}
                />
              )}
            </Field>
            <Form.Text className="text-danger">{errors.vehicleType}</Form.Text>
          </Form.Group>
        )}
    </>
  );
}

ActivityTypeField.defaultProps = {
  handleOnChange: null,
  isDisabled: false,
};

ActivityTypeField.propTypes = {
  fieldValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  activityTypes: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export function ReferenceTypeField(props) {
  const {
    fieldValue,
    handleBlur,
    handleChange,
    values,
    handleOnChange,
    isLoading,
    datasets,
    selectedActivityTypeDataset,
    validateHandler,
    children,
    isUpdate,
    isYearLocked,
  } = props;

  const checkYearLock = isUpdate && isYearLocked;

  const handleValidateReferenceTypeField = () => {
    if (values.reference && values.year) {
      return validateDatasetReferenceField(
        values.year,
        selectedActivityTypeDataset
      );
    }
    return "Dataset reference is required";
  };

  return (
    <Form.Group className="mt-3 mb-3">
      <Form.Label>
        Dataset reference <sup className="text-danger ps-1 fs-3 top-0">*</sup>{" "}
      </Form.Label>
      <Field
        name="reference"
        value={fieldValue}
        onChange={handleChange}
        onBlur={handleBlur}
        validate={validateHandler || handleValidateReferenceTypeField}
      >
        {({ field, form }) => (
          <SelectInputFormik
            {...field}
            values={values}
            options={datasets.map((d) => ({ label: d.name, value: d.id }))}
            form={form}
            field={field}
            isLoading={isLoading}
            selectedOption={{
              value: selectedActivityTypeDataset.id,
              label: selectedActivityTypeDataset.name,
            }}
            onChange={
              handleOnChange &&
              ((option) => handleOnChange(option, form, field))
            }
            isDisabled={checkYearLock}
          />
        )}
      </Field>
      {isUSEEIO2018Dataset(selectedActivityTypeDataset.id) ? (
        <Form.Text>
          US EEIO factors are US specific but can be used as a proxy for all
          geographical locations.
          <br />
        </Form.Text>
      ) : null}
      {children}
    </Form.Group>
  );
}

ReferenceTypeField.defaultProps = {
  isLoading: false,
  validateHandler: null,
  children: null,
};

ReferenceTypeField.propTypes = {
  fieldValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  datasets: PropTypes.array.isRequired,
  selectedActivityTypeDataset: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  validateHandler: PropTypes.func,
  children: PropTypes.node,
};

export function ReportActivityModalTitle(props) {
  const { isUpdate, scope, activityCategory } = props;

  if (isUpdate) {
    return (
      <Modal.Title>
        Update Scope {scope}{" "}
        <i>{activityCategory && `- ${activityCategory}`}</i> Emissions
      </Modal.Title>
    );
  } else {
    return (
      <Modal.Title>
        Report Scope {scope}{" "}
        <i>{activityCategory && `- ${activityCategory}`}</i> Emissions
      </Modal.Title>
    );
  }
}

ReportActivityModalTitle.defaultProps = {
  activityCategory: null,
};

ReportActivityModalTitle.propTypes = {
  isUpdate: PropTypes.bool.isRequired,
  scope: PropTypes.number.isRequired,
  activityCategory: PropTypes.string,
};

export function EmissionResourceField(props) {
  const {
    values,
    keyValue,
    fieldValue,
    handleBlur,
    handleChange,
    isLoading,
    handleOnChange,
    options,
    selectedOption,
    children,
    fieldLabel,
    isUpdate,
    isYearLocked,
  } = props;

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <Form.Group controlId="activity-resource" className="my-4" key={keyValue}>
      <Form.Label>{fieldLabel}</Form.Label>
      <Field
        name="resource"
        value={fieldValue}
        isValid={fieldValue}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {({ field, form }) => (
          <SelectInputFormik
            name="resource"
            values={values}
            options={options}
            isLoading={isLoading}
            form={form}
            field={field}
            selectedOption={selectedOption}
            onChange={
              handleOnChange &&
              ((option) => handleOnChange(option, form, field))
            }
            isDisabled={checkYearLock}
          />
        )}
      </Field>
      {children}
    </Form.Group>
  );
}

EmissionResourceField.defaultProps = {
  handleOnChange: null,
  selectedOption: null,
  fieldLabel: "Search or select the type of resource used",
};

EmissionResourceField.propTypes = {
  values: PropTypes.object.isRequired,
  keyValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  fieldValue: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func,
  selectedOption: PropTypes.object,
  fieldLabel: PropTypes.string,
};

export function EmissionResourceFieldv2(props) {
  const {
    values,
    keyValue,
    fieldValue,
    handleBlur,
    handleChange,
    isLoading,
    handleOnChange,
    options,
    selectedOption,
    children,
    fieldLabel,
    isUpdate,
    isYearLocked,
  } = props;

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <small className={props.isSelected ? "" : "form-text"}>
          {props.data.subLabel}
        </small>
      </components.Option>
    );
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        Not found
      </components.NoOptionsMessage>
    );
  };

  const filterOption = (candidate, input) => {
    // candidate = {label: string, value: string, data: {label: string, value: string, subLabel: string}}
    // input: string = the input search keyword

    if (trim(input)) {
      // split input into search tokens
      const tokens = trim(input).toLowerCase().split(" ");

      // compose a keywords array containing label and subLabel metadata
      const keyWords = [
        candidate.data.label.toLowerCase(),
        ...candidate.data.subLabel.toLowerCase().split(","),
      ];

      // check every token matches atleast one keyword
      return tokens.every((token) =>
        keyWords.some((keyWord) => trim(keyWord).includes(token))
      );
    }

    return true;
  };

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <Form.Group controlId="activity-resource" className="mb-3" key={keyValue}>
      <Form.Label>{fieldLabel}</Form.Label>
      <Field
        name="resource"
        value={fieldValue}
        isValid={fieldValue}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {({ field, form }) => (
          <SelectInputFormik
            name="resource"
            values={values}
            options={options}
            isLoading={isLoading}
            form={form}
            field={field}
            selectedOption={selectedOption}
            components={{ Option, NoOptionsMessage }}
            filterOption={filterOption}
            onChange={
              handleOnChange &&
              ((option) => handleOnChange(option, form, field))
            }
            isDisabled={checkYearLock}
          />
        )}
      </Field>
      {children}
    </Form.Group>
  );
}

EmissionResourceFieldv2.defaultProps = {
  handleOnChange: null,
  selectedOption: null,
  fieldLabel: (
    <span>
      Search or select the type of resource used
      <sup className="text-danger ps-1 fs-3 top-0">*</sup>
    </span>
  ),
};

EmissionResourceFieldv2.propTypes = {
  values: PropTypes.object.isRequired,
  keyValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  fieldValue: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func,
  selectedOption: PropTypes.object,
  fieldLabel: PropTypes.string,
};

export function ReportActivityModalFooter(props) {
  const {
    emissionFactorsActivityTypeId,
    scope,
    handleClose,
    isValid,
    isYearLocked,
  } = props;

  return (
    <Modal.Footer>
      {emissionFactorsActivityTypeId && (
        <h5 className="me-auto">
          <Link
            to={`/emissions-factors?activityCategoryId=${emissionFactorsActivityTypeId}&scope=${scope}`}
            target="_blank"
            className="text-decoration-none "
          >
            Emission Factors{" "}
            <span className="material-icons md-18 material-icons-outlined">
              open_in_new
            </span>
          </Link>
        </h5>
      )}
      <Button size="sm" variant="link" onClick={handleClose}>
        Cancel
      </Button>
      <Button type="submit" size="sm" disabled={!isValid || isYearLocked}>
        Submit
      </Button>
    </Modal.Footer>
  );
}

ReportActivityModalFooter.propTypes = {
  emissionFactorsActivityTypeId: PropTypes.string.isRequired,
  scope: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isYearLocked: PropTypes.bool.isRequired,
};

export function ReportActivityEmissionFactorRateHelperText(props) {
  const {
    values,
    values: { year, gwpModel },
    selectedEmissionFactor,
    selectedActivityTypeDataset,
    isUpdate,
    organizationGwpModels,
  } = props;

  const selectedYearGwpModel = organizationGwpModels?.find(
    (model) => Number.parseInt(model.year) === Number.parseInt(year)
  );

  const currentAR = isUpdate
    ? gwpModel?.name
    : !(
        selectedEmissionFactor?.co2EmissionFactor &&
        selectedEmissionFactor?.ch4EmissionFactor &&
        selectedEmissionFactor?.n2oEmissionFactor
      )
    ? selectedActivityTypeDataset?.gwpModel?.name
    : isUpdate &&
      !(
        selectedEmissionFactor?.co2EmissionFactor &&
        selectedEmissionFactor?.ch4EmissionFactor &&
        selectedEmissionFactor?.n2oEmissionFactor
      )
    ? selectedActivityTypeDataset?.gwpModel?.name
    : selectedYearGwpModel?.name;

  const isFossil = selectedEmissionFactor?.metadata?.some(
    (item) => item.value === "Fossil Origin" && currentAR === "AR6"
  );

  const co2GasValues = isUpdate
    ? gwpModel?.gasValues?.find((gv) => gv.gasFormula === "CO2")
    : selectedYearGwpModel?.gasValues?.find((gv) => gv.gasFormula === "CO2");
  const n2oGasValues = isUpdate
    ? gwpModel?.gasValues?.find((gv) => gv.gasFormula === "N2O")
    : selectedYearGwpModel?.gasValues?.find((gv) => gv.gasFormula === "N2O");
  const ch4GasValues = isUpdate
    ? gwpModel?.gasValues?.find((gv) =>
        isFossil ? gv.gasFormula === "CH4_Fossil" : gv.gasFormula === "CH4"
      )
    : selectedYearGwpModel?.gasValues?.find((gv) =>
        isFossil ? gv.gasFormula === "CH4_Fossil" : gv.gasFormula === "CH4"
      );

  return (
    <Form.Text>
      {!!selectedEmissionFactor.id && (
        <>
          EF (
          {!(
            selectedEmissionFactor?.co2EmissionFactor &&
            selectedEmissionFactor?.ch4EmissionFactor &&
            selectedEmissionFactor?.n2oEmissionFactor
          ) ? (
            <>
              {Intl.NumberFormat("en-us", {
                maximumSignificantDigits: 20,
              }).format(selectedEmissionFactor.emissionFactor)}{" "}
              <small>
                tCO<sub>2</sub>e
              </small>
              {", "}
            </>
          ) : (
            <></>
          )}
          {selectedEmissionFactor.co2EmissionFactor
            ? Intl.NumberFormat("en-us", {
                maximumSignificantDigits: 20,
              }).format(selectedEmissionFactor.co2EmissionFactor)
            : "n/a"}{" "}
          <small>
            tCO<sub>2</sub>
          </small>
          {", "}
          {selectedEmissionFactor.ch4EmissionFactor
            ? Intl.NumberFormat("en-us", {
                maximumSignificantDigits: 20,
              }).format(selectedEmissionFactor.ch4EmissionFactor)
            : "n/a"}{" "}
          <small>
            tCH<sub>4</sub>
          </small>
          {", "}
          {selectedEmissionFactor.n2oEmissionFactor
            ? Intl.NumberFormat("en-us", {
                maximumSignificantDigits: 20,
              }).format(selectedEmissionFactor.n2oEmissionFactor)
            : "n/a"}{" "}
          <small>
            tN<sub>2</sub>O
          </small>
          ) /{selectedEmissionFactor.unit}{" "}
          {isInflationFactorAppliedOnActivity(selectedActivityTypeDataset) &&
            `, Inflation Factor ${getInflationRateFromYear(
              year,
              selectedActivityTypeDataset
            )}`}
          <br />
          {isUpdate
            ? gwpModel?.name
            : !(
                selectedEmissionFactor?.co2EmissionFactor &&
                selectedEmissionFactor?.ch4EmissionFactor &&
                selectedEmissionFactor?.n2oEmissionFactor
              )
            ? selectedActivityTypeDataset?.gwpModel?.name
            : isUpdate &&
              !(
                selectedEmissionFactor?.co2EmissionFactor &&
                selectedEmissionFactor?.ch4EmissionFactor &&
                selectedEmissionFactor?.n2oEmissionFactor
              )
            ? selectedActivityTypeDataset?.gwpModel?.name
            : selectedYearGwpModel?.name}{" "}
          GWP Values:{" "}
          <small>
            CO<sub>2</sub>
          </small>
          {" - "}
          {co2GasValues?.value
            ? Intl.NumberFormat("en-us", {
                maximumSignificantDigits: 20,
              }).format(co2GasValues?.value)
            : "n/a"}
          {", "}
          <small>
            CH<sub>4</sub>
          </small>
          {" - "}
          {ch4GasValues?.value
            ? Intl.NumberFormat("en-us", {
                maximumSignificantDigits: 20,
              }).format(ch4GasValues?.value)
            : "n/a"}
          {", "}
          <small>
            N<sub>2</sub>O
          </small>
          {" - "}
          {n2oGasValues?.value
            ? Intl.NumberFormat("en-us", {
                maximumSignificantDigits: 20,
              }).format(n2oGasValues?.value)
            : "n/a"}{" "}
        </>
      )}
    </Form.Text>
  );
}

ReportActivityEmissionFactorRateHelperText.propTypes = {
  values: PropTypes.shape({
    resource: PropTypes.string.isRequired,
  }).isRequired,
  selectedEmissionFactor: PropTypes.object.isRequired,
  selectedActivityTypeDataset: PropTypes.object.isRequired,
};

export const renderEmisisonResourceFieldv2 = (
  values,
  handleBlur,
  handleChange,
  selectedEmissionFactor,
  setSelectedEmissionFactor,
  selectedActivityTypeDataset,
  emptyEmFactor,
  emissionFactors,
  isLoading,
  isInvestments = false,
  isInvestmentProject = false,
  isUpdate,
  isYearLocked,
  organizationGwpModels
) => {
  const Field = (
    <EmissionResourceFieldv2
      fieldValue={values.resource}
      fieldLabel={
        isInvestments ? (
          <span>
            Select primary industry sector of{" "}
            {isInvestmentProject ? " investment" : " project"}
            <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
          </span>
        ) : undefined
      }
      isLoading={isLoading}
      keyValue={values.reference + values.resource}
      values={values}
      handleBlur={handleBlur}
      handleChange={handleChange}
      options={getEmissionFactorOptions(emissionFactors)}
      selectedOption={{
        value: selectedEmissionFactor.id,
        label: selectedEmissionFactor.emissionResource,
      }}
      handleOnChange={(option, form, field) => {
        if (option) {
          form.setFieldValue(field.name, option.value);
          setSelectedEmissionFactor(
            getEmissionFactorFromId(emissionFactors, option.value)
          );
        } else {
          form.setFieldValue(field.name, "");
          setSelectedEmissionFactor(emptyEmFactor);
        }
        return option;
      }}
      isUpdate={isUpdate}
      isYearLocked={isYearLocked}
    >
      {values.resource && (
        <ReportActivityEmissionFactorRateHelperText
          values={values}
          selectedEmissionFactor={selectedEmissionFactor}
          selectedActivityTypeDataset={selectedActivityTypeDataset}
          isUpdate={isUpdate}
          organizationGwpModels={organizationGwpModels}
        />
      )}
    </EmissionResourceFieldv2>
  );

  return Field;
};

export const renderEmisisonResourceFieldNonSmev2 = (
  values,
  handleBlur,
  handleChange,
  emissionFactors,
  isLoading
) => {
  const Field = (
    <EmissionResourceFieldv2
      fieldValue={values.resource}
      isLoading={isLoading}
      keyValue={values.reference + values.resource}
      values={values}
      handleBlur={handleBlur}
      handleChange={handleChange}
      options={getEmissionFactorOptions(emissionFactors)}
    />
  );

  return Field;
};

export function CountryField(props) {
  const {
    values,
    fieldValue,
    handleBlur,
    handleChange,
    selectedOption,
    children,
    fieldLabel,
    setError,
    setFieldValue,
    defaultCountry,
    isUpdate,
    isYearLocked,
    fetchEmissionFactors,
    isSelectedDatasetExiobase,
  } = props;

  const [fieldOptions, setFieldOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOption, setDefaultOption] = useState();

  const subscribedPromises = useRef([]);

  const fetchCountries = useCallback(() => {
    setIsLoading(true);

    const countryPromise = get("countries", {
      params: {
        datasetId: isSelectedDatasetExiobase ? values.reference : undefined,
      },
    });
    countryPromise.promise
      .then((response) => {
        setFieldOptions(
          response.data.map((country) => {
            if (
              country.name
                .toLowerCase()
                .includes(defaultCountry.toLowerCase()) &&
              !fieldValue
            ) {
              setDefaultOption({ label: country.name, value: country.id });
              setFieldValue("country", country.id);
            }
            return {
              label: country.name,
              value: country.id,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(countryPromise);
  }, [
    setError,
    setFieldValue,
    fieldValue,
    defaultCountry,
    isSelectedDatasetExiobase,
    values.reference,
  ]);

  useEffect(() => {
    fetchCountries();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchCountries]);

  const handleValidateCountryField = (value) => {
    if (!value) {
      return "Country field is required";
    }
  };

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <Form.Group controlId="activity-country" className="my-4">
      <Form.Label>{fieldLabel}</Form.Label>
      <Field
        name="country"
        value={fieldValue}
        isValid={fieldValue}
        onBlur={handleBlur}
        onChange={handleChange}
        validate={handleValidateCountryField}
      >
        {({ field, form }) => (
          <SelectInputFormik
            name="country"
            values={values}
            options={fieldOptions}
            isLoading={isLoading}
            form={form}
            field={field}
            selectedOption={!fieldValue ? selectedOption : defaultOption}
            isDisabled={checkYearLock}
            onChange={(option) => {
              if (option) {
                setFieldValue(field.name, option.value);
                isSelectedDatasetExiobase &&
                  fetchEmissionFactors(
                    values.activityType,
                    values.reportingMethod,
                    values.reference,
                    option.label
                  );
              } else {
                setFieldValue(field.name, "");
              }
            }}
          />
        )}
      </Field>
      {children}
    </Form.Group>
  );
}

CountryField.defaultProps = {
  children: null,
  fieldLabel: "Select country of activity",
  defaultCountry: "United States",
  isSelectedDatasetExiobase: false,
};

CountryField.propTypes = {
  values: PropTypes.object.isRequired,
  fieldValue: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  children: PropTypes.element,
  fieldLabel: PropTypes.string,
  defaultCountry: PropTypes.string,
  isSelectedDatasetExiobase: PropTypes.bool,
};

export function CustomEFFields({
  values,
  isInvestments,
  handleBlur,
  handleChange,
  errors,
  touched,
  setFieldValue,
  isLoading,
  setSelectedEmissionFactor,
  emissionFactors,
  emptyEmFactor,
  selectedEmissionFactor,
  activityAttachments,
  handleDeleteAttachment,
  selectedActivity,
  isInvestmentProject = true,
  scope,
  isUpdate,
  isYearLocked,
}) {
  const checkYearLock = isUpdate && isYearLocked;
  return (
    <>
      {values.activityType && isInvestmentProject && (
        <EmissionResourceField
          fieldValue={values.resource}
          fieldLabel={
            isInvestments ? (
              <span>
                Select primary industry sector of
                {isInvestmentProject ? "investment" : "project"}
                <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
              </span>
            ) : (
              <span>
                Search or select the type of resource used
                <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
              </span>
            )
          }
          keyValue={values.activityType + values.resource}
          isLoading={isLoading}
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          options={removeDuplicateOptions(
            removeUnitsToDuplicateEmissionFactors(emissionFactors),
            "label"
          )}
          handleOnChange={(option, form, field) => {
            if (option) {
              form.setFieldValue(field.name, option.value);
              setSelectedEmissionFactor(
                getEmissionFactorFromId(emissionFactors, option.value)
              );
            } else {
              form.setFieldValue(field.name, "");
              setSelectedEmissionFactor(emptyEmFactor);
            }
            return option;
          }}
          selectedOption={{
            value: selectedEmissionFactor.id,
            label: selectedEmissionFactor.emissionResource,
          }}
          isUpdate={isUpdate}
          isYearLocked={isYearLocked}
        />
      )}
      <Form.Group controlId="resource-amount" className="mt-3">
        <Form.Label>
          Quantity
          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
        </Form.Label>
        <NumberFormat
          customInput={Form.Control}
          name="amount"
          value={values.amount}
          thousandSeparator={true}
          onValueChange={(numberItem) => {
            setFieldValue("amount", numberItem.value);
          }}
          placeholder={"Enter quantity of resource"}
          onBlur={handleBlur}
          disabled={checkYearLock}
        />
        <Form.Text className="text-danger">
          {touched.amount && errors.amount}
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="customEmissionFactorUnit" className="my-3">
        <Form.Label>
          Select unit of measurement{" "}
          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
        </Form.Label>
        <Field
          name="customEmissionFactorUnit"
          value={
            ["t", "metric ton"].includes(values.customEmissionFactorUnit)
              ? "tonne"
              : ["short ton"].includes(values.customEmissionFactorUnit)
              ? "ton (US)"
              : ["gallon"].includes(values.customEmissionFactorUnit)
              ? "US gallon"
              : values.customEmissionFactorUnit
          }
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {({ field, form }) => (
            <SelectInputFormik
              name="customEmissionFactorUnit"
              values={values}
              options={
                ["5.4 - Commercial real estate", "5.5 - Mortgages"].includes(
                  selectedActivity
                )
                  ? EmissionResourceAreaUnits
                  : EmissionResourceUnits?.filter((data) =>
                      scope !== 3 ? data?.label !== "Currencies" : data
                    )
              }
              autoSort={false}
              form={form}
              field={field}
              selectedOption={{
                label: ["t", "metric ton"].includes(
                  values.customEmissionFactorUnit
                )
                  ? "tonne"
                  : ["short ton"].includes(values.customEmissionFactorUnit)
                  ? "ton (US)"
                  : ["gallon"].includes(values.customEmissionFactorUnit)
                  ? "US gallon"
                  : ["Unit"].includes(values.customEmissionFactorUnit)
                  ? "unit"
                  : values.customEmissionFactorUnit,
                value: ["t", "metric ton"].includes(
                  values.customEmissionFactorUnit
                )
                  ? "tonne"
                  : ["short ton"].includes(values.customEmissionFactorUnit)
                  ? "ton (US)"
                  : ["gallon"].includes(values.customEmissionFactorUnit)
                  ? "US gallon"
                  : ["Unit"].includes(values.customEmissionFactorUnit)
                  ? "unit"
                  : values.customEmissionFactorUnit,
              }}
              isDisabled={checkYearLock}
            />
          )}
        </Field>
        <Form.Text className="text-danger">
          {touched.customEmissionFactorUnit && errors.customEmissionFactorUnit}
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="resource-ef">
        <Form.Label>
          Custom emission factor (
          <small>
            tCO<sub>2</sub>e
          </small>
          {"/"}
          {values.customEmissionFactorUnit})
          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
        </Form.Label>

        <NumberFormat
          customInput={Form.Control}
          name="customEmissionFactor"
          value={values.customEmissionFactor}
          thousandSeparator={true}
          onValueChange={(numberItem) => {
            setFieldValue("customEmissionFactor", numberItem.value);
          }}
          placeholder={"Enter emission factor"}
          onBlur={handleBlur}
          disabled={checkYearLock}
        />
        <Form.Text className="text-danger">
          {touched.customEmissionFactor && errors.customEmissionFactor}
        </Form.Text>
        <Form.Text className="text-muted">
          {values.amount && values.customEmissionFactor ? (
            <>
              This will amount to{" "}
              <span className="fw-bold">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 6,
                  minimumFractionDigits: 2,
                }).format(
                  Number.parseFloat(values.amount) *
                    Number.parseFloat(values.customEmissionFactor)
                )}{" "}
                tCO<sub>2</sub>e
              </span>
            </>
          ) : (
            <></>
          )}
        </Form.Text>
      </Form.Group>
      {isInvestments && (
        <InvestmentFields
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          selectedActivity={selectedActivity}
          isUpdate={isUpdate}
          isYearLocked={isYearLocked}
        />
      )}
      <EmissionCommentField
        fieldValue={values.comment}
        handleBlur={handleBlur}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        isUpdate={isUpdate}
        isYearLocked={isYearLocked}
      />
      <span className="mt-2">
        <FileUploadField
          fieldName="file"
          touched={touched}
          errors={errors}
          values={values}
          handleBlur={handleBlur}
          handleChange={(event) => {
            const _file = event.currentTarget.files[0];
            setFieldValue("file", _file);
          }}
          attachments={activityAttachments}
          handleDeleteAttachment={handleDeleteAttachment}
          isUpdate={isUpdate}
        />
      </span>
    </>
  );
}

export function PrecalcEmissionFields({
  values,
  isInvestments,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  touched,
  isLoading,
  setSelectedEmissionFactor,
  emissionFactors,
  emptyEmFactor,
  selectedEmissionFactor,
  activityAttachments,
  handleDeleteAttachment,
  selectedActivity,
  isInvestmentProject = true,
  scope,
  isUpdate,
  isYearLocked,
}) {
  const checkYearLock = isUpdate && isYearLocked;

  return (
    <>
      {values.activityType && (
        <span key={values.activityType + values.reportingMethod}>
          {isInvestmentProject && (
            <EmissionResourceField
              fieldValue={values.resource}
              fieldLabel={
                isInvestments ? (
                  <span>
                    Select primary industry sector of
                    {isInvestmentProject ? " investment" : " project"}
                    <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                  </span>
                ) : (
                  <span>
                    Search or select the type of resource used
                    <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                  </span>
                )
              }
              keyValue={values.activityType + values.resource}
              isLoading={isLoading}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              options={removeDuplicateOptions(
                removeUnitsToDuplicateEmissionFactors(emissionFactors),
                "label"
              )}
              handleOnChange={(option, form, field) => {
                if (option) {
                  form.setFieldValue(field.name, option.value);
                  setSelectedEmissionFactor(
                    getEmissionFactorFromId(emissionFactors, option.value)
                  );
                } else {
                  form.setFieldValue(field.name, "");
                  setSelectedEmissionFactor(emptyEmFactor);
                }
                return option;
              }}
              selectedOption={{
                value: selectedEmissionFactor.id,
                label: selectedEmissionFactor.emissionResource,
              }}
              isUpdate={isUpdate}
              isYearLocked={isYearLocked}
            />
          )}

          <Form.Group controlId="resource-amount" className="mt-3">
            <Form.Label>Quantity (optional)</Form.Label>

            <NumberFormat
              customInput={Form.Control}
              name="amount"
              value={values.amount}
              thousandSeparator={true}
              onValueChange={(numberItem) => {
                setFieldValue("amount", numberItem.value);
              }}
              placeholder={"Enter quantity of resource"}
              onBlur={handleBlur}
              disabled={checkYearLock}
            />
            <Form.Text className="text-danger">
              {touched.amount && errors.amount}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="customEmissionFactorUnit" className="my-3">
            <Form.Label>Select unit of measurement (optional)</Form.Label>
            <Field
              name="customEmissionFactorUnit"
              value={
                ["t", "metric ton"].includes(values.customEmissionFactorUnit)
                  ? "tonne"
                  : ["short ton"].includes(values.customEmissionFactorUnit)
                  ? "ton (US)"
                  : ["gallon"].includes(values.customEmissionFactorUnit)
                  ? "US gallon"
                  : values.customEmissionFactorUnit
              }
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {({ field, form }) => (
                <SelectInputFormik
                  name="customEmissionFactorUnit"
                  values={values}
                  options={
                    [
                      "5.4 - Commercial real estate",
                      "5.5 - Mortgages",
                    ].includes(selectedActivity)
                      ? EmissionResourceAreaUnits
                      : EmissionResourceUnits?.filter((data) =>
                          scope !== 3 ? data?.label !== "Currencies" : data
                        )
                  }
                  autoSort={false}
                  form={form}
                  field={field}
                  selectedOption={{
                    label: ["t", "metric ton"].includes(
                      values.customEmissionFactorUnit
                    )
                      ? "tonne"
                      : ["short ton"].includes(values.customEmissionFactorUnit)
                      ? "ton (US)"
                      : ["gallon"].includes(values.customEmissionFactorUnit)
                      ? "US gallon"
                      : ["Unit"].includes(values.customEmissionFactorUnit)
                      ? "unit"
                      : values.customEmissionFactorUnit,
                    value: ["t", "metric ton"].includes(
                      values.customEmissionFactorUnit
                    )
                      ? "tonne"
                      : ["short ton"].includes(values.customEmissionFactorUnit)
                      ? "ton (US)"
                      : ["gallon"].includes(values.customEmissionFactorUnit)
                      ? "US gallon"
                      : ["Unit"].includes(values.customEmissionFactorUnit)
                      ? "unit"
                      : values.customEmissionFactorUnit,
                  }}
                  isDisabled={checkYearLock}
                />
              )}
            </Field>
          </Form.Group>

          <Form.Group controlId="resource-amount">
            <Form.Label>
              {isInvestments ? (
                <>
                  Provide the emissions of the project in tCO<sub>2</sub>e
                </>
              ) : (
                <>
                  {" "}
                  Provide the emissions in tCO
                  <sub>2</sub>e
                </>
              )}
              <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
            </Form.Label>

            <NumberFormat
              customInput={Form.Control}
              value={values.emissions}
              thousandSeparator={true}
              onValueChange={(numberItem) => {
                setFieldValue("emissions", numberItem.value);
              }}
              name="emissions"
              placeholder="Enter value of emission"
              onBlur={handleBlur}
              isValid={values.emissions}
              isInvalid={errors.emissions && touched.emissions}
              disabled={checkYearLock}
            />

            <Form.Control.Feedback type="invalid">
              {errors.emissions}
            </Form.Control.Feedback>
          </Form.Group>
          {isInvestments && (
            <InvestmentFields
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              selectedActivity={selectedActivity}
              isUpdate={isUpdate}
              isYearLocked={isYearLocked}
            />
          )}
          <EmissionCommentField
            fieldValue={values.comment}
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            isUpdate={isUpdate}
            isYearLocked={isYearLocked}
          />
          <span className="mt-2">
            <FileUploadField
              fieldName="file"
              touched={touched}
              errors={errors}
              values={values}
              handleBlur={handleBlur}
              handleChange={(event) => {
                const _file = event.currentTarget.files[0];
                setFieldValue("file", _file);
              }}
              attachments={activityAttachments}
              handleDeleteAttachment={handleDeleteAttachment}
              isUpdate={isUpdate}
            />
          </span>
        </span>
      )}
    </>
  );
}

export function ReportingMethodField({
  values,
  handleChange,
  handleBlur,
  isDisabled,
  fetchEmissionFactors,
  setFieldValue,
  emissionTypeOptions,
  emptyInitialValues,
  setSelectedEmissionFactor,
  emptyEmFactor,
  fetchActivityTypeDataset,
  setSelectedActivityTypeDataset,
  isUpdate,
  isYearLocked,
}) {
  const options =
    emissionTypeOptions.length > 0
      ? emissionTypeOptions
      : [
          {
            label: REPORTING_METHODS.customEF,
            value: REPORTING_METHODS.customEF,
            visible: true,
          },
          {
            label: REPORTING_METHODS.directUserInput,
            value: REPORTING_METHODS.directUserInput,
            visible: true,
          },
          {
            label: REPORTING_METHODS.standardEF,
            value: REPORTING_METHODS.standardEF,
            visible: true,
          },
        ];

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <Form.Group controlId="reporting-method" className="mt-3">
      <Form.Label>Report Emissions Using</Form.Label>
      <Field
        name="reportingMethod"
        value={values.reportingMethod}
        onChange={handleChange}
        onBlur={handleBlur}
        isValid={values.reportingMethod}
      >
        {({ field, form }) => (
          <SelectInputFormik
            name={field.name}
            values={values}
            options={options
              .filter((opt) => opt.visible)
              .map(({ label, value }) => ({
                label,
                value,
              }))}
            form={form}
            field={field}
            isDisabled={isDisabled || checkYearLock}
            onChange={(option) => {
              if (
                option &&
                option.value?.includes(REPORTING_METHODS.standardEF)
              ) {
                fetchActivityTypeDataset(values.activityType);
              } else {
                fetchEmissionFactors(values.activityType, option.value);
              }

              const {
                year,
                activityType,
                vehicleType,
                projectType,
                subActivityType,
              } = values;

              for (const [key, value] of Object.entries(emptyInitialValues)) {
                if (key === field.name) {
                  setFieldValue(key, option.value);
                } else if (key === "year") {
                  setFieldValue(key, year);
                } else if (key === "activityType") {
                  setFieldValue(key, activityType);
                } else if (key === "vehicleType") {
                  setFieldValue(key, vehicleType);
                } else if (key === "projectType") {
                  setFieldValue(key, projectType);
                } else if (key === "subActivityType") {
                  setFieldValue(key, subActivityType);
                } else {
                  setFieldValue(key, value);
                }
              }
              setSelectedEmissionFactor(emptyEmFactor);
              setSelectedActivityTypeDataset(emptyEmFactor.dataset);
            }}
          />
        )}
      </Field>
    </Form.Group>
  );
}

ReportingMethodField.defaultProps = {
  emissionTypeOptions: [],
  isDisabled: false,
};

ReportingMethodField.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  fetchEmissionFactors: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  emissionTypeOptions: PropTypes.array,
  isDisabled: PropTypes.bool,
};

export const isExiobase2019Dataset = (datasetName) =>
  datasetName?.toLowerCase().includes("exiobase") &&
  datasetName?.toLowerCase().includes("2019");

export function StandardDatasetEFFields({
  values,
  isInvestments,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  touched,
  isLoading,
  setSelectedEmissionFactor,
  emissionFactors,
  emptyEmFactor,
  selectedEmissionFactor,
  activityAttachments,
  handleDeleteAttachment,
  setError,
  activity,
  selectedActivityTypeDataset,
  emptyInitialValues,
  activityTypeDatasets,
  setSelectedActivityTypeDataset,
  fetchEmissionFactorsAndDatasets,
  fetchEmissionFactors,
  selectedActivity,
  isInvestmentProject = true,
  isUpdate,
  isYearLocked,
  organizationGwpModels,
}) {
  const checkYearLock = isUpdate && isYearLocked;

  return (
    <>
      <ReferenceTypeField
        fieldValue={values.reference}
        handleBlur={handleBlur}
        handleChange={handleChange}
        values={values}
        datasets={activityTypeDatasets}
        selectedActivityTypeDataset={selectedActivityTypeDataset}
        isLoading={isLoading}
        isUpdate={isUpdate}
        isYearLocked={isYearLocked}
        handleOnChange={(option, form, field) => {
          if (option) {
            const { year, activityType, reportingMethod } = values;

            for (const [key, value] of Object.entries(emptyInitialValues)) {
              if (key === field.name) {
                setFieldValue(key, option.value);
              } else if (key === "activityType") {
                setFieldValue(key, activityType);
              } else if (key === "year") {
                setFieldValue(key, year);
              } else if (key === "reportingMethod") {
                setFieldValue(key, reportingMethod);
              } else {
                setFieldValue(key, value);
              }
            }
            setFieldValue(field.name, option.value);

            const selectedDataset = activityTypeDatasets.find(
              (dataset) => dataset.id === option.value
            );
            setSelectedActivityTypeDataset(selectedDataset);

            setSelectedEmissionFactor(emptyEmFactor);

            fetchEmissionFactorsAndDatasets(
              option.value,
              activityType,
              reportingMethod,
              isExiobase2019Dataset(selectedDataset?.name)
                ? activity.emissionFactorCountry?.name || "United States"
                : ""
            );
          } else {
            const { year, activityType, reportingMethod } = values;

            for (const [key, value] of Object.entries(emptyInitialValues)) {
              if (key === field.name) {
                setFieldValue(key, option.value);
              } else if (key === "activityType") {
                setFieldValue(key, activityType);
              } else if (key === "year") {
                setFieldValue(key, year);
              } else if (key === "reportingMethod") {
                setFieldValue(key, reportingMethod);
              } else {
                setFieldValue(key, value);
              }
            }

            setFieldValue(field.name, "");

            setSelectedActivityTypeDataset(emptyEmFactor.dataset);
            setSelectedEmissionFactor(emptyEmFactor);
          }
          return option;
        }}
      >
        {touched.reference && errors.reference && (
          <Form.Text className="text-danger">{errors.reference}</Form.Text>
        )}
      </ReferenceTypeField>
      {selectedActivityTypeDataset &&
        selectedActivityTypeDataset.type === "Financial" && (
          <CountryField
            values={values}
            fieldValue={values.country}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setError={setError}
            setFieldValue={setFieldValue}
            selectedOption={{
              label: activity.emissionFactorCountry?.name,
              value: activity.emissionFactorCountry?.id,
            }}
            fetchEmissionFactors={fetchEmissionFactors}
            errors={errors}
            isUpdate={isUpdate}
            isYearLocked={isYearLocked}
            isSelectedDatasetExiobase={isExiobase2019Dataset(
              selectedActivityTypeDataset?.name
            )}
          >
            {touched.country && errors.country && (
              <Form.Text className="text-danger">{errors.country}</Form.Text>
            )}
          </CountryField>
        )}
      {values.reference && (
        <span key={values.reference}>
          {isInvestments && (
            <InvestmentFields
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              selectedActivity={selectedActivity}
              isUpdate={isUpdate}
              isYearLocked={isYearLocked}
            />
          )}
          {isInvestmentProject &&
            renderEmisisonResourceFieldv2(
              values,
              handleBlur,
              handleChange,
              selectedEmissionFactor,
              setSelectedEmissionFactor,
              selectedActivityTypeDataset,
              emptyEmFactor,
              emissionFactors,
              isLoading,
              isInvestments,
              isInvestmentProject,
              isUpdate,
              isYearLocked,
              organizationGwpModels
            )}
        </span>
      )}
      {values.resource && (
        <span>
          <EmissionAmountField
            fieldValue={values.amount}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            emissionUnit={selectedEmissionFactor.unit}
            isUpdate={isUpdate}
            isYearLocked={isYearLocked}
            selectedActivityTypeDataset={selectedActivityTypeDataset}
          />
          {selectedActivityTypeDataset?.type === "Physical" &&
            !checkYearLock &&
            ![
              "letter",
              "parcel",
              "Room per night",
              "employee per day",
              "tonne.km",
              "per FTE Working Hour",
              "kWh/m2.year",
              "million litres",
            ]?.some(
              (elem) => elem?.trim() === selectedEmissionFactor?.unit?.trim()
            ) && (
              <UnitConverter
                setFieldValue={setFieldValue}
                values={values}
                emissionUnit={selectedEmissionFactor.unit}
              />
            )}
          {((selectedActivityTypeDataset?.type === "Financial" &&
            !checkYearLock &&
            selectedEmissionFactor?.unit === "USD" &&
            selectedActivityTypeDataset?.inflationFactors?.some(
              (activities) => activities?.year === Number(values?.year)
            )) ||
            (selectedActivityTypeDataset?.type === "Financial" &&
              !checkYearLock &&
              selectedActivityTypeDataset?.name === "Exiobase 2019")) && (
            <CurrencyConverter
              setFieldValue={setFieldValue}
              reportingYear={values?.year}
              values={values}
              emissionUnit={"USD"}
              selectedOption={{
                label: activity?.rawUnit,
                value: activity?.rawUnit,
              }}
              module={
                selectedActivityTypeDataset?.name === "Exiobase 2019"
                  ? "exiobase"
                  : ""
              }
            />
          )}
          <EmissionCommentField
            fieldValue={values.comment}
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            isUpdate={isUpdate}
            isYearLocked={isYearLocked}
          />
          <span className="mt-2">
            <FileUploadField
              fieldName="file"
              touched={touched}
              errors={errors}
              values={values}
              handleBlur={handleBlur}
              handleChange={(event) => {
                const _file = event.currentTarget.files[0];
                setFieldValue("file", _file);
              }}
              attachments={activityAttachments}
              handleDeleteAttachment={handleDeleteAttachment}
            />
          </span>
        </span>
      )}
    </>
  );
}

export function InvestmentFields({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  selectedActivity,
  isUpdate,
  isYearLocked,
}) {
  const env = process.env.REACT_APP_BEE_ENDPOINT.includes(
    "sandbox-bee.netzerocompass.com"
  );

  const contentId = PcafContentArticles?.find((data) =>
    selectedActivity?.includes(data?.section)
  );

  const checkYearLock = isUpdate && isYearLocked;

  return (
    <>
      <Form.Group controlId="activity-equityOwnership" className="my-4">
        <Form.Label>
          Provide attribution factor (%)
          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
        </Form.Label>
        <Form.Control
          type="number"
          name="equityOwnership"
          value={values.equityOwnership}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
          onBlur={handleBlur}
          placeholder="Enter attribution factor percentage(0-100)"
          isValid={values.equityOwnership}
          isInvalid={errors.equityOwnership && touched.equityOwnership}
          disabled={checkYearLock}
        />
        <Form.Control.Feedback type="invalid">
          {errors.equityOwnership}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="activity-pcafQodScore" className="my-4">
        <Form.Label>Provide the PCAF Data Quality Score (optional)</Form.Label>
        <Form.Control
          type="number"
          name="pcafQodScore"
          value={values.pcafQodScore}
          onChange={handleChange}
          onWheel={(e) => e.target.blur()}
          onBlur={handleBlur}
          placeholder="Enter PCAF Data Quality Score"
          isValid={values.pcafQodScore}
          isInvalid={errors.pcafQodScore && touched.pcafQodScore}
        />
        {contentId && (
          <Link
            to={`/contents/${
              env
                ? contentId.sandboxContentArticleId
                : contentId.productionContentArticleId
            }`}
            target="_blank"
          >
            PCAF Score Guide{" "}
            <span className="material-icons md-18 material-icons-outlined">
              open_in_new
            </span>
          </Link>
        )}
        <Form.Control.Feedback type="invalid">
          {errors.pcafQodScore}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

export function DataTags({ setFieldValue, values }) {
  const [selectedOptions, setSelectedOptions] = useState(
    values?.tags?.map((tag) => ({ label: tag?.value, value: tag?.id }))
  );
  const [show, setShow] = useState(false);
  const [tag, setTag] = useState("");
  const [fieldOptions, setFieldOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const account = useContext(AccountContext);

  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  };

  const SortableMultiValue = SortableElement((props) => {
    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return (
      <components.MultiValue {...props} innerProps={innerProps}>
        <span>{props.data.label}</span>
      </components.MultiValue>
    );
  });

  const SortableMultiValueLabel = sortableHandle((props) => (
    <components.MultiValueLabel {...props} />
  ));

  const SortableSelect = SortableContainer(Select);

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setFieldValue(
      "tags",
      selectedOptions?.map((option) => option?.value)
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedOptions, oldIndex, newIndex);
    setSelectedOptions(newValue);
    setFieldValue(
      "tags",
      newValue?.map((option) => option?.value)
    );
  };

  const subscribedPromises = useRef([]);

  const fetchTags = useCallback(() => {
    setIsLoading(true);

    const fetchTagPromise = get(`accounts/${account.id}/tags`);
    fetchTagPromise.promise
      .then((response) => {
        setFieldOptions(
          response.data.map((tag) => ({
            label: tag?.value,
            value: tag?.id,
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(fetchTagPromise);
  }, [account.id, setError]);

  useEffect(() => {
    fetchTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchTags]);

  const addTag = () => {
    setError(null);
    setIsLoading(true);
    const addTagPromise = post(`accounts/${account.id}/tags`, {
      type: tag,
      value: tag,
    });
    addTagPromise.promise
      .then((response) => {
        fetchTags();
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : (
        <>
          <Form.Group controlId="tags" className="mb-2">
            <Form.Label>
              Data Tags{" "}
              <span>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      These organization-specific data tags can be used to
                      filter in the Power BI dashboard. You may select multiple
                      tags in any order.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined md-18">info</span>
                </OverlayTrigger>
              </span>
            </Form.Label>
            <SortableSelect
              useDragHandle={true}
              axis="x"
              onSortEnd={onSortEnd}
              transitionDuration={100}
              distance={4}
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              isMulti
              options={fieldOptions}
              value={selectedOptions}
              onChange={handleChange}
              helperClass="sortableHelper"
              placeholder="Search or select data tags..."
              components={{
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#E9EFC0",
                  primary: "#007a5f",
                },
              })}
              styles={{
                control: (ownStyles) => ({
                  ...ownStyles,
                  borderColor: values["tags"] ? "#007a5f" : "#EEEEEE",
                }),
                dropdownIndicator: (ownStyles) => ({
                  ...ownStyles,
                  color: "#212121",
                }),
                multiValue: (provided) => ({
                  ...provided,
                  cursor: "grab",
                }),
              }}
            />
          </Form.Group>
          <div className="py-2">
            <Button
              variant="link"
              onClick={() => {
                setShow(!show);
              }}
              className="p-0 mt-n3"
            >
              Create a Data Tag
            </Button>
            <Collapse in={show}>
              <div>
                <Alert
                  variant="dark"
                  className="bg-light"
                  dismissible
                  onClose={() => setShow(!show)}
                >
                  <Form.Control
                    type="text"
                    name="tag"
                    className="mt-4"
                    value={tag}
                    placeholder="Enter data tag name"
                    onChange={(e) => setTag(e.target.value)}
                  />
                  <div className="my-3 text-end">
                    <>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={(e) => {
                          e.preventDefault();
                          setTag("");
                          setShow(!show);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        size="sm"
                        disabled={!tag}
                        onClick={(e) => {
                          e.preventDefault();
                          addTag();
                          setTag("");
                          setShow(!show);
                        }}
                      >
                        Save
                      </Button>
                    </>
                  </div>
                </Alert>
              </div>
            </Collapse>
          </div>
        </>
      )}
    </>
  );
}

export function OrganizationGrouping({
  setFieldValue,
  values,
  handleBlur,
  organization,
}) {
  const [selectedOptions, setSelectedOptions] = useState({
    label: values?.organizationGrouping?.value,
    value: values?.organizationGrouping?.id,
  });
  const [show, setShow] = useState(false);
  const [organizationGrouping, setOrganizationGrouping] = useState("");
  const [fieldOptions, setFieldOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const account = useContext(AccountContext);
  const subscribedPromises = useRef([]);

  const handleChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setSelectedOptions(selectedOption);
    setFieldValue("organizationGrouping", [selectedOption?.value]);
  };

  const fetchTags = useCallback(() => {
    setIsLoading(true);

    const fetchTagPromise = get(
      `accounts/${account.id}/organization/${organization?.id}/tags/primary`
    );
    fetchTagPromise.promise
      .then((response) => {
        setFieldOptions(
          response.data.map((tag) => ({
            label: tag?.value,
            value: tag?.id,
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(fetchTagPromise);
  }, [account.id, setError]);

  useEffect(() => {
    fetchTags();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchTags]);

  const addTag = () => {
    setError(null);
    setIsLoading(true);
    const addTagPromise = post(`accounts/${account.id}/tags`, {
      type: organizationGrouping,
      value: organizationGrouping,
      is_primary: 1,
      organization_id: organization?.id,
    });
    addTagPromise.promise
      .then((response) => {
        fetchTags();
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : (
        <>
          <Form.Group controlId="organizationGrouping" className="mb-2">
            <Form.Label>
              Custom Emission Grouping{" "}
              <span>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      This organization-specific grouping classifies emissions
                      and can be used for data aggregation in the Power BI
                      dashboard.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined md-18">info</span>
                </OverlayTrigger>
              </span>
            </Form.Label>
            <Field
              name="organizationGrouping"
              value={selectedOptions}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {({ field, form }) => (
                <SelectInputFormik
                  {...field}
                  id="organizationGrouping"
                  values={values}
                  options={fieldOptions}
                  form={form}
                  field={field}
                  isLoading={isLoading}
                  selectedOption={selectedOptions}
                  onChange={handleChange}
                />
              )}
            </Field>
          </Form.Group>
          <div className="py-2">
            <Button
              variant="link"
              onClick={() => {
                setShow(!show);
              }}
              className="p-0 mt-n3"
            >
              Create a Custom Emission Grouping
            </Button>
            <Collapse in={show}>
              <div>
                <Alert
                  variant="dark"
                  className="bg-light"
                  dismissible
                  onClose={() => setShow(!show)}
                >
                  <Form.Control
                    type="text"
                    name="tag"
                    className="mt-4"
                    value={organizationGrouping}
                    placeholder="Enter custom emission grouping"
                    onChange={(e) => setOrganizationGrouping(e.target.value)}
                  />
                  <div className="my-3 text-end">
                    <>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={(e) => {
                          e.preventDefault();
                          setOrganizationGrouping("");
                          setShow(!show);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        size="sm"
                        disabled={!organizationGrouping}
                        onClick={(e) => {
                          e.preventDefault();
                          addTag();
                          setOrganizationGrouping("");
                          setShow(!show);
                        }}
                      >
                        Save
                      </Button>
                    </>
                  </div>
                </Alert>
              </div>
            </Collapse>
          </div>
        </>
      )}
    </>
  );
}
