import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import { useParams } from "react-router-dom";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import { checkIfIsInBau, formatNumber, unionByYear } from "../helper";
import {
  getBusinessAsUsual,
  getBusinessAsUsualHeatingFuels,
  getBusinessAsUsualMisc,
  getBusinessAsUsualTransport,
  getEconomicGrowth,
  getGridDecarbonizationBAU,
  getHeatingFuelsDecarbonizationBAU,
  getMergedSTBi,
  getMiscDecarbonizationBAU,
  getTransportDecarbonizationBAU,
} from "../forecastingModels";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import BusinessAsUsualChart from "./BusinessAsUsualChart/BusinessAsUsualChart";
import UserCategories from "utils/userCategories";
import { Alert, Dropdown, Table } from "react-bootstrap";
import { isArray } from "lodash";
import DataLayer from "utils/DataLayer";

const ForecastingOverview = ({
  isShow,
  organization,
  breadcrumbs,
  scopeOne,
  scopeTwo,
  scopeThree,
  baselineYear,
  endYear,
  forecastingConfig,
  onChartRendered,
  targetScopeOne,
  targetScopeTwo,
  targetScopeThree,
  targetBaselineYear,
  sbtiConfig,
  isForecastingUpdate,
}) => {
  const [bau, setBau] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [selectedScope, setSelectedScope] = useState("Scope 1+2+3");
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();
  const [economicGrowth, setEconomicGrowth] = useState([]);
  const [selectedYear, setSelectedYear] = useState(
    Number(targetBaselineYear) < Number(baselineYear)
      ? targetBaselineYear
      : baselineYear
  );
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [yearData, setYearData] = useState([]);
  const [
    economicGrowthEmissionPercentage,
    setEconomicGrowthEmissionPercentage,
  ] = useState(100);
  const [
    gridDecarbonizationEmissionPercentage,
    setGridDecarbonizationEmissionPercentage,
  ] = useState(100);
  const [
    gridDecarbonizationEmissionPercentageThree,
    setGridDecarbonizationEmissionPercentageThree,
  ] = useState(100);
  const [
    heatingFuelDecarbEmissionPercentage,
    setHeatingFuelDecarbEmissionPercentage,
  ] = useState(100);
  const [
    heatingFuelDecarbEmissionPercentageThree,
    setHeatingFuelDecarbEmissionPercentageThree,
  ] = useState(100);
  const [
    transportDecarbEmissionPercentage,
    setTransportDecarbEmissionPercentage,
  ] = useState(100);
  const [
    transportDecarbEmissionPercentageThree,
    setTransportDecarbEmissionPercentageThree,
  ] = useState(100);
  const [gridDecarbonization, setGridDecarbonization] = useState([]);
  const [scienceBasedTarget, setScienceBasedTarget] = useState({});
  const [decarbOfHeatingFuels, setDecarbOfHeatingFuels] = useState([]);
  const [decarbOfTransport, setDecarbOfTransport] = useState([]);
  const [miscDecarb, setMiscDecarb] = useState([]);
  const [bauDecarb, setBauDecarb] = useState([]);

  const userCategory = UserCategories();

  const memoizedConfig = useMemo(() => {
    if (forecastingConfig) {
      return JSON.parse(forecastingConfig);
    } else {
      return [];
    }
  }, [forecastingConfig]);

  const memoizedSBTiConfig = useMemo(() => {
    if (sbtiConfig) {
      return JSON.parse(sbtiConfig);
    } else {
      return [];
    }
  }, [sbtiConfig]);

  useEffect(() => {
    const getRecentYearValues = (arr) => {
      const difference = Number(baselineYear) - Number(targetBaselineYear);
      if (difference > 0) {
        const diffArr = Array(difference)
          .fill("-")
          .map((val, index) => {
            return {
              year: arr[0]?.year - (index + 1),
            };
          });

        arr.splice(0, 0, ...diffArr.reverse());
      }
      return arr?.filter(
        (val) => Number(val.year) <= Number(endYear || "2050")
      );
    };
    function fetchDecarbBAU(organizationId) {
      const bauPromise = get(
        `organizations/${organizationId}/bau-decarbonization`
      );
      Promise.all([bauPromise.promise])
        .then((responses) => {
          setBauDecarb(getRecentYearValues(responses[0]?.data));
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(bauPromise);
    }
    function fetchMiscLever(organizationId) {
      let arr = [];
      if (memoizedConfig.length) {
        const config = memoizedConfig.filter((lever) => lever.type);
        config.forEach((lever) => {
          arr = [
            ...arr,
            get(
              `organizations/${organizationId}/miscellaneous-decarbonization`,
              { params: { type: lever.type } }
            ),
          ];
        });

        if (arr.length) {
          Promise.all(arr.map((promise) => promise.promise))
            .then((responses) => {
              const decarb = responses.filter(
                (response) => response.data.length
              );
              fetchDecarbBAU(organizationId);
              setMiscDecarb(decarb);
              setIsLoading(false);
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setIsLoading(false);
        }
      }
      subscribedPromises.current.push(...arr);
    }
    function fetchForecasting(organizationId) {
      setError(null);
      setIsLoading(true);

      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );

      const gridDecarbonizationPromise = get(
        `organizations/${organizationId}/grid-decarbonization`
      );

      const SBTiPromise = get(
        `organizations/${organizationId}/science-based-target`
      );

      const decarbOfHeatingFuelsPromise = get(
        `organizations/${organizationId}/heating-fuels-decarbonization`
      );

      const decarbOfTransportPromise = get(
        `organizations/${organizationId}/transport-decarbonization`
      );

      Promise.all([
        economicGrowthPromise.promise,
        gridDecarbonizationPromise.promise,
        SBTiPromise.promise,
        decarbOfHeatingFuelsPromise.promise,
        decarbOfTransportPromise.promise,
      ])
        .then((responses) => {
          const [
            { data: economicGrowth = [] },
            { data: gridDecarb = [] },
            { data: scienceBasedTarget = [] },
            { data: decarbOfHeatingFuels = [] },
            { data: decarbOfTransport = [] },
          ] = responses || [];

          setEconomicGrowth(economicGrowth[0] ? economicGrowth[0]?.data : []);
          setEconomicGrowthEmissionPercentage(
            economicGrowth[0]
              ? economicGrowth[0]?.scopeThreeEmissionPercentage
              : []
          );
          setGridDecarbonization(gridDecarb[0] ? gridDecarb[0]?.data : []);
          setGridDecarbonizationEmissionPercentage(
            gridDecarb[0] ? gridDecarb[0]?.scopeTwoEmissionPercentage : []
          );
          setGridDecarbonizationEmissionPercentageThree(
            gridDecarb[0] ? gridDecarb[0]?.scopeThreeEmissionPercentage : []
          );
          setDecarbOfHeatingFuels(
            decarbOfHeatingFuels[0] ? decarbOfHeatingFuels[0]?.data : []
          );
          setHeatingFuelDecarbEmissionPercentage(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeOneEmissionPercentage
              : []
          );
          setHeatingFuelDecarbEmissionPercentageThree(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeThreeEmissionPercentage
              : []
          );
          setDecarbOfTransport(
            decarbOfTransport[0] ? decarbOfTransport[0]?.data : []
          );
          setTransportDecarbEmissionPercentage(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeOneEmissionPercentage
              : []
          );
          setTransportDecarbEmissionPercentageThree(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeThreeEmissionPercentage
              : []
          );
          setScienceBasedTarget(
            scienceBasedTarget[0] ? scienceBasedTarget[0] : {}
          );
          if (!memoizedConfig?.filter((lever) => lever.type).length) {
            fetchDecarbBAU(organizationId);
          } else {
            fetchMiscLever(organizationId);
          }
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(
        economicGrowthPromise,
        gridDecarbonizationPromise,
        SBTiPromise,
        decarbOfHeatingFuelsPromise,
        decarbOfTransportPromise
      );
    }

    if (!!memoizedConfig?.length) {
      fetchForecasting(organizationId);
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    memoizedConfig,
    baselineYear,
    endYear,
    targetBaselineYear,
    isForecastingUpdate,
  ]);

  return (
    <div id={userCategory}>
      <DataLayer />
      {isShow && (
        <div className="my-3">
          <BreadCrumbs
            breadcrumbs={[
              ...breadcrumbs,
              {
                name: "Forecasting",
                link: `/organizations/${organization.id}/forecasting`,
                active: true,
              },
            ]}
          />
        </div>
      )}
      {isShow && <h2 className="mb-4 pt-2">Forecasting Overview</h2>}
      {!!memoizedConfig?.length ? (
        <div className="my-3 border p-3">
          <div className="d-flex flex-row">
            <h3 className="flex-fill pt-2">
              {isShow ? "Overview" : "Forecasting Overview"}
            </h3>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  size="sm"
                  className="bg-primary bg-opacity-25 text-dark border-0"
                >
                  {selectedScope === "All"
                    ? "Filter by All Scopes"
                    : `Filter by ${selectedScope}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[
                    "Scope 1+2+3",
                    "Scope 1+2",
                    "Scope 1",
                    "Scope 2",
                    "Scope 3",
                  ].map((scope, index) => (
                    <Dropdown.Item
                      key={index}
                      className={selectedScope === scope ? "active" : ""}
                      onClick={() =>
                        setSelectedScope((prev) => {
                          if (prev === scope) return scope;

                          return scope;
                        })
                      }
                    >
                      {scope}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <hr />
          {isLoading && <Loader />}
          {!isLoading && !error && (
            <BusinessAsUsualChart
              // data={bau}
              data={bauDecarb}
              emission={scienceBasedTarget}
              selectedScope={selectedScope}
              forecastingConfig={memoizedConfig}
              onChartRendered={onChartRendered}
              sbtiConfig={memoizedSBTiConfig}
              setYearData={setYearData}
              selectedYear={selectedYear}
              selectedIndex={selectedIndex}
              yearData={yearData}
              organization={organization}
              baselineYear={baselineYear}
              targetBaselineYear={targetBaselineYear}
              targetEmissions={
                (selectedScope.includes("1") ? targetScopeOne : 0) +
                (selectedScope.includes("2") ? targetScopeTwo : 0) +
                (selectedScope.includes("3") ? targetScopeThree : 0)
              }
            />
          )}
          {isShow && !isLoading && !error && (
            <>
              <div className="mt-5">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    size="sm"
                    className="bg-primary bg-opacity-25 text-dark border-0"
                  >
                    Selected Year {selectedYear}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflow: "auto" }}
                  >
                    {bauDecarb?.map(({ year }, index) => (
                      <Dropdown.Item
                        key={year}
                        onClick={() => {
                          setSelectedIndex(index);
                          setSelectedYear(year);
                        }}
                      >
                        {year}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <h3 className="text-center mt-5">{selectedYear}</h3>
                <Table size="sm" responsive className="my-3">
                  <tbody>
                    {yearData?.map((data, index) => (
                      <tr key={index}>
                        <td className="text-start text-nowrap px-3">
                          <span
                            style={{
                              display: "inline-block",
                              height: 12,
                              width: 12,
                              borderRadius: "50%",
                              marginRight: 10,
                              backgroundColor: `${data?.color}`,
                            }}
                          ></span>{" "}
                          {data?.name}
                        </td>
                        <td className="text-end text-nowrap px-3">
                          {isArray(data?.data)
                            ? formatNumber(Number(data?.data[1])?.toFixed())
                            : formatNumber(Number(data?.data)?.toFixed())}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
      ) : (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
      {window.location.pathname.includes("/forecasting") && (
        <div className="my-4 p-3 border">
          <ContentDetails contentId={"66470d13a0162"} view={"full"} />
        </div>
      )}
    </div>
  );
};

export default ForecastingOverview;
