/**
 * All re-usable table components should be placed here.
 * Always be careful when updating these components as they are used by several other components
 * When adding new components, always add prop types if it needs props for easier documentation
 */

import React from "react";
import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export function SortArrowUp({ isActive }) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip className={isActive ? "" : "d-none"}>Ascending order</Tooltip>
      }
    >
      <span
        className={`material-icons-outlined md-16 ${
          !isActive ? "text-muted" : "fw-bold"
        }`}
        style={{ marginRight: "-0.2rem" }}
      >
        arrow_upward
      </span>
    </OverlayTrigger>
  );
}

SortArrowUp.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export function SortArrowDown({ isActive }) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip className={isActive ? "" : "d-none"}>Descending order</Tooltip>
      }
    >
      <span
        className={`material-icons-outlined md-16 ${
          !isActive ? "text-muted" : "fw-bold"
        }`}
      >
        arrow_downward
      </span>
    </OverlayTrigger>
  );
}

SortArrowDown.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export function TableSortArrows({ sortColumn, colName, sortAsc }) {
  if (sortColumn === colName) {
    return (
      <span className="text-nowrap">
        {sortAsc && <SortArrowUp isActive={sortAsc} />}
        {!sortAsc && <SortArrowDown isActive={!sortAsc} />}
      </span>
    );
  }

  return (
    <span className="text-nowrap">
      <SortArrowUp isActive={false} />
      <SortArrowDown isActive={false} />
    </span>
  );
}

TableSortArrows.propTypes = {
  sortColumn: PropTypes.string.isRequired,
  colName: PropTypes.string.isRequired,
  sortAsc: PropTypes.bool.isRequired,
};
