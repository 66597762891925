import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageReductions from "./ManageReductions/ManageReductions";
import MiscLeverChart from "./MiscLeverChart/MiscLeverChart";
import "./MiscLever.scss";
import { formatNumber, formatPercentage } from "../helper";
import {
  getEconomicGrowth,
  getMiscDecarbonization,
} from "../forecastingModels";
import { TableSortArrows } from "components/Shared/TableComponents";

const MiscLever = ({
  organization,
  breadcrumbs,
  baselineYear,
  scopeOne,
  scopeTwo,
  scopeThree,
  forecastingConfig,
  emissions,
  onLeverNameUpdated,
  isForecastingUpdate,
}) => {
  const [emission, setEmission] = useState();
  const [growthEmission, setGrowthEmission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [scopePreference, setScopePreference] = useState("");
  const [scopePreferencePercentage, setScopePreferencePercentage] =
    useState(100);
  const [
    secondaryScopePreferencePercentage,
    setSecondaryScopePreferencePercentage,
  ] = useState(100);
  const [scopeThreePercentage, setScopeThreePercentage] = useState(100);
  const [miscLeverName, setMiscLeverName] = useState("");
  const subscribedPromises = useRef([]);
  const { organizationId, type } = useParams();

  const [
    miscellaneousDecarbonizationData,
    setMiscellaneousDecarbonizationData,
  ] = useState();

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "year",
    updatedAt: true,
    sortAsc: false,
  });

  const sortingTable = (column, objValue) => {
    emission?.data?.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];
      if (macA == null) macA = 0; // Convert null to 0
      if (macB == null) macB = 0; // Convert null to 0

      if (macA === "" || macA === "n/a") {
        return tableSortState[column] ? 1 : -1;
      }

      if (macB === "" || macB === "n/a") {
        return tableSortState[column] ? -1 : 1;
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setEmission([...emission]);
  };

  const memoizedConfig = useMemo(() => {
    if (forecastingConfig) {
      return JSON.parse(forecastingConfig);
    } else {
      return [];
    }
  }, [forecastingConfig]);

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );
      const miscellaneousDecarbonizationPromise = get(
        `organizations/${organizationId}/miscellaneous-decarbonization`,
        { params: { type } }
      );
      Promise.all([
        economicGrowthPromise.promise,
        miscellaneousDecarbonizationPromise.promise,
      ])
        .then((responses) => {
          setIsLoading(false);

          const [{ data: economicGrowth = [] }, misc] = responses || [];

          setScopePreference(misc?.scopePreference);
          setScopePreferencePercentage(misc?.scopePreferencePercentage || 100);
          setSecondaryScopePreferencePercentage(
            misc?.secondaryScopePreferencePercentage || 100
          );
          setScopeThreePercentage(
            (!isNaN(Number(economicGrowth[0]?.scopeThreeEmissionPercentage)) &&
              Number(economicGrowth[0]?.scopeThreeEmissionPercentage)) ||
              100
          );
          setEmission({
            ...misc,
          });

          calculateDecarb(
            { ...misc },
            economicGrowth[0] ? economicGrowth[0]?.data : [],
            misc?.data || [],
            misc?.scopePreference || 0,
            (!isNaN(Number(misc?.scopePreferencePercentage)) &&
              Number(misc?.scopePreferencePercentage)) ||
              100,
            (!isNaN(Number(misc?.secondaryScopePreferencePercentage)) &&
              Number(misc?.secondaryScopePreferencePercentage)) ||
              0
          );

          setMiscellaneousDecarbonizationData(misc?.calculation);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(miscellaneousDecarbonizationPromise);
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    scopeOne,
    scopeTwo,
    emission?.scopePreferencePercentage,
    emission?.scopePreference,
    memoizedConfig,
    type,
    isForecastingUpdate,
  ]);

  const calculateDecarb = (
    emissions = {},
    economicGrowthData = [],
    miscData = [],
    scopePreference = 0,
    scopePreferencePercentage = 100,
    secondaryScopePreferencePercentage = 0
  ) => {
    const economicGrowthChunk = getEconomicGrowth(
      economicGrowthData,
      scopeOne,
      scopeTwo,
      scopeThree,
      scopeThreePercentage
    );
    const miscChunk = getMiscDecarbonization(
      miscData,
      economicGrowthChunk,
      scopePreference,
      scopePreferencePercentage,
      secondaryScopePreferencePercentage
    );
    setGrowthEmission(economicGrowthChunk);
    setEmission({
      ...emissions,
      data: miscChunk,
    });
  };

  useEffect(() => {
    const index = memoizedConfig.findIndex((lever) => lever.type === type);
    if (index > -1) {
      const name = memoizedConfig[index].title;
      setMiscLeverName(name);
    }
  }, [type, memoizedConfig]);

  return (
    <div>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: miscLeverName,
              link: `/organizations/${organization.id}/forecasting/3/miscellaneous-decarbonization`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">{miscLeverName}</h2>
      <div className="my-3 p-3 border">
        <h3>
          Emissions Forecast {scopePreference && `(Scope ${scopePreference})`}
          <ManageReductions
            onManageReductionsUpdated={(data) =>
              setEmission({
                ...data,
                data: getMiscDecarbonization(
                  data?.data,
                  growthEmission,
                  scopePreference,
                  scopePreferencePercentage,
                  secondaryScopePreferencePercentage
                ),
              })
            }
            emissions={emission}
            scopeEmissions={emissions}
            baselineYear={baselineYear}
            type={type}
            memoizedConfig={memoizedConfig}
            miscLeverName={miscLeverName}
            onLeverNameUpdated={(data) => onLeverNameUpdated(data)}
            isForecastingUpdate={isForecastingUpdate}
          />
        </h3>
        {!isLoading && !error && (
          <MiscLeverChart
            data={miscellaneousDecarbonizationData}
            scopeOne={scopeOne}
            miscLeverName={miscLeverName}
            scopePreference={scopePreference}
          />
        )}
        {error && !isLoading && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="MiscLeverTableDiv">
          <Table size="sm" striped responsive className="first-column-fixed">
            <thead>
              <tr>
                <th
                  className="text-end text-nowrap px-3 table-sorter year-column"
                  onClick={() => sortingTable("year")}
                >
                  Year{" "}
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"year"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() => sortingTable("reduction_percentage")}
                >
                  % Reduction in Emission Factor
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"reduction_percentage"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() => sortingTable("reduction")}
                >
                  Scope {scopePreference} Reduction{" "}
                  <small>
                    (tCO<sub>2</sub>e)
                  </small>
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"reduction"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() => sortingTable("emission")}
                >
                  Scope {scopePreference} Emissions
                  <small>
                    (tCO<sub>2</sub>e)
                  </small>
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"emission"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                {scopePreference == 1 || scopePreference == 2 ? (
                  <>
                    <th
                      className="text-end text-nowrap px-3 table-sorter"
                      onClick={() => sortingTable("s3_emission")}
                    >
                      Scope 3 Reduction{" "}
                      <small>
                        (tCO<sub>2</sub>e)
                      </small>
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"s3_emission"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                    <th
                      className="text-end text-nowrap px-3 table-sorter"
                      onClick={() => sortingTable("s3_reduction")}
                    >
                      Scope 3 Emissions
                      <small>
                        (tCO<sub>2</sub>e)
                      </small>
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"s3_reduction"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                  </>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {emission?.data?.map(
                (
                  {
                    year,
                    reduction_percentage,
                    reduction,
                    emission,
                    s3_emission,
                    s3_reduction,
                  },
                  index
                ) => (
                  <tr key={index}>
                    <td className="text-end text-nowrap px-3 year-column">
                      {year}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatPercentage(reduction_percentage)}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(reduction)}
                    </td>
                    <td className="text-end text-nowrap px-3">
                      {formatNumber(emission)}
                    </td>
                    {scopePreference == 1 || scopePreference == 2 ? (
                      <>
                        <td className="text-end text-nowrap px-3">
                          {formatNumber(s3_reduction)}
                        </td>
                        <td className="text-end text-nowrap px-3">
                          {formatNumber(s3_emission)}
                        </td>
                      </>
                    ) : null}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      )}
      {!error && !isLoading && !emission?.id && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
    </div>
  );
};

MiscLever.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  baselineYear: PropTypes.number,
  scopeOne: PropTypes.number,
};

export default MiscLever;
