import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form, Alert, Badge } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { upload } from "utils/DeApi";

import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import Loader from "components/Loader/Loader";

const ActivitiesUpload = ({ onActivitiesUploaded, site }) => {
  const subscribedPromises = useRef([]);
  const attachmentRef = useRef();

  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setIsLoading();
    setError();
    setSuccess();
    attachmentRef.current.reset();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const uploadActvities = ({ file, type }) => {
    setError();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    const filePromise = upload(`sites/${site.id}/activities/import`, formData);

    filePromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        setSuccess(true);
        attachmentRef.current.reset();
        onActivitiesUploaded();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setSuccess();
        }
      });
    subscribedPromises.current.push(filePromise);
  };

  const schema = yup.object().shape({
    file: yup
      .mixed()
      .required("A file is required")
      .test(
        "fileFormat",
        "The file should be in CSV format (.csv extension only)",
        (value) => {
          return value && ["text/csv"].includes(value.type);
        }
      ),
  });

  return (
    <>
      <Button variant="secondary" onClick={handleShow} size="sm">
        Import Activities <Badge bg="danger">BETA</Badge>
      </Button>

      <Modal
        className="ActivitiesUpload"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Import Activities <Badge bg="danger">BETA</Badge>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            uploadActvities(values);
          }}
          initialValues={{ file: "", type: "" }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            handleChange,
            touched,
            errors,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} ref={attachmentRef}>
              <Modal.Body>
                <ContentDetails contentId={"643ee76dd1f82"} />

                <Form.Group controlId="file" className="mb-3 mt-3">
                  <Form.Label>Please attach csv file.</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("file", file);
                    }}
                    onBlur={handleBlur}
                    isValid={touched.file && !errors.file}
                    accept=".csv"
                    multiple
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.file}
                  </Form.Control.Feedback>
                </Form.Group>

                {isLoading && <Loader />}
                {error && (
                  <Form.Text className="text-danger">
                    {error?.data?.message}
                  </Form.Text>
                )}
                {success && (
                  <Alert variant="info">
                    Activities uploaded successfully!
                  </Alert>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>

                <Button
                  type="submit"
                  size="sm"
                  disabled={!isValid || isLoading || !attachmentRef.current}
                >
                  Import
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ActivitiesUpload.propTypes = {
  onActivitiesUploaded: PropTypes.func.isRequired,
};

export default ActivitiesUpload;
