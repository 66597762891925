import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFormattedEmission } from "utils/StringUtils";
import SiteReviewUpdateActivity from "./SiteReviewUpdateActivity";
import { TableSortArrows } from "components/Shared/TableComponents";

const SiteReviewActivitiesTable = ({
  activities,
  handleFlagActivityAsOk,
  handleClose,
  updateSiteReviewActivity,
}) => {
  const [showComment, setShowComment] = useState();
  const [activitiesData, setActivitiesData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "year",
    updatedAt: true,
    sortAsc: false,
  });

  const sortReviewActivities = (column, objValue) => {
    activities.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];

      if (macA === null || macA === undefined || macA === "n/a") {
        return tableSortState[column] ? 1 : -1;
      }

      if (macB === null || macB === undefined || macB === "n/a") {
        return tableSortState[column] ? -1 : 1;
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setActivitiesData([...activitiesData]);
  };
  return (
    <div className="tbody-animate table-scoll site-review-table">
      <Table responsive hover size="sm">
        <thead>
          <tr>
            <th
              className="text-start table-sorter"
              onClick={() => sortReviewActivities("yearEnded")}
            >
              Year{" "}
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"yearEnded"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-start table-sorter"
              onClick={() => sortReviewActivities("activityType", "label")}
            >
              Source{" "}
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"activityType"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-start table-sorter"
              onClick={() =>
                sortReviewActivities("emissionFactor", "emissionResource")
              }
            >
              Resource{" "}
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"emissionFactor"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-center table-sorter"
              onClick={() => sortReviewActivities("scope")}
            >
              Scope{" "}
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"scope"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-end table-sorter"
              onClick={() => sortReviewActivities("amount")}
            >
              Quantity{" "}
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"amount"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-end table-sorter"
              onClick={() => sortReviewActivities("emissions")}
            >
              Emissions{" "}
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"emissions"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-end table-sorter"
              onClick={() => sortReviewActivities("updatedAt")}
            >
              UpdatedAt{" "}
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"updatedAt"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th className="text-center table-sorter">Exception Status </th>
            <th className="text-center table-sorter">Exception Comment </th>
            <th className="text-start table-sorter">Exception Cleared By </th>
            <th className="text-end table-sorter">Date Cleared </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => {
            const activityUpdatedAt = new Date(activity?.updatedAt);
            const activityEmissionFactorUpdatedAt = new Date(
              activity?.emissionFactor?.updatedAt
            );
            const anomalyMessage = activity?.isFlaggedMessage
              ? activity?.isFlaggedComments?.[0]?.body
              : activity?.reportingMethod === "Standard EF" &&
                activityEmissionFactorUpdatedAt > activityUpdatedAt
              ? "Emission factor for this activity has been updated, please recalculate the emissions as needed."
              : !activity?.isActivity && !activity?.attachments?.length
              ? "This is a user-calculated emission but has no supporting documents, please update as needed"
              : "Emission factor for this activity has been updated, please recalculate the emissions as needed.";
            return (
              <React.Fragment key={activity.id}>
                <tr>
                  <td className="text-start text-nowrap">
                    {activity.yearEnded?.substring(0, 4) || "2021"}
                  </td>
                  <td className="text-start text-nowrap">
                    {activity.activityType?.categoryNumber
                      ? `${activity.activityType?.categoryNumber}. `
                      : ""}
                    {activity.activityType?.label}
                    {activity.comment && (
                      <Button
                        variant="link"
                        size="sm"
                        className="py-0"
                        onClick={() => {
                          showComment === activity.id
                            ? setShowComment(null)
                            : setShowComment(activity.id);
                        }}
                      >
                        <span className="material-icons-outlined mt-n2 md-20">
                          info
                        </span>
                      </Button>
                    )}
                  </td>
                  <td className="text-start text-nowrap">
                    {activity.emissionFactor?.emissionResource || "n/a"}
                  </td>
                  <td className="text-center text-nowrap">{activity.scope}</td>
                  <td className="text-end text-nowrap">
                    {activity.amount !== undefined ? (
                      <>
                        {getFormattedEmission(activity.amount)}{" "}
                        <small>
                          {activity.isActivity
                            ? activity.emissionFactor?.unit
                            : activity.customEmissionFactorUnit ||
                              activity.emissionFactor?.unit}{" "}
                        </small>
                      </>
                    ) : (
                      "n/a"
                    )}{" "}
                  </td>
                  <td className="text-end text-nowrap pe-2">
                    {getFormattedEmission(activity.emissions)}{" "}
                  </td>
                  <td className="text-end text-nowrap pe-2">
                    <small>
                      {new Date(activity.updatedAt).toLocaleString([], {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                    </small>
                  </td>
                  <td className="text-center text-nowrap">
                    {activity?.isFlagged === 0 ? (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip>
                            {activity?.isFlaggedComments?.[0]?.body ||
                              "Activity is ok"}
                          </Tooltip>
                        }
                      >
                        <span className="material-icons-outlined md-20 text-success">
                          check_circle
                        </span>
                      </OverlayTrigger>
                    ) : activity?.isFlagged === 1 ? (
                      <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>{anomalyMessage}</Tooltip>}
                      >
                        <span className="material-icons-outlined md-20 text-danger">
                          report_problem
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip>
                            Unknown - run anomaly check to see status of
                            activity
                          </Tooltip>
                        }
                      >
                        <span className="material-icons-outlined md-20">
                          question_mark
                        </span>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td className="text-center text-nowrap">
                    {activity?.isFlagged ||
                    activity?.isFlaggedComments?.[0]?.body ? (
                      <SiteReviewUpdateActivity
                        activity={activity}
                        updateSiteReviewActivity={updateSiteReviewActivity}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="text-nowrap">
                    {activity?.latestException &&
                    !activity?.latestException?.isFlagged
                      ? activity?.latestException?.subscriber?.name
                      : ""}
                  </td>
                  <td className="text-end text-nowrap">
                    {activity?.latestException &&
                    !activity?.latestException?.isFlagged ? (
                      <small>
                        {new Date(
                          activity?.latestException?.updatedAt
                        ).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </small>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="text-end text-nowrap">
                    <React.Fragment>
                      {activity?.isFlagged ? (
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip>
                              {!activity?.isFlaggedComments?.[0]?.body
                                ? "Please provide a comment before clearing the exception."
                                : "Click to clear the exception."}
                            </Tooltip>
                          }
                        >
                          <span>
                            <Button
                              size="sm"
                              variant="outline-secondary"
                              className="py-0 mx-1"
                              onClick={() => updateSiteReviewActivity(activity)}
                              disabled={
                                activity?.isFlagged === undefined ||
                                !activity?.isFlagged ||
                                !activity?.isFlaggedComments?.[0]?.body
                              }
                            >
                              <span className="material-icons-outlined md-20">
                                restart_alt
                              </span>
                            </Button>
                          </span>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                      {activity?.isFlagged === undefined ||
                      activity?.isFlagged ? (
                        <Link
                          to={`/organizations/${activity?.site?.organizationId}/sites/${activity?.siteId}/scopes/${activity?.scope}?activityCategoryId=${activity?.activityTypeId}&activityCategory=${activity?.activityType?.label}&categoryNumber=${activity?.activityType?.categoryNumber}`}
                          state={{
                            year: activity.yearEnded?.substring(0, 4) || "2021",
                          }}
                        >
                          <Button
                            size="sm"
                            variant="outline-primary"
                            className="py-0 me-1"
                            onClick={handleClose}
                          >
                            <OverlayTrigger
                              placement="left"
                              overlay={<Tooltip>Review this activity</Tooltip>}
                            >
                              <span className="material-icons-outlined md-20">
                                open_in_new
                              </span>
                            </OverlayTrigger>
                          </Button>
                        </Link>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  </td>
                </tr>
                {showComment === activity.id && (
                  <tr>
                    <td className="text-center pt-1">
                      <button
                        className="btn-close btn-sm"
                        aria-label="Close"
                        onClick={() => setShowComment(null)}
                      ></button>
                    </td>
                    <td colSpan={6} className="pb-3">
                      {activity.comment}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SiteReviewActivitiesTable.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFlagActivityAsOk: PropTypes.func.isRequired,
};

export default SiteReviewActivitiesTable;
