import React, { useEffect, useState, useRef, useCallback } from "react";

import { get } from "utils/DeApi";
import { Modal, Button, Alert, Badge } from "react-bootstrap";
import { getFormattedEmission } from "utils/StringUtils";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import { Link } from "react-router-dom";
import { groupBy } from "lodash";

const AuditReview = ({ activityId, tableData, accountId }) => {
  const [show, setShow] = useState(false);

  const subscribedPromises = useRef([]);

  const [auditActivityDetails, setAudiActivityDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activityDetail = tableData?.find(
    (item) => item?.activityId === activityId
  );

  const activityGroupDetails = groupBy(
    auditActivityDetails,
    (item) => item?.type
  );

  const getYear = (year) => {
    const fullYear = new Date(year).getFullYear();

    return fullYear;
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const fetchAuditTrailsDetails = useCallback(() => {
    setError(null);
    setIsLoading(true);
    let getAuditTrails = get(`audit-trails/activities/${activityId}`, {
      params: {
        accountId: accountId,
      },
    });

    getAuditTrails.promise
      .then((response) => {
        setAudiActivityDetails(response.data);

        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(getAuditTrails);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [activityId, accountId]);

  useEffect(() => {
    if (show === true) {
      fetchAuditTrailsDetails();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchAuditTrailsDetails, show]);

  return (
    <>
      <Button
        onClick={handleShow}
        size="sm"
        className="py-0 px-3"
        variant="primary"
      >
        Review
      </Button>

      <Modal className="AuditTrails" show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Review</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Modal.Body>
              <h4>Activity Summary</h4>

              {auditActivityDetails ? (
                <div className="mt-3 modal-scroll">
                  {activityGroupDetails?.deleted && (
                    <>
                      <Badge className="bg-danger mb-2 text-white text-capitalize bg-rounded px-1 me-1">
                        {activityGroupDetails?.deleted[0]?.type}
                      </Badge>{" "}
                      by{" "}
                      <span className="text-capitalize">
                        {activityGroupDetails?.deleted[0]?.member?.name}
                      </span>
                      <span className="float-end">
                        <small>
                          {new Date(
                            activityGroupDetails?.deleted[0]?.createdAt
                          ).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                          })}
                        </small>
                      </span>
                      <p>This activity has been deleted.</p>
                    </>
                  )}
                  {activityGroupDetails?.updated && (
                    <>
                      {activityGroupDetails?.updated
                        ?.filter(
                          (filteredItem) =>
                            filteredItem.logHistory?.attributes.length !== 0
                        )
                        .map((item, index) => {
                          const timestampChanged =
                            index === 0 ||
                            new Date(item.createdAt).toLocaleTimeString() !==
                              new Date(
                                activityGroupDetails.updated[
                                  index - 1
                                ].createdAt
                              ).toLocaleTimeString();
                          return (
                            <React.Fragment key={index}>
                              {timestampChanged && (
                                <>
                                  <Badge
                                    className={
                                      "mt-3 bg-primary mb-2 text-white text-capitalize bg-rounded px-1 me-1"
                                    }
                                  >
                                    {item.type}
                                  </Badge>{" "}
                                  by{" "}
                                  <span className="text-capitalize">
                                    {item?.member?.name}
                                  </span>
                                  <span className="float-end">
                                    <small>
                                      {new Date(item.createdAt).toLocaleString(
                                        [],
                                        {
                                          dateStyle: "short",
                                          timeStyle: "short",
                                        }
                                      )}
                                    </small>
                                  </span>
                                </>
                              )}
                              <ul className="mb-0">
                                {Object.entries(
                                  item?.logHistory?.attributes
                                ).map(([attributesKey, attributesKeyValue]) => (
                                  <li key={attributesKey}>
                                    <span className="text-capitalize">
                                      {attributesKey === "yearEnded"
                                        ? "year"
                                        : attributesKey === "ch4Emissions"
                                        ? "CH4 Emissions"
                                        : attributesKey === "co2Emissions"
                                        ? "CO2 Emissions"
                                        : attributesKey === "n2oEmissions"
                                        ? "N2O Emissions"
                                        : attributesKey === "gwpBasis"
                                        ? "GWP Basis"
                                        : attributesKey
                                            .replace(/([A-Z])/g, " $1")
                                            .toLowerCase()}
                                      :
                                    </span>{" "}
                                    {Object.entries(item?.logHistory?.old).map(
                                      ([oldKey, oldValue]) =>
                                        attributesKey === oldKey && (
                                          <span key={oldKey}>
                                            <strong>
                                              {[
                                                "emissions",
                                                "amount",
                                                "pcafQodScore",
                                                "ch4Emissions",
                                                "co2Emissions",
                                                "n2oEmissions",
                                              ].includes(attributesKey)
                                                ? getFormattedEmission(
                                                    oldValue,
                                                    1
                                                  )
                                                : attributesKey === "yearEnded"
                                                ? getYear(oldValue)
                                                : attributesKey ===
                                                  "customEmissionFactor"
                                                ? parseFloat(oldValue || 0)
                                                : oldValue || "N/A"}
                                            </strong>
                                          </span>
                                        )
                                    )}{" "}
                                    to{" "}
                                    <strong>
                                      {[
                                        "emissions",
                                        "amount",
                                        "pcafQodScore",
                                        "ch4Emissions",
                                        "co2Emissions",
                                        "n2oEmissions",
                                      ].includes(attributesKey)
                                        ? getFormattedEmission(
                                            attributesKeyValue,
                                            1
                                          )
                                        : attributesKey === "yearEnded"
                                        ? getYear(attributesKeyValue)
                                        : attributesKey ===
                                          "customEmissionFactor"
                                        ? parseFloat(attributesKeyValue || 0)
                                        : attributesKeyValue || "N/A"}
                                    </strong>
                                  </li>
                                ))}
                              </ul>
                            </React.Fragment>
                          );
                        })}
                    </>
                  )}

                  {activityGroupDetails?.created && (
                    <>
                      <Badge className="bg-light-green text-primary mt-3 mb-2 text-capitalize bg-rounded px-1 me-1">
                        {activityGroupDetails?.created[0]?.type}
                      </Badge>{" "}
                      by{" "}
                      <span className="text-capitalize">
                        {activityGroupDetails?.created[0]?.member?.name}
                      </span>
                      <span className="float-end">
                        <small>
                          {new Date(
                            activityGroupDetails?.created[0]?.createdAt
                          ).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                          })}
                        </small>
                      </span>
                      <ul>
                        {activityGroupDetails?.created?.map((item, index) =>
                          Object.entries(item?.logHistory?.attributes).map(
                            ([createdKey, createdValue]) =>
                              ![
                                "metaId",
                                "id",
                                "siteId",
                                "isActivity",
                                "activityImportId",
                              ].includes(createdKey) &&
                              !!createdValue && (
                                <li
                                  className="text-capitalize"
                                  key={createdKey}
                                >
                                  {createdKey === "yearEnded"
                                    ? "year"
                                    : createdKey === "ch4Emissions"
                                    ? "CH4 Emissions"
                                    : createdKey === "co2Emissions"
                                    ? "CO2 Emissions"
                                    : createdKey === "n2oEmissions"
                                    ? "N2O Emissions"
                                    : createdKey === "gwpBasis"
                                    ? "GWP Basis"
                                    : createdKey
                                        .replace(/([A-Z])/g, " $1")
                                        .toLowerCase()}
                                  :{" "}
                                  <strong>
                                    {[
                                      "emissions",
                                      "amount",
                                      "pcafQodScore",
                                      "ch4Emissions",
                                      "co2Emissions",
                                      "n2oEmissions",
                                    ].includes(createdKey)
                                      ? getFormattedEmission(createdValue, 1)
                                      : createdKey === "yearEnded"
                                      ? getYear(createdValue)
                                      : createdKey === "customEmissionFactor"
                                      ? parseFloat(createdValue || 0)
                                      : createdValue}
                                  </strong>
                                </li>
                              )
                          )
                        )}
                      </ul>
                    </>
                  )}
                </div>
              ) : (
                <Alert variant="info" className="my-3">
                  There is currently nothing to show here.
                </Alert>
              )}
              {error && <ErrorHandler error={error} />}
            </Modal.Body>
          </>
        )}

        <Modal.Footer>
          <Button size="sm" variant="link" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            as={activityGroupDetails?.deleted ? "" : Link}
            to={`/organizations/${activityDetail.organizationId}/sites/${activityDetail.siteId}/scopes/${activityDetail.scope}?activityCategoryId=${activityDetail.activityTypeId}&activityCategory=${activityDetail.label}&categoryNumber=${activityDetail?.categoryNumber}`}
            className="py-0 px-3"
            variant="primary"
            size="sm"
            disabled={activityGroupDetails?.deleted}
          >
            View Activity Page
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AuditReview;
