import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import "./App.css";

import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import NoMatch from "./NoMatch/NoMatch";
import Footer from "./Footer/Footer";

import Dashboard from "components/Dashboard/Dashboard";
import Content from "components/Content/Content";
import Organization from "components/Organization/Organization";
import Portfolio from "components/Portfolio/Portfolio";
import Account from "components/Account/Account";

import { UserContext, UserProvider } from "contexts/UserProvider";
import { AccountProvider } from "contexts/AccountProvider";
import ContentProvider from "contexts/ContentContext";

import EmissionFactors from "./../EmissionFactors/EmissionFactors";
import AuditTrails from "components/AuditTrails/AuditTrails";
import Disclaimer from "components/Disclaimer/Disclaimer";
import AllArticles from "./WhatsNew/AllArticles";
import AuthRoute from "auth/authRoute";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
const App = () => {
  return (
    <div className="Main">
      <UserProvider>
        <AccountProvider>
          <ContentProvider>
            <Router>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<PrivateRoute />}>
                  <Route
                    index
                    element={
                      <>
                        <Dashboard />
                        <Disclaimer />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="organizations/:organizationId/*"
                    element={
                      <>
                        <Organization />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="portfolios/*"
                    element={
                      <>
                        <Portfolio />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="emissions-factors"
                    element={
                      <>
                        <EmissionFactors />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="contents/*"
                    element={
                      <>
                        <Content />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="all-updates"
                    element={
                      <>
                        <AllArticles />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="logout"
                    element={
                      <>
                        <Logout />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="account-management"
                    element={
                      <>
                        <Account />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="audit-trails"
                    element={
                      <>
                        <AuditTrails />
                        <Footer />
                      </>
                    }
                  />
                </Route>

                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route
                  path="*"
                  element={
                    <>
                      <NoMatch />
                      <Footer />
                    </>
                  }
                />
              </Routes>
            </Router>
          </ContentProvider>
        </AccountProvider>
      </UserProvider>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  return user ? <Outlet /> : <Navigate to="login" state={{ from: location }} />;
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

export default App;
