import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./PortfolioScopeEmissionsChart.scss";
import * as echarts from "echarts";

const PortfolioScopeEmissionsChart = ({ portfolios, selectedScope }) => {
  const el = useRef(null);

  const totalEmissions = portfolios.reduce(
    (acc, curr) => {
      acc.scopeOneEmissions += curr.scopeOneEmissions;
      acc.scopeTwoEmissions += curr.scopeTwoEmissions;
      acc.scopeThreeEmissions += curr.scopeThreeEmissions;
      return acc;
    },
    { scopeOneEmissions: 0, scopeTwoEmissions: 0, scopeThreeEmissions: 0 }
  );

  useEffect(() => {
    const formattedEmissions = [
      {
        name: "Scope 1",
        value: totalEmissions?.scopeOneEmissions,
      },
      {
        name: "Scope 2",
        value: totalEmissions?.scopeTwoEmissions,
      },
      {
        name: "Scope 3",
        value: totalEmissions?.scopeThreeEmissions,
      },
    ];

    let filteredEmissions = [];
    switch (selectedScope) {
      case "Scope 1+2+3":
        filteredEmissions = formattedEmissions.filter((emission) =>
          ["Scope 1", "Scope 2", "Scope 3"].includes(emission.name)
        );
        break;
      case "Scope 1+2":
        filteredEmissions = formattedEmissions.filter((emission) =>
          ["Scope 1", "Scope 2"].includes(emission.name)
        );
        break;
      case "Scope 1":
        filteredEmissions = formattedEmissions.filter((emission) =>
          ["Scope 1"].includes(emission.name)
        );
        break;
      case "Scope 2":
        filteredEmissions = formattedEmissions.filter((emission) =>
          ["Scope 2"].includes(emission.name)
        );
        break;
      case "Scope 3":
        filteredEmissions = formattedEmissions.filter((emission) =>
          ["Scope 3"].includes(emission.name)
        );
        break;
      default:
        break;
    }

    const colorMapping = {
      "Scope 1": "#b5c334",
      "Scope 2": "#fac858",
      "Scope 3": "#eb8146",
    };

    const colors = filteredEmissions.map(
      (emission) => colorMapping[emission.name]
    );

    const option = {
      color: colors,
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value
            ? Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(value)
            : 0,
      },
      legend: {
        show: false,
      },

      series: [
        {
          name: "",
          type: "pie",
          radius: "80%",
          center: ["50%", "50%"],
          data: filteredEmissions.every((item) => item.value === 0)
            ? []
            : filteredEmissions,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.8)",
            },
          },
          label: {
            position: "outer",
            alignTo: "none",
            textStyle: {
              fontSize: 10,
            },
            formatter: ({ name, value }) =>
              `${name}: ${
                value
                  ? Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(value)
                  : 0
              }`,
          },
        },
      ],
    };
    const chart = echarts.init(el.current);

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [el, portfolios, selectedScope]);

  return (
    <div className="PortfoliosChart">
      <div className="portfolios-emissions-chart-container me-auto" ref={el} />
    </div>
  );
};

function PortfolioScopeEmissions({ portfolios = [], selectedScope }) {
  if (portfolios.length > 0) {
    return (
      <PortfolioScopeEmissionsChart
        portfolios={portfolios}
        selectedScope={selectedScope}
      />
    );
  } else {
    return <span />;
  }
}

PortfolioScopeEmissions.defaultProps = {
  portfolios: [],
  selectedScope: "Scope 1+2+3",
};

PortfolioScopeEmissions.propTypes = {
  portfolios: PropTypes.array,
  selectedScope: PropTypes.string,
};

export default PortfolioScopeEmissions;
