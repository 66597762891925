import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../contexts/AccountProvider";

function AuthRoute({ children, roles }) {
  const navigate = useNavigate();
  const accountContexts = useContext(AccountContext);

  // localStorage.clear();
  useEffect(() => {
    if (!roles?.includes(accountContexts?.member?.role)) {
      return navigate("/");
    }
    return null;
  }, []);

  return <div>{children}</div>;
}

export default AuthRoute;
