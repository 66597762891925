import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  InputGroup,
  ButtonGroup,
  Modal,
  Spinner,
  Tab,
  Table,
  Tabs,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { post, put } from "utils/DeApi";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { get } from "utils/DeApi";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import { getAllYearsBetweenDates } from "utils/dateUtils";

const defaultSBTiProps = [
  {
    type: "sbti",
    show: true,
    scope1: true,
    scope2: true,
    scope3: true,
    title: "SBTi 1.5C Aligned",
    label: "SBTi 1.5C Aligned",
  },
  {
    type: "sbti",
    show: true,
    scope1: true,
    scope2: true,
    scope3: true,
    title: "SBTi 1.5C S1+2 & WB2C S3",
    label: "SBTi 1.5C S1+2 & WB2C S3",
  },
];

const ManageTargetEmissions = ({
  emissions,
  onManageEmissionsUpdated,
  setIsTargetUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [sites, setSites] = useState([]);
  const [scopeTwoPreference, setScopeTwoPreference] = useState(0); // market based is preference 1 or true and location based is preference 0 or false
  const [step, setStep] = useState("step-1");
  const shouldDefaultAddable =
    emissions?.sbtiConfig &&
    JSON?.parse(emissions?.sbtiConfig)?.find((data) => data?.type === "sbti");

  const [SBTiProps, setSBTiProps] = useState(
    emissions?.sbtiConfig
      ? [
          ...[
            !shouldDefaultAddable && [
              {
                type: "sbti",
                show: true,
                scope1: true,
                scope2: true,
                scope3: true,
                title: "SBTi 1.5C Aligned",
                label: "SBTi 1.5C Aligned",
              },
              {
                type: "sbti",
                show: true,
                scope1: true,
                scope2: true,
                scope3: true,
                title: "SBTi 1.5C S1+2 & WB2C S3",
                label: "SBTi 1.5C S1+2 & WB2C S3",
              },
            ],
          ],
          ...JSON.parse(emissions?.sbtiConfig),
        ]?.filter((data) => data)
      : [...defaultSBTiProps]
  );
  const [selectedTarget, setSelectedTarget] = useState("");
  const [moduleError, setModuleError] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [showTargetBauDropdown, setShowTargetDropdown] = useState(false);

  const targets = [
    {
      type: "User Defined Targets",
      scope1: true,
      scope2: true,
      scope3: true,
      target: "user_defined",
      show: true,
      addedTarget: true,
    },
  ];

  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const { organizationId } = useParams();

  const handleClose = () => {
    setShow(false);
    setStep("step-1");
    setIsEditing(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    emissionsScopeOne: yup
      .number()
      .label("Emissions Scope 1")
      .moreThan(0)
      .required(),
    emissionsScopeTwo: yup
      .number()
      .label("Emissions Scope 2")
      .moreThan(0)
      .required(),
    emissionsScopeThree: yup.number().label("Emissions Scope 3").required(),
    year: yup
      .number("Baseline Year is a required field")
      .label("Baseline Year")
      .required(),
    mostRecentYear: yup
      .number("Most Recent Year (MRY) is a required field")
      .label("Most Recent Year (MRY)"),
    nearTermTargetYear: yup
      .number("Near-Term Target Year is a required field")
      .label("Near-Term Target Year")
      .required(),
    netzeroTargetYear: yup
      .number("Net-Zero Target Year is a required field")
      .label("Net-Zero Target Year"),
    mostRecentYearEmissionsScopeOne: yup
      .number()
      .nullable()
      .label("Most Recent Year Emissions Scope 1")
      .min(0)
      .when(["mostRecentYear", "year"], {
        is: (mostRecentYear, year) => {
          return Math.abs(Number(mostRecentYear) - Number(year)) >= 2;
        },
        then: yup.number().required(),
      }),
    mostRecentYearEmissionsScopeTwo: yup
      .number()
      .nullable()
      .label("Most Recent Year Emissions Scope 2")
      .min(0)
      .when(["mostRecentYear", "year"], {
        is: (mostRecentYear, year) => {
          return Math.abs(Number(mostRecentYear) - Number(year)) >= 2;
        },
        then: yup.number().required(),
      }),
    mostRecentYearEmissionsScopeThree: yup
      .number()
      .nullable()
      .label("Most Recent Year Emissions Scope 3")
      .min(0),
  });

  const setModule = () => {
    setModuleError(null);
    const data = [...SBTiProps];
    if (!selectedTarget) {
      setModuleError({
        statusText: "Please Select Target First",
        data: { error: { message: "" } },
      });
      return;
    }
    if (selectedTarget.type?.includes("User Defined Targets")) {
      const levers = data.filter((lever) =>
        lever.type?.includes("User Defined Targets")
      );

      let module = { ...selectedTarget };

      if (levers.length) {
        module["type"] = `User Defined Targets - ${levers.length + 1}`;
      }

      data.push(module);
      setSBTiProps([...data]);
      setSelectedTarget("");
    } else {
      const index = data.findIndex(
        (value) => value.type === selectedTarget.type
      );
      if (index > -1) {
        setModuleError({
          statusText: "Target Already Exist",
          data: { error: { message: "" } },
        });
      } else {
        data.push(selectedTarget);
        setSBTiProps([...data]);
        setSelectedTarget("");
      }
    }
  };

  const updateEmissions = ({
    emissionsScopeOne,
    emissionsScopeTwo,
    emissionsScopeThree,
    limitReductionPercentage,
    scopeThreeLimitReductionPercentage,
    year,
    mostRecentYear,
    nearTermTargetYear,
    netzeroTargetYear,
    mostRecentYearEmissionsScopeOne,
    mostRecentYearEmissionsScopeTwo,
    mostRecentYearEmissionsScopeThree,
  }) => {
    setError(null);
    setIsLoading(true);
    const data = {
      organization_id: organizationId,
      emissions_scope_one: !isNaN(Number(emissionsScopeOne))
        ? Number(emissionsScopeOne)
        : "",
      emissions_scope_two: !isNaN(Number(emissionsScopeTwo))
        ? Number(emissionsScopeTwo)
        : "",
      emissions_scope_three: !isNaN(Number(emissionsScopeThree))
        ? Number(emissionsScopeThree)
        : "",
      mostRecentYearEmissionsScopeOne:
        mostRecentYearEmissionsScopeOne &&
        !isNaN(Number(mostRecentYearEmissionsScopeOne))
          ? Number(mostRecentYearEmissionsScopeOne)
          : null,
      mostRecentYearEmissionsScopeTwo:
        mostRecentYearEmissionsScopeTwo &&
        !isNaN(Number(mostRecentYearEmissionsScopeTwo))
          ? Number(mostRecentYearEmissionsScopeTwo)
          : null,
      mostRecentYearEmissionsScopeThree:
        mostRecentYearEmissionsScopeThree &&
        !isNaN(Number(mostRecentYearEmissionsScopeThree))
          ? Number(mostRecentYearEmissionsScopeThree)
          : null,
      limit_reduction_percentage: !isNaN(Number(limitReductionPercentage))
        ? Number(limitReductionPercentage)
        : "",
      scope_three_limit_reduction_percentage: !isNaN(
        Number(scopeThreeLimitReductionPercentage)
      )
        ? Number(scopeThreeLimitReductionPercentage)
        : "",
      baseline_year: year,
      mostRecentYear,
      nearTermTargetYear,
      netzeroTargetYear,
      sbtiConfig: JSON.stringify(
        SBTiProps?.map(({ addedTarget, ...keepAttrs }) => keepAttrs)
      )
        ?.replace("2° C", "WB2C")
        ?.replace("1.5° C", "1.5C"),
    };
    const organizationPromise = emissions?.id
      ? put(`science-based-target/${emissions?.id}`, data)
      : post(`organizations/${organizationId}/science-based-target`, data);
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        handleClose();
        onManageEmissionsUpdated(response.data);
        setIsTargetUpdate(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  const filterSitesByYear = (sites = [], year = "") =>
    sites.filter(({ yearEnded }) => parseInt(yearEnded) === parseInt(year));

  const sumOfScopes = (sites, year) => {
    return filterSitesByYear(sites, year).reduce(
      (prev, curr) => {
        return {
          scopeOneEmissions: Math.round(
            prev.scopeOneEmissions + curr.scopeOneEmissions
          ),
          scopeTwoEmissions: Math.round(
            prev.scopeTwoEmissions + curr.scopeTwoEmissions
          ),
          scopeThreeEmissions: Math.round(
            prev.scopeThreeEmissions + curr.scopeThreeEmissions
          ),
        };
      },
      {
        scopeOneEmissions: 0,
        scopeTwoEmissions: 0,
        scopeThreeEmissions: 0,
      }
    );
  };

  const deleteModule = (index) => {
    const data = [...SBTiProps];
    data.splice(index, 1);
    setSBTiProps([...data]);
  };

  useEffect(() => {
    if (show) {
      setError(null);
      const bottomUpSummaryPromise = get(
        `organizations/${organizationId}/sites-summary`,
        {
          params: {
            "filter[preference]": scopeTwoPreference,
          },
        }
      );
      bottomUpSummaryPromise.promise
        .then((response) => {
          setError(null);
          setSites(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
          }
        });

      subscribedPromises.current.push(bottomUpSummaryPromise);
    }
  }, [scopeTwoPreference, organizationId, show]);

  useEffect(() => {
    if (show) {
      setError(null);
      setModuleError(null);
      setSelectedTarget("");
      setShowTargetDropdown(false);
      setSBTiProps(
        emissions?.sbtiConfig
          ? [
              ...[
                !shouldDefaultAddable && [
                  {
                    type: "sbti",
                    show: true,
                    scope1: true,
                    scope2: true,
                    scope3: true,
                    title: "SBTi 1.5C Aligned",
                    label: "SBTi 1.5C Aligned",
                  },
                  {
                    type: "sbti",
                    show: true,
                    scope1: true,
                    scope2: true,
                    scope3: true,
                    title: "SBTi 1.5C S1+2 & WB2C S3",
                    label: "SBTi 1.5C S1+2 & WB2C S3",
                  },
                ],
              ],
              ...JSON.parse(emissions?.sbtiConfig),
            ]?.filter((data) => data)
          : [...JSON.parse(JSON.stringify(defaultSBTiProps))]
      );
    }
  }, [show, emissions?.sbtiConfig]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      <Button size="sm" variant="primary" onClick={handleShow} className="py-0">
        Manage Target Setting
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header className="border-bottom" closeButton>
          <Modal.Title className="fs-4">Manage Target Setting</Modal.Title>
        </Modal.Header>
        {error && <ErrorHandler error={error} />}
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateEmissions(values)}
          initialValues={{
            emissionsScopeOne: !Number(emissions?.emissionsScopeOne || 0)
              ? ""
              : Math.round(Number(emissions?.emissionsScopeOne)),
            emissionsScopeTwo: !Number(emissions?.emissionsScopeTwo || 0)
              ? ""
              : Math.round(Number(emissions?.emissionsScopeTwo)),
            emissionsScopeThree: Math.round(
              Number(emissions?.emissionsScopeThree)
            ),
            mostRecentYearEmissionsScopeOne: !Number(
              emissions?.mostRecentYearEmissionsScopeOne || 0
            )
              ? null
              : Math.round(Number(emissions?.mostRecentYearEmissionsScopeOne)),
            mostRecentYearEmissionsScopeTwo: !Number(
              emissions?.mostRecentYearEmissionsScopeTwo || 0
            )
              ? null
              : Math.round(Number(emissions?.mostRecentYearEmissionsScopeTwo)),
            mostRecentYearEmissionsScopeThree: !Number(
              emissions?.mostRecentYearEmissionsScopeThree || 0
            )
              ? null
              : Math.round(
                  Number(emissions?.mostRecentYearEmissionsScopeThree)
                ),
            limitReductionPercentage: emissions?.limitReductionPercentage || 50,
            scopeThreeLimitReductionPercentage:
              emissions?.sopeThreeLimitReductionPercentage || 50,
            year: emissions?.baselineYear || "",
            mostRecentYear: emissions?.mostRecentYear || "",
            nearTermTargetYear: emissions?.nearTermTargetYear || "",
            netzeroTargetYear: emissions?.netzeroTargetYear || "2050",
          }}
          validateOnMount={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={step}
                  onSelect={(k) => setStep(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="step-1"
                    // title="Define Start Year"
                    title={
                      <>
                        Define Time Horizon
                        {!!Object.keys(errors)?.length && (
                          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        )}
                      </>
                    }
                  >
                    <Form.Group controlId="activity-year" className="mb-3">
                      <Form.Label>
                        Base Year
                        <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                      </Form.Label>
                      <Field
                        as={Form.Select}
                        name="year"
                        value={values.year}
                        onChange={(e) => {
                          setFieldValue("year", e.target.value);
                          setFieldValue("mostRecentYear", e.target.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.year}
                        isInvalid={errors.year && touched.year}
                      >
                        <option value="">Select year</option>
                        {getAllYearsBetweenDates(
                          new Date("2015-01-01"),
                          new Date(`${new Date().getFullYear() - 1}-12-31`)
                        )?.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                      <Form.Control.Feedback type="invalid">
                        {errors.year}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="mostRecent-year" className="mb-3">
                      <Form.Label>Most Recent Year (MRY)</Form.Label>
                      <Field
                        as={Form.Select}
                        name="mostRecentYear"
                        value={values.mostRecentYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.mostRecentYear}
                        isInvalid={
                          errors.mostRecentYear && touched.mostRecentYear
                        }
                      >
                        <option value="" disabled={values.mostRecentYear}>
                          Select year
                        </option>
                        {getAllYearsBetweenDates(
                          new Date(`${values?.year}-01-01`),
                          new Date(`${new Date().getFullYear()}-12-31`)
                        )?.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                      <Form.Control.Feedback type="invalid">
                        {errors.mostRecentYear}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="nearTerm-year" className="mb-3">
                      <Form.Label>
                        Near-Term Target Year
                        <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        <span>
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip>
                                Base and target years: Targets must cover a
                                minimum of 5 years and a maximum of 10 years
                                from the date the target is submitted to the
                                SBTi for an official validation.
                              </Tooltip>
                            }
                          >
                            <span className="material-icons-outlined md-18">
                              info
                            </span>
                          </OverlayTrigger>
                        </span>
                      </Form.Label>
                      <Field
                        as={Form.Select}
                        name="nearTermTargetYear"
                        value={values.nearTermTargetYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.nearTermTargetYear}
                        isInvalid={
                          errors.nearTermTargetYear &&
                          touched.nearTermTargetYear
                        }
                        disabled={!values.year}
                      >
                        <option value="">Select year</option>
                        {getAllYearsBetweenDates(
                          new Date(
                            `${
                              Number(values?.mostRecentYear || values?.year) + 5
                            }-01-01`
                          ),
                          new Date(
                            `${
                              Number(values?.mostRecentYear || values?.year) +
                              10
                            }-12-31`
                          )
                        )?.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                      <Form.Control.Feedback type="invalid">
                        {errors.nearTermTargetYear}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="target-year" className="mb-3">
                      <Form.Label>Net-Zero Target Year</Form.Label>
                      <Field
                        as={Form.Select}
                        name="netzeroTargetYear"
                        value={values.netzeroTargetYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={values.netzeroTargetYear}
                        isInvalid={
                          errors.netzeroTargetYear && touched.netzeroTargetYear
                        }
                      >
                        {getAllYearsBetweenDates(
                          new Date(`${new Date().getFullYear()}-01-01`),
                          new Date("2050-12-31")
                        )?.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                      <Form.Control.Feedback type="invalid">
                        {errors.netzeroTargetYear}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {!isNaN(Number(values.year)) &&
                    !!sites &&
                    !!sites.length ? (
                      <div className="bg-light px-2 py-2 border rounded-1 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="m-0">
                            Base year emissions{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>{" "}
                            from Bottom-Up Analysis{" "}
                            <span className="text-muted">optional</span>
                          </p>
                          <div className="d-inline-flex align-items-center mx-1 align-center">
                            <ScopeTwoPreferenceToggler
                              scopeTwoPreference={scopeTwoPreference}
                              setScopeTwoPreference={setScopeTwoPreference}
                            />
                          </div>
                        </div>
                        <hr />
                        {sites.length > 0 &&
                        filterSitesByYear(sites, values.year).length > 0 ? (
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th scope="col">Year</th>
                                <th scope="col">Scope 1</th>
                                <th scope="col">Scope 2</th>
                                <th scope="col">Scope 3</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                <tr>
                                  <th scope="row">{values.year}</th>
                                  <td>
                                    {isNaN(
                                      Number(
                                        sumOfScopes(sites, values.year)
                                          .scopeOneEmissions
                                      )
                                    )
                                      ? "n/a"
                                      : new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 3,
                                        }).format(
                                          sumOfScopes(sites, values.year)
                                            .scopeOneEmissions
                                        )}
                                  </td>
                                  <td>
                                    {isNaN(
                                      Number(
                                        sumOfScopes(sites, values.year)
                                          .scopeTwoEmissions
                                      )
                                    )
                                      ? "n/a"
                                      : new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 3,
                                        }).format(
                                          sumOfScopes(sites, values.year)
                                            .scopeTwoEmissions
                                        )}
                                  </td>
                                  <td>
                                    {isNaN(
                                      Number(
                                        sumOfScopes(sites, values.year)
                                          .scopeThreeEmissions
                                      )
                                    )
                                      ? "n/a"
                                      : new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 3,
                                        }).format(
                                          sumOfScopes(sites, values.year)
                                            .scopeThreeEmissions
                                        )}
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      variant="outline-dark"
                                      className="py-0 float-end"
                                      onClick={() => {
                                        setFieldValue(
                                          "emissionsScopeOne",
                                          sumOfScopes(sites, values.year)
                                            .scopeOneEmissions,
                                          true
                                        );

                                        setFieldValue(
                                          "emissionsScopeTwo",
                                          sumOfScopes(sites, values.year)
                                            .scopeTwoEmissions,
                                          true
                                        );

                                        setFieldValue(
                                          "emissionsScopeThree",
                                          sumOfScopes(sites, values.year)
                                            .scopeThreeEmissions,
                                          true
                                        );

                                        setTimeout(() => {
                                          setFieldTouched(
                                            "emissionsScopeOne",
                                            true
                                          );
                                          setFieldTouched(
                                            "emissionsScopeTwo",
                                            true
                                          );
                                          setFieldTouched(
                                            "emissionsScopeThree",
                                            true
                                          );
                                        }, 100);
                                      }}
                                    >
                                      use values
                                    </Button>
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        ) : (
                          <Alert variant="info">
                            No scope data available for base year emissions.
                          </Alert>
                        )}
                      </div>
                    ) : (
                      <Alert variant="info">
                        No scope data available for base year emissions.
                      </Alert>
                    )}
                    <p className="m-0">
                      Base year emissions{" "}
                      <small>
                        (tCO<sub>2</sub>e)
                      </small>{" "}
                      from Bottom-Up Analysis{" "}
                    </p>
                    <hr />
                    <Form.Group controlId="emissionsScopeOne" className="mb-2">
                      <Form.Label>
                        Emissions scope 1{" "}
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="emissionsScopeOne"
                        size="sm"
                        value={values.emissionsScopeOne}
                        customInput={Form.Control}
                        decimalScale={0}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("emissionsScopeOne", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.emissionsScopeOne}
                        isInvalid={
                          errors.emissionsScopeOne && touched.emissionsScopeOne
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionsScopeOne}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="emissionsScopeTwo" className="my-2">
                      <Form.Label>
                        Emissions scope 2{" "}
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="emissionsScopeTwo"
                        size="sm"
                        value={values.emissionsScopeTwo}
                        customInput={Form.Control}
                        decimalScale={0}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue("emissionsScopeTwo", numberItem.value);
                        }}
                        onBlur={handleBlur}
                        isValid={values.emissionsScopeTwo}
                        isInvalid={
                          errors.emissionsScopeTwo && touched.emissionsScopeTwo
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionsScopeTwo}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="emissionsScopeThree"
                      className="my-2"
                    >
                      <Form.Label>
                        Emissions scope 3{" "}
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                        <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                      </Form.Label>
                      <NumberFormat
                        name="emissionsScopeThree"
                        size="sm"
                        value={values.emissionsScopeThree}
                        customInput={Form.Control}
                        decimalScale={0}
                        thousandSeparator={true}
                        onValueChange={(numberItem) => {
                          setFieldValue(
                            "emissionsScopeThree",
                            numberItem.value
                          );
                        }}
                        onBlur={handleBlur}
                        isValid={
                          values.emissionsScopeThree ||
                          values.emissionsScopeThree === 0
                        }
                        isInvalid={
                          errors.emissionsScopeThree &&
                          touched.emissionsScopeThree
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.emissionsScopeThree}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {values?.mostRecentYear && values?.year && (
                      <>
                        {!isNaN(Number(values.mostRecentYear)) ? (
                          !!sites && !!sites.length ? (
                            <div className="bg-light px-2 py-2 border rounded-1 mb-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="m-0">
                                  MRY emissions{" "}
                                  <small>
                                    (tCO<sub>2</sub>e)
                                  </small>{" "}
                                  from Bottom-Up Analysis{" "}
                                  <span className="text-muted">optional</span>
                                </p>
                                <div className="d-inline-flex align-items-center mx-1 align-center">
                                  <ScopeTwoPreferenceToggler
                                    scopeTwoPreference={scopeTwoPreference}
                                    setScopeTwoPreference={
                                      setScopeTwoPreference
                                    }
                                  />
                                </div>
                              </div>
                              <hr />
                              {sites.length > 0 &&
                              filterSitesByYear(sites, values.mostRecentYear)
                                .length > 0 ? (
                                <Table size="sm" responsive>
                                  <thead>
                                    <tr>
                                      <th scope="col">Year</th>
                                      <th scope="col">Scope 1</th>
                                      <th scope="col">Scope 2</th>
                                      <th scope="col">Scope 3</th>
                                      <th scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      <tr>
                                        <th scope="row">
                                          {values.mostRecentYear}
                                        </th>
                                        <td>
                                          {isNaN(
                                            Number(
                                              sumOfScopes(
                                                sites,
                                                values.mostRecentYear
                                              ).scopeOneEmissions
                                            )
                                          )
                                            ? "n/a"
                                            : new Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 3,
                                              }).format(
                                                sumOfScopes(
                                                  sites,
                                                  values.mostRecentYear
                                                ).scopeOneEmissions
                                              )}
                                        </td>
                                        <td>
                                          {isNaN(
                                            Number(
                                              sumOfScopes(
                                                sites,
                                                values.mostRecentYear
                                              ).scopeTwoEmissions
                                            )
                                          )
                                            ? "n/a"
                                            : new Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 3,
                                              }).format(
                                                sumOfScopes(
                                                  sites,
                                                  values.mostRecentYear
                                                ).scopeTwoEmissions
                                              )}
                                        </td>
                                        <td>
                                          {isNaN(
                                            Number(
                                              sumOfScopes(
                                                sites,
                                                values.mostRecentYear
                                              ).scopeThreeEmissions
                                            )
                                          )
                                            ? "n/a"
                                            : new Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 3,
                                              }).format(
                                                sumOfScopes(
                                                  sites,
                                                  values.mostRecentYear
                                                ).scopeThreeEmissions
                                              )}
                                        </td>
                                        <td>
                                          <Button
                                            size="sm"
                                            variant="outline-dark"
                                            className="py-0 float-end"
                                            onClick={() => {
                                              setFieldValue(
                                                "mostRecentYearEmissionsScopeOne",
                                                sumOfScopes(
                                                  sites,
                                                  values.mostRecentYear
                                                ).scopeOneEmissions,
                                                true
                                              );

                                              setFieldValue(
                                                "mostRecentYearEmissionsScopeTwo",
                                                sumOfScopes(
                                                  sites,
                                                  values.mostRecentYear
                                                ).scopeTwoEmissions,
                                                true
                                              );

                                              setFieldValue(
                                                "mostRecentYearEmissionsScopeThree",
                                                sumOfScopes(
                                                  sites,
                                                  values.mostRecentYear
                                                ).scopeThreeEmissions,
                                                true
                                              );

                                              setTimeout(() => {
                                                setFieldTouched(
                                                  "mostRecentYearEmissionsScopeOne",
                                                  true
                                                );
                                                setFieldTouched(
                                                  "mostRecentYearEmissionsScopeTwo",
                                                  true
                                                );
                                                setFieldTouched(
                                                  "mostRecentYearEmissionsScopeThree",
                                                  true
                                                );
                                              }, 100);
                                            }}
                                          >
                                            use values
                                          </Button>
                                        </td>
                                      </tr>
                                    }
                                  </tbody>
                                </Table>
                              ) : (
                                <Alert variant="info">
                                  No scope data available for most recent year
                                  emissions.
                                </Alert>
                              )}
                            </div>
                          ) : (
                            <Alert variant="info">
                              No scope data available for most recent year
                              emissions.
                            </Alert>
                          )
                        ) : (
                          <Alert variant="info">
                            No scope data available for most recent year
                            emissions.
                          </Alert>
                        )}
                        <p className="m-0">
                          MRY emissions{" "}
                          <small>
                            (tCO<sub>2</sub>e)
                          </small>{" "}
                          from Bottom-Up Analysis{" "}
                        </p>
                        <hr />
                        <Form.Group
                          controlId="mostRecentYearEmissionsScopeOne"
                          className="mb-2"
                        >
                          <Form.Label>
                            Emissions scope 1{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>
                            {Math.abs(
                              Number(values?.mostRecentYear) -
                                Number(values?.year)
                            ) >= 2 && (
                              <sup className="ps-1 fs-3 top-0 text-danger">
                                *
                              </sup>
                            )}
                          </Form.Label>
                          <NumberFormat
                            name="mostRecentYearEmissionsScopeOne"
                            size="sm"
                            value={values.mostRecentYearEmissionsScopeOne}
                            customInput={Form.Control}
                            decimalScale={0}
                            thousandSeparator={true}
                            onValueChange={(numberItem) => {
                              setFieldValue(
                                "mostRecentYearEmissionsScopeOne",
                                numberItem.value
                              );
                            }}
                            onBlur={handleBlur}
                            isValid={values.mostRecentYearEmissionsScopeOne}
                            isInvalid={
                              errors.mostRecentYearEmissionsScopeOne &&
                              touched.mostRecentYearEmissionsScopeOne
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.mostRecentYearEmissionsScopeOne}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          controlId="mostRecentYearEmissionsScopeTwo"
                          className="my-2"
                        >
                          <Form.Label>
                            Emissions scope 2{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>
                            {Math.abs(
                              Number(values?.mostRecentYear) -
                                Number(values?.year)
                            ) >= 2 && (
                              <sup className="ps-1 fs-3 top-0 text-danger">
                                *
                              </sup>
                            )}
                          </Form.Label>
                          <NumberFormat
                            name="mostRecentYearEmissionsScopeTwo"
                            size="sm"
                            value={values.mostRecentYearEmissionsScopeTwo}
                            customInput={Form.Control}
                            decimalScale={0}
                            thousandSeparator={true}
                            onValueChange={(numberItem) => {
                              setFieldValue(
                                "mostRecentYearEmissionsScopeTwo",
                                numberItem.value
                              );
                            }}
                            onBlur={handleBlur}
                            isValid={values.mostRecentYearEmissionsScopeTwo}
                            isInvalid={
                              errors.mostRecentYearEmissionsScopeTwo &&
                              touched.mostRecentYearEmissionsScopeTwo
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.mostRecentYearEmissionsScopeTwo}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          controlId="mostRecentYearEmissionsScopeThree"
                          className="my-2"
                        >
                          <Form.Label>
                            Emissions scope 3{" "}
                            <small>
                              (tCO<sub>2</sub>e)
                            </small>
                          </Form.Label>
                          <NumberFormat
                            name="mostRecentYearEmissionsScopeThree"
                            size="sm"
                            value={values.mostRecentYearEmissionsScopeThree}
                            customInput={Form.Control}
                            decimalScale={0}
                            thousandSeparator={true}
                            onValueChange={(numberItem) => {
                              setFieldValue(
                                "mostRecentYearEmissionsScopeThree",
                                numberItem.value
                              );
                            }}
                            onBlur={handleBlur}
                            isValid={values.mostRecentYearEmissionsScopeThree}
                            isInvalid={
                              errors.mostRecentYearEmissionsScopeThree &&
                              touched.mostRecentYearEmissionsScopeThree
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.mostRecentYearEmissionsScopeThree}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}
                  </Tab>
                  <Tab eventKey="step-2" title="Set Display Preference">
                    <div className="d-flex flex-row pt-2 pb-4 px-1">
                      <h3 className="flex-fill pt-2 text-bold">
                        Set Target Display Preference
                      </h3>
                      <div>
                        <Button
                          className="rounded-0"
                          onClick={() => {
                            setShowTargetDropdown((prev) => !prev);
                            setModuleError(false);
                          }}
                        >
                          Targets
                        </Button>
                      </div>
                    </div>
                    {showTargetBauDropdown && (
                      <>
                        <InputGroup className="mb-3">
                          <Form.Select
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value !== "") {
                                value = JSON.parse(value);
                              }
                              setSelectedTarget(value);
                            }}
                            value={JSON.stringify(selectedTarget)}
                            // value={selectedTarget}
                          >
                            <option value="">Select target</option>
                            {targets.map((target) => (
                              <option
                                value={JSON.stringify(target)}
                                // value={target}
                                key={target.type}
                              >
                                {target.type}
                              </option>
                            ))}
                          </Form.Select>
                          <Button onClick={setModule}>Add</Button>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              setShowTargetDropdown((prev) => !prev);
                              setModuleError(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </InputGroup>
                        {moduleError && <ErrorHandler error={moduleError} />}
                      </>
                    )}
                    <Table size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Targets</th>
                          <th className="text-end">
                            Display in other graphs (e.g., Target Overview and
                            Forecasting)?
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {SBTiProps.map(({ type, title, show }, index) => (
                          <tr key={index}>
                            <td className="text-start text-nowrap fs-5">
                              {index !== isEditing && (
                                <>
                                  <p>{type === "sbti" ? title : type}</p>
                                </>
                              )}
                              {isEditing === index && (
                                <div className="d-flex">
                                  <Form.Control
                                    size="sm"
                                    type="text"
                                    name="miscTargetName"
                                    defaultValue={
                                      type === "sbti" ? title : type
                                    }
                                    placeholder="Enter target name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={values.miscTargetName === ""}
                                  />
                                  <Button
                                    size="sm"
                                    disabled={
                                      !values.miscTargetName ||
                                      values.miscTargetName === ""
                                    }
                                    onClick={() => {
                                      if (values.miscTargetName) {
                                        if (!SBTiProps[index].addedTarget) {
                                          if (
                                            SBTiProps[index].type !== "sbti"
                                          ) {
                                            SBTiProps[index].oldType =
                                              SBTiProps[index].type;
                                          } else {
                                            SBTiProps[index].oldTitle =
                                              SBTiProps[index].title;
                                            if (
                                              !SBTiProps[index].hasOwnProperty(
                                                "label"
                                              )
                                            ) {
                                              SBTiProps[index].label =
                                                SBTiProps[index].title ===
                                                "SBTi 1.5C Aligned"
                                                  ? "SBTi 1.5C Aligned"
                                                  : "SBTi 1.5C S1+2 & WB2C S3";
                                            }
                                          }
                                        }
                                        SBTiProps[index].type !== "sbti"
                                          ? (SBTiProps[index].type =
                                              values.miscTargetName)
                                          : (SBTiProps[index].title =
                                              values.miscTargetName);
                                        setSBTiProps([...SBTiProps]);
                                        setIsEditing(false);
                                      }
                                    }}
                                  >
                                    <span className="material-icons-outlined md-18">
                                      check_circle
                                    </span>
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="light"
                                    onClick={() => {
                                      setFieldValue(
                                        "miscTargetName",
                                        type === "sbti" ? title : type
                                      );
                                      setIsEditing(false);
                                    }}
                                  >
                                    <span className="material-icons-outlined md-18">
                                      cancel
                                    </span>
                                  </Button>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <div key={"default-checkbox"}>
                                <Form.Check
                                  type={"checkbox"}
                                  id={"default-checkbox"}
                                  checked={show}
                                  name={type
                                    ?.replace("2° C", "WB2C")
                                    ?.replace("1.5° C", "1.5C")}
                                  onChange={(e) => {
                                    const updatedSBTiProps = [...SBTiProps];
                                    const item = updatedSBTiProps[index];
                                    if (item) {
                                      item.show = e.target.checked;
                                      setSBTiProps(updatedSBTiProps);
                                    }
                                  }}
                                />
                              </div>
                            </td>
                            <td className="text-end">
                              <ButtonGroup aria-label="First group" size="sm">
                                <Button
                                  size="sm"
                                  variant="outline-primary"
                                  className="py-0"
                                  onClick={() => setIsEditing(index)}
                                >
                                  <span className="material-icons-outlined md-18">
                                    edit
                                  </span>
                                </Button>
                                {type !== "sbti" && (
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="py-0"
                                    onClick={() => deleteModule(index)}
                                  >
                                    <span className="material-icons-outlined md-18">
                                      delete
                                    </span>
                                  </Button>
                                )}
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer
                className={!isValid ? "justify-content-between" : ""}
              >
                {!isValid && (
                  <Form.Text className="text-danger float-start">
                    Please review and check for missing data for the mandatory
                    fields.
                  </Form.Text>
                )}
                <span className="d-flex align-items-center">
                  <Button onClick={handleClose} variant="link">
                    Close
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    className="float-end ms-2"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner animation="border" size="sm" className="me-2" />
                    )}
                    Save
                  </Button>
                </span>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ManageTargetEmissions.propTypes = {
  onManageEmissionsUpdated: PropTypes.func.isRequired,
};

export default ManageTargetEmissions;
