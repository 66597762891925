import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "../ReportActivity.scss";

import * as yup from "yup";
import { Formik } from "formik";
import { Modal, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import { post, put, get } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import {
  EmissionYearField,
  ReportActivityModalToggleButton,
  ActivityTypeField,
  ReportActivityModalTitle,
  ReportActivityModalFooter,
  CustomEFFields,
  PrecalcEmissionFields,
  StandardDatasetEFFields,
  ReportingMethodField,
  DataTags,
  ReportingMonthField,
  ReportingQuarterField,
  OrganizationGrouping,
} from "../ReportActivityComponents";
import {
  createFile,
  deleteFile,
} from "components/Organization/Site/SiteDetail/FileUpload/FileUpload";
import { REPORTING_METHODS } from "../constants";

function ReportScopeOneActivity({
  site,
  activityTypes,
  onActivityReported,
  activity,
  isUpdate,
  initialValues,
  scope = 1,
  siteYearsUnderReview,
  organization,
}) {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [emissionFactorsActivityTypeId, setEmissionFactorsActivityTypeId] =
    useState();
  const [activityTypeDatasets, setActivityTypeDatasets] = useState([]);

  const [emissionFactors, setEmissionFactors] = useState([]);
  const [selectedEmissionFactor, setSelectedEmissionFactor] = useState({});
  const [selectedActivityTypeDataset, setSelectedActivityTypeDataset] =
    useState({});
  const [activityAttachments, setActivityAttachments] = useState([]);
  const [isYearLocked, setIsYearLocked] = useState(false);

  const [searchParams] = useSearchParams();

  const [emptyInitialValues] = useState({
    year: "",
    activityType: "",
    resource: "",
    reference: "",
    country: "",
    customEmissionFactor: "",
    customEmissionFactorUnit: "",
    reportingMethod: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    year: yup.string(),
    month: yup.string(),
    quarter: yup.string(),
    activityType: yup.string().required(),
    reportingMethod: yup
      .string()
      .required("You need to specify how to report an activity"),
    resource: yup.string().required(),
    reference: yup.string().when("reportingMethod", {
      is: (reportingMethod) =>
        reportingMethod?.includes(REPORTING_METHODS.standardEF),
      then: yup.string().required("Dataset reference is required"),
      otherwise: yup.string(),
    }),
    amount: yup
      .number()
      .min(0, "Quantity of resource used must be greater than or equal to 0")
      .when("reportingMethod", {
        is: (reportingMethod) =>
          reportingMethod?.includes(REPORTING_METHODS.directUserInput),
        then: yup.number().nullable(),
        otherwise: yup
          .number()
          .when("reportingMethod", {
            is: (reportingMethod) =>
              reportingMethod?.includes(REPORTING_METHODS.standardEF),
            then: yup.number(),
            otherwise: yup.number().min(0),
          })
          .required("Quantity of resource used is required"),
      })
      .max(
        9999999999999999n,
        `Must not exceed ${Intl.NumberFormat("en-us").format(99999999999999)}.`
      ),
    rawUnit: yup.string(),
    rawAmount: yup.number().when("rawUnit", {
      is: (rawUnit) => !rawUnit || rawUnit.trim() === "",
      then: yup.number().nullable(),
      otherwise: yup
        .number()
        .min(0)
        .max(
          99999999999999,
          `Must not exceed ${Intl.NumberFormat("en-us").format(
            99999999999999
          )}.`
        ),
    }),
    tags: yup.array(),
    organizationGrouping: yup.mixed(),
    comment: yup.string().max(255),
    file: yup.mixed(),
    customEmissionFactor: yup.string().when("reportingMethod", {
      is: (reportingMethod) =>
        reportingMethod?.includes(REPORTING_METHODS.customEF),
      then: yup.string().required("Custom emission factor is required"),
      otherwise: yup.string().nullable(),
    }),
    customEmissionFactorUnit: yup.string().when("reportingMethod", {
      is: (reportingMethod) =>
        reportingMethod?.includes(REPORTING_METHODS.customEF),
      then: yup.string().required("Custom emission factor unit is required"),
      otherwise: yup.string().nullable(),
    }),
    emissions: yup
      .number()
      .min(0)
      .max(
        99999999999999,
        `Must not exceed ${Intl.NumberFormat("en-us").format(99999999999999)}.`
      )
      .when("reportingMethod", {
        is: (reportingMethod) =>
          reportingMethod?.includes(REPORTING_METHODS.directUserInput),
        then: yup.number().required("Emissions value is required"),
        otherwise: yup.number(),
      }),
  });

  const emptyEmFactor = {
    id: "",
    emissionResource: "",
    dataset: { id: "", name: "", inflationFactors: [] },
    unit: "",
    metadata: [],
  };

  const fetchActivityTypeDataset = useCallback(
    (activityTypeId) => {
      if (show) {
        setError(null);
        setIsLoading(true);

        const activityTypeDatasetPromise = get(
          `activity-types/${activityTypeId}/datasets`
        );

        activityTypeDatasetPromise.promise
          .then((response) => {
            setActivityTypeDatasets(response.data);
            setIsLoading(false);
            setError(null);
          })
          .catch((error) => {
            if (!error.isCanceled) {
              setError(error);
              setIsLoading(false);
            }
          });
        subscribedPromises.current.push(activityTypeDatasetPromise);
      }
    },
    [show]
  );

  const fetchEmissionFactors = useCallback(
    (activityTypeId, reportingMethod, datasetId) => {
      setError(null);
      setIsLoading(true);

      const emisisonFactorsPromise = get("emission-factors", {
        params: {
          datasetId,
          activityTypeId,
          type: REPORTING_METHODS[reportingMethod],
        },
      });

      emisisonFactorsPromise.promise
        .then((response) => {
          setEmissionFactors(response.data);
          setError(null);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(emisisonFactorsPromise);
    },
    []
  );

  const fetchEmissionFactorsAndDatasets = useCallback(
    (datasetId, activityTypeId, reportingMethod, _) => {
      if (datasetId && activityTypeId && show) {
        setError(null);
        setIsLoading(true);

        const emisisonFactorsPromise = get("emission-factors", {
          params: {
            datasetId,
            activityTypeId,
            type: REPORTING_METHODS[reportingMethod],
          },
        });

        const activityTypeDatasetPromise = get(
          `activity-types/${activityTypeId}/datasets`
        );

        Promise.all([
          emisisonFactorsPromise.promise,
          activityTypeDatasetPromise.promise,
        ])
          .then((responses) => {
            setEmissionFactors(responses[0].data);
            setActivityTypeDatasets(responses[1].data);
            setError(null);
            setIsLoading(false);
          })
          .catch((error) => {
            if (!error.isCanceled) {
              setError(error);
              setIsLoading(false);
            }
          });
        subscribedPromises.current.push(
          emisisonFactorsPromise,
          activityTypeDatasetPromise
        );
      } else if (activityTypeId && show) {
        reportingMethod === REPORTING_METHODS.standardEF
          ? fetchActivityTypeDataset(activityTypeId)
          : fetchEmissionFactors(activityTypeId, reportingMethod);
      }
    },
    [show, fetchActivityTypeDataset]
  );

  const handleFormSubmit = (values) => {
    if (isUpdate) {
      updateActivity(values);
    } else {
      reportActivity(values);
    }
  };

  const updateActivity = ({
    activityType,
    resource,
    amount,
    rawAmount,
    rawUnit,
    customEmissionFactor,
    customEmissionFactorUnit,
    comment,
    year,
    file,
    reportingMethod,
    emissions,
    tags,
    organizationGrouping,
    month,
    quarter,
  }) => {
    setError(null);
    setIsLoading(true);

    const activityPromise = put(`activities/${activity.id}`, {
      activityTypeId: activityType,
      emissionFactorId: resource,
      amount:
        Number.parseFloat(amount) || Number.parseFloat(amount) === 0
          ? amount
          : undefined,
      rawAmount:
        rawAmount === activity?.rawAmount &&
        rawUnit === activity?.rawUnit &&
        amount !== activity?.amount
          ? ""
          : rawAmount,
      rawUnit:
        rawAmount === activity?.rawAmount &&
        rawUnit === activity?.rawUnit &&
        amount !== activity?.amount
          ? ""
          : rawUnit,
      customEmissionFactor,
      customEmissionFactorUnit,
      isActivity: reportingMethod === REPORTING_METHODS.standardEF ? 1 : 0,
      emissions: reportingMethod.includes(REPORTING_METHODS.customEF)
        ? Number.parseFloat(amount) * Number.parseFloat(customEmissionFactor)
        : reportingMethod.includes(REPORTING_METHODS.directUserInput)
        ? emissions
        : undefined,
      comment,
      yearEnded: `${year}-12-31`,
      reportingMethod,
      tags: tags?.[0]?.id
        ? [
            !!organizationGrouping?.length
              ? Array.isArray(organizationGrouping) &&
                (organizationGrouping.includes(undefined) ||
                  organizationGrouping.includes(null))
                ? ""
                : organizationGrouping
              : organizationGrouping?.id,
            ...tags
              .map((tag) => tag.id)
              .filter((id) => id !== organizationGrouping.id),
          ]
        : !!organizationGrouping?.length
        ? organizationGrouping?.concat(tags)
        : [organizationGrouping?.id]?.concat(tags),
      month,
      quarter,
    });

    activityPromise.promise
      .then((response) => {
        if (file) {
          const uploadFilePromise = createFile(
            file,
            "activities",
            response.data.id
          );
          subscribedPromises.current.push(uploadFilePromise);
          uploadFilePromise.promise
            .then((res) => {
              setError(null);
              setIsLoading(false);
              onActivityReported(response.data);
              handleClose();
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setError(null);
          setIsLoading(false);
          onActivityReported(response.data);
          handleClose();
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activityPromise);
  };

  const reportActivity = ({
    activityType,
    resource,
    amount,
    rawAmount,
    rawUnit,
    customEmissionFactor,
    customEmissionFactorUnit,
    comment,
    year,
    file,
    reportingMethod,
    emissions,
    tags,
    organizationGrouping,
    month,
    quarter,
  }) => {
    setError(null);
    setIsLoading(true);

    const activityPromise = post(`sites/${site.id}/activities`, {
      activityTypeId: activityType,
      emissionFactorId: resource,
      amount:
        Number.parseFloat(amount) || Number.parseFloat(amount) === 0
          ? amount
          : undefined,
      rawAmount,
      rawUnit,
      customEmissionFactor,
      customEmissionFactorUnit,
      isActivity: reportingMethod === REPORTING_METHODS.standardEF ? 1 : 0,
      emissions: reportingMethod.includes(REPORTING_METHODS.customEF)
        ? Number.parseFloat(amount) * Number.parseFloat(customEmissionFactor)
        : reportingMethod.includes(REPORTING_METHODS.directUserInput)
        ? emissions
        : undefined,
      comment,
      scope,
      yearEnded: `${year}-12-31`,
      reportingMethod,
      tags: organizationGrouping?.concat(tags),
      month,
      quarter,
    });

    activityPromise.promise
      .then((response) => {
        if (file) {
          const uploadFilePromise = createFile(
            file,
            "activities",
            response.data.id
          );
          subscribedPromises.current.push(uploadFilePromise);
          uploadFilePromise.promise
            .then((res) => {
              setError(null);
              setIsLoading(false);
              onActivityReported(response.data);
              handleClose();
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setError(null);
          setIsLoading(false);
          onActivityReported(response.data);
          handleClose();
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activityPromise);
  };

  useEffect(() => {
    setEmissionFactors([activity.emissionFactor || emptyEmFactor]);
    setSelectedEmissionFactor(activity.emissionFactor || emptyEmFactor);
    setActivityTypeDatasets([
      activity.emissionFactor?.dataset || emptyEmFactor.dataset,
    ]);
    setSelectedActivityTypeDataset(
      activity.emissionFactor?.dataset || emptyEmFactor.dataset
    );
    setActivityAttachments(activity.attachments);

    const defaultActivityTypeId =
      activityTypes.length === 1 ? activityTypes[0]?.id : "";

    setEmissionFactorsActivityTypeId(defaultActivityTypeId);

    const referenceId = activity.emissionFactor?.dataset?.id;

    fetchEmissionFactorsAndDatasets(
      referenceId,
      activity.activityTypeId || defaultActivityTypeId,
      initialValues.reportingMethod
    );

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    activity,
    activityTypes,
    fetchEmissionFactorsAndDatasets,
    initialValues.reportingMethod,
  ]);

  const handleDeleteAttachment = (attachmentId) => {
    setError(null);
    setIsLoading(true);
    const deleteAttachmentPromise = deleteFile(
      "activities",
      activity.id,
      attachmentId
    );

    deleteAttachmentPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onActivityReported(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <ReportActivityModalToggleButton
        isUpdate={isUpdate}
        handleShow={handleShow}
      />

      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        size="lg"
        className="right"
        fullscreen="sm-down"
      >
        <Modal.Header closeButton>
          <ReportActivityModalTitle
            scope={scope}
            activityCategory={searchParams.get("activityCategory")}
            isUpdate={isUpdate}
          />
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => handleFormSubmit(values)}
          initialValues={initialValues}
          validateOnMount
          validateOnChange
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
            setFieldError,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body className="py-2">
                <EmissionYearField
                  fieldValue={values.year}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  selectedActivityTypeDataset={selectedActivityTypeDataset}
                  setFieldError={setFieldError}
                  setFieldTouched={setFieldTouched}
                  siteYearsUnderReview={siteYearsUnderReview}
                  setIsYearLocked={setIsYearLocked}
                  isUpdate={isUpdate}
                  organizationFiscalStartMonth={organization.startOfYear || 1}
                  organizationFiscalEndMonth={organization.endOfYear || 12}
                />
                {console.log("values", values)}

                <ReportingMonthField
                  fieldValue={values.month}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  organizationFiscalStartMonth={organization.startOfYear || 1}
                  selectedYear={values.year}
                />
                <ReportingQuarterField
                  fieldValue={values.quarter}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />

                <OrganizationGrouping
                  setFieldValue={setFieldValue}
                  values={values}
                  handleBlur={handleBlur}
                  organization={organization}
                />

                <DataTags setFieldValue={setFieldValue} values={values} />

                <ActivityTypeField
                  scope={scope}
                  fieldValue={values.activityType}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  activityTypes={activityTypes}
                  values={values}
                  isUpdate={isUpdate}
                  isYearLocked={isYearLocked}
                  handleOnChange={(option, form, field) => {
                    if (option) {
                      setEmissionFactorsActivityTypeId(option.value);
                      const { year } = values;
                      for (const [key, value] of Object.entries(
                        emptyInitialValues
                      )) {
                        if (key === field.name) {
                          setFieldValue(key, option.value);
                        } else if (key === "year") {
                          setFieldValue(key, year);
                        } else {
                          setFieldValue(key, value);
                        }
                      }

                      setSelectedActivityTypeDataset(emptyEmFactor.dataset);
                      setSelectedEmissionFactor(emptyEmFactor);

                      fetchActivityTypeDataset(option.value);
                    } else {
                      const { year } = values;
                      for (const [key, value] of Object.entries(
                        emptyInitialValues
                      )) {
                        if (key === "year") {
                          setFieldValue(key, year);
                        } else {
                          setFieldValue(key, value);
                        }
                      }
                      setSelectedActivityTypeDataset(emptyEmFactor.dataset);
                      setSelectedEmissionFactor(emptyEmFactor);
                    }
                    return option;
                  }}
                />

                {values.activityType && (
                  <span key={values.activityType}>
                    <ReportingMethodField
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      fetchEmissionFactors={fetchEmissionFactors}
                      setFieldValue={setFieldValue}
                      emptyInitialValues={emptyInitialValues}
                      setSelectedEmissionFactor={setSelectedEmissionFactor}
                      emptyEmFactor={emptyEmFactor}
                      fetchActivityTypeDataset={fetchActivityTypeDataset}
                      setSelectedActivityTypeDataset={
                        setSelectedActivityTypeDataset
                      }
                      isUpdate={isUpdate}
                      isYearLocked={isYearLocked}
                    />
                  </span>
                )}

                {values.activityType && values.reportingMethod && (
                  <span key={values.activityType + values.reportingMethod}>
                    {values.reportingMethod === REPORTING_METHODS.customEF ? (
                      <CustomEFFields
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isInvestments={false}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}
                        setSelectedEmissionFactor={setSelectedEmissionFactor}
                        emissionFactors={emissionFactors}
                        emptyEmFactor={emptyEmFactor}
                        selectedEmissionFactor={selectedEmissionFactor}
                        activityAttachments={activityAttachments}
                        handleDeleteAttachment={handleDeleteAttachment}
                        scope={scope}
                        isYearLocked={isYearLocked}
                        isUpdate={isUpdate}
                      />
                    ) : values.reportingMethod ===
                      REPORTING_METHODS.directUserInput ? (
                      <PrecalcEmissionFields
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isInvestments={false}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}
                        setSelectedEmissionFactor={setSelectedEmissionFactor}
                        emissionFactors={emissionFactors}
                        emptyEmFactor={emptyEmFactor}
                        selectedEmissionFactor={selectedEmissionFactor}
                        activityAttachments={activityAttachments}
                        handleDeleteAttachment={handleDeleteAttachment}
                        scope={scope}
                        isUpdate={isUpdate}
                        isYearLocked={isYearLocked}
                      />
                    ) : (
                      <StandardDatasetEFFields
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isInvestments={false}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}
                        setSelectedEmissionFactor={setSelectedEmissionFactor}
                        emissionFactors={emissionFactors}
                        emptyEmFactor={emptyEmFactor}
                        selectedEmissionFactor={selectedEmissionFactor}
                        isUpdate={isUpdate}
                        activityAttachments={activityAttachments}
                        handleDeleteAttachment={handleDeleteAttachment}
                        setError={setError}
                        activity={activity}
                        selectedActivityTypeDataset={
                          selectedActivityTypeDataset
                        }
                        emptyInitialValues={emptyInitialValues}
                        activityTypeDatasets={activityTypeDatasets}
                        setSelectedActivityTypeDataset={
                          setSelectedActivityTypeDataset
                        }
                        fetchEmissionFactorsAndDatasets={
                          fetchEmissionFactorsAndDatasets
                        }
                        isYearLocked={isYearLocked}
                        organizationGwpModels={organization?.gwpModels || []}
                      />
                    )}
                  </span>
                )}

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <ReportActivityModalFooter
                emissionFactorsActivityTypeId={emissionFactorsActivityTypeId}
                scope={scope}
                handleClose={handleClose}
                isValid={isValid}
                isYearLocked={isYearLocked}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

ReportScopeOneActivity.propTypes = {
  activityTypes: PropTypes.array.isRequired,
  onActivityReported: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  organization: PropTypes.object,
};

export default ReportScopeOneActivity;
