import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";

import {
  Container,
  Row,
  Col,
  Alert,
  Nav,
  Form,
  Dropdown,
  Table,
} from "react-bootstrap";
import { Link, NavLink, Route, Routes } from "react-router-dom";

import { AccountContext } from "contexts/AccountProvider";

import { getFormattedEmission } from "utils/StringUtils";
import { getEmissionFromPercentageEquity } from "utils/numerals";
import { getAllYearsBetweenDates } from "utils/dateUtils";

import { get } from "utils/DeApi";

import Header from "components/App/Header/Header";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";

import PortfolioDetail from "./PortfolioDetail/PortfolioDetail";
import PortfolioCreate from "./PortfolioCreate/PortfolioCreate";
import PortfolioList from "./PortfolioList/PortfolioList";
import PortfolioEmissions from "./PortfolioChart/PortfolioEmissionsChart";
import PortfolioNzifChart from "./PortfolioNzifChart/PortfolioNzifChart";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";
import PortfolioScopeEmissions from "./PortfolioScopeEmissionsChart/PortfolioScopeEmissionsChart";
import "./Portfolio.scss";

export default function Portfolio() {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const account = useContext(AccountContext);

  const [portfolios, setPortfolios] = useState([]);
  const [totalEmissions, setTotalEmissions] = useState();
  const [nzifStatistics, setNzifStatistics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [scopeTwoPreference, setScopeTwoPreference] = useState(0);
  const [selectedScope, setSelectedScope] = useState("Scope 1+2+3");

  const [emissionYear, setEmissionYear] = useState(
    new Date().getFullYear() - 1
  );

  const fetchPortfolios = useCallback(() => {
    setError(null);
    setIsLoading(true);
    const portfoliosPromise = get("portfolios", {
      params: {
        accountId: account.id,
        yearEnded: `${emissionYear}-12-31`,
        "filter[preference]": scopeTwoPreference,
      },
    });
    const statisticPromise = get("/nzif-statistics", {
      params: {
        yearEnded: emissionYear + "-12-31",
        accountId: account.id,
      },
    });

    Promise.all([portfoliosPromise.promise, statisticPromise.promise])
      .then((responses) => {
        setPortfolios(responses[0].data);
        setNzifStatistics(responses[1].data);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(portfoliosPromise);
  }, [account.id, emissionYear, scopeTwoPreference]);

  useEffect(() => {
    fetchPortfolios();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchPortfolios]);

  useEffect(() => {
    const totalEmissionsCal = portfolios?.portfolios?.reduce(
      (acc, curr) => {
        acc.scopeOneEmissions += curr.scopeOneEmissions;
        acc.scopeTwoEmissions += curr.scopeTwoEmissions;
        acc.scopeThreeEmissions += curr.scopeThreeEmissions;
        return acc;
      },
      { scopeOneEmissions: 0, scopeTwoEmissions: 0, scopeThreeEmissions: 0 }
    );
    setTotalEmissions(totalEmissionsCal);
  }, [portfolios]);

  return (
    <>
      <Header />
      <DataLayer />
      <Container id={userCategory} className="MainContent">
        <Row>
          <Col xs={12} md={3} className="border-end py-3 scroller">
            <div className="my-3">
              <h1>Portfolios</h1>
              <h4 className="my-3">
                <Link
                  className="text-decoration-none"
                  to={`/portfolios`}
                  id="overviewLink"
                >
                  Overview
                </Link>
              </h4>
              <hr />
            </div>

            <Nav className="flex-column" variant="pills">
              {portfolios?.portfolios?.map((portfolio) => (
                <Nav.Item key={portfolio.id}>
                  <Nav.Link as={NavLink} to={`/portfolios/${portfolio.id}`}>
                    {portfolio.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col xs={12} md={9} className="py-3">
            <Routes>
              <Route
                path="/"
                element={
                  <div>
                    {isLoading && <Loader />}
                    {error && <ErrorHandler error={error} />}
                    {portfolios && !error && !isLoading && (
                      <>
                        <div className="my-3">
                          <BreadCrumbs
                            breadcrumbs={[
                              { name: "Dashboard", link: "/" },
                              {
                                name: "Portfolios",
                                link: `/portfolios`,
                                active: true,
                              },
                            ]}
                          />
                        </div>
                        <span className="float-end">
                          <PortfolioCreate
                            onPortfolioCreated={fetchPortfolios}
                            variant="primary"
                          />
                        </span>
                        <h2>Overview</h2>
                        <p className="pt-1">
                          A portfolio is a group of portfolio companies in which
                          an investment firm owns equity.
                        </p>
                        <hr />
                        <>
                          {(() => {
                            return (
                              <>
                                <div className="text-end">
                                  <div className="text-end">
                                    <div className="d-inline-flex me-2">
                                      <ScopeTwoPreferenceToggler
                                        scopeTwoPreference={scopeTwoPreference}
                                        setScopeTwoPreference={
                                          setScopeTwoPreference
                                        }
                                      />
                                    </div>
                                    <div className="d-inline-flex">
                                      <Form.Label className="text-nowrap pt-2 me-2">
                                        <small>Reporting Year</small>
                                      </Form.Label>{" "}
                                      <Form.Select
                                        aria-label="year-filter"
                                        size="sm"
                                        className="ps-3"
                                        value={emissionYear}
                                        onChange={(ev) => {
                                          let selectedYear = ev.target.value;
                                          setEmissionYear(selectedYear);
                                        }}
                                        id="yearFilter"
                                      >
                                        {getAllYearsBetweenDates().map(
                                          (year) => (
                                            <option key={year} value={year}>
                                              {year}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>{" "}
                                    </div>
                                    <div className="d-inline-flex align-items-center mx-1 my-3 px-3 pb-1 bg-info text-nowrap text-primary bg-opacity-10 text-nowrap">
                                      <span className="fs-1 me-1">
                                        {portfolios &&
                                          portfolios?.portfolios?.length}
                                      </span>{" "}
                                      Portfolios
                                    </div>
                                    <div className="d-inline-flex align-items-center mx-1 my-3 px-3 pb-1 bg-danger text-danger bg-opacity-10 text-nowrap">
                                      <span className="fs-1 me-1">
                                        {getFormattedEmission(
                                          portfolios?.totalFinancedEmissions ||
                                            0
                                        )}
                                      </span>
                                      <small className="me-1">
                                        tCO<sub>2</sub>e
                                      </small>{" "}
                                      {emissionYear} Emissions
                                    </div>
                                  </div>
                                </div>
                                {!portfolios?.portfolios?.length &&
                                !isLoading ? (
                                  <Alert variant="info">
                                    There are currently no portfolios to show.
                                    Add portfolios to start grouping
                                    organization.
                                  </Alert>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      <Col
                                        md={6}
                                        className="p-3 me-2 my-3 border text-center"
                                      >
                                        <h4 className="my-3">
                                          {emissionYear} Financed Emissions by
                                          Portfolio
                                        </h4>
                                        <h5>&nbsp;</h5>
                                        <PortfolioEmissions
                                          portfolios={portfolios?.portfolios}
                                        />
                                      </Col>
                                      <Col
                                        md={6}
                                        className="p-3 my-3 border text-center"
                                      >
                                        <h4 className="my-3">
                                          {emissionYear} NZIF Alignment for All
                                          Portfolios
                                        </h4>
                                        <h5>
                                          (Total AUM: USD{" "}
                                          {getFormattedEmission(
                                            nzifStatistics?.totalAum || 0
                                          )}
                                          )
                                        </h5>
                                        <PortfolioNzifChart
                                          nzifStatistics={
                                            nzifStatistics?.nzifCategories
                                          }
                                        />
                                      </Col>
                                    </div>
                                    <Col md={12} className="p-3 border">
                                      <Row>
                                        <Col
                                          md={11}
                                          className="fs-4 mt-1 text-center"
                                        >
                                          Financed Emissions from Portfolio by
                                          Scope
                                        </Col>
                                        <Col md={1}>
                                          <Dropdown className="float-end">
                                            <Dropdown.Toggle
                                              variant="light"
                                              id="dropdown-basic"
                                              size="sm"
                                              className="bg-primary bg-opacity-25 text-dark border-0"
                                            >
                                              {selectedScope === "All"
                                                ? "Filter by Scope 1+2+3"
                                                : `Filter by ${selectedScope}`}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {[
                                                "Scope 1+2+3",
                                                "Scope 1+2",
                                                "Scope 1",
                                                "Scope 2",
                                                "Scope 3",
                                              ].map((scope, index) => (
                                                <Dropdown.Item
                                                  key={index}
                                                  className={
                                                    selectedScope === scope
                                                      ? "active"
                                                      : ""
                                                  }
                                                  onClick={() =>
                                                    setSelectedScope((prev) => {
                                                      if (prev === scope)
                                                        return scope;

                                                      return scope;
                                                    })
                                                  }
                                                >
                                                  {scope}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </Col>
                                      </Row>
                                      <PortfolioScopeEmissions
                                        portfolios={portfolios?.portfolios}
                                        selectedScope={selectedScope}
                                      />
                                    </Col>
                                    <PortfolioList
                                      portfolios={portfolios?.portfolios}
                                      fetchPortfolios={fetchPortfolios}
                                      setSelectedScope={setSelectedScope}
                                    />
                                    <h3 className="mt-4">
                                      Financed Emissions from Portfolio by Scope
                                    </h3>
                                    <hr />
                                    <div className="portfolioScopeList">
                                      <Table
                                        hover
                                        size="sm"
                                        responsive
                                        className="mb-3 first-column-fixed"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th className="text-end">
                                              Scope 1
                                            </th>
                                            <th className="text-end">
                                              Scope 2
                                            </th>
                                            <th className="text-end">
                                              Scope 3
                                            </th>
                                            <th className="text-end">
                                              Total Financed Emissions
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {portfolios?.portfolios
                                            ?.sort(
                                              (a, b) =>
                                                b.financedEmissions -
                                                a.financedEmissions
                                            )
                                            ?.map((portfolio) => (
                                              <tr key={portfolio?.id}>
                                                <td
                                                  className="first-column fixedTd text-nowrap align-middle text-truncate"
                                                  title={portfolio?.name}
                                                >
                                                  <Link
                                                    className="text-decoration-none"
                                                    to={`/portfolios/${portfolio.id}`}
                                                  >
                                                    {portfolio.name}
                                                  </Link>
                                                </td>
                                                <td className="text-end text-nowrap align-middle">
                                                  {getFormattedEmission(
                                                    portfolio?.scopeOneEmissions ||
                                                      0
                                                  )}
                                                </td>
                                                <td className="text-end text-nowrap align-middle">
                                                  {getFormattedEmission(
                                                    portfolio?.scopeTwoEmissions ||
                                                      0
                                                  )}
                                                </td>
                                                <td className="text-end text-nowrap align-middle">
                                                  {getFormattedEmission(
                                                    portfolio?.scopeThreeEmissions ||
                                                      0
                                                  )}
                                                </td>
                                                <td className="text-end text-nowrap align-middle">
                                                  {getFormattedEmission(
                                                    (portfolio?.scopeOneEmissions ||
                                                      0) +
                                                      (portfolio?.scopeTwoEmissions ||
                                                        0) +
                                                      (portfolio?.scopeThreeEmissions ||
                                                        0)
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                          <tr>
                                            <td className="text-nowrap px-3">
                                              Total
                                            </td>
                                            <td className="text-end align-middle">
                                              {getFormattedEmission(
                                                totalEmissions?.scopeOneEmissions ||
                                                  0
                                              )}
                                            </td>
                                            <td className="text-end align-middle">
                                              {getFormattedEmission(
                                                totalEmissions?.scopeTwoEmissions ||
                                                  0
                                              )}
                                            </td>
                                            <td className="text-end align-middle">
                                              {getFormattedEmission(
                                                totalEmissions?.scopeThreeEmissions ||
                                                  0
                                              )}
                                            </td>
                                            <td className="text-end align-middle">
                                              {getFormattedEmission(
                                                (totalEmissions?.scopeOneEmissions ||
                                                  0) +
                                                  (totalEmissions?.scopeTwoEmissions ||
                                                    0) +
                                                  (totalEmissions?.scopeThreeEmissions ||
                                                    0)
                                              )}
                                            </td>
                                          </tr>
                                        </tfoot>
                                      </Table>
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          })()}
                        </>
                      </>
                    )}
                  </div>
                }
              />
              <Route path="/:portfolioId" element={<PortfolioDetail />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
}
