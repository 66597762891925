import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { AccountContext } from "contexts/AccountProvider";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import CreateUpdateGWPModel from "./UpdateGwpModels/UpdateGwpModels";
import { get } from "utils/DeApi";
import { getAllYearsBetweenDates } from "utils/dateUtils";

const GwpModels = ({ organization, onOrganizationUpdated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);

  const [gwpModels, setGwpModels] = useState([]);
  const [updatedOrg, setUpdatedOrg] = useState(organization);

  const accountContext = useContext(AccountContext);
  const [account, setAccount] = useState();

  const isOrgGWPSameToAccountForYear = (year) => {
    const orgGwpModelName = orgCurrYearGwpModel(year);
    const accountGwpModelName = accountCurrYearGwpModel(year);

    return accountGwpModelName === orgGwpModelName;
  };

  const orgCurrYearGwpModel = (year) =>
    updatedOrg?.gwpModels?.find(
      (model) => Number.parseInt(model.year) === Number.parseInt(year)
    )?.name;

  const accountCurrYearGwpModel = (year) =>
    account?.gwpModels.find(
      (model) => Number.parseInt(model.year) === Number.parseInt(year)
    )?.name;

  const navigate = useNavigate();

  const orgCreatedDate = new Date(updatedOrg?.createdAt).getFullYear();

  const fetchAccounts = useCallback(() => {
    setError(null);
    setIsLoading(true);

    let getAccount = get(`accounts/${accountContext?.id}`);

    getAccount.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setAccount(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(getAccount);
  }, [accountContext?.id]);

  const fetchOrganizationGwpModels = useCallback(() => {
    if (accountContext.member.role === "member") {
      navigate(`/organizations/${organization.id}`);
    } else {
      setError(null);
      setIsLoading(true);
      const fetchGwpModels = get(`/gwp-models`);
      const fetchOrg = get(`organizations/${organization.id}`);

      Promise.all([fetchGwpModels.promise, fetchOrg.promise])
        .then((responses) => {
          setGwpModels(responses[0].data);
          setUpdatedOrg(responses[1].data);
          onOrganizationUpdated(responses[1].data);
          setIsLoading(false);
          setError(null);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(fetchGwpModels, fetchOrg);
    }
  }, [accountContext, organization.id, navigate, fetchAccounts]);

  useEffect(() => {
    fetchOrganizationGwpModels();
    fetchAccounts();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrganizationGwpModels, fetchAccounts]);

  return (
    <div>
      <span className="float-end">
        <div className="d-inline-flex">
          <CreateUpdateGWPModel
            organization={updatedOrg}
            gwpModels={gwpModels}
            onGWPModelUpdated={(_) => fetchOrganizationGwpModels()}
          />
        </div>
      </span>
      <h4>GWP Basis by Year</h4>

      <div className="my-3">
        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}

        {!isLoading && (
          <Table hover className="my-4" size="sm" responsive>
            <thead>
              <tr>
                <th className="text-end">Year</th>
                <th className="text-end">GWP Basis</th>
                <th className="text-end">Description</th>
              </tr>
            </thead>
            <tbody>
              {getAllYearsBetweenDates().map((year) => (
                <tr key={year}>
                  <td className="text-end text-nowrap">{year}</td>
                  <td className="text-end text-nowrap">
                    {orgCurrYearGwpModel(year) || (
                      <small className="text-muted">n/a</small>
                    )}
                    {!isOrgGWPSameToAccountForYear(year) && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            {`Organization's GWP basis (${
                              orgCurrYearGwpModel(year) || "n/a"
                            }) is different from the one (${
                              accountCurrYearGwpModel(year) || "n/a"
                            }) set for the account for ${year}`}
                          </Tooltip>
                        }
                      >
                        <span className="material-icons-outlined md-16 text-warning">
                          warning
                        </span>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td className="text-end text-nowrap">
                    {updatedOrg?.gwpModels?.find(
                      (model) =>
                        Number.parseInt(model.year) === Number.parseInt(year)
                    )?.description || <small className="text-muted">n/a</small>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default GwpModels;
