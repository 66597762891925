import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Col,
  Form,
  Offcanvas,
  Row,
  Table,
} from "react-bootstrap";
import { get } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import AbatementProjectCreate from "./AbatementProjectCreate/AbatementProjectCreate";

const AbatementLibrary = ({ sites, onAbatementProjectCreated }) => {
  const [abatementOpportunities, setAbatementOpportunities] = useState([]);
  const [query, setQuery] = useState("");
  const [sectors, setSectors] = useState([]);
  const [sector, setSector] = useState("reset");
  const [subSectors, setSubSectors] = useState([]);
  const [subSector, setSubSector] = useState("reset");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [isExpanding, setIsExpanding] = useState(false);
  const [errorExpanding, setErrorExpanding] = useState();
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setQuery("");
    setSector("reset");
    setSubSector("reset");
    setSubSectors([]);
  };
  const handleShow = () => setShow(true);

  const fetchSubSectors = (sectorId) => {
    setErrorExpanding(null);
    setIsExpanding(true);
    const organizationPromise = get(
      `abatement/industry-sectors/${sectorId}/sub-sectors`
    );
    organizationPromise.promise
      .then((response) => {
        setIsExpanding(false);
        setSubSectors(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setErrorExpanding(error);
          setIsExpanding(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    const fetchAbatementLibrary = () => {
      setError(null);
      setIsLoading(true);
      const abatementOpportunityPromise = get(`abatement-opportunities`);
      abatementOpportunityPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setAbatementOpportunities(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(abatementOpportunityPromise);
    };

    fetchAbatementLibrary();

    const fetchSectors = () => {
      setErrorExpanding(null);
      setIsExpanding(true);
      const organizationPromise = get(`abatement/industry-sectors`);
      organizationPromise.promise
        .then((response) => {
          const sector = response.data || [];
          setIsExpanding(false);
          setSectors(sector);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setErrorExpanding(error);
            setIsExpanding(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    fetchSectors();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const filteredRows = abatementOpportunities
    .filter((project) => {
      if (sector && sector !== "reset") return project?.sector?.id === sector;
      return true;
    })
    .filter((project) => {
      if (subSector && subSector !== "reset")
        return project?.subSector?.id === subSector;
      return true;
    })
    .filter((project) => {
      const title = project?.title || "",
        sector = project?.sector?.name || "",
        subSector = project?.subSector?.name || "";

      if (query || query !== "")
        return (
          title.toLowerCase().includes(query.toLowerCase()) ||
          sector.toLowerCase().includes(query.toLowerCase()) ||
          subSector.toLowerCase().includes(query.toLowerCase())
        );
      return true;
    })
    .map((project) => (
      <tr key={project?.id}>
        <td>{project?.title}</td>
        <td>{project?.sector?.name || ""}</td>
        <td>{project?.subSector?.name || ""}</td>
        <td className="text-end">{project?.projectLifetime}</td>
        <td className="text-end">
          {new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 3,
            style: "percent",
          }).format(project?.discountRate / 100)}
        </td>
        <td className="text-end">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(project?.disposalCost)}
        </td>
        <td className="text-end">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(project?.initialInvestment)}
        </td>
        <td className="text-end">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(project?.annualSavings)}
        </td>
        <td className="text-end">
          {new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 3,
          }).format(project?.cumulativeCarbonSavings)}
        </td>
        {onAbatementProjectCreated && (
          <td>
            <AbatementProjectCreate
              sites={sites}
              project={project}
              onAbatementProjectCreated={(data) => {
                onAbatementProjectCreated(data);
                handleClose();
              }}
            />
          </td>
        )}
      </tr>
    ));

  return (
    <>
      <Button size="sm" onClick={handleShow} className="float-end">
        {onAbatementProjectCreated
          ? "Add Project From Library"
          : "View Opportunity Library"}
      </Button>
      <Offcanvas
        placement="end"
        show={show}
        onHide={handleClose}
        className="w-75"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Abatement Opportunities Library</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="Sectors"
              className="mb-3"
            >
              <Form.Control
                name="query"
                value={query}
                placeholder="Search..."
                onChange={(e) => {
                  const query = e.target.value;
                  setQuery(query);
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="Sectors"
              className="mb-3"
            >
              <Form.Select
                name="section"
                value={sector}
                onChange={(e) => {
                  const sectorId = e.target.value;
                  if (sectorId !== "reset") fetchSubSectors(sectorId);
                  setSector(sectorId);
                  setSubSector("reset");
                }}
              >
                <option value={"reset"}>
                  {isExpanding ? "Loading..." : "Filter by Sector"}
                </option>
                {sectors.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="subSectors"
              className="mb-3"
            >
              <Form.Select
                name="subSector"
                value={subSector}
                disabled={isExpanding || subSectors.length === 0}
                onChange={(e) => {
                  const subSectorId = e.target.value;
                  setSubSector(subSectorId);
                }}
              >
                <option value={"reset"}>
                  {isExpanding ? "Loading..." : "Filter by Sub-Sector"}
                </option>
                {subSectors.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <Table size="sm" striped responsive>
            <thead>
              <tr>
                <th>Project</th>
                <th>Sector</th>
                <th>Sub Sector</th>
                <th className="text-end">
                  Project Lifetime <small>(Yrs)</small>
                </th>
                <th className="text-end">Discount Rate</th>
                <th className="text-end">Disposal Cost</th>
                <th className="text-end">Initial Investment</th>
                <th className="text-end">Annual Savings</th>
                <th className="text-end">
                  Annual Emissions Reduction{" "}
                  <small>
                    (tCO<sub>2</sub>e)
                  </small>
                </th>
                {onAbatementProjectCreated && <th></th>}
              </tr>
            </thead>
            <tbody>{filteredRows}</tbody>
          </Table>
          {!error && !isLoading && filteredRows.length === 0 && (
            <Alert variant="info">
              There is currently nothing to show here.
            </Alert>
          )}
          {error && !isLoading && <ErrorHandler error={error} />}
          {errorExpanding && !isExpanding && (
            <ErrorHandler error={errorExpanding} />
          )}
          {isLoading && <Loader />}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

AbatementLibrary.propTypes = {
  onAbatementProjectCreated: PropTypes.func,
};

export default AbatementLibrary;
