import React, { useState, useEffect, useRef, useContext } from "react";
import { Table, Alert } from "react-bootstrap";
import PropTypes from "prop-types";

import { get } from "utils/DeApi";
import { getFormattedEmission } from "utils/StringUtils";
import { AccountContext } from "contexts/AccountProvider";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import TopDownUpdate from "./TopDownUpdate/TopDownUpdate";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";

function TopDownAnalysis({
  organization,
  organizationInfo,
  onOrganizationInfoUpdated,
  onOrganizationInfoDeleted,
}) {
  const subscribedPromises = useRef([]);
  const [emissionFactors, setEmissionFactors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const account = useContext(AccountContext);

  const userCategory = UserCategories();

  const fetchEmissionFactors = () => {
    setError(null);
    setIsLoading(true);
    const sectorsPromise = get(`emission-factors/top-down`);
    sectorsPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setEmissionFactors(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(sectorsPromise);
  };

  useEffect(() => {
    fetchEmissionFactors();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organization.id]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            {
              name: organization.name,
              link: `/organizations/${organization.id}`,
            },
            {
              name: "Top-Down Estimation",
              link: `/organizations/${organization.id}/top-down`,
              active: true,
            },
          ]}
        />
      </div>
      {!!organizationInfo.length && (
        <div className="d-inline-flex float-end">
          <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10">
            <span className="fs-1">
              {getFormattedEmission(
                organizationInfo[organizationInfo.length - 1]
                  .organizationEmissions
              )}{" "}
            </span>
            <small>
              tCO<sub>2</sub>e
            </small>{" "}
            {organizationInfo[organizationInfo.length - 1]?.yearEnded.substring(
              0,
              4
            ) || ""}{" "}
            Emissions
          </div>
        </div>
      )}
      <h2>Top-Down Estimation</h2>
      <hr />

      {account.member.role !== "member" && (
        <span className="float-end">
          <TopDownUpdate
            organization={organization}
            organizationInfo={organizationInfo}
            emissionFactors={emissionFactors}
            onTopDownUpdated={onOrganizationInfoUpdated}
            onTopDownDeleted={onOrganizationInfoDeleted}
          />
        </span>
      )}
      <h4 className="mb-3">Emissions Estimates via Top-Down Approach</h4>

      {!!organizationInfo.length ? (
        <div className="table-scroll-end ">
          <Table size="sm" striped responsive className="first-column-fixed">
            <thead>
              <tr>
                <th></th>
                {organizationInfo.map((item) => (
                  <th className="text-end" key={item.yearEnded}>
                    {item.yearEnded.substring(0, 4)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-nowrap">Annual Revenue (USD)</td>
                {organizationInfo.map((item) => (
                  <td
                    className="text-nowrap text-end ps-3"
                    key={item.yearEnded}
                  >
                    {Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.revenue)}
                    {"k"}
                  </td>
                ))}
              </tr>

              <tr>
                <td className="text-nowrap">Primary Location</td>
                {organizationInfo.map((item) => {
                  let location = item.emissionFactor?.metadata?.find(
                    (meta) => meta.type === "country"
                  )?.value;
                  return (
                    <td className="text-end text-end ps-3" key={item.yearEnded}>
                      {location || "N/A"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td className="text-nowrap">Industry</td>
                {organizationInfo.map((item) => {
                  let industry = item.emissionFactor?.emissionResource;
                  return (
                    <td className="text-end text-end ps-3" key={item.yearEnded}>
                      {industry || "N/A"}
                    </td>
                  );
                })}
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="text-nowrap">
                  Emissions (tCO<sub>2</sub>e)
                </td>
                {organizationInfo.map((item) => (
                  <td className="text-end text-end ps-3" key={item.yearEnded}>
                    <big>
                      {getFormattedEmission(item.organizationEmissions)}
                    </big>{" "}
                  </td>
                ))}
              </tr>
            </tfoot>
          </Table>
        </div>
      ) : (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}

      <div className="my-4 p-3 border">
        <ContentDetails contentId={"625dc3afd73c0"} view={"full"} />
      </div>
    </div>
  );
}

TopDownAnalysis.propTypes = {
  organization: PropTypes.object.isRequired,
  organizationInfo: PropTypes.array.isRequired,
  onOrganizationInfoUpdated: PropTypes.func.isRequired,
};

export default TopDownAnalysis;
