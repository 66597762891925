import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Alert, Button, Card, Col, Container, Row } from "react-bootstrap";
import { UserContext } from "./UserProvider";

import { logout } from "utils/BeeApi";
import { get, put, logout as logoutDE } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import Logo from "components/App/Logo/Logo";
import CopyRight from "components/App/Copyright/Copyright";
import { UserDispatchContext } from "contexts/UserProvider";

const AccountContext = createContext(undefined);
const AccountDispatchContext = createContext(undefined);

function AccountProvider({ children }) {
  const subscribedPromises = useRef([]);

  const [account, setAccount] = useState();
  const [accountInvitations, setAccountInvitations] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const user = useContext(UserContext);
  const setUser = useContext(UserDispatchContext);

  const dispatchContext = (acc) => {
    let key = "b3JnYW5pemF0aW9v";
    let value = btoa(acc.id);
    localStorage.setItem(key, value);
    setAccount(acc);
  };

  const logoutUser = () => {
    setError(null);
    setIsLoading(true);
    logout()
      .then(() => {
        return logoutDE();
      })
      .then(() => {
        setUser(null);
        setAccount(null);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setUser(null);
        setAccount(null);
      });
  };

  const joinAccount = (invitation) => {
    setError(null);
    setIsLoading(true);
    let joinAccount = put(`invitations/${invitation.token}`);
    joinAccount.promise
      .then(() => {
        fetchAccounts();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const fetchAccounts = () => {
    setError(null);
    setIsLoading(true);

    let getAccounts = get(`accounts`);
    let getInvitations = get(`invitations`);
    Promise.all([getAccounts.promise, getInvitations.promise])
      .then((responses) => {
        let invitations = responses[1].data.filter(
          (item) => !item.subscriberJoinedAt
        );
        setAccountInvitations(invitations);

        let accounts = responses[0].data;
        let cachedAcc = localStorage.getItem("b3JnYW5pemF0aW9v");
        let cachedAccId = cachedAcc ? atob(cachedAcc) : null;
        let acc = cachedAccId
          ? accounts.find((item) => item.id === cachedAccId)
          : null;
        if (!acc) acc = accounts[0];

        setAccount(acc || accounts[0]);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(getAccounts, getInvitations);
  };

  useEffect(() => {
    if (user) fetchAccounts();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [user]);

  if (!user) return children;
  if (isLoading) return <Loader />;
  if (!account)
    return (
      <Container className="mt-2 mb-2">
        <Row className="justify-content-md-center">
          <Col xl={6} lg={7} md={8} sm={10}>
            <Logo />
            <Card className="border shadow-sm">
              <Card.Body>
                <h4>Hi {user.firstName}!</h4>

                {error && (
                  <Alert variant="warning" className={`mt-3 mb-3`}>
                    <h4>{error.statusText || "Oh snap! You got an error!"}</h4>
                    <p>
                      {error.data?.error?.message ||
                        "Something unexpected happened. Please try again."}
                    </p>
                    <small>{error.status || ""}</small>
                  </Alert>
                )}
                {accountInvitations && !!accountInvitations.length ? (
                  <div>
                    <p>
                      <i>{user.email}</i> has been invited to access the
                      following accounts.
                    </p>
                    <hr />
                    {accountInvitations.map((invitation, index) => (
                      <div className="border-bottom-1 py-3" key={index}>
                        <span className="float-end">
                          <Button
                            size="sm"
                            variant="primary"
                            className="px-3 py-0"
                            onClick={() => joinAccount(invitation)}
                          >
                            Join Now
                          </Button>{" "}
                        </span>
                        {invitation.account.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <hr />
                    <p>Welcome to Net Zero Compass.</p>
                    <p>
                      An action from your organizations' Net Zero Compass
                      administrator is needed to complete your set up.
                    </p>

                    <p>
                      Please check with your Net Zero Compass administrator to
                      grant you access to your organizations' account.
                    </p>
                  </>
                )}
                <hr />
                <p className="text-center">
                  <Button variant="link" onClick={logoutUser}>
                    Logout
                  </Button>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <CopyRight />
      </Container>
    );

  return (
    <AccountContext.Provider value={account}>
      <AccountDispatchContext.Provider value={dispatchContext}>
        {children}
      </AccountDispatchContext.Provider>
    </AccountContext.Provider>
  );
}

export { AccountProvider, AccountContext, AccountDispatchContext };
