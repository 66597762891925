import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { TableSortArrows } from "components/Shared/TableComponents";
import { useEffect, useRef, useState } from "react";
import { Alert, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { get, put } from "utils/DeApi";
import ScenarioCreate from "./ScenarioCreate/ScenarioCreate";
import ScenarioDelete from "./ScenarioDelete/ScenarioDelete";
import ScenarioEdit from "./ScenarioEdit/ScenarioEdit";
import "./ScenarioPlanning.scss";

const ScenarioPlanning = ({ organization }) => {
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();
  const [projects, setProjects] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [scenariosExpandableTable, setScenariosExpandableTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState([]);
  const [error, setError] = useState();

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "name",
    updatedAt: true,
    sortAsc: false,
  });
  const [tableSortStateExpandableTable, setTableSortStateExpandableTable] =
    useState({
      sortColumn: "name",
      updatedAt: true,
      sortAsc: false,
    });

  const fetchScenarios = () => {
    setError(null);
    setIsLoading(true);
    const scenariosPromise = get(`organizations/${organizationId}/scenarios`);
    scenariosPromise.promise
      .then((response) => {
        setScenarios(response.data);
        setScenariosExpandableTable(response.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(scenariosPromise);
  };

  useEffect(() => {
    setError(null);
    setIsLoading(true);
    const scenariosPromise = get(`organizations/${organizationId}/scenarios`);
    const customFieldsPromise = get(
      `organizations/${organizationId}/custom-fields`
    );
    const organizationPromise = get(
      `organizations/${organizationId}/abatement-projects`
    );
    Promise.all([
      scenariosPromise.promise,
      customFieldsPromise.promise,
      organizationPromise.promise,
    ])
      .then((responses) => {
        setScenarios(responses?.[0]?.data);
        setScenariosExpandableTable(responses?.[0]?.data);
        setCustomFields(responses?.[1]?.data);
        setProjects(responses?.[2]?.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(
      scenariosPromise,
      customFieldsPromise,
      organizationPromise
    );
  }, [organizationId]);

  const activateScenario = (scenario, cb) => {
    setError(null);
    cb(true);
    const activePromise = put(`scenarios/${scenario?.id}`, {
      active: !scenario?.active,
    });
    activePromise.promise
      .then((response) => {
        cb(false);
        setError(null);
        scenario.active = !scenario.active;
        const updateScenarios = scenarios.map((item) => {
          if (item.id === scenario.id) {
            return scenario;
          } else {
            return item;
          }
        });
        setScenarios(updateScenarios);
        setScenariosExpandableTable(updateScenarios);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          cb(true);
          setError(error);
        }
      });
    subscribedPromises.current.push(activePromise);
  };

  const sortingTable = (column, objValue) => {
    scenarios?.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];

      if (
        macA === null ||
        macA === undefined ||
        macA === "n/a" ||
        macA === ""
      ) {
        return tableSortState[column] ? 1 : -1;
      }

      if (
        macB === null ||
        macB === undefined ||
        macB === "n/a" ||
        macA === ""
      ) {
        return tableSortState[column] ? -1 : 1;
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setScenarios([...scenarios]);
  };
  const sortingTableExpandableTable = (tableName, column, id) => {
    let scenario = scenariosExpandableTable.find((s) => s.name === tableName);
    let otherScenarios = scenariosExpandableTable.filter(
      (s) => s.name !== tableName
    );

    if (!scenario) return;

    let isAsc = tableSortStateExpandableTable[column];
    scenario.projects.sort((a, b) => {
      let valA = a[column];
      let valB = b[column];

      if (valA == null || valA === "n/a") valA = isAsc ? Infinity : -Infinity;
      if (valB == null || valB === "n/a") valB = isAsc ? Infinity : -Infinity;

      if (typeof valA === "number" && typeof valB === "number") {
        return isAsc ? valA - valB : valB - valA;
      } else {
        return isAsc
          ? valA.localeCompare(valB, "en-us")
          : valB.localeCompare(valA, "en-us");
      }
    });

    const newSortState = {
      ...tableSortStateExpandableTable,
      sortColumn: column + id,
      sortAsc: isAsc,
      [column]: !isAsc,
    };

    setTableSortStateExpandableTable(newSortState);
    setScenariosExpandableTable([...otherScenarios, ...scenario]);
  };

  const ExpandableTable = ({ scenario, onScenarioActive }) => {
    const isExpanded = id.includes(scenario.id);

    const toggleExpand = (scenarioId) => {
      setId((prevIds) =>
        prevIds.includes(scenarioId)
          ? prevIds.filter((id) => id !== scenarioId)
          : [...prevIds, scenarioId]
      );
    };

    return (
      <div className="my-2" key={scenario?.id}>
        <div className="d-flex justify-content-between">
          <h4
            role="button"
            tabIndex="0"
            onClick={() => {
              toggleExpand(scenario.id);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleExpand(scenario.id);
              }
            }}
          >
            <span className="material-icons-outlined me-1 md-16 text-muted">
              {isExpanded ? "expand_less" : "expand_more"}
            </span>
            {""}
            {scenario?.name}
          </h4>
          <span>
            <CustomToggleSwitch
              onChange={(cb) => onScenarioActive(scenario, cb)}
              isChecked={scenario?.active}
            />
            <span>
              <ScenarioEdit
                projects={projects}
                customFields={customFields}
                scenario={scenario}
                onScenarioUpdate={fetchScenarios}
              />
            </span>
            <span>
              <ScenarioDelete
                scenario={scenario}
                onScenarioDelete={fetchScenarios}
              />
            </span>
          </span>
        </div>
        {isExpanded && (
          <Table
            size="sm"
            responsive
            striped
            className="first-column-fixed mb-0"
          >
            <thead>
              <tr>
                <th
                  className="table-sorter"
                  onClick={() => {
                    sortingTableExpandableTable(
                      scenario?.name,
                      "name",
                      scenario?.id
                    );
                  }}
                >
                  Project Name{" "}
                  <TableSortArrows
                    sortColumn={tableSortStateExpandableTable.sortColumn}
                    colName={`name${scenario?.id}`}
                    sortAsc={tableSortStateExpandableTable.sortAsc}
                  />
                </th>
                <th
                  className="text-end table-sorter"
                  onClick={() => {
                    sortingTableExpandableTable(
                      scenario?.name,
                      "capitalCost",
                      scenario?.id
                    );
                  }}
                >
                  Capital Cost (USD){" "}
                  <TableSortArrows
                    sortColumn={tableSortStateExpandableTable.sortColumn}
                    colName={`capitalCost${scenario?.id}`}
                    sortAsc={tableSortStateExpandableTable.sortAsc}
                  />
                </th>
                <th
                  className="text-end table-sorter"
                  onClick={() => {
                    sortingTableExpandableTable(
                      scenario?.name,
                      "financialSaving",
                      scenario?.id
                    );
                  }}
                >
                  Financial Savings (USD/year){" "}
                  <TableSortArrows
                    sortColumn={tableSortStateExpandableTable.sortColumn}
                    colName={`financialSaving${scenario?.id}`}
                    sortAsc={tableSortStateExpandableTable.sortAsc}
                  />
                </th>
                <th
                  className="text-end table-sorter"
                  onClick={() => {
                    sortingTableExpandableTable(
                      scenario?.name,
                      "paybackPeriod",
                      scenario?.id
                    );
                  }}
                >
                  Average Payback (years){" "}
                  <TableSortArrows
                    sortColumn={tableSortStateExpandableTable.sortColumn}
                    colName={`paybackPeriod${scenario?.id}`}
                    sortAsc={tableSortStateExpandableTable.sortAsc}
                  />
                </th>
                <th
                  className="text-end table-sorter"
                  onClick={() => {
                    sortingTableExpandableTable(
                      scenario?.name,
                      "ghgEmissionsReduction",
                      scenario?.id
                    );
                  }}
                >
                  GHG Emissions Reduction (MTCO<sub>2</sub>e/year)
                  <TableSortArrows
                    sortColumn={tableSortStateExpandableTable.sortColumn}
                    colName={"ghgEmissionsReduction"}
                    sortAsc={tableSortStateExpandableTable.sortAsc}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {scenario?.projects?.map((project) => (
                <tr key={project?.id}>
                  <td>{project?.name}</td>
                  <td className="text-end">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(project?.capitalCost)}
                  </td>
                  <td className="text-end">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(project?.financialSaving)}
                  </td>
                  <td className="text-end">{project?.paybackPeriod}</td>
                  <td className="text-end">
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 3,
                    }).format(project?.ghgEmissionsReduction)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    );
  };
  return (
    <div>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            {
              name: organization.name,
              link: `/organizations/${organization?.id}`,
            },
            {
              name: "Scenario Analysis",
              link: `/organizations/${organization?.id}/scenario-analysis`,
              active: true,
            },
          ]}
        />
      </div>
      <div className="mb-2 pt-2 d-flex align-items-center justify-content-between">
        <h2>
          <span>Scenario Analysis Overview</span>
        </h2>
        <ScenarioCreate
          projects={projects}
          customFields={customFields}
          onScenarioCreate={fetchScenarios}
        />
      </div>
      <hr />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : !!scenarios?.length && !isLoading && !error ? (
        <>
          <div className="mb-3">
            <h3>Scenario Overview</h3>
            <Table
              size="sm"
              responsive
              striped
              className="first-column-fixed mb-0"
            >
              <thead>
                <tr>
                  <th
                    className="table-sorter"
                    onClick={() => {
                      sortingTable("name");
                    }}
                  >
                    Scenario{" "}
                    <TableSortArrows
                      sortColumn={tableSortState.sortColumn}
                      colName={"name"}
                      sortAsc={tableSortState.sortAsc}
                    />
                  </th>
                  <th
                    className="text-end table-sorter"
                    onClick={() => {
                      sortingTable("capitalCost");
                    }}
                  >
                    Capital Cost (USD)
                    <TableSortArrows
                      sortColumn={tableSortState.sortColumn}
                      colName={"capitalCost"}
                      sortAsc={tableSortState.sortAsc}
                    />
                  </th>
                  <th
                    className="text-end table-sorter"
                    onClick={() => {
                      sortingTable("financialSaving");
                    }}
                  >
                    Financial Savings (USD/year)
                    <TableSortArrows
                      sortColumn={tableSortState.sortColumn}
                      colName={"financialSaving"}
                      sortAsc={tableSortState.sortAsc}
                    />
                  </th>
                  <th
                    className="text-end table-sorter"
                    onClick={() => {
                      sortingTable("paybackPeriod");
                    }}
                  >
                    Average Payback (years)
                    <TableSortArrows
                      sortColumn={tableSortState.sortColumn}
                      colName={"paybackPeriod"}
                      sortAsc={tableSortState.sortAsc}
                    />
                  </th>
                  <th
                    className="text-end table-sorter"
                    onClick={() => {
                      sortingTable("ghgEmissionsReduction");
                    }}
                  >
                    GHG Emissions Reduction (MTCO<sub>2</sub>e/year)
                    <TableSortArrows
                      sortColumn={tableSortState.sortColumn}
                      colName={"ghgEmissionsReduction"}
                      sortAsc={tableSortState.sortAsc}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {scenarios?.map((scenario) => (
                  <tr key={scenario?.id}>
                    <td>{scenario?.name}</td>
                    <td className="text-end">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(scenario?.capitalCost)}
                    </td>
                    <td className="text-end">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(scenario?.financialSaving)}
                    </td>
                    <td className="text-end">{scenario?.paybackPeriod}</td>
                    <td className="text-end">
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 3,
                      }).format(scenario?.ghgEmissionsReduction)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {scenariosExpandableTable?.map((scenario) => (
            <ExpandableTable
              key={scenario?.id}
              scenario={scenario}
              onScenarioActive={(id, cb) => activateScenario(id, cb)}
            />
          ))}
        </>
      ) : (
        !isLoading &&
        !error &&
        !scenarios?.length && (
          <Alert variant="info">There is currently nothing to show here.</Alert>
        )
      )}

      <div className="my-4 p-3 border">
        <ContentDetails contentId={"66470e28044e6"} view={"full"} />
      </div>
    </div>
  );
};

const CustomToggleSwitch = ({ onChange, isChecked }) => {
  const [isToggling, setIsToggling] = useState(false);
  const toggleSwitch = () => {
    if (!isToggling) {
      onChange(setIsToggling);
    }
  };

  return (
    <>
      <div
        className={`scenario-custom-toggle-switch ${isChecked && "checked"}  ${
          isToggling && "disabled"
        }`}
        onClick={toggleSwitch}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === "Space") {
            toggleSwitch();
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div className="scenario-toggle-background">
          <div className="scenario-toggle-button" />
        </div>
        <span className="scenario-toggle-label">
          {isChecked ? "Yes" : "No"}
        </span>
      </div>
    </>
  );
};

export default ScenarioPlanning;
