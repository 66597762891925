const UnitsDataSet = [
  {
    GJ: "GJ",
    TJ: "TJ",
    MJ: "MJ",
    kWh: "kWh",
    MWh: "MWh",
    therm: "therm",
    toe: "toe",
    kcal: "kcal",
    mmBtu: "mmBtu",
  },
  {
    litre: "litre",
    kL: "kL",
    "cu ft": "cu ft",
    "cubic metre": "cubic metre",
    scf: "scf",
    // gallon: "gallon",
    "Imp. gallon": "Imp. gallon",
    "US gallon": "US gallon",
    bbl: "bbl",
  },
  {
    kg: "kg",
    tonne: "tonne",
    "ton (UK)": "ton (UK)",
    "ton (US)": "ton (US)",
    lb: "lb",
    // "metric ton": "metric ton",
    // "short ton": "short ton",
  },
  {
    m: "m",
    ft: "ft",
    mile: "mile",
    km: "km",
    nmi: "nmi",
    in: "in",
    cm: "cm",
    yd: "yd",
  },
];

function getFormula(unit, emissionUnit, amount) {
  const FormulasDataSet = [
    {
      key: "GJ to kWh",
      label: "Gigajoule to Kilowatt hour",
      conversionFactor: 277.777777778,
    },
    {
      key: "therm to kWh",
      label: "Therm to Kilowatt hour",
      conversionFactor: 29.3071083348765,
    },
    {
      key: "toe to kWh",
      label: "Tonne oil equivalent to Kilowatt hour",
      conversionFactor: 11629.9998357937,
    },
    {
      key: "kcal to kWh",
      label: "Kilocalorie to Kilowatt hour",
      conversionFactor: 0.00116272222222315,
    },
    {
      key: "mmBtu to kWh",
      label: "Metric Million British Thermal Unit to Kilowatt hour",
      conversionFactor: 293.0711,
    },
    {
      key: "MWh to kWh",
      label: "Megawatt hour to Kilowatt hour",
      conversionFactor: 1000,
    },
    {
      key: "cubic metre to litre",
      label: "Cubic Metre to Litre",
      conversionFactor: 1000,
    },
    {
      key: "cu ft to litre",
      label: "Cubic Feet to Litre",
      conversionFactor: 28.3168463686774,
    },
    {
      key: "gallon to litre",
      label: "Gallon to Litre",
      conversionFactor: 3.78541180346137,
    },
    {
      key: "Imp. gallon to litre",
      label: "Imperial gallon	to Litre",
      conversionFactor: 4.54609000618123,
    },
    {
      key: "US gallon to litre",
      label: "US gallon to Litre",
      conversionFactor: 3.78541180346137,
    },
    {
      key: "bbl to litre",
      label: "Barrel (US, petroleum) to Litre",
      conversionFactor: 158.987289125222,
    },
    {
      key: "kL to litre",
      label: "Kilo  Litre to Litre",
      conversionFactor: 1000,
    },
    {
      key: "litre to cubic metre",
      label: "Litre to Cubic Metre",
      conversionFactor: 0.001,
    },
    {
      key: "cu ft to cubic metre",
      label: "Cubic Feet to Cubic Metre",
      conversionFactor: 0.0283168463686774,
    },
    {
      key: "gallon to cubic metre",
      label: "Gallon to Cubic Metre",
      conversionFactor: 0.00378541180346137,
    },
    {
      key: "Imp. gallon to cubic metre",
      label: "Imperial gallon	to Cubic Metre",
      conversionFactor: 0.00454609000618123,
    },
    {
      key: "US gallon to cubic metre",
      label: "US gallon to Cubic Metre",
      conversionFactor: 0.00378541180346137,
    },
    {
      key: "bbl to cubic metre",
      label: "Barrel (US, petroleum) to Cubic Metre",
      conversionFactor: 0.158987289125222,
    },
    {
      key: "kL to cubic metre",
      label: "Kilo Litre to Cubic Metre",
      conversionFactor: 1,
    },
    {
      key: "kg to metric ton",
      label: "Kilogram to Metric Ton",
      conversionFactor: 0.001,
    },
    {
      key: "tonne to metric ton",
      label: "Tonne to Metric Ton",
      conversionFactor: 1,
    },
    {
      key: "ton (UK) to metric ton",
      label: "Ton (UK, long ton) to Metric Ton",
      conversionFactor: 1.01604642112889,
    },
    {
      key: "ton (US) to metric ton",
      label: "Ton (US, short ton) to Metric Ton",
      conversionFactor: 0.907184995885916,
    },
    {
      key: "lb to metric ton",
      label: "Pound to Metric Ton",
      conversionFactor: 0.000453592152289682,
    },
    {
      key: "short ton to metric ton",
      label: "Short Ton to Metric Ton",
      conversionFactor: 0.907184995885916,
    },
    {
      key: "GJ to mmBtu",
      label: "Gigajoule to Metric Million British Thermal Unit",
      conversionFactor: 0.947817,
    },
    {
      key: "kWh to mmBtu",
      label: "Kilowatt hour to Metric Million British Thermal Unit",
      conversionFactor: 0.003412,
    },
    {
      key: "therm to mmBtu",
      label: "Therm to Metric Million British Thermal Unit",
      conversionFactor: 0.1,
    },
    {
      key: "toe to mmBtu",
      label: "Tonne oil equivalent to Metric Million British Thermal Unit",
      conversionFactor: 39.68,
    },
    {
      key: "kcal to mmBtu",
      label: "Kilocalorie to Metric Million British Thermal Unit",
      conversionFactor: 0.000003966,
    },
    {
      key: "MWh to mmBtu",
      label: "Megawatt hour to Metric Million British Thermal Unit",
      conversionFactor: 3.412142,
    },
    {
      key: "litre to gallon",
      label: "Litre to Gallon",
      conversionFactor: 0.264172051,
    },
    {
      key: "cubic metre to gallon",
      label: "Cubic Metres to Gallon",
      conversionFactor: 264.172051,
    },
    {
      key: "cu ft to gallon",
      label: "Cubic Feet to Gallon",
      conversionFactor: 7.4805193830654,
    },
    {
      key: "Imp. gallon to gallon",
      label: "Imperial Gallon to Gallon",
      conversionFactor: 1.2009499209635,
    },
    {
      key: "US gallon to gallon",
      label: "US Gallon to Gallon",
      conversionFactor: 1,
    },
    {
      key: "bbl to gallon",
      label: "Barrel (US, petroleum) to Gallon",
      conversionFactor: 41.9999982511398,
    },
    {
      key: "kL to gallon",
      label: "Kilo Litre to Gallon",
      conversionFactor: 264.172,
    },
    {
      key: "kg to short ton",
      label: "Kilogram to Short Ton",
      conversionFactor: 0.001102311,
    },
    {
      key: "tonne to short ton",
      label: "Tonne to Short Ton",
      conversionFactor: 1.102311,
    },
    {
      key: "ton (UK) to short ton",
      label: "Ton (UK, long ton) to Short Ton",
      conversionFactor: 1.11999914652101,
    },
    {
      key: "ton (US) to short ton",
      label: "Ton (US, short ton) to Short Ton",
      conversionFactor: 1,
    },
    {
      key: "lb to short ton",
      label: "Pound to Short Ton",
      conversionFactor: 0.000499999618982592,
    },
    {
      key: "metric ton to short ton",
      label: "Metric ton to Short Ton",
      conversionFactor: 1.102311,
    },
    {
      key: "m to km",
      label: "Metre to Kilometres",
      conversionFactor: 0.001,
    },
    {
      key: "ft to km",
      label: "Feet to Kilometres",
      conversionFactor: 0.000304800000001219,
    },
    {
      key: "mile to km",
      label: "Miles to Kilometres",
      conversionFactor: 1.609344,
    },
    {
      key: "nmi to km",
      label: "Nautical Miles to Kilometres",
      conversionFactor: 1.85199999978826,
    },
    {
      key: "in to km",
      label: "Inch to Kilometres",
      conversionFactor: 0.000254,
    },
    {
      key: "yd to km",
      label: "Yard to Kilometres",
      conversionFactor: 0.00914,
    },
    {
      key: "m to mile",
      label: "Metre to Miles",
      conversionFactor: 0.000621371192237334,
    },
    {
      key: "ft to mile",
      label: "Feet to Miles",
      conversionFactor: 0.000189393939394697,
    },
    {
      key: "km to mile",
      label: "Kilometres to Miles",
      conversionFactor: 0.621371192237334,
    },
    {
      key: "nmi to mile",
      label: "Nautical Miles to Miles",
      conversionFactor: 1.15077944789197,
    },
    {
      key: "in to mile",
      label: "Inch to Miles",
      conversionFactor: 0.000157,
    },
    {
      key: "yd to mile",
      label: "Yard to Miles",
      conversionFactor: 0.00568,
    },
    {
      key: "tonne to kg",
      label: "Tonne to Kilogram",
      conversionFactor: 1000,
    },
    {
      key: "ton (UK) to kg",
      label: "Ton (UK, long ton) to Kilogram",
      conversionFactor: 1016.04642112889,
    },
    {
      key: "ton (US) to kg",
      label: "Ton (US, short ton) to Kilogram",
      conversionFactor: 907.184995885916,
    },
    {
      key: "lb to kg",
      label: "Pound to Kilogram",
      conversionFactor: 0.453592152289682,
    },
    {
      key: "metric ton to kg",
      label: "Metric Ton to Kilogram",
      conversionFactor: 1000,
    },
    {
      key: "short ton to kg",
      label: "Short Ton to Kilogram",
      conversionFactor: 907.184995885916,
    },
    {
      key: "GJ to MWh",
      label: "Gigajoule to Megawatt hour",
      conversionFactor: 0.278,
    },
    {
      key: "kWh to MWh",
      label: "Kilowatt hour to Megawatt hour",
      conversionFactor: 0.001,
    },
    {
      key: "therm to MWh",
      label: "Therm to Megawatt hour",
      conversionFactor: 0.029,
    },
    {
      key: "toe to MWh",
      label: "Tonne oil equivalent to Megawatt hour",
      conversionFactor: 11.63,
    },
    {
      key: "kcal to MWh",
      label: "Kilocalorie to Megawatt hour",
      conversionFactor: 0.00000116,
    },
    {
      key: "mmBtu to MWh",
      label: "Metric Million British Thermal Unit to Megawatt hour",
      conversionFactor: 0.2930711,
    },
    {
      key: "TJ to MWh",
      label: "Terajoule to Megawatt hour",
      conversionFactor: 278.0,
    },
    {
      key: "MWh to TJ",
      label: "Megawatt hour to Terajoule",
      conversionFactor: 0.0036,
    },
    {
      key: "TJ to kWh",
      label: "Terajoule to Kilowatt hour",
      conversionFactor: 277777.777778,
    },
    {
      key: "kWh to TJ",
      label: "Kilowatt hour to Terajoule",
      conversionFactor: 0.0000036,
    },
    {
      key: "TJ to therm",
      label: "Terajoule to Therm",
      conversionFactor: 9478.170777,
    },
    {
      key: "therm to TJ",
      label: "Therm to Terajoule",
      conversionFactor: 0.00010551,
    },
    {
      key: "TJ to toe",
      label: "Terajoule to Tonne oil equivalent",
      conversionFactor: 23.88459,
    },
    {
      key: "toe to TJ",
      label: "Tonne oil equivalent to Terajoule",
      conversionFactor: 0.041868,
    },
    {
      key: "TJ to kcal",
      label: "Terajoule to Kilocalorie",
      conversionFactor: 238902957.618615,
    },
    {
      key: "kcal to TJ",
      label: "Kilocalorie to Terajoule",
      conversionFactor: 0.0000000041868,
    },
    {
      key: "TJ to mmBtu",
      label: "Terajoule to Metric Million British Thermal Unit",
      conversionFactor: 947.817,
    },
    {
      key: "mmBtu to TJ",
      label: "Metric Million British Thermal Unit to Terajoule",
      conversionFactor: 0.2930711,
    },
    {
      key: "kWh to GJ",
      label: "Kilowatt hour to Gigajoule",
      conversionFactor: 0.00359999999999712,
    },
    {
      key: "TJ to GJ",
      label: "Terajoule to Gigajoule",
      conversionFactor: 1000,
    },
    {
      key: "GJ to TJ",
      label: "Gigajoule to Terajoule",
      conversionFactor: 0.001,
    },
    {
      key: "MJ to MWh",
      label: "Megajoule to Megawatt hour",
      conversionFactor: 0.000277778,
    },
    {
      key: "MWh to MJ",
      label: "Megawatt hour to Megajoule",
      conversionFactor: 3600,
    },
    {
      key: "MJ to kWh",
      label: "Megajoule to Kilowatt hour",
      conversionFactor: 0.277777778,
    },
    {
      key: "kWh to MJ",
      label: "Kilowatt hour to Megajoule",
      conversionFactor: 3.6,
    },
    {
      key: "MJ to therm",
      label: "Megajoule to Therm",
      conversionFactor: 0.009478171,
    },
    {
      key: "therm to MJ",
      label: "Therm to Megajoule",
      conversionFactor: 105.50559,
    },
    {
      key: "MJ to toe",
      label: "Megajoule to Tonne oil equivalent",
      conversionFactor: 0.0000238846,
    },
    {
      key: "toe to MJ",
      label: "Tonne oil equivalent to Megajoule",
      conversionFactor: 41868,
    },
    {
      key: "MJ to kcal",
      label: "Megajoule to Kilocalorie",
      conversionFactor: 238.9029576,
    },
    {
      key: "kcal to MJ",
      label: "Kilocalorie to Megajoule",
      conversionFactor: 0.0041868,
    },
    {
      key: "MJ to mmBtu",
      label: "Megajoule to Metric Million British Thermal Unit",
      conversionFactor: 0.000947817,
    },
    {
      key: "mmBtu to MJ",
      label: "Metric Million British Thermal Unit to Megajoule",
      conversionFactor: 1055.0559,
    },
    {
      key: "MJ to GJ",
      label: "Megajoule to Gigajoule",
      conversionFactor: 0.001,
    },
    {
      key: "GJ to MJ",
      label: "Gigajoule to Megajoule",
      conversionFactor: 1000,
    },
    {
      key: "MJ to TJ",
      label: "Megajoule to Terajoule",
      conversionFactor: 0.001,
    },
    {
      key: "TJ to MJ",
      label: "Terajoule to Megajoule",
      conversionFactor: 1000,
    },
    {
      key: "therm to GJ",
      label: "Therm to Gigajoule",
      conversionFactor: 0.105505590005471,
    },
    {
      key: "toe to GJ",
      label: "Tonne oil equivalent to Gigajoule",
      conversionFactor: 41.8679994088238,
    },
    {
      key: "kcal to GJ",
      label: "Kilocalorie to Gigajoule",
      conversionFactor: 0.000004186,
    },
    {
      key: "mmBtu to GJ",
      label: "Metric Million British Thermal Unit to Gigajoule",
      conversionFactor: 1.055056,
    },
    {
      key: "MWh to GJ",
      label: "Megawatt hour to Gigajoule",
      conversionFactor: 3.6,
    },
    {
      key: "litre to kL",
      label: "Litre to Kilo Litre",
      conversionFactor: 0.001,
    },
    {
      key: "cubic metre to kL",
      label: "Cubic Metre to Kilo Litre",
      conversionFactor: 1,
    },
    {
      key: "cu ft to kL",
      label: "Cubic Feet to Kilo Litre",
      conversionFactor: 0.0283168,
    },
    {
      key: "gallon to kL",
      label: "Gallon to Kilo Litre",
      conversionFactor: 0.00378541,
    },
    {
      key: "Imp. gallon to kL",
      label: "Imperial gallon	to Kilo Litre",
      conversionFactor: 0.00454609,
    },
    {
      key: "US gallon to kL",
      label: "US gallon to Kilo Litre",
      conversionFactor: 0.00378541,
    },
    {
      key: "bbl to kL",
      label: "Barrel (US, petroleum) to Kilo Litre",
      conversionFactor: 0.158987,
    },
    {
      key: "mmBtu to therm",
      label: "Metric Million British Thermal Unit to Therm",
      conversionFactor: 10,
    },
    {
      key: "mmBtu to toe",
      label: "Metric Million British Thermal Unit to Tonne oil equivalent",
      conversionFactor: 0.025199,
    },
    {
      key: "mmBtu to kcal",
      label: "Metric Million British Thermal Unit to Kilocalorie",
      conversionFactor: 251995.8,
    },
    {
      key: "MWh to therm",
      label: "Megawatt hour to Therm",
      conversionFactor: 34.12128,
    },
    {
      key: "MWh to toe",
      label: "Megawatt hour to Tonne oil equivalent",
      conversionFactor: 0.08598,
    },
    {
      key: "MWh to kcal",
      label: "Megawatt hour to Kilocalorie",
      conversionFactor: 859845.2,
    },
    {
      key: "kL to cu ft",
      label: "Kilo Litre to Cubic Feet",
      conversionFactor: 35.3147,
    },
    {
      key: "kL to Imp. gallon",
      label: "Kilo Litre to Imperial gallon",
      conversionFactor: 219.969,
    },
    {
      key: "kL to bbl",
      label: "Kilo Litre to Barrel (US, petroleum)",
      conversionFactor: 6.29,
    },
    {
      key: "cu ft to scf",
      label: "Cubic Feet to Standard Cubic Foot",
      conversionFactor: 1,
    },
    {
      key: "cu ft to Imp. gallon",
      label: "Cubic Feet to Imperial gallon",
      conversionFactor: 6.22883540144949,
    },
    {
      key: "cu ft to bbl",
      label: "Cubic Feet to Barrel (US, petroleum)",
      conversionFactor: 0.178107611775017,
    },
    {
      key: "scf to litre",
      label: "Standard Cubic Foot to Litre",
      conversionFactor: 28.3168463686774,
    },
    {
      key: "scf to cubic metre",
      label: "Standard Cubic Foot to Cubic Metre",
      conversionFactor: 0.0283168463686774,
    },
    {
      key: "scf to cu ft",
      label: "Standard Cubic Foot to Cubic Feet",
      conversionFactor: 1,
    },
    {
      key: "scf to Imp. gallon",
      label: "Standard Cubic Foot to Imperial gallon",
      conversionFactor: 6.2288,
    },
    {
      key: "scf to gallon",
      label: "Standard Cubic Foot to Gallon",
      conversionFactor: 7.4805193830654,
    },
    {
      key: "scf to bbl",
      label: "Standard Cubic Foot to Barrel (US, petroleum)",
      conversionFactor: 0.17811,
    },
    {
      key: "scf to kL",
      label: "Standard Cubic Foot to Kilo Litre",
      conversionFactor: 0.0283168,
    },
    {
      key: "litre to cu ft",
      label: "Litre to Cubic Feet",
      conversionFactor: 0.035314667,
    },
    {
      key: "litre to scf",
      label: "Litre to Standard Cubic Foot",
      conversionFactor: 0.035314667,
    },
    {
      key: "cubic metre to cu ft",
      label: "Cubic Metre to Cubic Feet",
      conversionFactor: 35.315,
    },
    {
      key: "cubic metre to scf",
      label: "Cubic Metre to Standard Cubic Foot",
      conversionFactor: 35.315,
    },
    {
      key: "Imp. gallon to cu ft",
      label: "Imperial gallon	to Cubic Feet",
      conversionFactor: 0.160543654720318,
    },
    {
      key: "Imp. gallon to scf",
      label: "Imperial gallon	to Standard Cubic Foot",
      conversionFactor: 0.160543654720318,
    },
    {
      key: "gallon to cu ft",
      label: "Gallon to Cubic Feet",
      conversionFactor: 0.13368,
    },
    {
      key: "gallon to scf",
      label: "Gallon to Standard Cubic Foot",
      conversionFactor: 0.13368,
    },
    {
      key: "US gallon to cu ft",
      label: "US gallon to Cubic Feet",
      conversionFactor: 0.133680557297108,
    },
    {
      key: "US gallon to scf",
      label: "US gallon to Standard Cubic Foot",
      conversionFactor: 0.133680557297108,
    },
    {
      key: "bbl to cu ft",
      label: "Barrel (US, petroleum) to Cubic Feet",
      conversionFactor: 5.61458317268993,
    },
    {
      key: "bbl to scf",
      label: "Barrel (US, petroleum) to Standard Cubic Foot",
      conversionFactor: 5.61458317268993,
    },
    {
      key: "kL to scf",
      label: "Kilo Litre to Standard Cubic Foot",
      conversionFactor: 35.3147,
    },
    {
      key: "GJ to therm",
      label: "Gigajoule to Therm",
      conversionFactor: 9.478170777,
    },
    {
      key: "GJ to toe",
      label: "Gigajoule to Tonne oil equivalent",
      conversionFactor: 0.02388459,
    },
    {
      key: "GJ to kcal",
      label: "Gigajoule to Kilocalorie",
      conversionFactor: 238902.957618615,
    },
    {
      key: "kWh to therm",
      label: "Kilowatt hour to Therm",
      conversionFactor: 0.0341214147971727,
    },
    {
      key: "kWh to toe",
      label: "Kilowatt hour to Tonne oil equivalent",
      conversionFactor: 0.0000859845239999312,
    },
    {
      key: "kWh to kcal",
      label: "Kilowatt hour to Kilocalorie",
      conversionFactor: 860.050647426326,
    },
    {
      key: "therm to toe",
      label: "Therm to Tonne oil equivalent",
      conversionFactor: 0.00251995775998878,
    },
    {
      key: "therm to kcal",
      label: "Therm to Kilocalorie",
      conversionFactor: 25205.597497604,
    },
    {
      key: "toe to therm",
      label: "Tonne oil equivalent to Therm",
      conversionFactor: 396.832048488168,
    },
    {
      key: "toe to kcal",
      label: "Tonne oil equivalent to Kilocalorie",
      conversionFactor: 10002388.8883424,
    },
    {
      key: "kcal to therm",
      label: "Kilocalorie to Therm",
      conversionFactor: 0.0000396737272383667,
    },
    {
      key: "kcal to toe",
      label: "Kilocalorie to Tonne oil equivalent",
      conversionFactor: 0.0000001,
    },
    {
      key: "litre to Imp. gallon",
      label: "Litre to Imperial gallon",
      conversionFactor: 0.219969248,
    },
    {
      key: "litre to bbl",
      label: "Litre to Barrel (US, petroleum)",
      conversionFactor: 0.006289811,
    },
    {
      key: "litre to US gallon",
      label: "Litre to US gallon",
      conversionFactor: 0.264172051,
    },
    {
      key: "kL to US gallon",
      label: "Kilo  Litre to US gallon",
      conversionFactor: 264.172,
    },
    {
      key: "scf to US gallon",
      label: "Standard Cubic Foot to US gallon",
      conversionFactor: 7.48051938,
    },
    {
      key: "cubic metre to Imp. gallon",
      label: "Cubic Metres to Imperial gallon",
      conversionFactor: 219.969248,
    },
    {
      key: "cubic metre to US gallon",
      label: "Cubic Metres to US gallon",
      conversionFactor: 264.172051,
    },
    {
      key: "cubic metre to bbl",
      label: "Cubic Metres to Barrel (US, petroleum)",
      conversionFactor: 6.289811,
    },
    {
      key: "cu ft to US gallon",
      label: "Cubic Feet to US gallon",
      conversionFactor: 7.4805193830654,
    },
    {
      key: "Imp. gallon to US gallon",
      label: "Imperial Gallon to US Gallon",
      conversionFactor: 1.2009499209635,
    },
    {
      key: "Imp. gallon to bbl",
      label: "Imperial Gallon to Barrel (US, petroleum)",
      conversionFactor: 0.0285940469278688,
    },
    {
      key: "US gallon to Imp. gallon",
      label: "US gallon to Imperial Gallon",
      conversionFactor: 0.832674187777722,
    },
    {
      key: "US gallon to bbl",
      label: "US gallon to Barrel (US, petroleum)",
      conversionFactor: 0.0238095248009412,
    },
    {
      key: "bbl to US gallon",
      label: "Barrel (US, petroleum) to US gallon",
      conversionFactor: 41.9999982511398,
    },
    {
      key: "bbl to Imp. gallon",
      label: "Barrel (US, petroleum) to Imperial Gallon",
      conversionFactor: 34.9723144304336,
    },
    {
      key: "kg to tonne",
      label: "Kilogram to Tonne",
      conversionFactor: 0.001,
    },
    {
      key: "kg to ton (UK)",
      label: "Kilogram to Ton (UK, long ton)",
      conversionFactor: 0.000984207,
    },
    {
      key: "kg to ton (US)",
      label: "Kilogram to Ton (US, short ton)",
      conversionFactor: 0.001102311,
    },
    {
      key: "kg to lb",
      label: "Kilogram to Pound",
      conversionFactor: 2.20462368,
    },
    {
      key: "tonne to ton (UK)",
      label: "Tonne to Ton (UK, long ton)",
      conversionFactor: 0.984207,
    },
    {
      key: "tonne to ton (US)",
      label: "Tonne to Ton (US, short ton)",
      conversionFactor: 1.102311,
    },
    {
      key: "tonne to lb",
      label: "Tonne to Pound",
      conversionFactor: 2204.62368,
    },
    {
      key: "ton (UK) to tonne",
      label: "Ton (UK, long ton) to Tonne",
      conversionFactor: 1.01604642112889,
    },
    {
      key: "ton (UK) to ton (US)",
      label: "Ton (UK, long ton) to Ton (US, short ton)",
      conversionFactor: 1.11999914652101,
    },
    {
      key: "ton (UK) to lb",
      label: "Ton (UK, long ton) to Pound",
      conversionFactor: 2240,
    },
    {
      key: "ton (US) to tonne",
      label: "Ton (US, short ton) to Tonne",
      conversionFactor: 0.907184995885916,
    },
    {
      key: "ton (US) to ton (UK)",
      label: "Ton (US, short ton) to Ton (UK, long ton)",
      conversionFactor: 0.89285782324589,
    },
    {
      key: "ton (US) to lb",
      label: "Ton (US, short ton) to Pound",
      conversionFactor: 2000.00152407079,
    },
    {
      key: "lb to tonne",
      label: "Pound to Tonne",
      conversionFactor: 0.000453592152289682,
    },
    {
      key: "lb to ton (UK)",
      label: "Pound to Ton (UK, long ton)",
      conversionFactor: 0.000446428571428571,
    },
    {
      key: "lb to ton (US)",
      label: "Pound to Ton (US, short ton)",
      conversionFactor: 0.000499999618982592,
    },
    {
      key: "m to ft",
      label: "Metre to Feet",
      conversionFactor: 3.280839895,
    },
    {
      key: "m to nmi",
      label: "Metre to Nautical Miles",
      conversionFactor: 0.000539957,
    },
    {
      key: "m to in",
      label: "Metre to Inch",
      conversionFactor: 39.37007874,
    },
    {
      key: "m to cm",
      label: "Metre to Centimeter",
      conversionFactor: 100,
    },
    {
      key: "m to yd",
      label: "Metre to Yard",
      conversionFactor: 1.093613298,
    },
    {
      key: "ft to m",
      label: "Feet to Metre",
      conversionFactor: 0.304800000001219,
    },
    {
      key: "ft to nmi",
      label: "Feet to Nautical Miles",
      conversionFactor: 0.00016457883371278,
    },
    {
      key: "ft to in",
      label: "Feet to Inch",
      conversionFactor: 12,
    },
    {
      key: "ft to cm",
      label: "Feet to Centimeter",
      conversionFactor: 30.4800000001219,
    },
    {
      key: "ft to yd",
      label: "Feet to Yard",
      conversionFactor: 0.333333333231733,
    },
    {
      key: "mile to m",
      label: "Miles to Metre",
      conversionFactor: 1609.344,
    },
    {
      key: "mile to ft",
      label: "Miles to Feet",
      conversionFactor: 5279.99999997888,
    },
    {
      key: "mile to nmi",
      label: "Miles to Nautical Miles",
      conversionFactor: 0.868976242,
    },
    {
      key: "mile to in",
      label: "Miles to Inch",
      conversionFactor: 63360,
    },
    {
      key: "mile to cm",
      label: "Miles to Centimeter",
      conversionFactor: 160934,
    },
    {
      key: "mile to yd",
      label: "Miles to Yard",
      conversionFactor: 1760,
    },
    {
      key: "km to m",
      label: "Kilometres to Metre",
      conversionFactor: 1000,
    },
    {
      key: "km to ft",
      label: "Kilometres to Feet",
      conversionFactor: 3280.839895,
    },
    {
      key: "km to nmi",
      label: "Kilometres to Nautical Miles",
      conversionFactor: 0.539956803517458,
    },
    {
      key: "km to in",
      label: "Kilometres to Inch",
      conversionFactor: 39370.1,
    },
    {
      key: "km to cm",
      label: "Kilometres to Centimeter",
      conversionFactor: 100000,
    },
    {
      key: "km to yd",
      label: "Kilometres to Yard",
      conversionFactor: 1093.61,
    },
    {
      key: "nmi to m",
      label: "Nautical Miles to Metre",
      conversionFactor: 1851.99999978826,
    },
    {
      key: "nmi to ft",
      label: "Nautical Miles to Feet",
      conversionFactor: 6076.1154848453,
    },
    {
      key: "nmi to in",
      label: "Nautical Miles to Inch",
      conversionFactor: 72913.4,
    },
    {
      key: "nmi to cm",
      label: "Nautical Miles to Centimeter",
      conversionFactor: 185200,
    },
    {
      key: "nmi to yd",
      label: "Nautical Miles to Yard",
      conversionFactor: 2025.37,
    },
    {
      key: "in to m",
      label: "Inch to Metre",
      conversionFactor: 0.0254000000001016,
    },
    {
      key: "in to ft",
      label: "Inch to Feet",
      conversionFactor: 0.0833333333333333,
    },
    {
      key: "in to nmi",
      label: "Inch to Nautical Miles",
      conversionFactor: 0.000013714902807775,
    },
    {
      key: "in to cm",
      label: "Inch to Centimeter",
      conversionFactor: 2.54000000001016,
    },
    {
      key: "in to yd",
      label: "Inch to Yard",
      conversionFactor: 0.0277777777693111,
    },
    {
      key: "cm to m",
      label: "Centimeter to Metre",
      conversionFactor: 0.01,
    },
    {
      key: "cm to ft",
      label: "Centimeter to Feet",
      conversionFactor: 0.03280839895,
    },
    {
      key: "cm to mile",
      label: "Centimeter to Miles",
      conversionFactor: 0.00000621371,
    },
    {
      key: "cm to km",
      label: "Centimeter to Kilometres",
      conversionFactor: 0.00001,
    },
    {
      key: "cm to nmi",
      label: "Centimeter to Nautical Miles",
      conversionFactor: 0.00000539957,
    },
    {
      key: "cm to in",
      label: "Centimeter to Inch",
      conversionFactor: 0.3937007874,
    },
    {
      key: "cm to yd",
      label: "Centimeter to Yard",
      conversionFactor: 0.01093613298,
    },
    {
      key: "yd to m",
      label: "Yard to Metre",
      conversionFactor: 0.914400000282367,
    },
    {
      key: "yd to ft",
      label: "Yard to Feet",
      conversionFactor: 3.0000000009144,
    },
    {
      key: "yd to nmi",
      label: "Yard to Nautical Miles",
      conversionFactor: 0.000493737,
    },
    {
      key: "yd to in",
      label: "Yard to Inch",
      conversionFactor: 36.0000000109728,
    },
    {
      key: "yd to cm",
      label: "Yard to Centimeter",
      conversionFactor: 91.4400000282367,
    },
    {
      key: "short ton to tonne",
      label: "Short Ton to Tonne",
      conversionFactor: 0.907184995885916,
    },
    {
      key: "short ton to ton (UK)",
      label: "Short Ton to Ton (UK, long ton)",
      conversionFactor: 0.89285782324589,
    },
    {
      key: "short ton to ton (US)",
      label: "Short Ton to Ton (US, short ton)",
      conversionFactor: 1,
    },
    {
      key: "short ton to lb",
      label: "Short Ton to Pound",
      conversionFactor: 2000.00152407079,
    },
    {
      key: "metric ton to tonne",
      label: "Metric Ton to Tonne",
      conversionFactor: 1,
    },
    {
      key: "metric ton to ton (UK)",
      label: "Metric Ton to Ton (UK, long ton)",
      conversionFactor: 0.984207,
    },
    {
      key: "metric ton to ton (US)",
      label: "Metric Ton to Ton (US, short ton)",
      conversionFactor: 1.102311,
    },
    {
      key: "metric ton to lb",
      label: "Metric Ton to Pound",
      conversionFactor: 2204.62368,
    },
  ];

  const formulas = FormulasDataSet?.filter(
    (data) =>
      data?.key?.toLowerCase() ===
      `${unit?.toLowerCase()} to ${
        ["passenger.km", "person.km"].includes(emissionUnit)
          ? "km"
          : ["kWh (Net CV)", "kWh (Gross CV)"].includes(emissionUnit)
          ? "kwh"
          : [
              "vehicle-mile",
              "ton-mile",
              "passenger-mile",
              "vehicle.mile",
              "ton (US).mile",
              "passenger.mile",
            ].includes(emissionUnit)
          ? "mile"
          : ["litres", "Litres"].includes(emissionUnit)
          ? "litre"
          : ["Tonne", "tonnes", "metric ton"].includes(emissionUnit)
          ? "tonne"
          : ["short ton"].includes(emissionUnit)
          ? "ton (US)"
          : ["gallon"].includes(emissionUnit)
          ? "US gallon"
          : emissionUnit?.toLowerCase()
      }`
  );
  return amount * formulas?.[0]?.conversionFactor;
}

export { UnitsDataSet, getFormula };
