import { useContext, useEffect } from "react";
import { UserContext } from "contexts/UserProvider";
import UserCategories from "./userCategories";

function DataLayer() {
  // Push the userEmail to the dataLayer when it changes
  const user = useContext(UserContext);
  const userCategory = UserCategories();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "User_status",
      userEmail: user?.email,
      role: userCategory?.join(","),
    });
  }, [user?.email]);

  return <span id="data-layer"></span>;
}

export default DataLayer;
