import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { put } from "utils/DeApi";

const OverviewSetting = ({
  organization,
  fetchOrganizationDetails,
  onOrganizationUpdated,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [checkboxState, setCheckboxState] = useState({
    topDown: false,
    bottomUp: false,
    pcaf: false,
    carbonIntensity: false,
    forecasting: false,
    sbti: false,
    abatement: false,
    roadmap: false,
  });
  const subscribedPromises = useRef([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setCheckboxState(organization?.featureVisibility);
  }, [show, organization]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const rows = [
    { id: "topDown-checkbox", name: "topDown", label: "Top-Down Estimation" },
    {
      id: "bottomUp-checkbox",
      name: "bottomUp",
      label: "Bottom-Up GHG Inventory",
    },
    { id: "pcaf-checkbox", name: "pcaf", label: "PCAF Data Quality Score" },
    {
      id: "carbonIntensity-checkbox",
      name: "carbonIntensity",
      label: "Carbon Intensity",
    },
    {
      id: "forecasting-checkbox",
      name: "forecasting",
      label: "Forecasting Overview",
    },
    { id: "sbti-checkbox", name: "sbti", label: "SBTi Overview" },
    {
      id: "abatement-checkbox",
      name: "abatement",
      label: "Abatement Planning",
    },
    { id: "roadmap-checkbox", name: "roadmap", label: "Roadmap" },
  ];

  const updateOverviewSetting = () => {
    setError(null);
    setIsLoading(true);
    const updateOverviewSettingPromise = put(
      `organizations/${organization?.id}/update-featureVisibility`,
      { featureVisibility: checkboxState }
    );
    updateOverviewSettingPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        onOrganizationUpdated({
          ...organization,
          featureVisibility: response?.featureVisibility,
        });
        handleClose();
        fetchOrganizationDetails();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(updateOverviewSettingPromise);
  };

  return (
    <>
      <Button
        size="sm"
        variant="light"
        className="bg-transparent border border-0 text-primary"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-20">settings</span>
      </Button>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Overview Setting</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>Overview Setting</th>
                <th className="text-center">Include?</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr key={row.id}>
                  <td>{row.label}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      id={row.id}
                      checked={checkboxState[row.name]}
                      className="text-center"
                      name={row.name}
                      onChange={handleCheckboxChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {error && <ErrorHandler error={error} />}
          {isLoading && <Loader />}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="link" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={updateOverviewSetting}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OverviewSetting;
