import { CSVLink } from "react-csv";

const CsvDownloader = ({ data, fileName, className, title, iconText }) => {
  return (
    <>
      <CSVLink
        data={data}
        filename={fileName}
        target="_blank"
        asyncOnClick
        className={className}
      >
        {iconText ? (
          <span title={title}>
            <span>{iconText}</span>
            <span className="material-icons-outlined md-18">file_download</span>
          </span>
        ) : (
          <span className="material-icons-outlined md-18" title={title}>
            file_download
          </span>
        )}
      </CSVLink>
    </>
  );
};

export default CsvDownloader;
