import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  Col,
  Container,
  Row,
  Alert,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { get, download } from "utils/DeApi";
import { getAllYearsBetweenDates } from "utils/dateUtils";
import Header from "components/App/Header/Header";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import OrganizationList from "components/Organization/OrganizationList/OrganizationList";
import OrganizationCreate from "components/Organization/OrganizationCreate/OrganizationCreate";

import { AccountContext } from "contexts/AccountProvider";
import { getFormattedEmission } from "utils/StringUtils";
import OrganizationsUpload from "components/Organization/OrganizationsUpload/OrganizationsUpload";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import PortfolioToggler from "components/Portfolio/PortfolioToggler/PortfolioToggler";
import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";

const Dashboard = () => {
  const subscribedPromises = useRef([]);
  const [searchParams] = useSearchParams();

  const account = useContext(AccountContext);

  const recentYear = new Date().getFullYear() - 1;

  const [year, setYear] = useState(recentYear.toString());
  const [organizations, setOrganizations] = useState();
  const [organizationSummary, setOrganizationSummary] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [isExpanding, setIsExpanding] = useState(false);
  const [expandingError, setExpandingError] = useState();
  const [scopeTwoPreference, setScopeTwoPreference] = useState(0);

  const [showingAllOrg, setShowingAllOrg] = useState(false);

  const [isExporting, setIsExporting] = useState(false);
  const [exportingError, setExportingError] = useState();

  const navigate = useNavigate();

  const userCategory = UserCategories();

  const portfolioId = searchParams.get("portfolioId");

  const fetchOrganizationsPortfolios = useCallback(() => {
    setError(null);
    setIsLoading(true);
    let organizationsPromise;

    const orgSummaryPromise = get(
      `organizations/summary?filter[year]=${year}`,
      {
        params: {
          accountId: account.id,
          portfolioId: portfolioId,
          "filter[preference]": scopeTwoPreference,
        },
      }
    );
    if (portfolioId) {
      organizationsPromise = get(`portfolios/${portfolioId}/organizations`, {
        params: {
          accountId: account.id,
          yearEnded: year ? `${year}-12-31` : null,
          offset: 0,
          limit: 25,
          "filter[preference]": scopeTwoPreference,
        },
      });
    } else {
      organizationsPromise = get("organizations", {
        params: {
          accountId: account.id,
          yearEnded: year ? `${year}-12-31` : null,
          offset: 0,
          limit: 25,
          sort: "-updatedAt",
          "filter[preference]": scopeTwoPreference,
        },
      });
    }

    Promise.all([organizationsPromise.promise, orgSummaryPromise.promise])
      .then((responses) => {
        setOrganizations(responses[0].data);
        setOrganizationSummary(responses[1].data);
        setIsLoading(false);
        setError(null);
        if (responses[0].data.length < 25) {
          setShowingAllOrg(true);
        } else {
          setShowingAllOrg(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(organizationsPromise);
  }, [account, searchParams, year, scopeTwoPreference]);

  const showMoreOrg = () => {
    setExpandingError(null);
    setIsExpanding(true);

    let orgPromise;

    const params = {
      params: {
        accountId: account.id,
        yearEnded: year ? `${year}-12-31` : null,
        offset: organizations.length,
        limit: 25,
        sort: "-updatedAt",
      },
    };

    if (portfolioId) {
      orgPromise = get(`portfolios/${portfolioId}/organizations`, {
        params: {
          accountId: account.id,
          yearEnded: year ? `${year}-12-31` : null,
          offset: organizations.length,
          limit: 25,
        },
      });
    } else {
      orgPromise = get(`/organizations`, params);
    }

    orgPromise.promise
      .then((response) => {
        setOrganizations([...organizations, ...response.data]);
        setExpandingError(null);
        setIsExpanding(false);
        if (response.data.length < 25) {
          setShowingAllOrg(true);
        } else {
          setShowingAllOrg(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setExpandingError(error);
          setIsExpanding(false);
        }
      });
    subscribedPromises.current.push(orgPromise);
  };

  const sortOrg = (sortingValue) => {
    setError(null);
    setIsLoading(true);

    let orgPromise;

    if (portfolioId) {
      orgPromise = get(`portfolios/${portfolioId}/organizations`, {
        params: {
          accountId: account.id,
          yearEnded: year ? `${year}-12-31` : null,
          offset: 0,
          limit: organizations.length,
          sort: sortingValue,
        },
      });
    } else {
      orgPromise = get(`/organizations`, {
        params: {
          accountId: account.id,
          yearEnded: year ? `${year}-12-31` : null,
          offset: 0,
          limit: organizations.length,
          sort: sortingValue,
        },
      });
    }

    orgPromise.promise
      .then((response) => {
        setOrganizations(response.data);
        setIsLoading(false);
        setError(null);
        if (response.data.length < 25) {
          setShowingAllOrg(true);
        } else {
          setShowingAllOrg(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setExpandingError(error);
          setIsExpanding(false);
        }
      });
    subscribedPromises.current.push(orgPromise);
  };

  const exportActivities = () => {
    setIsExporting(true);

    const exportPromise = download(
      `/accounts/${account.id}/activities/export`,
      {
        params: {
          "filter[yearEnded]": `${year}-12-31`,
        },
      }
    );

    exportPromise.promise
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `${account.name} - Activities.xlsx`);
        document.body.appendChild(link);

        link.click();
        setIsExporting(false);
      })
      .catch((error) => {
        setIsExporting(false);
        setExportingError(error);
        console.warn(error);
      });

    subscribedPromises.current.push(exportPromise);
  };

  useEffect(() => {
    fetchOrganizationsPortfolios();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrganizationsPortfolios]);

  return (
    <>
      <Header />
      <DataLayer />
      <Container id={userCategory} className="MainContent py-3">
        <Row className="my-3">
          <Col xs={12} md={4}>
            <h1>Hi, Welcome Back!</h1>
            <h6>Your Net Zero Compass Dashboard.</h6>
          </Col>
          <Col xs={12} md={8} className="text-end">
            <div className="py-3">
              {account.member.role !== "member" && (
                <>
                  <Button
                    variant="outline-primary"
                    as={Link}
                    to="/portfolios"
                    size="sm"
                    className="px-3"
                  >
                    Manage Portfolios
                  </Button>{" "}
                  <OrganizationsUpload
                    onOrganizationsUploaded={fetchOrganizationsPortfolios}
                  />{" "}
                  <OrganizationCreate
                    onOrganizationCreated={(org) =>
                      navigate(`/organizations/${org.id}`)
                    }
                  />
                </>
              )}{" "}
              <Button
                className="px-3"
                size="sm"
                variant="primary"
                onClick={exportActivities}
                disabled={isExporting}
              >
                Export Activities{"  "}
                {isExporting && (
                  <Spinner
                    animation="border"
                    variant="white"
                    size="sm"
                    className="ms-3"
                  />
                )}
                {exportingError && (
                  <span className="material-icons-outlined md-18 text-danger">
                    error
                  </span>
                )}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <Col xs={12} className="my-3">
            <div className="border p-3">
              <Row>
                <Col xs={12} md={3} className="my-3">
                  <h3>Organizations</h3>
                  <p>These are the emissions reporting entities.</p>
                </Col>
                <Col xs={12} md={9} className="text-end">
                  <div className="d-inline-flex">
                    <ScopeTwoPreferenceToggler
                      scopeTwoPreference={scopeTwoPreference}
                      setScopeTwoPreference={setScopeTwoPreference}
                    />
                  </div>
                  <div className="d-inline-flex align-items-center me-1 my-3">
                    <Form.Select
                      aria-label="year-filter"
                      size="sm"
                      className="mx-1"
                      value={year || ""}
                      onChange={(ev) => {
                        let selectedYear = ev.target.value;
                        setYear(selectedYear);
                      }}
                    >
                      {getAllYearsBetweenDates().map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  {organizations && (
                    <>
                      <div className="d-inline-flex align-items-center text-nowrap my-3 mx-1 pt-1">
                        <PortfolioToggler />
                      </div>
                      <div className="d-inline-flex align-items-center px-3 my-3 mx-1 bg-info text-nowrap text-primary bg-opacity-10">
                        <span className="fs-1 mx-1">
                          {organizationSummary?.organizationsCount || 0}
                        </span>
                        Organizations
                      </div>
                      <div className="d-inline-flex align-items-center px-3 my-3 bg-danger text-nowrap text-danger bg-opacity-10">
                        <span className="fs-1 mx-1">
                          {getFormattedEmission(
                            organizationSummary?.totalEmissions || 0
                          )}{" "}
                        </span>
                        <small className="mx-1">
                          tCO<sub>2</sub>e
                        </small>{" "}
                        {year} Emissions
                      </div>
                    </>
                  )}{" "}
                </Col>
              </Row>

              {isLoading && <Loader />}
              {error && <ErrorHandler error={error} />}
              {organizations && (
                <>
                  {!organizations.length ? (
                    <Alert variant="info">
                      {account.member.role === "member"
                        ? "There are currently no organizations to show. Please consult your Net Zero Compass administrator for access."
                        : "There are currently no organizations to show. Add organizations to start reporting emissions."}
                    </Alert>
                  ) : (
                    <>
                      <OrganizationList
                        organizations={organizations}
                        sortOrg={sortOrg}
                        isLoading={isLoading}
                      />
                      <div className="my-3">
                        {isExpanding && <Loader />}
                        {expandingError && (
                          <ErrorHandler error={expandingError} />
                        )}
                        {!showingAllOrg && (
                          <p className="text-center">
                            <Button
                              size="sm"
                              variant="primary"
                              className="px-3 my-2 bg-opacity-25"
                              onClick={showMoreOrg}
                              disabled={isExpanding}
                            >
                              Show More
                            </Button>
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
