import React from "react";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { getFormattedEmission } from "utils/StringUtils";

const LocationPin = ({ site, siteSummary, maxEmissions, minEmissions }) => {
  let navigate = useNavigate();

  const emissions =
    siteSummary && siteSummary[0] ? siteSummary[0].emissions : 0;

  if (!emissions)
    return (
      <span className="LocationPin">
        <OverlayTrigger
          overlay={
            <Tooltip id={site.id}>
              <small>
                {site.name}
                <br /> n/a
              </small>
            </Tooltip>
          }
        >
          <div
            className="site-marker"
            style={{
              width: 16,
              height: 16,
              background: "#777",
            }}
            tabIndex={0}
            role="button"
            onClick={() =>
              navigate(`/organizations/${site.organizationId}/sites/${site.id}`)
            }
            onKeyPress={(e) => {
              // Handle keyboard events if needed
              if (e.key === "Enter" || e.key === " ") {
                navigate(
                  `/organizations/${site.organizationId}/sites/${site.id}`
                );
              }
            }}
          ></div>
        </OverlayTrigger>
      </span>
    );

  let scale = Math.round((emissions / maxEmissions) * 38);
  let colorScale = emissions / maxEmissions;

  let locationSize = scale >= 38 ? 35 : scale;

  return (
    <span className="LocationPin">
      <OverlayTrigger
        overlay={
          <Tooltip id={site.id}>
            <>
              <small>{site.name}</small>
              <br />
              {getFormattedEmission(emissions)}{" "}
              <small>
                tCO<sub>2</sub>e
              </small>
            </>
          </Tooltip>
        }
      >
        <div
          className="site-marker"
          style={{
            width: locationSize + 16,
            height: locationSize + 16,
            animationDelay: `-${colorScale}s`,
            marginLeft: locationSize >= 28 ? "-20px" : "-5px",
          }}
          tabIndex={0}
          role="button"
          onClick={() =>
            navigate(`/organizations/${site.organizationId}/sites/${site.id}`)
          }
          onKeyPress={(e) => {
            // Handle keyboard events if needed
            if (e.key === "Enter" || e.key === " ") {
              navigate(
                `/organizations/${site.organizationId}/sites/${site.id}`
              );
            }
          }}
        ></div>
      </OverlayTrigger>
    </span>
  );
};

export default LocationPin;
