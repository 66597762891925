/* all date related function utils stay here */

function getAllYearsBetweenDates(
  startDate = new Date("2010-01-31"),
  endDate = new Date()
) {
  let initialYear = startDate.getFullYear();
  let currentYear = endDate.getFullYear();
  const years = [];
  while (currentYear >= initialYear) {
    const year = currentYear--;
    years.push(year.toString());
  }

  return years;
}

function getYearMonths() {
  const MONTHS = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  return MONTHS;
}

function getFiscalYearMonths(fiscalYearStartMonth, year) {
  const MONTHS = getYearMonths();

  const startIndex = MONTHS.findIndex(
    (month) => month.value === fiscalYearStartMonth
  );
  if (startIndex === -1) {
    console.error("Invalid fiscalYearStartMonth param");
    return MONTHS;
  }

  const fiscalMonths = [];

  // Add months from the start of fiscal year to end of previous calendar
  for (let i = startIndex; i < MONTHS.length; i++) {
    fiscalMonths.push({
      ...MONTHS[i],
      label:
        startIndex === 0
          ? `${MONTHS[i].label} ${year}`
          : `${MONTHS[i].label} ${year - 1}`,
    });
  }

  // Add months from the start of the calendar year to the end of fiscal year
  for (let i = 0; i < startIndex; i++) {
    fiscalMonths.push({
      ...MONTHS[i],
      label: `${MONTHS[i].label} ${year}`,
    });
  }

  return fiscalMonths;
}

function getYearQuarters() {
  const QUARTERS = [
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 },
  ];
  return QUARTERS;
}

function getFiscalQuarter(currentMonth, fiscalStartMonth) {
  // Determine the offset for the fiscal year
  const offset = fiscalStartMonth - 1;

  // Adjust the month to align with the fiscal year
  const adjustedMonth = ((currentMonth - 1 - offset + 12) % 12) + 1;

  // Determine the fiscal quarter
  const fiscalQuarter = Math.ceil(adjustedMonth / 3);

  return fiscalQuarter;
}

function getQuarterLabelFromValue(quarterValue) {
  return (
    getYearQuarters().find((quarter) => quarter.value === quarterValue)
      ?.label || ""
  );
}

function getMonthLabelFromValue(monthValue) {
  return (
    getYearMonths().find((month) => month.value === monthValue)?.label || ""
  );
}

export {
  getAllYearsBetweenDates,
  getYearMonths,
  getYearQuarters,
  getFiscalQuarter,
  getQuarterLabelFromValue,
  getMonthLabelFromValue,
  getFiscalYearMonths,
};
