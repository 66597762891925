import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Table } from "react-bootstrap";

import { getFormattedEmission } from "utils/StringUtils";
import { getMonthLabelFromValue } from "utils/dateUtils";
import { TableSortArrows } from "components/Shared/TableComponents";
import "./OrganizationActivities.scss";

const OrganizationActivityList = ({
  activities,
  sortOrgActivities,
  tableSortState,
  isLoading,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const data = activities.map((activity) => {
      const type = activity.activityType || {};

      return {
        ...activity,
        ...{
          title: type.title,
          categoryNumber: type.categoryNumber,
          type: type,
        },
      };
    });

    setTableData(data);
  }, [activities]);

  return (
    <div className="ActivityListDiv">
      <Table hover size="sm" responsive>
        <thead>
          <tr>
            <th
              className="table-sorter align-middle"
              onClick={() => !isLoading && sortOrgActivities("siteName")}
            >
              Site
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"siteName"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-start table-sorter align-middle"
              onClick={() => !isLoading && sortOrgActivities("yearEnded")}
            >
              Date
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"yearEnded"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-nowrap table-sorter align-middle"
              onClick={() => !isLoading && sortOrgActivities("activityType")}
            >
              Source
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"activityType"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-nowrap table-sorter align-middle"
              onClick={() =>
                !isLoading && sortOrgActivities("emissionResource")
              }
            >
              Resource
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"emissionResource"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="table-sorter align-middle"
              onClick={() => !isLoading && sortOrgActivities("scope")}
            >
              Scope
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"scope"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-end text-nowrap table-sorter align-middle"
              onClick={() => !isLoading && sortOrgActivities("emissions")}
            >
              Emissions{" "}
              <small>
                (tCO<sub>2</sub>e)
              </small>
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"emissions"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
            <th
              className="text-nowrap text-end table-sorter align-middle"
              onClick={() => !isLoading && sortOrgActivities("updatedAt")}
            >
              Updated At
              <TableSortArrows
                sortColumn={tableSortState.sortColumn}
                colName={"updatedAt"}
                sortAsc={tableSortState.sortAsc}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((activity) => {
            return (
              <tr key={activity.id}>
                <td>{activity?.site?.name || ""}</td>
                <td className="text-start">
                  {activity.month && (
                    <span>
                      {getMonthLabelFromValue(activity.month)} <br />
                    </span>
                  )}
                  {activity.calendarYear || activity.yearEnded || "2021"}
                </td>
                <td>
                  {activity?.categoryNumber
                    ? `${activity?.categoryNumber}. `
                    : ""}
                  {activity?.title || ""}
                </td>
                <td>
                  {activity?.emissionFactor
                    ? activity?.emissionFactor?.emissionResource +
                      "  " +
                      "-" +
                      "  " +
                      activity?.emissionFactor?.unit
                    : "n/a"}
                </td>
                <td>Scope {activity?.scope || ""}</td>

                <td className="text-end text-nowrap pe-2">
                  {getFormattedEmission(activity?.emissions, 1)}
                </td>
                <td className="text-end text-nowrap">
                  <small>
                    {new Date(activity?.updatedAt).toLocaleString([], {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </small>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

OrganizationActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
};

export default OrganizationActivityList;
