const isEqual = (num1, num2) => parseFloat(num1) === parseFloat(num2);

const filterByReduction = (data = [], percentage, postfix = "") =>
  data
    .map((items) => {
      return items.find(
        ({
          percentageReduction,
          scopeThreePercentageReduction,
          allScopePercentageReduction,
        }) =>
          isEqual(
            percentageReduction ||
              scopeThreePercentageReduction ||
              allScopePercentageReduction,
            percentage
          )
      )
        ? items.map(
            ({
              year,
              emissionReduction,
              limitConstrainedReductionEmission,
              percentageReduction,
              scopeThreePercentageReduction,
              allScopePercentageReduction,
              rawValueReductionEmission,
            }) => ({
              [`year${postfix}`]: year,
              [`emissionReduction${postfix}`]: emissionReduction,
              [`limitConstrainedReductionEmission${postfix}`]:
                limitConstrainedReductionEmission,
              [`percentageReduction${postfix}`]:
                percentageReduction ||
                scopeThreePercentageReduction ||
                allScopePercentageReduction,
              [`rawValueReductionEmission${postfix}`]:
                rawValueReductionEmission,
              ...(!!postfix ? { year: year } : {}),
            })
          )
        : null;
    })
    .flat()
    .filter((item) => !!item);

const unionByYear = (...chunks) => {
  const index = chunks
    .map((a) => a.length)
    .indexOf(Math.max(...chunks.map((a) => a.length)));

  if (index < 0) return [];

  return chunks[index].map(({ year, ...chunk }) => {
    let map = {};
    chunks
      .filter((item, ii) => ii !== index)
      .forEach((emissions) => {
        const found = emissions.find(({ year: y }) => y === year) || {};
        map = { ...map, ...found };
      });

    return {
      year,
      ...chunk,
      ...map,
    };
  });
};

const sum = (...items) =>
  items
    .filter((item) => !isNaN(Number(item)))
    .reduce((prev, curr) => prev + curr, 0);

export function formatNumber(number, decimals) {
  return isNaN(Number(number))
    ? "N/A"
    : new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimals || 3,
        minimumFractionDigits: decimals || 0,
      }).format(number);
}

export function formatPercentage(number, decimals) {
  return isNaN(Number(number))
    ? "N/A"
    : new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimals || 3,
        minimumFractionDigits: decimals || 0,
        style: "percent",
      }).format(number / 100);
}

export function colorfy(str) {
  if (!str) return "#000000";
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

export function findLastNotEqual(array = [], value) {
  return {
    value: array.findLast((item, index) => {
      return item !== value;
    }),
    index: array.findLastIndex((item, index) => {
      return item !== value;
    }),
  };
}

export function invertSymbol(arg) {
  const num = Number(arg);
  if (isNaN(num)) return null;
  return num ? num * -1 : num;
}

export function convertNegativeToZero(value) {
  if (value < 0) {
    return 0;
  }
  return value;
}

export function checkIfIsInBau(array = [], link) {
  var obj = array.find(function (obj) {
    return obj.link === link;
  });
  return obj && obj.isInBau === true;
}

export { isEqual, sum, unionByYear, filterByReduction };
