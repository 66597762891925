import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageS1Reductions from "../ManageS1Reductions/ManageS1Reductions";
import TransportDecarbonizationChart from "../DecarbonizationChart/DecarbonizationChart";
import "./TransportDecarbonization.scss";
import { formatNumber, formatPercentage } from "../helper";
import {
  getEconomicGrowth,
  getTransportDecarbonization,
} from "../forecastingModels";

import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";
import { TableSortArrows } from "components/Shared/TableComponents";

const TransportDecarbonization = ({
  organization,
  breadcrumbs,
  baselineYear,
  scopeOne,
  scopeTwo,
  scopeThree,
  isForecastingUpdate,
}) => {
  const userCategory = UserCategories();

  const [emission, setEmission] = useState();
  const [growthEmission, setGrowthEmission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [scopeOneReduction, setScopeOneReduction] = useState(100);
  const [scopeThreeReduction, setScopeThreeReduction] = useState(100);
  const [scopeThreeReductionGrowth, setScopeThreeReductionGrowth] =
    useState(100);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const [transportDecarbonizationData, setTransportDecarbonizationData] =
    useState();

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "year",
    updatedAt: true,
    sortAsc: false,
  });

  const sortingTable = (column, objValue) => {
    emission?.data?.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];
      if (macA == null) macA = 0; // Convert null to 0
      if (macB == null) macB = 0; // Convert null to 0

      if (macA === "" || macA === "n/a") {
        return tableSortState[column] ? 1 : -1;
      }

      if (macB === "" || macB === "n/a") {
        return tableSortState[column] ? -1 : 1;
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setEmission([...emission]);
  };
  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );
      const transportDecarbonizationPromise = get(
        `organizations/${organizationId}/transport-decarbonization`
      );
      Promise.all([
        economicGrowthPromise.promise,
        transportDecarbonizationPromise.promise,
      ])
        .then((responses) => {
          setIsLoading(false);

          const [{ data: economicGrowth = [] }, { data: transport = [] }] =
            responses || [];

          setScopeOneReduction(transport[0]?.scopeOneEmissionPercentage || 0);
          setScopeThreeReduction(
            transport[0]?.scopeThreeEmissionPercentage || 0
          );
          setEmission({
            ...transport[0],
          });

          setScopeThreeReductionGrowth(
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );

          calculateDecarb(
            { ...transport[0] },
            economicGrowth[0] ? economicGrowth[0]?.data : [],
            transport[0] ? transport[0]?.data : [],
            transport[0]?.scopeOneEmissionPercentage || 0,
            transport[0]?.scopeThreeEmissionPercentage || 0,
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );

          setTransportDecarbonizationData(transport[0]?.calculation);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(transportDecarbonizationPromise);
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    scopeOne,
    emission?.scopeOneEmissionPercentage,
    emission?.scopeThreeEmissionPercentage,
    isForecastingUpdate,
  ]);

  const calculateDecarb = (
    emissions = {},
    economicGrowthData = [],
    transportData = [],
    scopeOnePercentage = 100,
    scopeThreePercentage = 100,
    scopeThreePercentageGrowth = 100
  ) => {
    const economicGrowthChunk = getEconomicGrowth(
      economicGrowthData,
      scopeOne,
      scopeTwo,
      scopeThree,
      scopeThreePercentageGrowth
    );

    const transportChunk = getTransportDecarbonization(
      transportData,
      economicGrowthChunk,
      scopeOnePercentage,
      scopeThreePercentage
    );

    setGrowthEmission(economicGrowthChunk);
    setEmission({
      ...emissions,
      data: transportChunk,
    });
  };

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: "Decarbonization of Transport",
              link: `/organizations/${organization.id}/forecasting/3/transport-decarbonization`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">Decarbonization of Transport</h2>
      <div className="my-3 p-3 border">
        <h3>
          Emissions Forecast
          <ManageS1Reductions
            onManageS1ReductionsUpdated={(data) =>
              setEmission({
                ...data,
                data: getTransportDecarbonization(
                  data?.data,
                  growthEmission,
                  scopeOneReduction,
                  scopeThreeReduction
                ),
              })
            }
            emissions={emission}
            baselineYear={baselineYear}
            projectType="transport"
            isForecastingUpdate={isForecastingUpdate}
          />
        </h3>
        {!isLoading && !error && (
          <TransportDecarbonizationChart
            data={transportDecarbonizationData}
            scopeOne={scopeOne}
          />
        )}
        {error && !isLoading && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
      </div>
      <div className="TransportDecarbonizationTableDiv">
        <Table size="sm" striped responsive className="first-column-fixed">
          <thead>
            <tr>
              <th
                className="text-end text-nowrap px-3 year-column table-sorter"
                onClick={() => sortingTable("year")}
              >
                Year{" "}
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"year"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s1_reduction_percent")}
              >
                % Reduction in Transport Emission Factor
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s1_reduction_percent"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("cumulativeReduction")}
              >
                Cumulative % Reduction
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"cumulativeReduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s1_reduction")}
              >
                Scope 1 Reduction{" "}
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s1_reduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s1_emission")}
              >
                Scope 1 Emissions
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s1_emission"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s3_reduction")}
              >
                Scope 3 Reduction{" "}
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s3_reduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s3_emission")}
              >
                Scope 3 Emissions
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s3_emission"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {emission?.data?.map(
              (
                {
                  year,
                  cumulativeReduction,
                  s1_reduction_percent,
                  s1_reduction,
                  s1_emission,
                  s3_reduction,
                  s3_emission,
                  emission,
                },
                index
              ) => (
                <tr key={index}>
                  <td className="text-end text-nowrap px-3 year-column">
                    {year}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatPercentage(s1_reduction_percent)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatPercentage(cumulativeReduction)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s1_reduction, 2)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s1_emission, 2)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s3_reduction, 2)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s3_emission, 2)}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </div>

      {!error && !isLoading && !emission?.id && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"63176351a824f"} view={"full"} />
      </div>
    </div>
  );
};

export default TransportDecarbonization;
