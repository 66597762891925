const FormulasDataSet = [
  {
    currency: "EUR",
    currencyName: "Euro",
    conversionFactors: [
      {
        rate: 0.949624,
        year: "2022",
      },
      {
        rate: 0.84549414,
        year: "2021",
      },
      {
        rate: 0.8755064,
        year: "2020",
      },
      {
        rate: 0.893276257,
        year: "2019",
      },
      {
        rate: 0.84677267,
        year: "2018",
      },
    ],
  },
  {
    currency: "AUD",
    currencyName: "Australian Dollar",
    conversionFactors: [
      {
        rate: 1.33122426,
        year: "2022",
      },
      {
        rate: 1.33122426,
        year: "2021",
      },
      {
        rate: 1.45308512,
        year: "2020",
      },
      {
        rate: 1.43850654,
        year: "2019",
      },
      {
        rate: 1.33841215,
        year: "2018",
      },
    ],
  },
  {
    currency: "AWG",
    currencyName: "Aruban Florin",
    conversionFactors: [
      {
        rate: 1.79,
        year: "2022",
      },
      {
        rate: 1.79,
        year: "2021",
      },
      {
        rate: 1.79,
        year: "2020",
      },
      {
        rate: 1.79,
        year: "2019",
      },
      {
        rate: 1.79,
        year: "2018",
      },
    ],
  },
  {
    currency: "BMD",
    currencyName: "Bermudan Dollar",
    conversionFactors: [
      {
        rate: 1,
        year: "2022",
      },
      {
        rate: 1,
        year: "2021",
      },
      {
        rate: 1,
        year: "2020",
      },
      {
        rate: 1,
        year: "2019",
      },
      {
        rate: 1,
        year: "2018",
      },
    ],
  },
  {
    currency: "KYD",
    currencyName: "Cayman Islands Dollar",
    conversionFactors: [
      {
        rate: 0.83333,
        year: "2022",
      },
      {
        rate: 0.83333,
        year: "2021",
      },
      {
        rate: 0.83333,
        year: "2020",
      },
      {
        rate: 0.83333,
        year: "2019",
      },
      {
        rate: 0.83333,
        year: "2018",
      },
    ],
  },
  {
    currency: "ANG",
    currencyName: "Antillean Guilder",
    conversionFactors: [
      {
        rate: 1.79,
        year: "2022",
      },
      {
        rate: 1.79,
        year: "2021",
      },
      {
        rate: 1.79,
        year: "2020",
      },
      {
        rate: 1.79,
        year: "2019",
      },
      {
        rate: 1.79,
        year: "2018",
      },
    ],
  },
  {
    currency: "CZK",
    currencyName: "Czech Koruna",
    conversionFactors: [
      {
        rate: 23.357,
        year: "2022",
      },
      {
        rate: 21.67816667,
        year: "2021",
      },
      {
        rate: 23.21025,
        year: "2020",
      },
      {
        rate: 22.93225,
        year: "2019",
      },
      {
        rate: 21.72991667,
        year: "2018",
      },
    ],
  },
  {
    currency: "XPF",
    currencyName: "CFP Franc",
    conversionFactors: [
      {
        rate: 100.8802226,
        year: "2022",
      },
      {
        rate: 100.8802226,
        year: "2021",
      },
      {
        rate: 104.7106082,
        year: "2020",
      },
      {
        rate: 106.5889338,
        year: "2019",
      },
      {
        rate: 101.0468219,
        year: "2018",
      },
    ],
  },
  {
    currency: "MOP",
    currencyName: "Macanese Pataca",
    conversionFactors: [
      {
        rate: 8.00553175,
        year: "2022",
      },
      {
        rate: 8.00553175,
        year: "2021",
      },
      {
        rate: 7.989061917,
        year: "2020",
      },
      {
        rate: 8.070470333,
        year: "2019",
      },
      {
        rate: 8.072507417,
        year: "2018",
      },
    ],
  },
  {
    currency: "GIP",
    currencyName: "Gibraltar Pound",
    conversionFactors: [
      {
        rate: 0.727064945,
        year: "2022",
      },
      {
        rate: 0.727064945,
        year: "2021",
      },
      {
        rate: 0.779999577,
        year: "2020",
      },
      {
        rate: 0.78344511,
        year: "2019",
      },
      {
        rate: 0.74953154,
        year: "2018",
      },
    ],
  },
  {
    currency: "HKD",
    currencyName: "Hong Kong Dollar",
    conversionFactors: [
      {
        rate: 7.77325,
        year: "2022",
      },
      {
        rate: 7.77325,
        year: "2021",
      },
      {
        rate: 7.75725,
        year: "2020",
      },
      {
        rate: 7.835916667,
        year: "2019",
      },
      {
        rate: 7.8385,
        year: "2018",
      },
    ],
  },
  {
    currency: "GBP",
    currencyName: "Pound",
    conversionFactors: [
      {
        rate: 0.811302,
        year: "2022",
      },
      {
        rate: 0.72706494,
        year: "2021",
      },
      {
        rate: 0.77999958,
        year: "2020",
      },
      {
        rate: 0.78344511,
        year: "2019",
      },
      {
        rate: 0.74953154,
        year: "2018",
      },
    ],
  },
  {
    currency: "AFN",
    currencyName: "Afghanistan Afghani",
    conversionFactors: [
      {
        rate: 76.81353644,
        year: "2022",
      },
      {
        rate: 76.81353644,
        year: "2021",
      },
      {
        rate: 76.81353644,
        year: "2020",
      },
      {
        rate: 77.73794918,
        year: "2019",
      },
      {
        rate: 72.08324718,
        year: "2018",
      },
    ],
  },
  {
    currency: "ALL",
    currencyName: "Albanian Lek",
    conversionFactors: [
      {
        rate: 113.041667,
        year: "2022",
      },
      {
        rate: 103.52,
        year: "2021",
      },
      {
        rate: 108.65,
        year: "2020",
      },
      {
        rate: 109.8508333,
        year: "2019",
      },
      {
        rate: 107.9891667,
        year: "2018",
      },
    ],
  },
  {
    currency: "DZD",
    currencyName: "Algerian Dinar",
    conversionFactors: [
      {
        rate: 135.0640583,
        year: "2022",
      },
      {
        rate: 135.0640583,
        year: "2021",
      },
      {
        rate: 126.7768,
        year: "2020",
      },
      {
        rate: 119.3535583,
        year: "2019",
      },
      {
        rate: 116.5937917,
        year: "2018",
      },
    ],
  },
  {
    currency: "AON",
    currencyName: "Angolan Kwanza",
    conversionFactors: [
      {
        rate: 631.4419555,
        year: "2022",
      },
      {
        rate: 631.4419555,
        year: "2021",
      },
      {
        rate: 578.2587803,
        year: "2020",
      },
      {
        rate: 364.825805,
        year: "2019",
      },
      {
        rate: 252.8557477,
        year: "2018",
      },
    ],
  },
  {
    currency: "XCD",
    currencyName: "Eastern Caribbean Dollar",
    conversionFactors: [
      {
        rate: 2.7,
        year: "2022",
      },
      {
        rate: 2.7,
        year: "2021",
      },
      {
        rate: 2.7,
        year: "2020",
      },
      {
        rate: 2.7,
        year: "2019",
      },
      {
        rate: 2.7,
        year: "2018",
      },
    ],
  },
  {
    currency: "ARS",
    currencyName: "Argentine Peso",
    conversionFactors: [
      {
        rate: 94.99074167,
        year: "2022",
      },
      {
        rate: 94.99074167,
        year: "2021",
      },
      {
        rate: 70.53916667,
        year: "2020",
      },
      {
        rate: 48.14789167,
        year: "2019",
      },
      {
        rate: 28.09499167,
        year: "2018",
      },
    ],
  },
  {
    currency: "AMD",
    currencyName: "Dram",
    conversionFactors: [
      {
        rate: 503.769675,
        year: "2022",
      },
      {
        rate: 503.769675,
        year: "2021",
      },
      {
        rate: 489.0088586,
        year: "2020",
      },
      {
        rate: 480.4451288,
        year: "2019",
      },
      {
        rate: 482.9879466,
        year: "2018",
      },
    ],
  },
  {
    currency: "AZN",
    currencyName: "Manat",
    conversionFactors: [
      {
        rate: 1.7,
        year: "2022",
      },
      {
        rate: 1.7,
        year: "2021",
      },
      {
        rate: 1.7,
        year: "2020",
      },
      {
        rate: 1.7,
        year: "2019",
      },
      {
        rate: 1.700016667,
        year: "2018",
      },
    ],
  },
  {
    currency: "BSD",
    currencyName: "Bahamian Dollar",
    conversionFactors: [
      {
        rate: 1,
        year: "2022",
      },
      {
        rate: 1,
        year: "2021",
      },
      {
        rate: 1,
        year: "2020",
      },
      {
        rate: 1,
        year: "2019",
      },
      {
        rate: 1,
        year: "2018",
      },
    ],
  },
  {
    currency: "BHD",
    currencyName: "Baharaini Dinar",
    conversionFactors: [
      {
        rate: 0.376,
        year: "2022",
      },
      {
        rate: 0.376,
        year: "2021",
      },
      {
        rate: 0.376,
        year: "2020",
      },
      {
        rate: 0.376,
        year: "2019",
      },
      {
        rate: 0.376,
        year: "2018",
      },
    ],
  },
  {
    currency: "BDT",
    currencyName: "Taka",
    conversionFactors: [
      {
        rate: 85.08376325,
        year: "2022",
      },
      {
        rate: 85.08376325,
        year: "2021",
      },
      {
        rate: 84.87139167,
        year: "2020",
      },
      {
        rate: 84.4535225,
        year: "2019",
      },
      {
        rate: 83.46620192,
        year: "2018",
      },
    ],
  },
  {
    currency: "BBD",
    currencyName: "Barbados Dollar",
    conversionFactors: [
      {
        rate: 2,
        year: "2022",
      },
      {
        rate: 2,
        year: "2021",
      },
      {
        rate: 2,
        year: "2020",
      },
      {
        rate: 2,
        year: "2019",
      },
      {
        rate: 2,
        year: "2018",
      },
    ],
  },
  {
    currency: "BYN",
    currencyName: "Belarusian Ruble",
    conversionFactors: [
      {
        rate: 2.538675,
        year: "2022",
      },
      {
        rate: 2.538675,
        year: "2021",
      },
      {
        rate: 2.439575,
        year: "2020",
      },
      {
        rate: 2.091783333,
        year: "2019",
      },
      {
        rate: 2.0376083334,
        year: "2018",
      },
    ],
  },
  {
    currency: "BZD",
    currencyName: "Belize Dollar",
    conversionFactors: [
      {
        rate: 2,
        year: "2022",
      },
      {
        rate: 2,
        year: "2021",
      },
      {
        rate: 2,
        year: "2020",
      },
      {
        rate: 2,
        year: "2019",
      },
      {
        rate: 2,
        year: "2018",
      },
    ],
  },
  {
    currency: "XOF",
    currencyName: "CFA Franc",
    conversionFactors: [
      {
        rate: 623.759701,
        year: "2022",
      },
      {
        rate: 554.6025992,
        year: "2021",
      },
      {
        rate: 574.2945496,
        year: "2020",
      },
      {
        rate: 585.9508138,
        year: "2019",
      },
      {
        rate: 555.4464584,
        year: "2018",
      },
    ],
  },
  {
    currency: "BTN",
    currencyName: "Ngultrum",
    conversionFactors: [
      {
        rate: 73.93934825,
        year: "2022",
      },
      {
        rate: 73.93934825,
        year: "2021",
      },
      {
        rate: 74.09956688,
        year: "2020",
      },
      {
        rate: 70.42034054,
        year: "2019",
      },
      {
        rate: 68.38946709,
        year: "2018",
      },
    ],
  },
  {
    currency: "BOB",
    currencyName: "Boliviano",
    conversionFactors: [
      {
        rate: 6.91,
        year: "2022",
      },
      {
        rate: 6.91,
        year: "2021",
      },
      {
        rate: 6.91,
        year: "2020",
      },
      {
        rate: 6.91,
        year: "2019",
      },
      {
        rate: 6.91,
        year: "2018",
      },
    ],
  },
  {
    currency: "BAM",
    currencyName: "Convertible Mark",
    conversionFactors: [
      {
        rate: 1.653606154,
        year: "2022",
      },
      {
        rate: 1.653606154,
        year: "2021",
      },
      {
        rate: 1.716970495,
        year: "2020",
      },
      {
        rate: 1.747086461,
        year: "2019",
      },
      {
        rate: 1.656985441,
        year: "2018",
      },
    ],
  },
  {
    currency: "BWP",
    currencyName: "Botswana Pula",
    conversionFactors: [
      {
        rate: 11.08725833,
        year: "2022",
      },
      {
        rate: 11.08725833,
        year: "2021",
      },
      {
        rate: 11.45624167,
        year: "2020",
      },
      {
        rate: 10.75586667,
        year: "2019",
      },
      {
        rate: 10.199975,
        year: "2018",
      },
    ],
  },
  {
    currency: "BRL",
    currencyName: "Brazilian Real",
    conversionFactors: [
      {
        rate: 5.164131,
        year: "2022",
      },
      {
        rate: 5.39440079,
        year: "2021",
      },
      {
        rate: 5.155178788,
        year: "2020",
      },
      {
        rate: 3.944471097,
        year: "2019",
      },
      {
        rate: 3.653825361,
        year: "2018",
      },
    ],
  },
  {
    currency: "BND",
    currencyName: "Brunei Dollar",
    conversionFactors: [
      {
        rate: 1.343806963,
        year: "2022",
      },
      {
        rate: 1.343806963,
        year: "2021",
      },
      {
        rate: 1.37970343,
        year: "2020",
      },
      {
        rate: 1.364218514,
        year: "2019",
      },
      {
        rate: 1.348918565,
        year: "2018",
      },
    ],
  },
  {
    currency: "BGL",
    currencyName: "Bulgarian Lev",
    conversionFactors: [
      {
        rate: 1.860142,
        year: "2022",
      },
      {
        rate: 1.653775,
        year: "2021",
      },
      {
        rate: 1.716333333,
        year: "2020",
      },
      {
        rate: 1.747041667,
        year: "2019",
      },
      {
        rate: 1.657041667,
        year: "2018",
      },
    ],
  },
  {
    currency: "BIF",
    currencyName: "Burundi Franc",
    conversionFactors: [
      {
        rate: 1975.950881,
        year: "2022",
      },
      {
        rate: 1975.950881,
        year: "2021",
      },
      {
        rate: 1915.046176,
        year: "2020",
      },
      {
        rate: 1845.622891,
        year: "2019",
      },
      {
        rate: 1782.876875,
        year: "2018",
      },
    ],
  },
  {
    currency: "CVE",
    currencyName: "Cape Verde Escudo",
    conversionFactors: [
      {
        rate: 93.21809241,
        year: "2022",
      },
      {
        rate: 93.21809241,
        year: "2021",
      },
      {
        rate: 96.79574279,
        year: "2020",
      },
      {
        rate: 98.49517859,
        year: "2019",
      },
      {
        rate: 93.41357891,
        year: "2018",
      },
    ],
  },
  {
    currency: "KHR",
    currencyName: "Riel",
    conversionFactors: [
      {
        rate: 4098.722795,
        year: "2022",
      },
      {
        rate: 4098.722795,
        year: "2021",
      },
      {
        rate: 4092.783219,
        year: "2020",
      },
      {
        rate: 4061.148963,
        year: "2019",
      },
      {
        rate: 4051.1669,
        year: "2018",
      },
    ],
  },
  {
    currency: "XAF",
    currencyName: "CFA Franc",
    conversionFactors: [
      {
        rate: 623.759701,
        year: "2022",
      },
      {
        rate: 554.530675,
        year: "2021",
      },
      {
        rate: 575.5860045,
        year: "2020",
      },
      {
        rate: 585.9110132,
        year: "2019",
      },
      {
        rate: 555.4464584,
        year: "2018",
      },
    ],
  },
  {
    currency: "CAD",
    currencyName: "Canadian Dollar",
    conversionFactors: [
      {
        rate: 1.301555,
        year: "2022",
      },
      {
        rate: 1.253876902,
        year: "2021",
      },
      {
        rate: 1.341152672,
        year: "2020",
      },
      {
        rate: 1.326793363,
        year: "2019",
      },
      {
        rate: 1.295817928,
        year: "2018",
      },
    ],
  },
  {
    currency: "CLP",
    currencyName: "Chilean Peso",
    conversionFactors: [
      {
        rate: 873.31419,
        year: "2022",
      },
      {
        rate: 758.9553787,
        year: "2021",
      },
      {
        rate: 792.7272061,
        year: "2020",
      },
      {
        rate: 702.8974226,
        year: "2019",
      },
      {
        rate: 641.2768131,
        year: "2018",
      },
    ],
  },
  {
    currency: "CNY",
    currencyName: "Yuan Renminbi",
    conversionFactors: [
      {
        rate: 6.737158,
        year: "2022",
      },
      {
        rate: 6.44897518,
        year: "2021",
      },
      {
        rate: 6.900767269,
        year: "2020",
      },
      {
        rate: 6.90838501,
        year: "2019",
      },
      {
        rate: 6.615957177,
        year: "2018",
      },
    ],
  },
  {
    currency: "COP",
    currencyName: "Colombian Peso",
    conversionFactors: [
      {
        rate: 3743.589819,
        year: "2022",
      },
      {
        rate: 3743.589819,
        year: "2021",
      },
      {
        rate: 3694.854072,
        year: "2020",
      },
      {
        rate: 3280.831631,
        year: "2019",
      },
      {
        rate: 2955.70397,
        year: "2018",
      },
    ],
  },
  {
    currency: "KMF",
    currencyName: "Comoros Franc",
    conversionFactors: [
      {
        rate: 415.9558491,
        year: "2022",
      },
      {
        rate: 415.9558491,
        year: "2021",
      },
      {
        rate: 430.7209122,
        year: "2020",
      },
      {
        rate: 439.4631103,
        year: "2019",
      },
      {
        rate: 416.5848438,
        year: "2018",
      },
    ],
  },
  {
    currency: "CDF",
    currencyName: "Congolese Franc",
    conversionFactors: [
      {
        rate: 1989.391471,
        year: "2022",
      },
      {
        rate: 1989.391471,
        year: "2021",
      },
      {
        rate: 1851.122162,
        year: "2020",
      },
      {
        rate: 1647.760127,
        year: "2019",
      },
      {
        rate: 1622.523502,
        year: "2018",
      },
    ],
  },
  {
    currency: "CRC",
    currencyName: "Costa Rican Colone",
    conversionFactors: [
      {
        rate: 647.135818,
        year: "2022",
      },
      {
        rate: 620.78472,
        year: "2021",
      },
      {
        rate: 584.900855,
        year: "2020",
      },
      {
        rate: 587.2945957,
        year: "2019",
      },
      {
        rate: 576.9725012,
        year: "2018",
      },
    ],
  },
  {
    currency: "HRK",
    currencyName: "Kuna",
    conversionFactors: [
      {
        rate: 6.360083167,
        year: "2022",
      },
      {
        rate: 6.360083167,
        year: "2021",
      },
      {
        rate: 6.6140555,
        year: "2020",
      },
      {
        rate: 6.622545167,
        year: "2019",
      },
      {
        rate: 6.27902525,
        year: "2018",
      },
    ],
  },
  {
    currency: "DKK",
    currencyName: "Danish Krone",
    conversionFactors: [
      {
        rate: 7.144804,
        year: "2022",
      },
      {
        rate: 6.287113083,
        year: "2021",
      },
      {
        rate: 6.542152204,
        year: "2020",
      },
      {
        rate: 6.669446615,
        year: "2019",
      },
      {
        rate: 6.314618787,
        year: "2018",
      },
    ],
  },
  {
    currency: "DJF",
    currencyName: "Djibouti Franc",
    conversionFactors: [
      {
        rate: 177.721,
        year: "2022",
      },
      {
        rate: 177.721,
        year: "2021",
      },
      {
        rate: 177.721,
        year: "2020",
      },
      {
        rate: 177.721,
        year: "2019",
      },
      {
        rate: 177.721,
        year: "2018",
      },
    ],
  },
  {
    currency: "DOP",
    currencyName: "Dominican Peso",
    conversionFactors: [
      {
        rate: 57.22111667,
        year: "2022",
      },
      {
        rate: 57.22111667,
        year: "2021",
      },
      {
        rate: 56.52453333,
        year: "2020",
      },
      {
        rate: 51.29485833,
        year: "2019",
      },
      {
        rate: 49.50999286,
        year: "2018",
      },
    ],
  },
  {
    currency: "EGP",
    currencyName: "Egyptian Pound",
    conversionFactors: [
      {
        rate: 15.64452728,
        year: "2022",
      },
      {
        rate: 15.64452728,
        year: "2021",
      },
      {
        rate: 15.75917292,
        year: "2020",
      },
      {
        rate: 16.77058184,
        year: "2019",
      },
      {
        rate: 17.76729042,
        year: "2018",
      },
    ],
  },
  {
    currency: "ERN",
    currencyName: "Nakfa",
    conversionFactors: [
      {
        rate: 15.075,
        year: "2022",
      },
      {
        rate: 15.075,
        year: "2021",
      },
      {
        rate: 15.075,
        year: "2020",
      },
      {
        rate: 15.075,
        year: "2019",
      },
      {
        rate: 15.075,
        year: "2018",
      },
    ],
  },
  {
    currency: "SZL",
    currencyName: "Lilangeni",
    conversionFactors: [
      {
        rate: 14.78343514,
        year: "2022",
      },
      {
        rate: 14.78343514,
        year: "2021",
      },
      {
        rate: 16.47025587,
        year: "2020",
      },
      {
        rate: 14.45178923,
        year: "2019",
      },
      {
        rate: 13.23392647,
        year: "2018",
      },
    ],
  },
  {
    currency: "ETB",
    currencyName: "Ethiopian Birr",
    conversionFactors: [
      {
        rate: 34.9271654,
        year: "2022",
      },
      {
        rate: 34.9271654,
        year: "2021",
      },
      {
        rate: 34.9271654,
        year: "2020",
      },
      {
        rate: 29.06975,
        year: "2019",
      },
      {
        rate: 27.42938659,
        year: "2018",
      },
    ],
  },
  {
    currency: "FJD",
    currencyName: "Fiji Dollar",
    conversionFactors: [
      {
        rate: 2.070633333,
        year: "2022",
      },
      {
        rate: 2.070633333,
        year: "2021",
      },
      {
        rate: 2.168804924,
        year: "2020",
      },
      {
        rate: 2.160393115,
        year: "2019",
      },
      {
        rate: 2.087379789,
        year: "2018",
      },
    ],
  },
  {
    currency: "GMD",
    currencyName: "Gambian Dalasi",
    conversionFactors: [
      {
        rate: 51.48444388,
        year: "2022",
      },
      {
        rate: 51.48444388,
        year: "2021",
      },
      {
        rate: 51.50166037,
        year: "2020",
      },
      {
        rate: 50.06246137,
        year: "2019",
      },
      {
        rate: 48.15178064,
        year: "2018",
      },
    ],
  },
  {
    currency: "GEL",
    currencyName: "Lari",
    conversionFactors: [
      {
        rate: 2.9162,
        year: "2022",
      },
      {
        rate: 3.221558333,
        year: "2021",
      },
      {
        rate: 3.109016667,
        year: "2020",
      },
      {
        rate: 2.818145,
        year: "2019",
      },
      {
        rate: 2.534110833,
        year: "2018",
      },
    ],
  },
  {
    currency: "GHC",
    currencyName: "Ghana Cedi",
    conversionFactors: [
      {
        rate: 5.8057,
        year: "2022",
      },
      {
        rate: 5.8057,
        year: "2021",
      },
      {
        rate: 5.595708333,
        year: "2020",
      },
      {
        rate: 5.217366667,
        year: "2019",
      },
      {
        rate: 4.585325,
        year: "2018",
      },
    ],
  },
  {
    currency: "GTQ",
    currencyName: "Guatemalan Quetzal",
    conversionFactors: [
      {
        rate: 7.734388333,
        year: "2022",
      },
      {
        rate: 7.734388333,
        year: "2021",
      },
      {
        rate: 7.72165,
        year: "2020",
      },
      {
        rate: 7.696698333,
        year: "2019",
      },
      {
        rate: 7.519164583,
        year: "2018",
      },
    ],
  },
  {
    currency: "GNF",
    currencyName: "Guinea Franc",
    conversionFactors: [
      {
        rate: 9565.082183,
        year: "2022",
      },
      {
        rate: 9565.082183,
        year: "2021",
      },
      {
        rate: 9565.082183,
        year: "2020",
      },
      {
        rate: 9183.875864,
        year: "2019",
      },
      {
        rate: 9011.134177,
        year: "2018",
      },
    ],
  },
  {
    currency: "GYD",
    currencyName: "Guyana Dollar",
    conversionFactors: [
      {
        rate: 208.5,
        year: "2022",
      },
      {
        rate: 208.5,
        year: "2021",
      },
      {
        rate: 208.5,
        year: "2020",
      },
      {
        rate: 208.5,
        year: "2019",
      },
      {
        rate: 207.7166667,
        year: "2018",
      },
    ],
  },
  {
    currency: "HTG",
    currencyName: "Haitian Gourde",
    conversionFactors: [
      {
        rate: 89.22663651,
        year: "2022",
      },
      {
        rate: 89.22663651,
        year: "2021",
      },
      {
        rate: 93.50980721,
        year: "2020",
      },
      {
        rate: 88.81496618,
        year: "2019",
      },
      {
        rate: 68.03175398,
        year: "2018",
      },
    ],
  },
  {
    currency: "HNL",
    currencyName: "Honduran Lempira",
    conversionFactors: [
      {
        rate: 24.01665532,
        year: "2022",
      },
      {
        rate: 24.01665532,
        year: "2021",
      },
      {
        rate: 24.58188052,
        year: "2020",
      },
      {
        rate: 24.50853891,
        year: "2019",
      },
      {
        rate: 23.90272829,
        year: "2018",
      },
    ],
  },
  {
    currency: "HUF",
    currencyName: "Hungarian Forint",
    conversionFactors: [
      {
        rate: 303.1408333,
        year: "2022",
      },
      {
        rate: 303.1408333,
        year: "2021",
      },
      {
        rate: 307.9966667,
        year: "2020",
      },
      {
        rate: 290.66,
        year: "2019",
      },
      {
        rate: 270.2116667,
        year: "2018",
      },
    ],
  },
  {
    currency: "ISK",
    currencyName: "Icelandic Krona",
    conversionFactors: [
      {
        rate: 126.9888602,
        year: "2022",
      },
      {
        rate: 126.9888602,
        year: "2021",
      },
      {
        rate: 135.4217116,
        year: "2020",
      },
      {
        rate: 122.6067736,
        year: "2019",
      },
      {
        rate: 108.3001763,
        year: "2018",
      },
    ],
  },
  {
    currency: "INR",
    currencyName: "Indian Rupee",
    conversionFactors: [
      {
        rate: 73.91801282,
        year: "2022",
      },
      {
        rate: 73.91801282,
        year: "2021",
      },
      {
        rate: 74.09956688,
        year: "2020",
      },
      {
        rate: 70.42034054,
        year: "2019",
      },
      {
        rate: 68.38946709,
        year: "2018",
      },
    ],
  },
  {
    currency: "IDR",
    currencyName: "Indonesian Rupiah",
    conversionFactors: [
      {
        rate: 14308.1439,
        year: "2022",
      },
      {
        rate: 14308.1439,
        year: "2021",
      },
      {
        rate: 14582.20347,
        year: "2020",
      },
      {
        rate: 14147.67136,
        year: "2019",
      },
      {
        rate: 14236.93877,
        year: "2018",
      },
    ],
  },
  {
    currency: "IRR",
    currencyName: "Iranian Rial",
    conversionFactors: [
      {
        rate: 42000,
        year: "2022",
      },
      {
        rate: 42000,
        year: "2021",
      },
      {
        rate: 42000,
        year: "2020",
      },
      {
        rate: 42000,
        year: "2019",
      },
      {
        rate: 40864.32901,
        year: "2018",
      },
    ],
  },
  {
    currency: "IQD",
    currencyName: "Iraqi Dinar",
    conversionFactors: [
      {
        rate: 1450,
        year: "2022",
      },
      {
        rate: 1450,
        year: "2021",
      },
      {
        rate: 1192,
        year: "2020",
      },
      {
        rate: 1182,
        year: "2019",
      },
      {
        rate: 1182.75,
        year: "2018",
      },
    ],
  },
  {
    currency: "ILS",
    currencyName: "New Sheqel",
    conversionFactors: [
      {
        rate: 3.230198323,
        year: "2022",
      },
      {
        rate: 3.230198323,
        year: "2021",
      },
      {
        rate: 3.442405852,
        year: "2020",
      },
      {
        rate: 3.564527347,
        year: "2019",
      },
      {
        rate: 3.590558127,
        year: "2018",
      },
    ],
  },
  {
    currency: "JMD",
    currencyName: "Jamaican Dollar",
    conversionFactors: [
      {
        rate: 142.4028328,
        year: "2022",
      },
      {
        rate: 142.4028328,
        year: "2021",
      },
      {
        rate: 142.4028328,
        year: "2020",
      },
      {
        rate: 133.3121183,
        year: "2019",
      },
      {
        rate: 128.8715191,
        year: "2018",
      },
    ],
  },
  {
    currency: "JPY",
    currencyName: "Japanese Yen",
    conversionFactors: [
      {
        rate: 131.49814,
        year: "2022",
      },
      {
        rate: 109.7543238,
        year: "2021",
      },
      {
        rate: 106.7745823,
        year: "2020",
      },
      {
        rate: 109.0096659,
        year: "2019",
      },
      {
        rate: 110.4231793,
        year: "2018",
      },
    ],
  },
  {
    currency: "JOD",
    currencyName: "Jordanian Dinar",
    conversionFactors: [
      {
        rate: 0.71,
        year: "2022",
      },
      {
        rate: 0.71,
        year: "2021",
      },
      {
        rate: 0.71,
        year: "2020",
      },
      {
        rate: 0.71,
        year: "2019",
      },
      {
        rate: 0.71,
        year: "2018",
      },
    ],
  },
  {
    currency: "KZT",
    currencyName: "Tenge",
    conversionFactors: [
      {
        rate: 425.9075,
        year: "2022",
      },
      {
        rate: 425.9075,
        year: "2021",
      },
      {
        rate: 412.9533333,
        year: "2020",
      },
      {
        rate: 382.7473106,
        year: "2019",
      },
      {
        rate: 344.7058333,
        year: "2018",
      },
    ],
  },
  {
    currency: "KES",
    currencyName: "Kenyan Shilling",
    conversionFactors: [
      {
        rate: 109.6377466,
        year: "2022",
      },
      {
        rate: 109.6377466,
        year: "2021",
      },
      {
        rate: 106.4507802,
        year: "2020",
      },
      {
        rate: 101.9912984,
        year: "2019",
      },
      {
        rate: 101.301574,
        year: "2018",
      },
    ],
  },
  {
    currency: "KRW",
    currencyName: "Won",
    conversionFactors: [
      {
        rate: 1292.027353,
        year: "2022",
      },
      {
        rate: 1143.951667,
        year: "2021",
      },
      {
        rate: 1180.265833,
        year: "2020",
      },
      {
        rate: 1165.3575,
        year: "2019",
      },
      {
        rate: 1100.163333,
        year: "2018",
      },
    ],
  },
  {
    currency: "KWD",
    currencyName: "Kuwaiti Dinar",
    conversionFactors: [
      {
        rate: 0.301643111,
        year: "2022",
      },
      {
        rate: 0.301643111,
        year: "2021",
      },
      {
        rate: 0.306233122,
        year: "2020",
      },
      {
        rate: 0.303611163,
        year: "2019",
      },
      {
        rate: 0.301956494,
        year: "2018",
      },
    ],
  },
  {
    currency: "KGS",
    currencyName: "Som",
    conversionFactors: [
      {
        rate: 84.64082201,
        year: "2022",
      },
      {
        rate: 84.64082201,
        year: "2021",
      },
      {
        rate: 77.3461127,
        year: "2020",
      },
      {
        rate: 69.78934918,
        year: "2019",
      },
      {
        rate: 68.84032033,
        year: "2018",
      },
    ],
  },
  {
    currency: "LBP",
    currencyName: "Lebanese Pound",
    conversionFactors: [
      {
        rate: 1507.5,
        year: "2022",
      },
      {
        rate: 1507.5,
        year: "2021",
      },
      {
        rate: 1507.5,
        year: "2020",
      },
      {
        rate: 1507.5,
        year: "2019",
      },
      {
        rate: 1507.5,
        year: "2018",
      },
    ],
  },
  {
    currency: "LSL",
    currencyName: "Lesotho Loti",
    conversionFactors: [
      {
        rate: 14.77867821,
        year: "2022",
      },
      {
        rate: 14.77867821,
        year: "2021",
      },
      {
        rate: 16.45910539,
        year: "2020",
      },
      {
        rate: 14.44842705,
        year: "2019",
      },
      {
        rate: 13.23392647,
        year: "2018",
      },
    ],
  },
  {
    currency: "LRD",
    currencyName: "Liberian Dollar",
    conversionFactors: [
      {
        rate: 191.5179576,
        year: "2022",
      },
      {
        rate: 191.5179576,
        year: "2021",
      },
      {
        rate: 191.5179576,
        year: "2020",
      },
      {
        rate: 186.4297446,
        year: "2019",
      },
      {
        rate: 144.0555758,
        year: "2018",
      },
    ],
  },
  {
    currency: "LYD",
    currencyName: "Libyan Dinar",
    conversionFactors: [
      {
        rate: 1.398262897,
        year: "2022",
      },
      {
        rate: 1.398262897,
        year: "2021",
      },
      {
        rate: 1.398262897,
        year: "2020",
      },
      {
        rate: 1.398262897,
        year: "2019",
      },
      {
        rate: 1.364966667,
        year: "2018",
      },
    ],
  },
  {
    currency: "MGA",
    currencyName: "Malagasy Ariary",
    conversionFactors: [
      {
        rate: 3829.977849,
        year: "2022",
      },
      {
        rate: 3829.977849,
        year: "2021",
      },
      {
        rate: 3787.754058,
        year: "2020",
      },
      {
        rate: 3618.321858,
        year: "2019",
      },
      {
        rate: 3334.752255,
        year: "2018",
      },
    ],
  },
  {
    currency: "MWK",
    currencyName: "Malawian Kwacha",
    conversionFactors: [
      {
        rate: 749.5274939,
        year: "2022",
      },
      {
        rate: 749.5274939,
        year: "2021",
      },
      {
        rate: 749.5274939,
        year: "2020",
      },
      {
        rate: 745.5406679,
        year: "2019",
      },
      {
        rate: 732.3333333,
        year: "2018",
      },
    ],
  },
  {
    currency: "MYR",
    currencyName: "Ringgit",
    conversionFactors: [
      {
        rate: 4.143297598,
        year: "2022",
      },
      {
        rate: 4.143297598,
        year: "2021",
      },
      {
        rate: 4.203481949,
        year: "2020",
      },
      {
        rate: 4.142469736,
        year: "2019",
      },
      {
        rate: 4.035130137,
        year: "2018",
      },
    ],
  },
  {
    currency: "MVR",
    currencyName: "Rufiyaa",
    conversionFactors: [
      {
        rate: 15.37269841,
        year: "2022",
      },
      {
        rate: 15.37269841,
        year: "2021",
      },
      {
        rate: 15.38126953,
        year: "2020",
      },
      {
        rate: 15.38204192,
        year: "2019",
      },
      {
        rate: 15.39083727,
        year: "2018",
      },
    ],
  },
  {
    currency: "MRU",
    currencyName: "New Ouguiya",
    conversionFactors: [
      {
        rate: 37.18916667,
        year: "2022",
      },
      {
        rate: 37.18916667,
        year: "2021",
      },
      {
        rate: 37.18916667,
        year: "2020",
      },
      {
        rate: 36.69083333,
        year: "2019",
      },
      {
        rate: 35.6775,
        year: "2018",
      },
    ],
  },
  {
    currency: "MUR",
    currencyName: "Mauritius Rupee",
    conversionFactors: [
      {
        rate: 41.69213333,
        year: "2022",
      },
      {
        rate: 41.69213333,
        year: "2021",
      },
      {
        rate: 39.34693333,
        year: "2020",
      },
      {
        rate: 35.47351667,
        year: "2019",
      },
      {
        rate: 33.93445,
        year: "2018",
      },
    ],
  },
  {
    currency: "MXN",
    currencyName: "Mexican Peso",
    conversionFactors: [
      {
        rate: 20.12735,
        year: "2022",
      },
      {
        rate: 20.27240833,
        year: "2021",
      },
      {
        rate: 21.48560833,
        year: "2020",
      },
      {
        rate: 19.26363333,
        year: "2019",
      },
      {
        rate: 19.24434167,
        year: "2018",
      },
    ],
  },
  {
    currency: "MDL",
    currencyName: "Moldova Leu",
    conversionFactors: [
      {
        rate: 17.68046614,
        year: "2022",
      },
      {
        rate: 17.68046614,
        year: "2021",
      },
      {
        rate: 17.32183521,
        year: "2020",
      },
      {
        rate: 17.57346887,
        year: "2019",
      },
      {
        rate: 16.80205172,
        year: "2018",
      },
    ],
  },
  {
    currency: "MNT",
    currencyName: "Tugrik",
    conversionFactors: [
      {
        rate: 2849.288615,
        year: "2022",
      },
      {
        rate: 2849.288615,
        year: "2021",
      },
      {
        rate: 2813.289835,
        year: "2020",
      },
      {
        rate: 2663.541398,
        year: "2019",
      },
      {
        rate: 2472.484051,
        year: "2018",
      },
    ],
  },
  {
    currency: "MAD",
    currencyName: "Moroccan Dirham",
    conversionFactors: [
      {
        rate: 8.988484024,
        year: "2022",
      },
      {
        rate: 8.988484024,
        year: "2021",
      },
      {
        rate: 9.496847322,
        year: "2020",
      },
      {
        rate: 9.6170761,
        year: "2019",
      },
      {
        rate: 9.386102421,
        year: "2018",
      },
    ],
  },
  {
    currency: "MZM",
    currencyName: "Mozambican Metical",
    conversionFactors: [
      {
        rate: 65.465,
        year: "2022",
      },
      {
        rate: 65.465,
        year: "2021",
      },
      {
        rate: 69.465,
        year: "2020",
      },
      {
        rate: 62.54833333,
        year: "2019",
      },
      {
        rate: 60.32620764,
        year: "2018",
      },
    ],
  },
  {
    currency: "MMK",
    currencyName: "Kyat",
    conversionFactors: [
      {
        rate: 1381.619167,
        year: "2022",
      },
      {
        rate: 1381.619167,
        year: "2021",
      },
      {
        rate: 1381.619167,
        year: "2020",
      },
      {
        rate: 1518.255117,
        year: "2019",
      },
      {
        rate: 1429.807975,
        year: "2018",
      },
    ],
  },
  {
    currency: "NAD",
    currencyName: "Namibian Dollar",
    conversionFactors: [
      {
        rate: 14.778675,
        year: "2022",
      },
      {
        rate: 14.778675,
        year: "2021",
      },
      {
        rate: 16.46326667,
        year: "2020",
      },
      {
        rate: 14.44869042,
        year: "2019",
      },
      {
        rate: 13.23394167,
        year: "2018",
      },
    ],
  },
  {
    currency: "NPR",
    currencyName: "Nepalese Rupee",
    conversionFactors: [
      {
        rate: 118.1340816,
        year: "2022",
      },
      {
        rate: 118.1340816,
        year: "2021",
      },
      {
        rate: 118.3451873,
        year: "2020",
      },
      {
        rate: 112.6094828,
        year: "2019",
      },
      {
        rate: 108.9301341,
        year: "2018",
      },
    ],
  },
  {
    currency: "NZD",
    currencyName: "New Zealand Dollar",
    conversionFactors: [
      {
        rate: 1.577019,
        year: "2022",
      },
      {
        rate: 1.4138,
        year: "2021",
      },
      {
        rate: 1.542058333,
        year: "2020",
      },
      {
        rate: 1.517875,
        year: "2019",
      },
      {
        rate: 1.445258333,
        year: "2018",
      },
    ],
  },
  {
    currency: "NIO",
    currencyName: "Nicaraguan Cordoba Oro",
    conversionFactors: [
      {
        rate: 35.17101667,
        year: "2022",
      },
      {
        rate: 35.17101667,
        year: "2021",
      },
      {
        rate: 34.34212212,
        year: "2020",
      },
      {
        rate: 33.12174527,
        year: "2019",
      },
      {
        rate: 31.55321233,
        year: "2018",
      },
    ],
  },
  {
    currency: "NGN",
    currencyName: "Naira",
    conversionFactors: [
      {
        rate: 358.8107973,
        year: "2022",
      },
      {
        rate: 358.8107973,
        year: "2021",
      },
      {
        rate: 358.8107973,
        year: "2020",
      },
      {
        rate: 306.9209515,
        year: "2019",
      },
      {
        rate: 306.0836882,
        year: "2018",
      },
    ],
  },
  {
    currency: "MKD",
    currencyName: "Denar",
    conversionFactors: [
      {
        rate: 52.10215833,
        year: "2022",
      },
      {
        rate: 52.10215833,
        year: "2021",
      },
      {
        rate: 54.144325,
        year: "2020",
      },
      {
        rate: 54.9472,
        year: "2019",
      },
      {
        rate: 52.10710833,
        year: "2018",
      },
    ],
  },
  {
    currency: "NOK",
    currencyName: "Norway Krone",
    conversionFactors: [
      {
        rate: 9.613982,
        year: "2022",
      },
      {
        rate: 8.59,
        year: "2021",
      },
      {
        rate: 9.415833333,
        year: "2020",
      },
      {
        rate: 8.8,
        year: "2019",
      },
      {
        rate: 8.1325,
        year: "2018",
      },
    ],
  },
  {
    currency: "OMR",
    currencyName: "Omani Rial",
    conversionFactors: [
      {
        rate: 0.3845,
        year: "2022",
      },
      {
        rate: 0.3845,
        year: "2021",
      },
      {
        rate: 0.3845,
        year: "2020",
      },
      {
        rate: 0.3845,
        year: "2019",
      },
      {
        rate: 0.3845,
        year: "2018",
      },
    ],
  },
  {
    currency: "PKR",
    currencyName: "Pakistani Rupee",
    conversionFactors: [
      {
        rate: 162.9062537,
        year: "2022",
      },
      {
        rate: 162.9062537,
        year: "2021",
      },
      {
        rate: 161.8384797,
        year: "2020",
      },
      {
        rate: 150.0362538,
        year: "2019",
      },
      {
        rate: 121.8240689,
        year: "2018",
      },
    ],
  },
  {
    currency: "PAB",
    currencyName: "Balboa",
    conversionFactors: [
      {
        rate: 1,
        year: "2022",
      },
      {
        rate: 1,
        year: "2021",
      },
      {
        rate: 1,
        year: "2020",
      },
      {
        rate: 1,
        year: "2019",
      },
      {
        rate: 1,
        year: "2018",
      },
    ],
  },
  {
    currency: "PGK",
    currencyName: "Kina",
    conversionFactors: [
      {
        rate: 3.50877193,
        year: "2022",
      },
      {
        rate: 3.50877193,
        year: "2021",
      },
      {
        rate: 3.459974386,
        year: "2020",
      },
      {
        rate: 3.387537681,
        year: "2019",
      },
      {
        rate: 3.293463081,
        year: "2018",
      },
    ],
  },
  {
    currency: "PYG",
    currencyName: "Guarani",
    conversionFactors: [
      {
        rate: 6774.162735,
        year: "2022",
      },
      {
        rate: 6774.162735,
        year: "2021",
      },
      {
        rate: 6771.097425,
        year: "2020",
      },
      {
        rate: 6240.722078,
        year: "2019",
      },
      {
        rate: 5732.104556,
        year: "2018",
      },
    ],
  },
  {
    currency: "PEN",
    currencyName: "Nuevo Sol",
    conversionFactors: [
      {
        rate: 3.880554131,
        year: "2022",
      },
      {
        rate: 3.880554131,
        year: "2021",
      },
      {
        rate: 3.494941198,
        year: "2020",
      },
      {
        rate: 3.337265547,
        year: "2019",
      },
      {
        rate: 3.286602698,
        year: "2018",
      },
    ],
  },
  {
    currency: "PHP",
    currencyName: "Philippines Peso",
    conversionFactors: [
      {
        rate: 49.25459773,
        year: "2022",
      },
      {
        rate: 49.25459773,
        year: "2021",
      },
      {
        rate: 49.624096,
        year: "2020",
      },
      {
        rate: 51.79578265,
        year: "2019",
      },
      {
        rate: 52.66142995,
        year: "2018",
      },
    ],
  },
  {
    currency: "PLN",
    currencyName: "Zloty",
    conversionFactors: [
      {
        rate: 4.457758,
        year: "2022",
      },
      {
        rate: 3.861916667,
        year: "2021",
      },
      {
        rate: 3.899741667,
        year: "2020",
      },
      {
        rate: 3.839375,
        year: "2019",
      },
      {
        rate: 3.611716667,
        year: "2018",
      },
    ],
  },
  {
    currency: "QAR",
    currencyName: "Qatar Rial",
    conversionFactors: [
      {
        rate: 3.64,
        year: "2022",
      },
      {
        rate: 3.64,
        year: "2021",
      },
      {
        rate: 3.64,
        year: "2020",
      },
      {
        rate: 3.64,
        year: "2019",
      },
      {
        rate: 3.64,
        year: "2018",
      },
    ],
  },
  {
    currency: "RON",
    currencyName: "Romania Leu",
    conversionFactors: [
      {
        rate: 4.160416667,
        year: "2022",
      },
      {
        rate: 4.160416667,
        year: "2021",
      },
      {
        rate: 4.243991667,
        year: "2020",
      },
      {
        rate: 4.237925,
        year: "2019",
      },
      {
        rate: 3.941616667,
        year: "2018",
      },
    ],
  },
  {
    currency: "RUB",
    currencyName: "Rouble",
    conversionFactors: [
      {
        rate: 73.65435,
        year: "2022",
      },
      {
        rate: 73.65435,
        year: "2021",
      },
      {
        rate: 72.10490833,
        year: "2020",
      },
      {
        rate: 64.73765833,
        year: "2019",
      },
      {
        rate: 62.66813333,
        year: "2018",
      },
    ],
  },
  {
    currency: "RWF",
    currencyName: "Rwandan Franc",
    conversionFactors: [
      {
        rate: 988.6248066,
        year: "2022",
      },
      {
        rate: 988.6248066,
        year: "2021",
      },
      {
        rate: 943.2780482,
        year: "2020",
      },
      {
        rate: 899.350509,
        year: "2019",
      },
      {
        rate: 861.0934122,
        year: "2018",
      },
    ],
  },
  {
    currency: "WST",
    currencyName: "Tala",
    conversionFactors: [
      {
        rate: 2.556092342,
        year: "2022",
      },
      {
        rate: 2.556092342,
        year: "2021",
      },
      {
        rate: 2.664960845,
        year: "2020",
      },
      {
        rate: 2.648826322,
        year: "2019",
      },
      {
        rate: 2.587279951,
        year: "2018",
      },
    ],
  },
  {
    currency: "STN",
    currencyName: "New Dobra",
    conversionFactors: [
      {
        rate: 21.5070591,
        year: "2022",
      },
      {
        rate: 21.5070591,
        year: "2021",
      },
      {
        rate: 21.5070591,
        year: "2020",
      },
      {
        rate: 21.88474182,
        year: "2019",
      },
      {
        rate: 21.88474182,
        year: "2018",
      },
    ],
  },
  {
    currency: "SAR",
    currencyName: "Saudi Riyal",
    conversionFactors: [
      {
        rate: 3.75,
        year: "2022",
      },
      {
        rate: 3.75,
        year: "2021",
      },
      {
        rate: 3.75,
        year: "2020",
      },
      {
        rate: 3.75,
        year: "2019",
      },
      {
        rate: 3.75,
        year: "2018",
      },
    ],
  },
  {
    currency: "RSD",
    currencyName: "Serbian Dinar",
    conversionFactors: [
      {
        rate: 111.662183,
        year: "2022",
      },
      {
        rate: 99.39594167,
        year: "2021",
      },
      {
        rate: 103.1632917,
        year: "2020",
      },
      {
        rate: 105.2495583,
        year: "2019",
      },
      {
        rate: 100.175075,
        year: "2018",
      },
    ],
  },
  {
    currency: "SCR",
    currencyName: "Seychelles Rupee",
    conversionFactors: [
      {
        rate: 16.92052257,
        year: "2022",
      },
      {
        rate: 16.92052257,
        year: "2021",
      },
      {
        rate: 17.61651876,
        year: "2020",
      },
      {
        rate: 14.03325,
        year: "2019",
      },
      {
        rate: 13.91111667,
        year: "2018",
      },
    ],
  },
  {
    currency: "SLL",
    currencyName: "Leone",
    conversionFactors: [
      {
        rate: 10439.42533,
        year: "2022",
      },
      {
        rate: 10439.42533,
        year: "2021",
      },
      {
        rate: 9829.926763,
        year: "2020",
      },
      {
        rate: 9010.221144,
        year: "2019",
      },
      {
        rate: 7931.63175,
        year: "2018",
      },
    ],
  },
  {
    currency: "SGD",
    currencyName: "Singapore Dollar",
    conversionFactors: [
      {
        rate: 1.378667,
        year: "2022",
      },
      {
        rate: 1.343483333,
        year: "2021",
      },
      {
        rate: 1.379741667,
        year: "2020",
      },
      {
        rate: 1.364158333,
        year: "2019",
      },
      {
        rate: 1.348841667,
        year: "2018",
      },
    ],
  },
  {
    currency: "SBD",
    currencyName: "Solomon Dollar",
    conversionFactors: [
      {
        rate: 8.030102991,
        year: "2022",
      },
      {
        rate: 8.030102991,
        year: "2021",
      },
      {
        rate: 8.21341291,
        year: "2020",
      },
      {
        rate: 8.173399298,
        year: "2019",
      },
      {
        rate: 7.952504861,
        year: "2018",
      },
    ],
  },
  {
    currency: "ZAR",
    currencyName: "Rand",
    conversionFactors: [
      {
        rate: 16.359386,
        year: "2022",
      },
      {
        rate: 14.77867821,
        year: "2021",
      },
      {
        rate: 16.45910539,
        year: "2020",
      },
      {
        rate: 14.44842705,
        year: "2019",
      },
      {
        rate: 13.23392647,
        year: "2018",
      },
    ],
  },
  {
    currency: "SSP",
    currencyName: "South Sudanese Pound",
    conversionFactors: [
      {
        rate: 306.3546199,
        year: "2022",
      },
      {
        rate: 306.3546199,
        year: "2021",
      },
      {
        rate: 165.9073167,
        year: "2020",
      },
      {
        rate: 157.9991667,
        year: "2019",
      },
      {
        rate: 141.3858333,
        year: "2018",
      },
    ],
  },
  {
    currency: "LKR",
    currencyName: "Sri Lanka Rupee",
    conversionFactors: [
      {
        rate: 185.5925578,
        year: "2022",
      },
      {
        rate: 185.5925578,
        year: "2021",
      },
      {
        rate: 185.5925578,
        year: "2020",
      },
      {
        rate: 178.744925,
        year: "2019",
      },
      {
        rate: 162.4648587,
        year: "2018",
      },
    ],
  },
  {
    currency: "SDG",
    currencyName: "Sudanese Pound",
    conversionFactors: [
      {
        rate: 53.9960119,
        year: "2022",
      },
      {
        rate: 53.9960119,
        year: "2021",
      },
      {
        rate: 53.9960119,
        year: "2020",
      },
      {
        rate: 45.76704545,
        year: "2019",
      },
      {
        rate: 24.3289109,
        year: "2018",
      },
    ],
  },
  {
    currency: "SRD",
    currencyName: "Surinamese Dollar",
    conversionFactors: [
      {
        rate: 18.23866667,
        year: "2022",
      },
      {
        rate: 18.23866667,
        year: "2021",
      },
      {
        rate: 9.309545455,
        year: "2020",
      },
      {
        rate: 7.458,
        year: "2019",
      },
      {
        rate: 7.462511198,
        year: "2018",
      },
    ],
  },
  {
    currency: "SEK",
    currencyName: "Swedish Krona",
    conversionFactors: [
      {
        rate: 10.114251,
        year: "2022",
      },
      {
        rate: 8.576566716,
        year: "2021",
      },
      {
        rate: 9.210309028,
        year: "2020",
      },
      {
        rate: 9.458349167,
        year: "2019",
      },
      {
        rate: 8.692518333,
        year: "2018",
      },
    ],
  },
  {
    currency: "CHF",
    currencyName: "Swiss Franc",
    conversionFactors: [
      {
        rate: 0.95494,
        year: "2022",
      },
      {
        rate: 0.913845833,
        year: "2021",
      },
      {
        rate: 0.938965,
        year: "2020",
      },
      {
        rate: 0.993709167,
        year: "2019",
      },
      {
        rate: 0.977891667,
        year: "2018",
      },
    ],
  },
  {
    currency: "TJS",
    currencyName: "Somoni",
    conversionFactors: [
      {
        rate: 11.30885,
        year: "2022",
      },
      {
        rate: 11.30885,
        year: "2021",
      },
      {
        rate: 10.32194167,
        year: "2020",
      },
      {
        rate: 9.530366667,
        year: "2019",
      },
      {
        rate: 9.151216667,
        year: "2018",
      },
    ],
  },
  {
    currency: "TZS",
    currencyName: "Tanzanian Shilling",
    conversionFactors: [
      {
        rate: 2297.764226,
        year: "2022",
      },
      {
        rate: 2297.764226,
        year: "2021",
      },
      {
        rate: 2294.146151,
        year: "2020",
      },
      {
        rate: 2288.206667,
        year: "2019",
      },
      {
        rate: 2263.781667,
        year: "2018",
      },
    ],
  },
  {
    currency: "THB",
    currencyName: "Bhat",
    conversionFactors: [
      {
        rate: 31.97709344,
        year: "2022",
      },
      {
        rate: 31.97709344,
        year: "2021",
      },
      {
        rate: 31.29367321,
        year: "2020",
      },
      {
        rate: 31.04760578,
        year: "2019",
      },
      {
        rate: 32.31022574,
        year: "2018",
      },
    ],
  },
  {
    currency: "TOP",
    currencyName: "Pa'anga",
    conversionFactors: [
      {
        rate: 2.264959601,
        year: "2022",
      },
      {
        rate: 2.264959601,
        year: "2021",
      },
      {
        rate: 2.299561508,
        year: "2020",
      },
      {
        rate: 2.28948563,
        year: "2019",
      },
      {
        rate: 2.236571476,
        year: "2018",
      },
    ],
  },
  {
    currency: "TTD",
    currencyName: "Trinidad and Tobago Dollar",
    conversionFactors: [
      {
        rate: 6.758530049,
        year: "2022",
      },
      {
        rate: 6.758530049,
        year: "2021",
      },
      {
        rate: 6.751052687,
        year: "2020",
      },
      {
        rate: 6.754326683,
        year: "2019",
      },
      {
        rate: 6.770750898,
        year: "2018",
      },
    ],
  },
  {
    currency: "TND",
    currencyName: "Tunisian Dinar",
    conversionFactors: [
      {
        rate: 2.794466667,
        year: "2022",
      },
      {
        rate: 2.794466667,
        year: "2021",
      },
      {
        rate: 2.812358333,
        year: "2020",
      },
      {
        rate: 2.934433333,
        year: "2019",
      },
      {
        rate: 2.646866667,
        year: "2018",
      },
    ],
  },
  {
    currency: "TRY",
    currencyName: "Turkish Lira",
    conversionFactors: [
      {
        rate: 8.850407549,
        year: "2022",
      },
      {
        rate: 8.850407549,
        year: "2021",
      },
      {
        rate: 7.008605416,
        year: "2020",
      },
      {
        rate: 5.673819308,
        year: "2019",
      },
      {
        rate: 4.828370147,
        year: "2018",
      },
    ],
  },
  {
    currency: "UGS",
    currencyName: "Ugandan shilling",
    conversionFactors: [
      {
        rate: 3587.051707,
        year: "2022",
      },
      {
        rate: 3587.051707,
        year: "2021",
      },
      {
        rate: 3718.248923,
        year: "2020",
      },
      {
        rate: 3704.049072,
        year: "2019",
      },
      {
        rate: 3727.068995,
        year: "2018",
      },
    ],
  },
  {
    currency: "UAH",
    currencyName: "Hryvnia",
    conversionFactors: [
      {
        rate: 27.28618938,
        year: "2022",
      },
      {
        rate: 27.28618938,
        year: "2021",
      },
      {
        rate: 26.95752438,
        year: "2020",
      },
      {
        rate: 25.84558933,
        year: "2019",
      },
      {
        rate: 27.20049233,
        year: "2018",
      },
    ],
  },
  {
    currency: "AED",
    currencyName: "U.A.Emirates Dirham",
    conversionFactors: [
      {
        rate: 3.6725,
        year: "2022",
      },
      {
        rate: 3.6725,
        year: "2021",
      },
      {
        rate: 3.6725,
        year: "2020",
      },
      {
        rate: 3.6725,
        year: "2019",
      },
      {
        rate: 3.6725,
        year: "2018",
      },
    ],
  },
  {
    currency: "UYU",
    currencyName: "Uruguayan Peso",
    conversionFactors: [
      {
        rate: 43.554575,
        year: "2022",
      },
      {
        rate: 43.554575,
        year: "2021",
      },
      {
        rate: 42.01329167,
        year: "2020",
      },
      {
        rate: 35.255375,
        year: "2019",
      },
      {
        rate: 30.72525833,
        year: "2018",
      },
    ],
  },
  {
    currency: "UZS",
    currencyName: "Sum",
    conversionFactors: [
      {
        rate: 10609.46439,
        year: "2022",
      },
      {
        rate: 10609.46439,
        year: "2021",
      },
      {
        rate: 10054.26133,
        year: "2020",
      },
      {
        rate: 8836.7875,
        year: "2019",
      },
      {
        rate: 8069.606237,
        year: "2018",
      },
    ],
  },
  {
    currency: "VUV",
    currencyName: "Vatu",
    conversionFactors: [
      {
        rate: 109.4525,
        year: "2022",
      },
      {
        rate: 109.4525,
        year: "2021",
      },
      {
        rate: 115.38,
        year: "2020",
      },
      {
        rate: 114.7325,
        year: "2019",
      },
      {
        rate: 110.165,
        year: "2018",
      },
    ],
  },
  {
    currency: "VND",
    currencyName: "Dong",
    conversionFactors: [
      {
        rate: 23159.78259,
        year: "2022",
      },
      {
        rate: 23159.78259,
        year: "2021",
      },
      {
        rate: 23208.36833,
        year: "2020",
      },
      {
        rate: 23050.24167,
        year: "2019",
      },
      {
        rate: 22602.05,
        year: "2018",
      },
    ],
  },
  {
    currency: "YER",
    currencyName: "Yemeni Rial",
    conversionFactors: [
      {
        rate: 1035.467186,
        year: "2022",
      },
      {
        rate: 1035.467186,
        year: "2021",
      },
      {
        rate: 743.0059644,
        year: "2020",
      },
      {
        rate: 486.7309229,
        year: "2019",
      },
      {
        rate: 214.89,
        year: "2018",
      },
    ],
  },
  {
    currency: "ZMK",
    currencyName: "Kwacha",
    conversionFactors: [
      {
        rate: 20.01848659,
        year: "2022",
      },
      {
        rate: 20.01848659,
        year: "2021",
      },
      {
        rate: 18.34409265,
        year: "2020",
      },
      {
        rate: 12.89,
        year: "2019",
      },
      {
        rate: 10.45833333,
        year: "2018",
      },
    ],
  },
  {
    currency: "ZWD",
    currencyName: "US Dollar",
    conversionFactors: [
      {
        rate: 88.55244726,
        year: "2022",
      },
      {
        rate: 88.55244726,
        year: "2021",
      },
      {
        rate: 51.32901312,
        year: "2020",
      },
      {
        rate: 51.32901312,
        year: "2019",
      },
      {
        rate: 51.32901312,
        year: "2018",
      },
    ],
  },
];

function getFormula(currencyData, currency, amount, reportingYear) {
  const filterFromCurrency = currencyData?.filter(
    (data) => data?.currency === currency
  );
  const filteredRate = filterFromCurrency?.[0]?.conversionFactors?.find(
    (data) => data?.year === reportingYear
  )?.rate;

  return (1 / filteredRate) * amount;
}

export { FormulasDataSet, getFormula };
