import Header from "components/App/Header/Header";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { get } from "utils/DeApi";
import { useSearchParams } from "react-router-dom";
import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";
import { TableSortArrows } from "components/Shared/TableComponents";
import "./EmissionFactors.scss";

const EmissionFactors = () => {
  const [emissionFactors, setEmissionFactors] = useState([]);
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [searchParams] = useSearchParams();

  const activityTypeId = searchParams.get("activityCategoryId");
  const [emissionData, setEmissionData] = useState([]);

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "createdAt",
    updatedAt: true,
    sortAsc: false,
  });

  useEffect(() => {
    const fetchEmissionFactors = () => {
      setError(null);
      setIsLoading(true);

      const emissionFactorsPromise = get(`emission-factors`, {
        params: {
          activityTypeId: searchParams.get("activityCategoryId"),
          filter: "id",
        },
      });

      emissionFactorsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);

          setEmissionFactors(response.data);
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(emissionFactorsPromise);
    };
    fetchEmissionFactors();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [searchParams]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  const sortOrganizations = (column, objValue) => {
    emissionFactors.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];

      if (macA === null || macA === undefined || macA === "n/a") {
        return tableSortState[column] ? 1 : -1;
      }

      if (macB === null || macB === undefined || macB === "n/a") {
        return tableSortState[column] ? -1 : 1;
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setEmissionData([...emissionData]);
  };

  return (
    <>
      <Header />
      <DataLayer />
      <Container
        id={userCategory}
        className="emission-factor-table MainContent py-3"
      >
        <Row className="my-3">
          <Col xs={12} md={12}>
            <h2>
              Scope {searchParams.get("scope")} -{" "}
              {emissionFactors && emissionFactors[0]?.activityTypes[0]?.title}
            </h2>
            {/* TODO: need to revise this logic, the activityTypeId is uuid and won't match any of these */}
            {activityTypeId === "13" ? (
              <p>
                Emissions = (Cost of Capital Good)*(Emission Factor
                Corresponding To Country & Industry)*1000/(inflation factor)
              </p>
            ) : activityTypeId === "25" ? (
              <p>
                Emissions = (Investee Revenue)*(Emission Factor Corresponding To
                Country & Industry)*(Percent Equity Ownership)*1000/(inflation
                factor)
              </p>
            ) : activityTypeId === "21" || activityTypeId === "15" ? (
              <p>
                Emissions = (Quantity of resource in k€)*(Emission
                Factor)*1000/(inflation factor)
              </p>
            ) : (
              <p>
                Emissions = Quantity of Resource Used * Corresponding Emission
                Factor {["2", "5"].includes(activityTypeId) && "/ 1000"}
              </p>
            )}
            <div>
              <Table responsive size="sm">
                <thead>
                  <tr>
                    <th>Activity Type</th>
                    <th
                      className="table-sorter"
                      onClick={() => sortOrganizations("emissionResource")}
                    >
                      Source of Emission{" "}
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"emissionResource"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>

                    <th
                      className="table-sorter text-center pe-3"
                      colSpan={2}
                      onClick={() => sortOrganizations("emissionFactor")}
                    >
                      Emissions Factor{" "}
                      <small className="text-nowrap">
                        (tCO<sub>2</sub>e)/unit
                      </small>
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"emissionFactor"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                    <th
                      className="text-nowrap table-sorter"
                      onClick={() => sortOrganizations("unit")}
                    >
                      Dataset{" "}
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"unit"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                    <th>Other Metadata </th>
                  </tr>
                </thead>
                <tbody>
                  {emissionFactors &&
                    emissionFactors
                      ?.filter((ef) => !ef.reference.includes("IEA"))
                      .map((ef) => {
                        return (
                          <tr key={ef.id}>
                            <td>{ef.activityTypes[0]?.title}</td>
                            <td>{ef.emissionResource}</td>
                            <td className="text-center" colSpan={2}>
                              {Intl.NumberFormat("en-us", {
                                maximumFractionDigits: 10,
                                minimumFractionDigits: 2,
                              }).format(ef.emissionFactor)}
                              <br />
                              <small className="text-nowrap">
                                tCO<sub>2</sub>e/{ef.unit ? ef.unit : "unit"}
                              </small>
                            </td>

                            <td>{ef.reference}</td>
                            <td>
                              {ef.metadata.length >= 1
                                ? ef.metadata.map((data) => (
                                    <>
                                      <small className="bg-light text-nowrap border px-1 me-2">
                                        {data.label}: {data.value}
                                      </small>
                                      <br />
                                    </>
                                  ))
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmissionFactors;
