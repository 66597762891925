import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { groupBy } from "lodash";

import {
  Page,
  Document,
  View,
  Text,
  Image,
  Link,
  pdf,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";

import { get } from "utils/BeeApi";
import { get as getDE } from "utils/DeApi";
import { getFormattedEmission } from "utils/StringUtils";

import ExportContent from "components/ExportContent/ExportContent";
import ExportStyles from "./ExportStyles";
import { getMergedSTBi } from "../OrganizationDetail/Forecasting/forecastingModels";
import {
  formatNumber,
  formatPercentage,
} from "../OrganizationDetail/Forecasting/helper";
import {
  locationsMap,
  projectStatusMap,
  projectTypesMap,
} from "../OrganizationDetail/Abatement/AbatementProjects/constants";
import { Dropdown, Spinner } from "react-bootstrap";
import { upload } from "utils/DeApi";
import { UserContext } from "contexts/UserProvider";

const styles = ExportStyles;

const OrganizationExport = ({
  organization,
  topDownChart,
  topDownSummary,
  bottomUpChart,
  emissionByScopeChart,
  bottomUpSummary,
  carbonIntensityChart,
  carbonIntensitySummary,
  forecastingChart,
  sbtiOverviewChart,
  abatementChart,
  roadmapChart,
  totalRoadmapEmissions,
  totalCarbonSaving,
  projects,
  group,
  status,
  site,
  abatementPotential,
  targetEmissions,
  scopeOne,
  scopeTwo,
  sites,
  scopeTwoPreference,
  setShowSummaryDropdown,
}) => {
  const subscribedPromises = useRef([]);

  // const [topDownContent, setTopDownContent] = useState();
  // const [bottomUpContent, setBottomUpContent] = useState();
  const [emissionScope, setEmissionScope] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingError, setGeneratingError] = useState();
  const [selectedMethod, setSelectedMethod] = useState("");
  const [error, setError] = useState();
  const { organizationId } = useParams();
  const user = useContext(UserContext);

  // useEffect(() => {
  //   const promises = subscribedPromises.current;
  //   setIsLoading(true);
  //   setError();

  //   const getTopDownContent = get(`contents/625dc3afd73c0`);
  //   const getBottomUpContent = get(`contents/6268006b31a3a`);

  //   Promise.all([getTopDownContent.promise, getBottomUpContent.promise])
  //     .then((responses) => {
  //       setTopDownContent(responses[0].data);
  //       setBottomUpContent(responses[1].data);
  //       setIsLoading(false);
  //       setError(null);
  //     })
  //     .catch((error) => {
  //       if (!error.isCanceled) {
  //         setError(error);
  //         setIsLoading(false);
  //       }
  //     });

  //   subscribedPromises.current.push(getTopDownContent, getBottomUpContent);

  //   return () => {
  //     promises.forEach((promise) => promise.cancel());
  //   };
  // }, []);

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const organizationScopePromise = getDE(
        `organizations/${organizationId}/emissions-sum-by-scope`,
        {
          params: {
            "filter[preference]": scopeTwoPreference,
          },
        }
      );

      organizationScopePromise.promise
        .then((response) => {
          setIsLoading(false);
          setEmissionScope(response?.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationScopePromise);
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId, scopeOne, scopeTwo]);

  useEffect(() => {
    if (isGenerating) {
      generateIt();
    }
  }, [isGenerating]);

  if (error) {
    console.warn(error);
    return <span />;
  }

  async function generateIt() {
    try {
      if (selectedMethod === "pdf") {
        await generatePDF(
          organization,
          topDownSummary,
          topDownChart,
          sites,
          bottomUpSummary,
          bottomUpChart,
          emissionByScopeChart,
          carbonIntensitySummary,
          carbonIntensityChart,
          forecastingChart,
          sbtiOverviewChart,
          abatementChart,
          roadmapChart,
          totalRoadmapEmissions,
          totalCarbonSaving,
          projects,
          group,
          status,
          site,
          abatementPotential,
          targetEmissions,
          scopeOne,
          scopeTwo,
          scopeTwoPreference,
          emissionScope
        );
        setIsGenerating(false);
        setSelectedMethod("");
        setShowSummaryDropdown(false);
        setGeneratingError();
      }
      if (selectedMethod === "word") {
        await generateWord(
          organization,
          topDownSummary,
          topDownChart,
          sites,
          bottomUpSummary,
          bottomUpChart,
          emissionByScopeChart,
          carbonIntensitySummary,
          carbonIntensityChart,
          forecastingChart,
          sbtiOverviewChart,
          abatementChart,
          roadmapChart,
          totalRoadmapEmissions,
          totalCarbonSaving,
          projects,
          group,
          status,
          site,
          abatementPotential,
          targetEmissions,
          scopeOne,
          scopeTwo,
          scopeTwoPreference,
          emissionScope,
          setIsGenerating,
          setSelectedMethod,
          setShowSummaryDropdown,
          setGeneratingError
        );
      }
    } catch (error) {
      setIsGenerating(false);
      setSelectedMethod("");
      console.error("PDF generation error:", error);
    }
  }

  const handleGeneratePDF = async () => {
    setIsGenerating(true);
    setSelectedMethod("pdf");
  };

  const handleGenerateWord = async () => {
    setIsGenerating(true);
    setSelectedMethod("word");
  };
  // if (!topDownContent || !bottomUpContent) return <span />;

  return (
    <>
      <Dropdown.Item
        key={"pdf"}
        onClick={handleGeneratePDF}
        disabled={isGenerating}
        className="d-flex align-items-center"
      >
        PDF
        {isGenerating && selectedMethod === "pdf" && (
          <Spinner animation="border" size="sm" className="ms-1" />
        )}
      </Dropdown.Item>
      <Dropdown.Item
        key={"word"}
        onClick={handleGenerateWord}
        disabled={isGenerating}
        className="d-flex align-items-center"
      >
        <span>Word</span>
        {isGenerating && selectedMethod === "word" && (
          <Spinner animation="border" size="sm" className="ms-1" />
        )}
        {!isGenerating && selectedMethod !== "pdf" && generatingError && (
          <span className="material-icons-outlined md-16 ms-1 pt-1 text-danger">
            error
          </span>
        )}
      </Dropdown.Item>
    </>
  );
};

const generatePDF = async (
  organization,
  topDownSummary,
  topDownChart,
  sites,
  bottomUpSummary,
  bottomUpChart,
  emissionByScopeChart,
  carbonIntensitySummary,
  carbonIntensityChart,
  forecastingChart,
  sbtiOverviewChart,
  abatementChart,
  roadmapChart,
  totalRoadmapEmissions,
  totalCarbonSaving,
  projects,
  group,
  status,
  site,
  abatementPotential,
  targetEmissions,
  scopeOne,
  scopeTwo,
  scopeTwoPreference,
  emissionScope
) => {
  const blob = await pdf(
    <OrganizationDocument
      organization={organization}
      topDownSummary={topDownSummary}
      topDownChart={topDownChart}
      sites={sites}
      bottomUpSummary={bottomUpSummary}
      bottomUpChart={bottomUpChart}
      emissionByScopeChart={emissionByScopeChart}
      carbonIntensitySummary={carbonIntensitySummary}
      carbonIntensityChart={carbonIntensityChart}
      forecastingChart={forecastingChart}
      sbtiOverviewChart={sbtiOverviewChart}
      abatementChart={abatementChart}
      roadmapChart={roadmapChart}
      totalRoadmapEmissions={totalRoadmapEmissions}
      totalCarbonSaving={totalCarbonSaving}
      projects={projects}
      group={group}
      status={status}
      site={site}
      abatementPotential={abatementPotential}
      targetEmissions={targetEmissions}
      scopeOne={scopeOne}
      scopeTwo={scopeTwo}
      scopeTwoPreference={scopeTwoPreference}
      emissionScope={emissionScope}
    />
  ).toBlob();
  const downloadLink = document.createElement("a");
  const url = URL.createObjectURL(blob);

  downloadLink.href = url;
  downloadLink.download = `${organization.name}_NZC.pdf`;

  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);

  URL.revokeObjectURL(url);
};

const generateWord = async (
  organization,
  topDownSummary,
  topDownChart,
  sites,
  bottomUpSummary,
  bottomUpChart,
  emissionByScopeChart,
  carbonIntensitySummary,
  carbonIntensityChart,
  forecastingChart,
  sbtiOverviewChart,
  abatementChart,
  roadmapChart,
  totalRoadmapEmissions,
  totalCarbonSaving,
  projects,
  group,
  status,
  site,
  abatementPotential,
  targetEmissions,
  scopeOne,
  scopeTwo,
  scopeTwoPreference,
  emissionScope,
  setIsGenerating,
  setSelectedMethod,
  setShowSummaryDropdown,
  setGeneratingError
) => {
  const pdfBlob = await pdf(
    <OrganizationDocument
      organization={organization}
      topDownSummary={topDownSummary}
      topDownChart={topDownChart}
      sites={sites}
      bottomUpSummary={bottomUpSummary}
      bottomUpChart={bottomUpChart}
      emissionByScopeChart={emissionByScopeChart}
      carbonIntensitySummary={carbonIntensitySummary}
      carbonIntensityChart={carbonIntensityChart}
      forecastingChart={forecastingChart}
      sbtiOverviewChart={sbtiOverviewChart}
      abatementChart={abatementChart}
      roadmapChart={roadmapChart}
      totalRoadmapEmissions={totalRoadmapEmissions}
      totalCarbonSaving={totalCarbonSaving}
      projects={projects}
      group={group}
      status={status}
      site={site}
      abatementPotential={abatementPotential}
      targetEmissions={targetEmissions}
      scopeOne={scopeOne}
      scopeTwo={scopeTwo}
      scopeTwoPreference={scopeTwoPreference}
      emissionScope={emissionScope}
    />
  ).toBlob();
  if (pdfBlob) {
    const formData = new FormData();
    formData.append("pdf", pdfBlob);
    formData.append(
      "organization",
      `${organization?.name?.replace(/ /g, "_")}_NZC`
    );
    const wordPromise = upload(`/files/pdf-to-word`, formData);

    wordPromise.promise
      .then((response) => {
        const link = document.createElement("a");
        link.href = decodeURIComponent(response?.presigned_output_url);

        document.body.appendChild(link);

        link.click();
        setIsGenerating(false);
        setSelectedMethod("");
        setShowSummaryDropdown(false);
        setGeneratingError();
      })
      .catch((error) => {
        setIsGenerating(false);
        setSelectedMethod("");
        setGeneratingError(error);
        console.warn(error);
      });
  }
};

function generateHeading(options) {
  const parts = [];

  if (options.topDown) parts.push("Top-Down Estimation");
  if (options.bottomUp) parts.push("Bottom-Up GHG Inventory");
  if (options.carbonIntensity) parts.push("Carbon Intensity");
  if (options.forecasting) parts.push("Forecasting Overview");
  if (options.sbti) parts.push("SBTi Overview");
  if (options.abatement) parts.push("Abatement Planning");
  if (options.roadmap) parts.push("Roadmap");

  if (parts.length === 0) {
    return "Summary Report";
  } else if (parts.length === 1) {
    return `Summary Report of ${parts[0]}`;
  } else {
    const lastPart = parts.pop();
    return `Summary Report of ${parts.join(", ")} and ${lastPart}`;
  }
}

//  Document Component
const OrganizationDocument = ({
  organization,

  topDownSummary,
  // topDownContent,
  topDownChart,

  bottomUpSummary,
  // bottomUpContent,
  bottomUpChart,
  emissionByScopeChart,

  carbonIntensitySummary,
  carbonIntensityChart,

  forecastingChart,

  sbtiOverviewChart,

  roadmapChart,
  totalRoadmapEmissions,
  totalCarbonSaving,

  abatementChart,
  projects,
  status,
  group,
  site,
  abatementPotential,

  targetEmissions,
  scopeOne,
  scopeTwo,
  sites,
  scopeTwoPreference,
  emissionScope,
}) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.body}>
        <View style={styles.heading}>
          <View style={styles.container}>
            <View>
              <View style={styles.column}>
                <Image style={styles.logoImage} src="/logo-green.png" />
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.title}>{organization.name}</Text>

            <Text style={styles.subtitle}>
              {generateHeading(organization?.featureVisibility)}
            </Text>
            <Text style={styles.paragraph}>
              {new Date().toLocaleDateString(undefined, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Text>
          </View>
        </View>

        {topDownSummary?.length && organization?.featureVisibility?.topDown && (
          <View style={styles.section}>
            <Text style={styles.sectionHeading}>Top-Down Estimation</Text>
            {/* <View>
              <ExportContent content={topDownContent} styles={styles} />
            </View> */}
            <View style={styles.contentSection}>
              <Text style={styles.staticPara}>
                It is a screening approach that estimates emissions based on
                industry average data and is accepted by both the GHG Protocol
                and SBTi for Scope 3 emissions reporting. The top-down estimate
                can be calculated using consolidated industry sources and
                studies, for example the Environmentally Extended Input Output
                (EEIO) emission factors (e.g. USEPA, EC, DEFRA, Exiobase or
                Ecoinvent), or from peer benchmarking using data from CDP and
                other GHG reporting initiatives.
              </Text>
            </View>

            {!!topDownSummary?.length && (
              <View style={styles.chartSection} wrap={false}>
                <Text style={styles.chartTitle}>
                  Year over Year Top-Down Emissions Estimates
                </Text>
                {topDownChart && (
                  <Image src={topDownChart} style={styles.chart}></Image>
                )}
              </View>
            )}
            <View style={styles.tableSection}>
              <Text style={styles.tableTitle}>
                Top-Down Emissions Estimates{" "}
              </Text>
              <TopDownSummary topDownSummary={topDownSummary} />
            </View>
          </View>
        )}
        {!!bottomUpSummary?.length &&
          organization?.featureVisibility?.bottomUp && (
            <View style={styles.section}>
              <Text style={styles.sectionHeading}>Bottom-Up GHG Inventory</Text>
              {/* <View>
              <ExportContent content={bottomUpContent} styles={styles} />
            </View> */}
              <View style={styles.contentSection}>
                <Text style={styles.staticPara}>
                  The bottom-up approach requires calculating emissions
                  associated with each individual GHG emitting activity based on
                  relevant activity data (e.g., energy consumption, quantity of
                  goods purchased, direct emissions, distance travelled)
                  collected at the company level. The activity data is
                  translated into GHG emissions using emissions factors from
                  relevant reliable sources.
                </Text>
              </View>

              {/* <View style={styles.chartSection} wrap={false}>
            <Text style={styles.chartTitle}>
              Comparison of Bottom-Up Emissions Estimates by Year and Site
            </Text>
            <Image src={bottomUpChart} style={styles.chart}></Image>
          </View> */}

              <View style={styles.tableSection}>
                <Text style={styles.tableTitle}>
                  Bottom-Up Emissions by Scope and by Year{" "}
                  {!scopeTwoPreference ? "(Location-Based)" : "(Market-Based)"}
                </Text>

                <BottomUpSummaryByScope bottomUpSummary={bottomUpSummary} />
              </View>

              <View style={styles.tableSection}>
                <Text style={styles.tableTitle}>
                  Bottom-Up Emissions by Site and by Year{" "}
                  {!scopeTwoPreference ? "(Location-Based)" : "(Market-Based)"}
                </Text>

                <BottomUpSummary
                  bottomUpSummary={bottomUpSummary}
                  sites={sites}
                />
              </View>
            </View>
          )}

        {!!bottomUpSummary?.length &&
          organization?.featureVisibility?.bottomUp && (
            <View style={styles.section} break>
              <Text style={styles.chartHeading}>
                Most recent GHG Inventory: Year {emissionScope?.[0]?.year}
                {!scopeTwoPreference ? " (Location-Based)" : " (Market-Based)"}
              </Text>
              <View style={styles.scopeHeadSection}>
                <View style={styles.financedCol}>
                  <Text style={styles.scopeNumber}>
                    {!!emissionScope?.length
                      ? getFormattedEmission(
                          Number(emissionScope?.[0]?.scopeOneEmissions) +
                            Number(emissionScope?.[0]?.scopeTwoEmissions)
                        )
                      : 0}{" "}
                    tCO
                    <Text style={styles.subscript}>2</Text>e
                  </Text>
                  <Text style={styles.scopeHeading}>
                    Scope 1&2 (operational)
                  </Text>
                </View>
                <View style={styles.valueCol}>
                  <Text style={styles.scopeThree}>
                    {!!emissionScope?.length
                      ? getFormattedEmission(
                          Number(emissionScope?.[0]?.scopeThreeEmissions)
                        )
                      : 0}{" "}
                    tCO
                    <Text style={styles.subscript}>2</Text>e
                  </Text>
                  <Text style={styles.scopeHeading}>Scope 3 (value chain)</Text>
                </View>
              </View>
              <View style={styles.chartSection} wrap={false}>
                <Text style={styles.chartTitle}>
                  Year over Year Emissions by Scope{" "}
                  {!scopeTwoPreference ? "(Location-Based)" : "(Market-Based)"}
                </Text>
                {emissionByScopeChart && (
                  <Image
                    src={emissionByScopeChart}
                    style={styles.chart}
                  ></Image>
                )}
              </View>
              <View style={styles.chartSection} wrap={false}>
                <Text style={styles.chartTitle}>
                  Year over Year Emissions by Site{" "}
                  {!scopeTwoPreference ? "(Location-Based)" : "(Market-Based)"}
                </Text>
                {bottomUpChart && (
                  <Image src={bottomUpChart} style={styles.chart}></Image>
                )}
              </View>
            </View>
          )}

        {!!carbonIntensitySummary?.length &&
          organization?.featureVisibility?.carbonIntensity && (
            <View
              style={styles.carbonSection}
              break={
                !!bottomUpSummary?.length &&
                organization?.featureVisibility?.bottomUp
              }
            >
              <Text style={styles.chartHeading}>Carbon Intensity</Text>
              <View style={styles.chartSection} wrap={false}>
                {carbonIntensityChart && (
                  <Image
                    src={carbonIntensityChart}
                    style={styles.carbonChart}
                  ></Image>
                )}
              </View>
            </View>
          )}

        {!!carbonIntensitySummary?.length &&
          organization?.featureVisibility?.carbonIntensity && (
            <View style={styles.tableSection}>
              <CarbonIntensitySummary
                carbonIntensitySummary={carbonIntensitySummary}
              />
            </View>
          )}

        {targetEmissions &&
          !!Object.keys(targetEmissions)?.length &&
          (organization?.featureVisibility?.forecasting ||
            organization?.featureVisibility?.sbti) && (
            <View style={styles.section} break>
              <Text style={styles.chartHeading}>
                Forecasting and SBTi Targets{" "}
              </Text>
              {organization?.featureVisibility?.forecasting && (
                <View style={styles.chartSection} wrap={false}>
                  <Text style={styles.chartTitle}>Forecasting Overview</Text>
                  {forecastingChart && (
                    <Image src={forecastingChart} style={styles.chart}></Image>
                  )}
                </View>
              )}
              {organization?.featureVisibility?.sbti && (
                <View style={styles.chartSection} wrap={false}>
                  <Text style={styles.chartTitle}>SBTi Overview</Text>
                  {sbtiOverviewChart && (
                    <Image src={sbtiOverviewChart} style={styles.chart}></Image>
                  )}
                </View>
              )}
            </View>
          )}

        {abatementPotential && organization?.featureVisibility?.abatement && (
          <View style={styles.section} break>
            <Text style={styles.chartHeading}>Abatement Planning</Text>
            <View style={styles.totalSection}>
              <Text style={styles.totalHeading}>Abatement Potential*</Text>
              <Text style={styles.totalNumber}>
                {formatNumber(totalCarbonSaving)} tCO
                <Text style={styles.subscript}>2</Text>e/year
              </Text>
            </View>
            <View style={styles.chartSection} wrap={false}>
              <Text style={styles.chartTitle}>
                Marginal Abatement Cost Curve
              </Text>
              {abatementChart && (
                <Image
                  src={abatementChart}
                  style={styles.abatementChart}
                ></Image>
              )}
              <Text style={styles.chartTitle}>
                * Excluding non-committed and deferred projects
              </Text>
            </View>
          </View>
        )}

        {totalCarbonSaving !== undefined &&
          totalRoadmapEmissions > 0 &&
          organization?.featureVisibility?.roadmap && (
            <View style={styles.newSection} break={!abatementPotential}>
              <Text style={styles.chartHeading}>Roadmap</Text>
              <View style={styles.chartSection} wrap={false}>
                {roadmapChart && (
                  <Image
                    src={roadmapChart}
                    style={styles.forecastingChart}
                  ></Image>
                )}
              </View>
            </View>
          )}

        {!!projects?.length && organization?.featureVisibility?.abatement && (
          <View style={styles.tableSection} break>
            <Text style={styles.tableTitle}>Projects</Text>
            <AbatementSummary
              projects={projects}
              status={status}
              group={group}
              site={site}
            />
          </View>
        )}

        <Text
          style={styles.timeStamp}
          render={() => {
            var timeStamp = new Date();
            return `Prepared on: ${timeStamp.toDateString()}`;
          }}
          fixed
        />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <View>
          <Text style={styles.disclaimerHeading}>
            ERM Helpdesk:{" "}
            <Text>
              <Link
                src={"mailto:support.nzc@erm.com"}
                style={styles.disclaimerLink}
              >
                <Text>support.nzc@erm.com</Text>
              </Link>
            </Text>
          </Text>
          <Text style={styles.disclaimerHeading}>Disclaimer and IP</Text>
          <Text style={styles.disclaimerText}>
            The methods contained within the Net Zero Compass software product
            are protected and owned by ERM Information Solutions, Inc. No
            parties may copy, reproduce or otherwise transit the data or methods
            contained in this platform without prior written consent of ERM. ERM
            is not responsible for how this platform is used by clients or any
            third parties and we disclaim any responsibility for reliance on the
            results which depend on user inputs. We recommend that users perform
            quality assurance of the results before use in any reporting or to
            support any Net Zero investment decisions.
          </Text>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>
            Copyright © {new Date().getFullYear()} ERM
          </Text>
          <Text style={styles.footerText}>
            <Link src={"https://www.netzerocompass.com"} style={styles.link}>
              <Text>https://www.netzerocompass.com</Text>
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

//  Top Down Summary
const TopDownSummary = ({ topDownSummary }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>Reporting Year</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>Revenue</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>Location</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>Industry</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>
            Emissions (tCO<Text style={styles.subscript}>2</Text>e)
          </Text>
        </View>
      </View>
      {topDownSummary.map((item, index) => {
        return (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.yearEnded.substring(0, 4)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(item.revenue)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {
                  item.emissionFactor?.metadata?.find(
                    (md) => md.type === "country"
                  ).value
                }
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {item.emissionFactor?.emissionResource}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {getFormattedEmission(item.organizationEmissions)}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

//  Bottom Up Summary
const BottomUpSummary = ({ bottomUpSummary, sites }) => {
  const emissionsYears = Object.keys(
    groupBy(bottomUpSummary, "yearEnded")
  ).slice(-5);

  const data = sites.map((site) => {
    let siteTableData = { ...site };
    emissionsYears.forEach((year) => {
      let yearEmissions = bottomUpSummary.find(
        (item) =>
          String(item.yearEnded) === String(year) && item.siteId === site.id
      );

      siteTableData[year] = yearEmissions?.emissions;
    });
    return siteTableData;
  });
  data.sort((a, b) => (a["updatedAt"] <= b["updatedAt"] ? 1 : -1));
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>Site</Text>
        </View>
        {emissionsYears.map((item) => {
          return (
            <View style={styles.tableCol} key={item}>
              <Text style={styles.tableCellHeaderRight}>{item}</Text>
            </View>
          );
        })}
      </View>
      {data.map((item, index) => {
        return (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text
                style={
                  /\p{Script=Han}/u.test(item?.name)
                    ? styles.tableCellC
                    : styles.tableCell
                }
              >
                {item.name?.length > 12 ? (
                  <Text>
                    {item?.name?.slice(0, 12)}
                    <View></View>
                    {item?.name?.slice(12)}
                  </Text>
                ) : (
                  item?.name
                )}
              </Text>
            </View>
            {emissionsYears.map((year) => {
              return (
                <View style={styles.tableCol} key={year}>
                  <Text style={styles.tableCellRight}>
                    {item[year] !== undefined
                      ? getFormattedEmission(item[year])
                      : "n/a"}
                  </Text>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

const BottomUpSummaryByScope = ({ bottomUpSummary }) => {
  const emissionsByYear = bottomUpSummary.reduce((acc, item) => {
    const year = item.yearEnded;
    if (!acc[year]) {
      acc[year] = {
        scopeOneEmissions: 0,
        scopeTwoEmissions: 0,
        scopeThreeEmissions: 0,
      };
    }
    acc[year].scopeOneEmissions += item.scopeOneEmissions || 0;
    acc[year].scopeTwoEmissions += item.scopeTwoEmissions || 0;
    acc[year].scopeThreeEmissions += item.scopeThreeEmissions || 0;
    return acc;
  }, {});

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>Year</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>Scope 1</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>Scope 2</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeaderRight}>Scope 3</Text>
        </View>
      </View>
      {Object.entries(emissionsByYear).map((item, index) => {
        return (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item[0]}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {item[1].scopeOneEmissions
                  ? getFormattedEmission(item[1].scopeOneEmissions)
                  : "n/a"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {item[1].scopeTwoEmissions
                  ? getFormattedEmission(item[1].scopeTwoEmissions)
                  : "n/a"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellRight}>
                {item[1].scopeThreeEmissions
                  ? getFormattedEmission(item[1].scopeThreeEmissions)
                  : "n/a"}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const CarbonIntensitySummary = ({ carbonIntensitySummary }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}></View>
        {carbonIntensitySummary?.map((item) => (
          <View style={styles.tableCol} key={item.yearEnded}>
            <Text style={styles.tableCellHeaderRight}>
              {item.yearEnded.substring(0, 4)}
            </Text>
          </View>
        ))}
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Revenue (MM USD)</Text>
        </View>
        {carbonIntensitySummary.map((item) => (
          <View style={styles.tableCol} key={item.yearEnded}>
            <Text style={styles.tableCellRight}>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.revenue / 1000000)}
              MM
            </Text>
          </View>
        ))}
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            Emissions (tCO<Text style={styles.subscriptTable}>2</Text>e)
          </Text>
        </View>
        {carbonIntensitySummary.map((item) => (
          <View style={styles.tableCol} key={item.yearEnded}>
            <Text style={styles.tableCellRight}>
              {getFormattedEmission(item.emissions)}{" "}
            </Text>
          </View>
        ))}
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>
            Emissions to Revenue Intensity (tCO
            <Text style={styles.subscriptTable}>2</Text>e/$MM)
          </Text>
        </View>
        {carbonIntensitySummary.map((item) => (
          <View style={styles.tableCol} key={item.yearEnded}>
            <Text style={styles.tableCellRight}>
              {Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(item.carbonIntensity)}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

const SBTISummary = ({ emission, scopeOne, scopeTwo }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.boldTextCell}>Year</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.boldTextCell}>WB2C - % reduction per annum</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.boldTextCell}>
            (tCO
            <Text style={styles.subscript}>2</Text>e/annum saving)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.boldTextCell}>
            WB2C SBTi Target Scope 1+2 (w/ constrain)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.boldTextCell}>1.5C - % reduction per annum</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.boldTextCell}>
            (tCO
            <Text style={styles.subscript}>2</Text>e/annum saving)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.boldTextCell}>
            1.5C SBTi Target Scope 1+2 (w/ constrain)
          </Text>
        </View>
      </View>
      {emission?.data &&
        getMergedSTBi(emission, scopeOne, scopeTwo).map(
          (
            {
              year,
              percentageReduction25,
              emissionReduction25,
              limitConstrainedReductionEmission25,

              percentageReduction,
              emissionReduction,
              limitConstrainedReductionEmission,
            },
            index
          ) => (
            <View style={styles.tableRow} key={year}>
              <View style={styles.tableCol}>
                <Text style={styles.boldTextCell}>{year}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatPercentage(percentageReduction25)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatNumber(emissionReduction25)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatNumber(limitConstrainedReductionEmission25)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatPercentage(percentageReduction)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatNumber(emissionReduction)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatNumber(limitConstrainedReductionEmission)}
                </Text>
              </View>
            </View>
          )
        )}
    </View>
  );
};

const AbatementSummary = ({ projects, status, group, site }) => {
  const wrapText = (text, maxLineLength) => {
    const sections = text.split(/(,|%)/);
    let lines = [""];
    let currentLineIndex = 0;

    sections.forEach((section) => {
      const currentLine = lines[currentLineIndex];

      if ((currentLine + section).length <= maxLineLength) {
        lines[currentLineIndex] += section;
      } else {
        lines.push(section);
        currentLineIndex++;
      }
    });

    return lines;
  };
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Project</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Status</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Type</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Location</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Project Lifetime (Yrs)</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Payback Period (Yrs)</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Internal Rate of Return</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Net Present Value</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>
            Abatement Potential (tCO
            <Text style={styles.subscript}>2</Text>
            e/year)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>
            MAC ($ per tCO
            <Text style={styles.subscript}>2</Text>)
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>Initial Investment</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCellBold}>
            Annual Emissions Reduction
          </Text>
        </View>
      </View>
      {projects
        .filter((project) => {
          if (!group?.length) return true;
          if (group === "reset") return true;
          return group?.filter((val) => project?.group?.id === val?.id)?.length;
        })
        .filter((project) => {
          if (!status?.length) return true;
          if (status === "reset") return true;
          return status?.filter(
            (val) => parseInt(project?.projectStatus) === parseInt(val?.key)
          ).length;
        })
        .filter((project) => {
          if (!site?.length) return true;
          if (site === "reset") return true;
          return site?.filter((val) => project?.site?.id === val?.id)?.length;
        })
        .map((project) => (
          <View style={styles.tableRow} key={project?.id}>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCellBold}>{project?.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {
                  projectStatusMap.find(
                    ({ key }) =>
                      parseInt(key) === parseInt(project?.projectStatus)
                  )?.value
                }
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {
                  projectTypesMap.find(
                    ({ key }) =>
                      parseInt(key) === parseInt(project?.projectType)
                  )?.value
                }
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {isNaN(Number(project?.location))
                  ? project?.location
                  : locationsMap.find(
                      ({ key }) => parseInt(key) === parseInt(project?.location)
                    )?.value}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {wrapText(project?.projectLifetime?.toString(), 5)?.map(
                  (line, index) => (
                    <Text key={index}>{line}</Text>
                  )
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {wrapText(project?.paybackPeriod?.toString(), 5)?.map(
                  (line, index) => (
                    <Text key={index}>{line}</Text>
                  )
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {!isNaN(Number(project?.internalRateOfReturn))
                  ? wrapText(
                      new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 3,
                        style: "percent",
                      }).format(project?.internalRateOfReturn / 100),
                      5
                    )?.map((line, index) => <Text key={index}>{line}</Text>)
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {wrapText(
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(project?.netPresentValue),
                  5
                )?.map((line, index) => (
                  <Text key={index}>{line}</Text>
                ))}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {wrapText(
                  new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 3,
                  }).format(project?.lifetimeCarbonEmissionReduction),
                  5
                )?.map((line, index) => (
                  <Text key={index}>{line}</Text>
                ))}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {wrapText(
                  new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 3,
                  }).format(project?.marginalAbatementCost),
                  5
                )?.map((line, index) => (
                  <Text key={index}>{line}</Text>
                ))}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {wrapText(
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(project?.initialInvestment),
                  5
                )?.map((line, index) => (
                  <Text key={index}>{line}</Text>
                ))}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableSmallCell}>
                {wrapText(
                  new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 3,
                  }).format(project?.annualCarbonEmissionReduction),
                  5
                )?.map((line, index) => (
                  <Text key={index}>{line}</Text>
                ))}
              </Text>
            </View>
          </View>
        ))}
      <View style={styles.tableRowTotal}>
        <View style={styles.tableColC}>
          <Text style={styles.tableSmallCellBold}>Total</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCell}>
            {wrapText(
              Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "USD",
              }).format(
                projects
                  ?.filter((project) => {
                    if (!group?.length) return true;
                    if (group === "reset") return true;
                    return group?.filter(
                      (val) => project?.group?.id === val?.id
                    )?.length;
                  })
                  .filter((project) => {
                    if (!status?.length) return true;
                    if (status === "reset") return true;
                    return status?.filter(
                      (val) =>
                        parseInt(project?.projectStatus) === parseInt(val?.key)
                    )?.length;
                  })
                  .filter((project) => {
                    if (!site?.length) return true;
                    if (site === "reset") return true;
                    return site?.filter((val) => project?.site?.id === val?.id)
                      ?.length;
                  })
                  .reduce((acc, curr) => {
                    return acc + curr?.initialInvestment;
                  }, 0)
              ),
              5
            )?.map((line, index) => (
              <Text key={index}>{line}</Text>
            ))}
          </Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableSmallCell}>
            {wrapText(
              Intl.NumberFormat("en-us", {
                maximumFractionDigits: 3,
              }).format(
                projects
                  ?.filter((project) => {
                    if (!group?.length) return true;
                    if (group === "reset") return true;
                    return group?.filter(
                      (val) => project?.group?.id === val?.id
                    )?.length;
                  })
                  .filter((project) => {
                    if (!status?.length) return true;
                    if (status === "reset") return true;
                    return status?.filter(
                      (val) =>
                        parseInt(project?.projectStatus) === parseInt(val?.key)
                    )?.length;
                  })
                  .filter((project) => {
                    if (!site?.length) return true;
                    if (site === "reset") return true;
                    return site?.filter((val) => project?.site?.id === val?.id)
                      ?.length;
                  })
                  .reduce((acc, curr) => {
                    return acc + curr?.annualCarbonEmissionReduction;
                  }, 0)
              ),
              5
            )?.map((line, index) => (
              <Text key={index}>{line}</Text>
            ))}
          </Text>
        </View>
      </View>
    </View>
  );
};

OrganizationExport.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default OrganizationExport;
