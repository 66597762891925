import { useRef, useState, useEffect } from "react";
import {
  Alert,
  Button,
  Form,
  Offcanvas,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  locationsMap,
  projectCombinedStatusMap,
  projectDataConfidenceMap,
  projectTypesMap,
} from "../../AbatementProjects/constants";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import * as yup from "yup";
import { Formik } from "formik";
import "./ScenarioCreate.scss";
import { post } from "utils/DeApi";
import { TableSortArrows } from "components/Shared/TableComponents";

const NumberColumnFilter = ({
  minValue,
  maxValue,
  onMinChange,
  onMaxChange,
  minControlId,
  maxControlId,
}) => {
  return (
    <div className="d-flex justify-content-between">
      <Form.Group controlId={minControlId}>
        <Form.Label className="d-flex justify-content-center mb-0">
          Min:
        </Form.Label>
        <Form.Control
          type="number"
          value={minValue}
          onChange={(e) =>
            onMinChange(e.target.value === "" ? "" : e.target.value)
          }
        />
      </Form.Group>
      <Form.Group controlId={maxControlId} className="ms-1">
        <Form.Label className="d-flex justify-content-center mb-0">
          Max:
        </Form.Label>
        <Form.Control
          type="number"
          value={maxValue}
          disabled={!minValue}
          onChange={(e) =>
            onMaxChange(e.target.value === "" ? "" : e.target.value)
          }
        />
      </Form.Group>
    </div>
  );
};

const ScenarioCreate = ({ projects, customFields, onScenarioCreate }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [projectsLocation, setProjectsLocation] = useState([]);
  const [projectsSite, setProjectsSite] = useState([]);
  const [projectsStatus, setProjectsStatus] = useState([]);
  const [projectsType, setProjectsType] = useState([]);
  const [columnFilteredProjects, setColumnFilteredProjects] = useState([]);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [individuallySelectedProjects, setIndividuallySelectedProjects] =
    useState([]);
  const [minInitialInvestment, setMinInitialInvestment] = useState("");
  const [maxInitialInvestment, setMaxInitialInvestment] = useState("");

  const [minAnnualCashflow, setMinAnnualCashflow] = useState("");
  const [maxAnnualCashflow, setMaxAnnualCashflow] = useState("");

  const [minPaybackPeriod, setMinPaybackPeriod] = useState("");
  const [maxPaybackPeriod, setMaxPaybackPeriod] = useState("");

  const [minAnnualEmissionReduction, setMinAnnualEmissionReduction] =
    useState("");
  const [maxAnnualEmissionReduction, setMaxAnnualEmissionReduction] =
    useState("");

  const [minInternalRateReturn, setMinInternalRateReturn] = useState("");
  const [maxInternalRateReturn, setMaxInternalRateReturn] = useState("");

  const [minNetPresentValue, setMinNetPresentValue] = useState("");
  const [maxNetPresentValue, setMaxNetPresentValue] = useState("");

  const [minMarginalAbatementCost, setMinMarginalAbatementCost] = useState("");
  const [maxMarginalAbatementCost, setMaxMarginalAbatementCost] = useState("");

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();
  const initialValues = {
    scenarioName: "",
  };

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "",
    updatedAt: true,
    sortAsc: false,
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setSelectAllCheckbox(false);
    setIndividuallySelectedProjects([]);
    setMinInitialInvestment("");
    setMaxInitialInvestment("");
    setMinAnnualCashflow("");
    setMaxAnnualCashflow("");
    setMinPaybackPeriod("");
    setMaxPaybackPeriod("");
    setMinAnnualEmissionReduction("");
    setMaxAnnualEmissionReduction("");
    setMinInternalRateReturn("");
    setMaxInternalRateReturn("");
    setMinNetPresentValue("");
    setMaxNetPresentValue("");
    setMinMarginalAbatementCost("");
    setMaxMarginalAbatementCost("");
    setSelectedLocation("");
    setSelectedSite("");
    setSelectedStatus("");
    setSelectedType("");
  };

  const schema = yup.object().shape({
    scenarioName: yup.string().required("Scenario Name is a required field!"),
  });

  useEffect(() => {
    setColumnFilteredProjects(projects);
    const allProjectsLocation = projects
      ?.map((project) => project?.location)
      ?.filter((location) => location);
    const uniqueProjectsLocation = allProjectsLocation.filter(
      (location, index, array) => array.indexOf(location) === index
    );
    const allProjectsSite = projects
      ?.map((project) => project?.site?.name)
      ?.filter((site) => site);
    const uniqueProjectsSite = allProjectsSite.filter(
      (site, index, array) => array.indexOf(site) === index
    );
    const allProjectsStatus = projects
      ?.map((project) => project?.projectStatus)
      ?.filter((status) => status);
    const uniqueProjectsStatus = allProjectsStatus.filter(
      (status, index, array) => array.indexOf(status) === index
    );
    const filteredStatusMap = projectCombinedStatusMap.filter((status) =>
      uniqueProjectsStatus.includes(status.key)
    );
    const allProjectsType = projects
      ?.map((project) => project?.decarbActionCategory || project?.projectType)
      ?.filter((type) => type);
    const uniqueProjectsType = allProjectsType.filter((type, index, array) => {
      if (typeof type === "object" && type !== null) {
        const existingIndex = array.findIndex((el) => el?.id === type.id);
        return index === existingIndex;
      } else {
        return array.indexOf(type) === index;
      }
    });
    const filteredTypeMap = uniqueProjectsType?.map((type) =>
      typeof type !== "object"
        ? projectTypesMap?.find((projectType) => projectType?.key === type)
        : type
    );
    setProjectsLocation(uniqueProjectsLocation);
    setProjectsSite(uniqueProjectsSite);
    setProjectsStatus(filteredStatusMap);
    setProjectsType(filteredTypeMap);
  }, [projects]);

  const handleCheckboxChange = (event, projectId) => {
    const isChecked = event.target.checked;
    if (projectId === "selectAll") {
      setSelectAllCheckbox(isChecked);
      if (isChecked) {
        const allProjectIds = columnFilteredProjects?.map(
          (project) => project.id
        );
        setIndividuallySelectedProjects(allProjectIds);
      } else {
        setIndividuallySelectedProjects([]);
      }
    } else {
      if (isChecked) {
        setIndividuallySelectedProjects((prevSelected) => [
          ...prevSelected,
          projectId,
        ]);
      } else {
        setIndividuallySelectedProjects((prevSelected) =>
          prevSelected.filter((id) => id !== projectId)
        );
        setSelectAllCheckbox(false);
      }
    }
    if (isChecked && projectId === "selectAll") {
      const allProjectIds = columnFilteredProjects?.map(
        (project) => project.id
      );
    }
  };

  const filterProjects = () => {
    const filters = [
      (project) => {
        const value = project.initialInvestment;
        return (
          (!minInitialInvestment ||
            value >= parseFloat(minInitialInvestment)) &&
          (!maxInitialInvestment || value <= parseFloat(maxInitialInvestment))
        );
      },
      (project) => {
        const value = project.annualSavings;
        return (
          (!minAnnualCashflow || value >= parseFloat(minAnnualCashflow)) &&
          (!maxAnnualCashflow || value <= parseFloat(maxAnnualCashflow))
        );
      },
      (project) => {
        const value = project.paybackPeriod;
        return (
          (!minPaybackPeriod || value >= parseFloat(minPaybackPeriod)) &&
          (!maxPaybackPeriod || value <= parseFloat(maxPaybackPeriod))
        );
      },
      (project) => {
        const value = project.annualCarbonEmissionReduction;
        return (
          (!minAnnualEmissionReduction ||
            value >= parseFloat(minAnnualEmissionReduction)) &&
          (!maxAnnualEmissionReduction ||
            value <= parseFloat(maxAnnualEmissionReduction))
        );
      },
      (project) => {
        const value = project.internalRateOfReturn;
        return (
          (!minInternalRateReturn ||
            value >= parseFloat(minInternalRateReturn)) &&
          (!maxInternalRateReturn || value <= parseFloat(maxInternalRateReturn))
        );
      },
      (project) => {
        const value = project.netPresentValue;
        return (
          (!minNetPresentValue || value >= parseFloat(minNetPresentValue)) &&
          (!maxNetPresentValue || value <= parseFloat(maxNetPresentValue))
        );
      },
      (project) => {
        const value = project.marginalAbatementCost;
        return (
          (!minMarginalAbatementCost ||
            value >= parseFloat(minMarginalAbatementCost)) &&
          (!maxMarginalAbatementCost ||
            value <= parseFloat(maxMarginalAbatementCost))
        );
      },
      (project) => !selectedLocation || project.location === selectedLocation,
      (project) => !selectedSite || project?.site?.name === selectedSite,
      (project) =>
        !selectedStatus ||
        Number(project.projectStatus) === Number(selectedStatus),
      (project) =>
        !selectedType ||
        (project?.decarbActionCategory?.id
          ? project?.decarbActionCategory?.id === selectedType
          : Number(project.projectType) === Number(selectedType)),
    ];

    const filteredProjects = projects.filter((project) => {
      return filters.every((filter) => filter(project));
    });

    setColumnFilteredProjects(filteredProjects);
  };

  const sortingTable = (column, objValue) => {
    columnFilteredProjects?.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];

      if (
        macA === null ||
        macA === undefined ||
        macA === "n/a" ||
        macA === ""
      ) {
        return tableSortState[column] ? 1 : -1;
      }

      if (
        macB === null ||
        macB === undefined ||
        macB === "n/a" ||
        macB === ""
      ) {
        return tableSortState[column] ? -1 : 1;
      }

      const isDateA = /^\d{2}\/\d{2}\/\d{4}$/.test(macA);
      const isDateB = /^\d{2}\/\d{2}\/\d{4}$/.test(macB);

      if (isDateA && isDateB) {
        const [monthA, dayA, yearA] = macA.split("/");
        const [monthB, dayB, yearB] = macB.split("/");
        macA = new Date(yearA, monthA - 1, dayA);
        macB = new Date(yearB, monthB - 1, dayB);
      }
      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else if (isDateA && isDateB) {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setColumnFilteredProjects([...columnFilteredProjects]);
  };

  const sortCustomFields = (column, fieldName, fieldType) => {
    const a = projects.sort((a, b) => {
      let valueA = a?.customFields?.find(
        (data) => data?.label === fieldName && data?.type === fieldType
      );

      let valueB = b?.customFields?.find(
        (data) => data?.label === fieldName && data?.type === fieldType
      );

      if (
        valueA === undefined ||
        valueA?.value === null ||
        valueA?.value === undefined
      ) {
        return tableSortState[column] ? 1 : -1;
      }

      if (
        valueB === undefined ||
        valueB?.value === null ||
        valueB?.value === undefined
      ) {
        return tableSortState[column] ? -1 : 1;
      }

      if (tableSortState[column]) {
        return valueA.value > valueB.value ? 1 : -1;
      } else {
        return valueA.value < valueB.value ? 1 : -1;
      }
    });

    tableSortState.sortColumn = fieldName;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];
    setTableSortState({ ...tableSortState });
    setColumnFilteredProjects([...a]);
  };

  useEffect(() => {
    filterProjects();
  }, [
    minInitialInvestment,
    maxInitialInvestment,
    minAnnualCashflow,
    maxAnnualCashflow,
    minPaybackPeriod,
    maxPaybackPeriod,
    minAnnualEmissionReduction,
    maxAnnualEmissionReduction,
    minInternalRateReturn,
    maxInternalRateReturn,
    minNetPresentValue,
    maxNetPresentValue,
    minMarginalAbatementCost,
    maxMarginalAbatementCost,
    selectedLocation,
    selectedSite,
    selectedStatus,
    selectedType,
  ]);

  const createScenario = ({ scenarioName }) => {
    setError(null);
    setIsLoading(true);
    const createScenarioPromise = post(
      `organizations/${organizationId}/scenarios`,
      {
        name: scenarioName,
        projects: individuallySelectedProjects,
      }
    );
    createScenarioPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onScenarioCreate();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(createScenarioPromise);
  };

  const handleLocationChange = (value) => {
    setSelectedLocation(value);
  };

  const handleSiteChange = (value) => {
    setSelectedSite(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  return (
    <>
      <Button size="sm" onClick={handleShow} className="float-end">
        Create Scenario
      </Button>
      <Offcanvas
        placement="end"
        show={show}
        onHide={handleClose}
        className="w-75"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Scenario</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <ErrorHandler error={error} />
          ) : (
            <div>
              <Formik
                validationSchema={schema}
                onSubmit={(values) => createScenario(values)}
                enableReinitialize
                initialValues={initialValues}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  isValid,
                  errors,
                  touched,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="scenarioName" className="mb-2">
                      <Form.Label>
                        Scenario Name{" "}
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>{" "}
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          type="text"
                          name="scenarioName"
                          value={values.scenarioName}
                          placeholder="Enter scenario's name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={values.scenarioName}
                          isInvalid={
                            errors.scenarioName && touched.scenarioName
                          }
                        />
                        <Button
                          type="submit"
                          className="mx-1"
                          size="sm"
                          disabled={
                            !values.scenarioName ||
                            !individuallySelectedProjects?.length
                          }
                        >
                          Submit
                        </Button>
                      </div>
                      <Form.Text type="invalid" className="text-danger">
                        {errors.scenarioName}
                      </Form.Text>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
              <div className="projectsTable">
                <Table size="sm" responsive striped id="projects-listing">
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="select-all-checkbox"
                          checked={selectAllCheckbox}
                          onChange={(e) => handleCheckboxChange(e, "selectAll")}
                        />
                      </th>
                      <th
                        className="text-nowrap px-3 mw-50 projectCol table-sorter"
                        onClick={() => {
                          sortingTable("name");
                        }}
                      >
                        Project
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"name"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("group", "name");
                        }}
                      >
                        Abatement Opportunity Grouping
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"group"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th className="text-nowrap px-3">
                        Abatement Opportunity Sub-Group
                        <Form.Select
                          aria-label="type-filter"
                          size="sm"
                          value={selectedType || ""}
                          onChange={(e) => handleTypeChange(e.target.value)}
                          className="dropdown-filter"
                        >
                          <option value="">
                            All Abatement Opportunity Sub-Groups
                          </option>
                          {projectsType?.map((type) => (
                            <option
                              key={type?.key || type?.id}
                              value={type?.key || type?.id}
                            >
                              {type?.value || type?.name}
                            </option>
                          ))}
                        </Form.Select>
                      </th>
                      <th className="text-nowrap px-3">
                        Location
                        <Form.Select
                          aria-label="location-filter"
                          size="sm"
                          value={selectedLocation || ""}
                          onChange={(e) => handleLocationChange(e.target.value)}
                          className="dropdown-filter"
                        >
                          <option value="">All Locations</option>
                          {projectsLocation?.map((location) => (
                            <option key={location} value={location}>
                              {location}
                            </option>
                          ))}
                        </Form.Select>
                      </th>
                      <th className="text-nowrap px-3">
                        Site
                        <Form.Select
                          aria-label="site-filter"
                          size="sm"
                          value={selectedSite || ""}
                          onChange={(e) => handleSiteChange(e.target.value)}
                          className="dropdown-filter"
                        >
                          <option value="">All Sites</option>
                          {projectsSite?.map((site) => (
                            <option key={site} value={site}>
                              {site}
                            </option>
                          ))}
                        </Form.Select>
                      </th>
                      <th className="text-nowrap px-3 ">
                        Status
                        <Form.Select
                          aria-label="status-filter"
                          size="sm"
                          value={selectedStatus || ""}
                          onChange={(e) => handleStatusChange(e.target.value)}
                          className="dropdown-filter"
                        >
                          <option value="">All Status</option>
                          {projectsStatus?.map((status) => (
                            <option key={status?.key} value={status?.key}>
                              {status?.value}
                            </option>
                          ))}
                        </Form.Select>
                      </th>
                      <th className="text-end text-nowrap px-3 ">
                        Initial Investment ($)
                        <NumberColumnFilter
                          minValue={minInitialInvestment}
                          maxValue={maxInitialInvestment}
                          onMinChange={setMinInitialInvestment}
                          onMaxChange={setMaxInitialInvestment}
                          minControlId="minInitialInvestment"
                          maxControlId="maxInitialInvestment"
                        />
                      </th>
                      <th className="text-end text-nowrap px-3 ">
                        Annual Cashflow ($)
                        <NumberColumnFilter
                          minValue={minAnnualCashflow}
                          maxValue={maxAnnualCashflow}
                          onMinChange={setMinAnnualCashflow}
                          onMaxChange={setMaxAnnualCashflow}
                          minControlId="minAnnualCashflow"
                          maxControlId="maxAnnualCashflow"
                        />
                      </th>
                      <th className="text-end text-nowrap px-3">
                        Annual Emissions Reduction
                        <NumberColumnFilter
                          minValue={minAnnualEmissionReduction}
                          maxValue={maxAnnualEmissionReduction}
                          onMinChange={setMinAnnualEmissionReduction}
                          onMaxChange={setMaxAnnualEmissionReduction}
                          minControlId="minAnnualEmissionReduction"
                          maxControlId="maxAnnualEmissionReduction"
                        />
                      </th>
                      <th className="text-end text-nowrap px-3 ">
                        Payback Period <small>(Yrs)</small>
                        <NumberColumnFilter
                          minValue={minPaybackPeriod}
                          maxValue={maxPaybackPeriod}
                          onMinChange={setMinPaybackPeriod}
                          onMaxChange={setMaxPaybackPeriod}
                          minControlId="minPaybackPeriod"
                          maxControlId="maxPaybackPeriod"
                        />
                      </th>
                      <th className="text-end text-nowrap px-3 ">
                        Internal Rate of Return
                        <NumberColumnFilter
                          minValue={minInternalRateReturn}
                          maxValue={maxInternalRateReturn}
                          onMinChange={setMinInternalRateReturn}
                          onMaxChange={setMaxInternalRateReturn}
                          minControlId="minInternalRateReturn"
                          maxControlId="maxInternalRateReturn"
                        />
                      </th>
                      <th className="text-end text-nowrap px-3">
                        Net Present Value
                        <NumberColumnFilter
                          minValue={minNetPresentValue}
                          maxValue={maxNetPresentValue}
                          onMinChange={setMinNetPresentValue}
                          onMaxChange={setMaxNetPresentValue}
                          minControlId="minNetPresentValue"
                          maxControlId="maxNetPresentValue"
                        />
                      </th>
                      <th className="text-end text-nowrap px-3 ">
                        MAC{" "}
                        <small>
                          ($ per tCO<sub>2</sub>e)
                        </small>
                        <NumberColumnFilter
                          minValue={minMarginalAbatementCost}
                          maxValue={maxMarginalAbatementCost}
                          onMinChange={setMinMarginalAbatementCost}
                          onMaxChange={setMaxMarginalAbatementCost}
                          minControlId="minMarginalAbatementCost"
                          maxControlId="maxMarginalAbatementCost"
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("scope");
                        }}
                      >
                        Scope{" "}
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"scope"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("projectLifetime");
                        }}
                      >
                        Project Lifetime <small>(Yrs)</small>
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"projectLifetime"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("dataConfidence");
                        }}
                      >
                        Data Confidence
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"dataConfidence"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("emissionSavingsStartDate");
                        }}
                      >
                        Emission Savings Start Date
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"emissionSavingsStartDate"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("projectSize");
                        }}
                      >
                        Size of the Project (Scaling Unit)
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"projectSize"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("unit");
                        }}
                      >
                        Unit of measurement
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"unit"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("projectMaxSize");
                        }}
                      >
                        Max Size{" "}
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"projectMaxSize"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("autoRenew");
                        }}
                      >
                        Auto Renew Project{" "}
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"autoRenew"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("discountRate");
                        }}
                      >
                        Discount Rate (%)
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"discountRate"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("disposalCost");
                        }}
                      >
                        Disposal Cost ($)
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"disposalCost"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="text-end text-nowrap px-3 table-sorter"
                        onClick={() => {
                          sortingTable("lifetimeCarbonEmissionReduction");
                        }}
                      >
                        Lifetime Emissions Reduction
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"lifetimeCarbonEmissionReduction"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      {customFields?.map((customField, index) => (
                        <th
                          className={`${
                            customField?.type !== "textarea" ? "text-end" : ""
                          } text-nowrap px-3 table-sorter`}
                          key={index}
                          onClick={() => {
                            sortCustomFields(
                              customField.label,
                              customField.label,
                              customField.type
                            );
                          }}
                        >
                          {customField?.label}
                          <TableSortArrows
                            sortColumn={tableSortState.sortColumn}
                            colName={customField.label}
                            sortAsc={tableSortState.sortAsc}
                          />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {columnFilteredProjects?.map((project) => (
                      <tr key={project?.id}>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={
                              selectAllCheckbox ||
                              individuallySelectedProjects.includes(project.id)
                            }
                            onChange={(e) =>
                              handleCheckboxChange(e, project.id)
                            }
                          />
                        </td>
                        <td
                          className={`${
                            project?.name?.length > 30
                              ? "text-wrap"
                              : "text-nowrap"
                          } px-3 projectCol mw-50 fixedTd`}
                        >
                          {project?.name}{" "}
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip>
                                {project?.description ||
                                  "No description added."}
                              </Tooltip>
                            }
                          >
                            <span className="material-icons-outlined md-18">
                              info
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td className="text-nowrap px-3 align-middle">
                          {project?.group?.name}
                        </td>
                        <td className="text-nowrap px-3 align-middle">
                          {project?.decarbActionCategory?.name
                            ? project?.decarbActionCategory?.name
                            : projectTypesMap.find(
                                ({ key }) =>
                                  parseInt(key) ===
                                  parseInt(project?.projectType)
                              )?.value}
                        </td>
                        <td className="text-nowrap px-3 align-middle">
                          {isNaN(Number(project?.location))
                            ? project?.location
                            : locationsMap.find(
                                ({ key }) =>
                                  parseInt(key) === parseInt(project?.location)
                              )?.value}
                        </td>
                        <td className="text-nowrap px-3 align-middle">
                          {project?.site?.name}
                        </td>
                        <td className="text-nowrap px-3 align-middle">
                          {
                            projectCombinedStatusMap.find(
                              ({ key }) =>
                                parseInt(key) ===
                                parseInt(project?.projectStatus)
                            )?.value
                          }
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(project?.initialInvestment)}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(project?.annualSavings)}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 3,
                          }).format(project?.annualCarbonEmissionReduction)}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.paybackPeriod}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {!isNaN(Number(project?.internalRateOfReturn))
                            ? new Intl.NumberFormat("en-US", {
                                maximumFractionDigits: 3,
                                style: "percent",
                              }).format(project?.internalRateOfReturn / 100)
                            : "N/A"}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(project?.netPresentValue)}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 3,
                          }).format(project?.marginalAbatementCost)}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.scope}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.projectLifetime}
                        </td>
                        <td className="text-nowrap px-3 align-middle">
                          {
                            projectDataConfidenceMap?.find(
                              ({ key }) =>
                                parseInt(key) ===
                                parseInt(project?.dataConfidence)
                            )?.value
                          }
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.emissionSavingsStartDate}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.projectSize}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.unit}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.projectMaxSize}
                        </td>
                        <td className="text-nowrap px-3 align-middle">
                          {project?.autoRenew ? "Yes" : "No"}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {project?.discountRate}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(project?.disposalCost)}
                        </td>
                        <td className="text-end text-nowrap px-3 align-middle">
                          {new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 3,
                          }).format(project?.lifetimeCarbonEmissionReduction)}
                        </td>
                        {customFields?.map((customField, index) => (
                          <td
                            className={`${
                              customField?.type !== "textarea" ? "text-end" : ""
                            } text-nowrap px-3 align-middle`}
                            key={index}
                          >
                            {
                              project?.customFields?.find(
                                (data) =>
                                  data?.label === customField?.label &&
                                  data?.type === customField?.type
                              )?.value
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ScenarioCreate;
