import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./EmissionsBySite.scss";
import * as echarts from "echarts";

import { getFormattedEmission } from "utils/StringUtils";
import { groupBy } from "lodash";
import { useNavigate } from "react-router-dom";

const EmissionsBySite = ({ sites, sitesSummary, onChartRendered }) => {
  const el = useRef(null);
  const navigate = useNavigate();

  let emissionsYears = Object.keys(groupBy(sitesSummary, "yearEnded"));

  const labels = sites.map((site) => {
    return {
      siteId: site.id,
      name: "Labels",
      type: "bar",
      stack: site.name,
      label: {
        show: true,
        rotate: 90,
        align: "left",
        color: "#000",
        verticalAlign: "middle",
        overflow: "truncate",
        fontStyle: "normal",
        fontSize: 12,
        formatter: `{name|${
          site.name.length < 21 ? site.name : site.name.substring(0, 18) + "..."
        }}`,
        rich: { name: {} },
      },
      labelLayout: (params) => {
        return {
          y: params.rect.y - 5,
        };
      },
      barMaxWidth: 60,
      barMinWidth: 10,
      emphasis: { focus: "series", blurScope: "coordinateSystem" },
      data: emissionsYears.map((year) => {
        let summary = sitesSummary.find(
          (item) => item.yearEnded == year && item.siteId === site.id
        );
        return {
          value:
            summary?.scopeTwoEmissions ||
            summary?.scopeOneEmissions ||
            summary?.scopeThreeEmissions
              ? 0
              : undefined,
          itemStyle: { opacity: 0.8 },
        };
      }),
    };
  });

  const scopeOneEmissions = sites.map((site) => {
    return {
      siteId: site.id,
      name: "Scope 1",
      type: "bar",
      stack: site.name,

      barMaxWidth: 60,
      barMinWidth: 10,

      emphasis: { focus: "series", blurScope: "coordinateSystem" },
      data: emissionsYears.map((year) => {
        let summary = sitesSummary.find(
          (item) => item.yearEnded == year && item.siteId === site.id
        );
        return {
          value: summary?.scopeOneEmissions,
          itemStyle: { opacity: 0.8 },
        };
      }),
    };
  });
  const scopeTwoSeries = sites.map((site) => {
    return {
      siteId: site.id,
      name: "Scope 2",
      type: "bar",
      stack: site.name,

      barMaxWidth: 60,
      barMinWidth: 10,

      emphasis: { focus: "series", blurScope: "coordinateSystem" },
      data: emissionsYears.map((year) => {
        let summary = sitesSummary.find(
          (item) => item.yearEnded == year && item.siteId === site.id
        );
        return {
          value: summary?.scopeTwoEmissions,
          itemStyle: { opacity: 0.8 },
        };
      }),
    };
  });

  const scopeThreeSeries = sites.map((site) => {
    return {
      siteId: site.id,
      name: "Scope 3",
      type: "bar",
      stack: site.name,

      barMaxWidth: 60,
      barMinWidth: 10,
      emphasis: { focus: "series", blurScope: "coordinateSystem" },
      data: emissionsYears.map((year) => {
        let summary = sitesSummary.find(
          (item) => item.yearEnded == year && item.siteId === site.id
        );
        return {
          value: summary?.scopeThreeEmissions,
          itemStyle: { opacity: 0.8 },
        };
      }),
    };
  });

  // const series = sites.map((site) => {
  //   return {
  //     name: site.name,
  //     type: "bar",
  //     label: {
  //       show: true,
  //       rotate: 90,
  //       align: "left",
  //       color: "#000",
  //       verticalAlign: "middle",
  //       position: "insideBottom",
  //       distance: 15,
  //       fontStyle: "normal",
  //       formatter: `{name|${site.name}}`,
  //       fontSize: 16,
  //       rich: { name: {} },
  //     },
  //     emphasis: { focus: "series" },
  //     data: emissionsYears.map((year) => {
  //       let summary = sitesSummary.find(
  //         (item) => item.yearEnded == year && item.siteId === site.id
  //       );
  //       return summary?.emissions;
  //     }),
  //   };
  // });

  const series = [
    ...scopeOneEmissions,
    ...scopeTwoSeries,
    ...scopeThreeSeries,
    ...labels,
  ];
  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value ? `${getFormattedEmission(value)} tCO₂e` : "N/A",
        axisPointer: { type: "shadow" },
      },
      color: ["#b5c334", "#fac858", "#eb8146"],
      legend: {
        backgroundColor: "white",
        left: "3%",
        data: [
          {
            name: "Scope 1",
          },
          {
            name: "Scope 2",
          },
          {
            name: "Scope 3",
          },
        ],
      },
      xAxis: {
        type: "category",
        data: emissionsYears,
      },
      yAxis: {
        type: "value",
        max: (value) => value.max + value.max * 0.3,
        axisLabel: {
          formatter: (value) =>
            Intl.NumberFormat("en-US", { notation: "compact" }).format(value) +
            " tCO₂e",
        },
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {
            name: `Emissons By Site`,
          },
        },
      },
      dataZoom: [{ minValueSpan: 1 }],
      grid: { left: "3%", right: "4%", bottom: "50px", containLabel: true },
      series: series,
    };

    const chart = echarts.init(el.current);
    chart.setOption(option);

    chart.on("click", (params) => {
      let siteId = series[params.seriesIndex].siteId;
      let orgId = sites[0]?.organizationId;
      navigate(`/organizations/${orgId}/sites/${siteId}`);
    });

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      onChartRendered(src);
    });

    return () => {
      chart.clear();
    };
  }, [el, sitesSummary]);

  return (
    <div className="EmissionsBySiteChart">
      <div className="emissions-site-chart-container me-auto" ref={el} />
    </div>
  );
};

EmissionsBySite.propTypes = {
  sites: PropTypes.array.isRequired,
  sitesSummary: PropTypes.array.isRequired,
};

export default EmissionsBySite;
