import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { uniqBy } from "lodash";

import { getFormattedEmission } from "utils/StringUtils";
import { TableSortArrows } from "components/Shared/TableComponents";

const OrganizationList = ({ organizations, sortOrg, isLoading }) => {
  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });

  useEffect(() => {
    const data = organizations.map((org) => {
      let summary = org.summary[0];
      return {
        ...org,
        ...{
          topDownEmissions: summary?.topDownEmissions,
          bottomUpEmissions: summary?.bottomUpEmissions,
          abatementPotential: summary?.abatementPotential,
          carbonIntensity: summary?.carbonIntensity,
          qodScore: summary?.qodScore,
        },
      };
    });
    setTableData(data);
  }, [organizations]);

  const sortOrganizations = (column) => {
    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });

    let columnValue = tableSortState.sortAsc
      ? tableSortState.sortColumn
      : `-${tableSortState.sortColumn}`;
    sortOrg(columnValue);
  };

  return (
    <Table hover responsive size="lg" id="organizationList">
      <thead>
        <tr>
          <th
            className="table-sorter"
            onClick={() => !isLoading && sortOrganizations("name")}
          >
            Name
            <TableSortArrows
              sortColumn={tableSortState.sortColumn}
              colName={"name"}
              sortAsc={tableSortState.sortAsc}
            />
          </th>
          <th
            className="text-end table-sorter"
            onClick={() => !isLoading && sortOrganizations("topDownEmissions")}
          >
            Top-Down <br /> Estimation{" "}
            <small>
              (tCO<sub>2</sub>e)
            </small>
            <TableSortArrows
              sortColumn={tableSortState.sortColumn}
              colName={"topDownEmissions"}
              sortAsc={tableSortState.sortAsc}
            />
          </th>
          <th
            className="text-end table-sorter"
            onClick={() => !isLoading && sortOrganizations("bottomUpEmissions")}
          >
            Bottom-Up GHG
            <br /> Inventory{" "}
            <small>
              (tCO<sub>2</sub>e)
            </small>
            <TableSortArrows
              sortColumn={tableSortState.sortColumn}
              colName={"bottomUpEmissions"}
              sortAsc={tableSortState.sortAsc}
            />
          </th>
          <th
            className="text-end table-sorter"
            onClick={() =>
              !isLoading && sortOrganizations("abatementPotential")
            }
          >
            Abatement <br />
            Potential{" "}
            <small>
              (tCO<sub>2</sub>e/year)
            </small>{" "}
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>
                  Excluding Non-Committed and Deferred projects.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined md-18">info</span>
            </OverlayTrigger>
            <TableSortArrows
              sortColumn={tableSortState.sortColumn}
              colName={"abatementPotential"}
              sortAsc={tableSortState.sortAsc}
            />
          </th>
          <th
            className="text-end table-sorter justify-content-between"
            onClick={() => !isLoading && sortOrganizations("carbonIntensity")}
          >
            Emissions to Revenue <br />
            Intensity{" "}
            <small>
              (tCO<sub>2</sub>e/$M)
            </small>{" "}
            <TableSortArrows
              sortColumn={tableSortState.sortColumn}
              colName={"carbonIntensity"}
              sortAsc={tableSortState.sortAsc}
            />
          </th>
          <th
            className="table-sorter ps-2"
            onClick={() => !isLoading && sortOrganizations("updatedAt")}
          >
            Updated At
            <TableSortArrows
              sortColumn={tableSortState.sortColumn}
              colName={"updatedAt"}
              sortAsc={tableSortState.sortAsc}
            />
          </th>
        </tr>
      </thead>

      <tbody>
        {uniqBy(tableData, "id").map((org) => {
          return (
            <tr key={org.id}>
              <td className="w-25">
                <Link
                  to={`/organizations/${org.id}`}
                  className="text-decoration-none"
                >
                  {org.name}
                </Link>
              </td>
              <td className="text-end">
                {getFormattedEmission(org.topDownEmissions || 0)}
              </td>
              <td className="text-end">
                {getFormattedEmission(org.bottomUpEmissions || 0)}
              </td>
              <td className="text-end">
                {getFormattedEmission(org.abatementPotential || 0)}
              </td>
              <td className="text-end">
                {org.carbonIntensity ? (
                  getFormattedEmission(org.carbonIntensity || 0)
                ) : (
                  <small>n/a</small>
                )}{" "}
              </td>
              <td className="ps-3 text-nowrap">
                <small>
                  {new Date(org.updatedAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default OrganizationList;
