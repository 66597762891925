import React, { useContext, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import { get } from "utils/DeApi";

import Header from "../App/Header/Header";
import OrganizationDetail from "./OrganizationDetail/OrganizationDetail";
import SiteDetail from "./Site/SiteDetail/SiteDetail";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import UserCategories from "utils/userCategories";
import { AccountContext } from "contexts/AccountProvider";
import DataLayer from "utils/DataLayer";

const Organization = () => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const account = useContext(AccountContext);

  const [organization, setOrganization] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();
  const params = useParams();
  const organizationId = params.organizationId;

  useEffect(() => {
    const fetchOrganization = () => {
      setError(null);
      setIsLoading(true);
      const organizationPromise = get(`organizations/${organizationId}`);
      organizationPromise.promise
        .then((response) => {
          let org = response.data;
          if (org.accountId !== account.id) navigate("/");
          else {
            setOrganization(org);
            setError(null);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    fetchOrganization();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId, account.id]);

  return (
    <>
      <Header />
      <DataLayer />
      <Container id={userCategory} className="MainContent">
        {error && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
        {organization && (
          <Routes>
            <Route
              path="/sites/:siteId/*"
              element={<SiteDetail organization={organization} />}
            />
            <Route
              path="*"
              element={
                <OrganizationDetail
                  organization={organization}
                  onOrganizationUpdated={setOrganization}
                />
              }
            />
          </Routes>
        )}
      </Container>
    </>
  );
};

export default Organization;
