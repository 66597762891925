import React, { useEffect, useRef, useState } from "react";
import "./EmissionProjectionsChart.scss";
import * as echarts from "echarts";
import {
  checkIfIsInBau,
  convertNegativeToZero,
  formatNumber,
} from "../../helper";
import { isArray } from "lodash";

const colorsPallete = [
  "#9DC4D6",
  "#D4E4F7",
  "#C5D9E9",
  "#AEC6CF",
  "#E6E6FA",
  "#CBFDCB",
  "#E0FFFF",
  "#B0E0E6",
  "#D4DDE9",
  "#E7EBF1",
  "#CCE7EF",
  "#E7F5F8",
  "#CDEBED",
  "#E7F5F6",
  "#D9EDE4",
  "#EAF6F0",
  "#E5F1D9",
  "#F0F7EA",
];

const EmissionProjectionsChart = ({
  data = [],
  projections = {},
  scopeTwo = 0,
  collapsed,
  selectedLegends,
  setSelectedLegends,
  showAllProjects,
  sbtiConfig,
  decarbLever,
  onChartRendered,
  emission,
  setYearData,
  setAllYearsData,
  selectedYear,
  selectedIndex,
  yearData,
  forecastingConfig,
  organization,
  showDecarbLevers,
  selectedScope,
  baselineYear,
  targetBaselineYear,
  targetEmissions,
}) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);

  const userDefinedColors = [
    "#436D5D",
    "#82A78D",
    "#B7B2AA",
    "#82887E",
    "#19FFBC",
    "#4682B4",
    "#87CEEB",
    "#132B15",
  ];

  const currentPreferences =
    emission?.sbtiConfig &&
    JSON.parse(emission?.sbtiConfig)?.filter((data) => data?.show);

  const targets = currentPreferences?.map((data) =>
    data?.type === "sbti" ? data?.label || data?.title : data?.type
  );

  const filteredScope =
    selectedScope === "Scope 1+2+3"
      ? "allScopes"
      : selectedScope === "Scope 1+2"
      ? "scopeOneAndTwo"
      : selectedScope === "Scope 1"
      ? "scopeOne"
      : selectedScope === "Scope 2"
      ? "scopeTwo"
      : "scopeThree";

  let firstLineData;
  let secondLineData;

  if (selectedScope === "Scope 3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_three_no_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "scope_three_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else if (selectedScope === "Scope 1+2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_and_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1+2+3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "all_scope_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "all_scope_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else {
    firstLineData = "";
    secondLineData = "";
  }

  const filteredTarget = "netZero";

  const filteredSbtiData = emission?.sbtiData?.[filteredTarget]?.calculations;

  const filterNewDataScopeOne = filteredSbtiData?.filter(
    (item) => item?.[firstLineData]
  );

  const filterNewDataScopeTwo = filteredSbtiData?.filter(
    (item) => item?.[secondLineData]
  );

  const scopeOneData = filterNewDataScopeOne?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[firstLineData]),
  ]);
  const scopeTwoData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[secondLineData]),
  ]);

  const filteredUserDefinedData = emission?.settings?.filter((data) =>
    targets?.includes(data?.type)
  );

  const filteredUserDefinedDataMap = filteredUserDefinedData?.map(
    (data) => data?.calculations?.[filteredScope]?.["netZero"]
  );

  const userDefinedNames =
    filteredUserDefinedData?.map((data) => data?.type) || [];

  const userDefinedValues =
    filteredUserDefinedDataMap?.map(
      (data) =>
        data?.map((inData) => [
          inData?.year?.toString(),
          inData?.emission_reduction,
        ]) || []
    ) || [];
  // filteredUserDefinedDataMap?.map(
  //   (data) => data?.map((inData) => inData?.emission_reduction) || []
  // ) || [];

  useEffect(() => {
    const availableMiscLevers = (
      forecastingConfig?.length
        ? forecastingConfig.filter((val) => {
            return val.type && checkIfIsInBau(forecastingConfig, val.type);
          })
        : []
    ).reverse();
    const selectedScopeKey =
      selectedScope === "Scope 1+2"
        ? "s1s2"
        : selectedScope === "Scope 1"
        ? "s1"
        : selectedScope === "Scope 2"
        ? "s2"
        : selectedScope === "Scope 3"
        ? "s3"
        : "total";
    const _data = data.reduce(
      (prev, values) => ({
        year: [...prev.year, values.year],
        bau: [...prev.bau, convertNegativeToZero(values?.[`${decarbLever}`])],
        sumOfAnnualSaving: [
          ...prev.sumOfAnnualSaving,
          convertNegativeToZero(values.sumOfAnnualSaving),
        ],
        economicGrowth: [
          ...prev.economicGrowth,
          selectedScopeKey === "total"
            ? values?.growthProduction
            : values?.[`${selectedScopeKey}GrowthProduction`],
        ],
        bauGridDecarb: [
          ...prev.bauGridDecarb,
          values?.[
            `${
              selectedScopeKey === "s1s2" ? "s2" : selectedScopeKey
            }ReductionGridDecarb`
          ],
        ],
        bauHeatingFuels: [
          ...prev.bauHeatingFuels,
          values?.[
            `${
              selectedScopeKey === "s1s2" ? "s1" : selectedScopeKey
            }ReductionHeatFuel`
          ],
        ],
        bauTransport: [
          ...prev.bauTransport,
          values?.[
            `${
              selectedScopeKey === "s1s2" ? "s1" : selectedScopeKey
            }ReductionTransport`
          ],
        ],
      }),
      {
        year: [],
        economicGrowth: [],
        bauGridDecarb: [],
        bauHeatingFuels: [],
        bauTransport: [],
        bau: [],
        sumOfAnnualSaving: [],
      }
    );

    const projects = Array.isArray(projections)
      ? projections.map((project, index) => ({
          ...project,
          data: project.data.map((value) => convertNegativeToZero(value)),
        }))
      : [];
    const historicalData = [];
    const difference = Number(baselineYear) - Number(targetBaselineYear);
    const values =
      (_data?.sumOfAnnualSaving?.find((item) => item !== undefined) -
        targetEmissions) /
      difference;

    if (difference > 0) {
      let value = targetEmissions;
      let year = Number(targetBaselineYear);
      for (let index = 0; index <= difference; index++) {
        historicalData.push([year?.toString(), value]);
        value += values;
        year += 1;
      }
    }
    let option = {
      title: {
        show:
          data.length === 0 &&
          scopeOneData?.length === 0 &&
          scopeTwoData?.length === 0 &&
          userDefinedValues?.length === 0,

        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: 100,
        right: 220,
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => formatNumber(value, 0),
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        orient: "vertical",
        top: 50,
        type: "scroll",
        selectedMode: false,
        right: 0,
        formatter: (value) =>
          value.length > 25 ? value.substring(0, 25) + "..." : value,
        tooltip: { show: true },
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {
            name: `Roadmap Overview - ${organization?.name}`,
          },
        },
      },
      dataZoom: [{ minValueSpan: 1 }],
      xAxis: {
        type: "category",
        name: "Year",
        data: _data.year,
        nameLocation: "center",
        nameGap: "30",
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        ...(scopeOneData && scopeOneData?.length !== 0
          ? [
              {
                name: currentPreferences?.[0]?.title || "SBTi 1.5C Aligned",
                data: scopeOneData,
                type: "line",
                color: "#32CD32",

                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),
        ...(scopeTwoData && scopeTwoData?.length !== 0
          ? [
              {
                name:
                  currentPreferences?.[1]?.title || "SBTi 1.5C S1+2 & WB2C S3",
                data: scopeTwoData,
                type: "line",
                color: "#90EE90",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              },
            ]
          : []),
        ...(userDefinedValues.length !== 0
          ? userDefinedValues
              .filter((filteredData) => filteredData)
              .map((data, i) => ({
                name: userDefinedNames[i],
                data: data,
                type: "line",
                color: userDefinedColors[i],
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
              }))
          : []),

        {
          name: "BAU trend",
          data: _data.bau,
          type: "line",
          symbolSize: 0,
          color: "#FFDC60",
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
        },
        {
          name: "Emissions Pathway",
          data: _data.sumOfAnnualSaving,
          type: "line",
          symbolSize: 0,
          color: "#3353FF",
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
        },
        ...(targetBaselineYear &&
        baselineYear &&
        Number(targetBaselineYear) < Number(baselineYear)
          ? [
              {
                name: "Historical Trend",
                historicalData: true,
                data: historicalData,
                type: "line",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                color: "#000000",
                z: 101,
              },
            ]
          : []),
        ...(showDecarbLevers
          ? [
              {
                name: "Growth in Production/Turnover",
                data: _data.economicGrowth,
                type: "line",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                color: "#5470c6",
                z: 102,
              },
            ]
          : []),
      ],
    };

    option.legend = {
      ...option.legend,
      data: option.series.map((item, index) => ({
        name: item.name,
        textStyle: {
          fontWeight: item.name === "Growth in Production/Turnover" ? 600 : 500,
        },
      })),
    };
    if (showAllProjects) {
      const sort = (a, b) => {
        for (let index = 0; index < a.data.length; index++) {
          const element = a.data[index];
          const element2 = b.data[index];
          if (element) {
            return element < (element2 || 0);
          }
        }
      };

      option.series = [
        ...option.series,
        ...projects
          .toSorted(sort)
          .filter((project) => {
            if (project?.name?.includes("Unabated Emissions")) {
              return !!(
                (project?.name?.includes("1") && selectedScope.includes("1")) ||
                (project?.name?.includes("2") && selectedScope.includes("2")) ||
                (project?.name?.includes("3") && selectedScope.includes("3"))
              );
            } else {
              return project?.scope && selectedScope.includes(project?.scope);
            }
          })
          .map((project) => {
            return {
              ...project,
              data: project?.name?.includes("Unabated Emissions")
                ? project?.data?.map((val, idx) => {
                    if (val === undefined) {
                      return (
                        (historicalData?.[idx]?.[1] / 100) *
                        (selectedScope === "Scope 1+2+3"
                          ? 33.34
                          : selectedScope === "Scope 1+2"
                          ? 50
                          : 100)
                      );
                    } else {
                      return val;
                    }
                  })
                : project?.data,
              symbolSize: 0,
              lineStyle: {
                ...project?.lineStyle,
                width: 5,
              },
            };
          }),
        ...(showDecarbLevers
          ? [
              ...(availableMiscLevers.length
                ? availableMiscLevers.map((val, index) => {
                    const chartData = data.map(
                      (curr) =>
                        curr?.miscData?.find(
                          (misc) => val?.type === misc?.type
                        )?.[
                          selectedScopeKey === "total"
                            ? `total_reduction`
                            : selectedScopeKey === "s3"
                            ? "s3_reduction"
                            : selectedScopeKey?.includes(
                                val?.scopeOne ? 1 : val?.scopeTwo ? 2 : 3
                              )
                            ? "reduction"
                            : 0
                        ],
                      0
                    );

                    return {
                      name: val.title,
                      data: chartData,
                      type: "line",
                      symbolSize: 0,
                      color: colorsPallete[index],
                      scope: val.scopeOne
                        ? 1
                        : val.scopeThree
                        ? 3
                        : val.scopeTwo
                        ? 2
                        : 0,
                      stack: "x",
                      areaStyle: {
                        type: "default",
                        opacity: 1,
                      },
                      lineStyle: {
                        normal: {
                          opacity: 0,
                        },
                      },
                    };
                  })
                : []),
              ...(checkIfIsInBau(forecastingConfig, "transport-decarbonization")
                ? [
                    {
                      name: "Decarb of Transport",
                      data: _data.bauTransport,
                      type: "line",
                      symbolSize: 0,
                      scope: 1,
                      stack: "x",
                      color: "#D1E6FF",
                      areaStyle: {
                        type: "default",
                        opacity: 1,
                      },
                      lineStyle: {
                        normal: {
                          opacity: 0,
                        },
                      },
                    },
                  ]
                : []),
              ...(checkIfIsInBau(
                forecastingConfig,
                "heating-fuels-decarbonization"
              )
                ? [
                    {
                      name: "Decarb of Heating Fuels",
                      data: _data.bauHeatingFuels,
                      type: "line",
                      symbolSize: 0,
                      scope: 1,
                      stack: "x",
                      color: "#D1E8E2",
                      areaStyle: {
                        type: "default",
                        opacity: 1,
                      },
                      lineStyle: {
                        normal: {
                          opacity: 0,
                        },
                      },
                    },
                  ]
                : []),
              ...(checkIfIsInBau(forecastingConfig, "grid-decarbonization")
                ? [
                    {
                      name: "Decarb of Grid Electricity",
                      data: _data.bauGridDecarb,
                      type: "line",
                      symbolSize: 0,
                      scope: 2,
                      stack: "x",
                      color: "#AFEEEE",
                      areaStyle: {
                        type: "default",
                        opacity: 1,
                      },
                      lineStyle: {
                        normal: {
                          opacity: 0,
                        },
                      },
                    },
                  ]
                : []),
            ].sort((a, b) => b?.scope - a?.scope)
          : []),
      ];
      option.legend.data = [
        ...option.legend.data,
        ...(showDecarbLevers
          ? [
              ...(checkIfIsInBau(forecastingConfig, "grid-decarbonization")
                ? [
                    {
                      name: "Decarb of Grid Electricity",
                      icon: "rect",
                      textStyle: {
                        fontWeight: 600,
                      },
                      scope: 2,
                    },
                  ]
                : []),
              ...(checkIfIsInBau(
                forecastingConfig,
                "heating-fuels-decarbonization"
              )
                ? [
                    {
                      name: "Decarb of Heating Fuels",
                      icon: "rect",
                      textStyle: {
                        fontWeight: 600,
                      },
                      scope: 1,
                    },
                  ]
                : []),
              ...(checkIfIsInBau(forecastingConfig, "transport-decarbonization")
                ? [
                    {
                      name: "Decarb of Transport",
                      icon: "rect",
                      textStyle: {
                        fontWeight: 600,
                      },
                      scope: 1,
                    },
                  ]
                : []),
              ...(availableMiscLevers.length
                ? availableMiscLevers.map((val, index) => {
                    return {
                      name: val.title,
                      icon: "rect",
                      textStyle: {
                        fontWeight: 600,
                      },
                      scope: val.scopeOne
                        ? 1
                        : val.scopeThree
                        ? 3
                        : val.scopeTwo
                        ? 2
                        : 0,
                    };
                  })
                : []),
            ].sort((a, b) => a?.scope - b?.scope)
          : []),
        ...projects
          .toSorted(sort)
          .reverse()
          .map((project) => ({
            name: project.name,
            icon: "rect",
            textStyle: {
              fontWeight: 600,
            },
          })),
      ];
      function customTooltipFormatter(params) {
        var tooltipContent = `<div class="mb-2">${params[0].axisValueLabel}</div>`; // Initialize the tooltip content
        option.legend.data.forEach((legend) => {
          var dataValue = params.find((param) => {
            return param.seriesName === legend.name;
          });
          if (dataValue) {
            tooltipContent += `<div class="d-flex justify-content-between align-items-center mb-1"><div><span class="d-inline-block me-2" style="width: 10px; height: 10px; border-radius: 50%; background-color: ${
              dataValue?.color
            }"></span>
            ${
              legend.name === "1.5C SBTi (w/ constrain)" ||
              legend.name === "WB2C SBTi (w/ constrain)" ||
              userDefinedNames?.includes(legend.name) ||
              legend.name === "SBTi 1.5C Aligned" ||
              legend.name === currentPreferences?.[0]?.title ||
              legend.name === "SBTi 1.5C S1+2 & WB2C S3" ||
              legend.name === currentPreferences?.[1]?.title ||
              legend.name === "BAU trend" ||
              legend.name === "Emissions Pathway"
                ? legend.name
                : `<b>${legend.name}</b>`
            }
          </div><div class="ps-4"><b>${formatNumber(
            dataValue?.value?.length
              ? Number(dataValue?.value[1]).toFixed()
              : Number(dataValue?.value)?.toFixed(),
            0
          )}</b></div></div>`;
          }
        });

        return tooltipContent;
      }
      option.tooltip.formatter = (params) => customTooltipFormatter(params);
    }
    option.series = option.series
      .filter((val) => val.name !== "Unabated Emissions")
      .map((val, index) => {
        if (
          val.name === "1.5C SBTi (w/ constrain)" ||
          val.name === "WB2C SBTi (w/ constrain)" ||
          userDefinedNames?.includes(val.name) ||
          val.name === "SBTi 1.5C Aligned" ||
          val.name === currentPreferences?.[0]?.title ||
          val.name === "SBTi 1.5C S1+2 & WB2C S3" ||
          val.name === currentPreferences?.[1]?.title ||
          val.name === "BAU trend" ||
          val.name === "Historical Trend" ||
          val.name === "Emissions Pathway" ||
          val.name === "Growth in Production/Turnover" ||
          val.name === "Unabated Emissions"
        ) {
          return {
            ...val,
            symbolSize: 0,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 5,
                },
              },
            },
          };
        } else {
          return {
            ...val,
            symbolSize: 0,
            lineStyle: {
              ...val?.lineStyle,
              width: 0,
            },
            itemStyle: {
              ...val?.itemStyle,
            },
            areaStyle: {
              ...val?.areaStyle,
              type: "default",
            },
          };
        }
      });
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });

    const maxValue = Math.max(
      ...[]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => (isArray(item) ? !isNaN(item[1]) : !isNaN(item)))
        .map((item) => (isArray(item) ? item[1] : item))
    );
    if (
      !max ||
      max < 1 ||
      max < maxValue ||
      ![]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => (isArray(item) ? !isNaN(item[1]) : !isNaN(item)))
        .map((item) => (isArray(item) ? item[1] : item))
        .includes(max)
    ) {
      setMax(maxValue);
    }

    option.yAxis.max = max;
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    function fillRemainingArrayIndexes(array, length, filler = "N/A") {
      while (array.length < length) {
        array.push(filler);
      }
      return array;
    }

    onChartRendered
      ? chart.on("finished", () => {
          let src = chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: "#fff",
          });

          onChartRendered(src);
        })
      : chart.on("finished", (params) => {
          const data = option?.legend?.data?.map((val) => {
            const series = option?.series?.find(
              (item) => val?.name === item?.name
            );
            return {
              year: selectedYear,
              data: series?.data?.find((data, index) => {
                return isArray(data)
                  ? data[0] == selectedYear
                  : index === selectedIndex;
              }),
              name: series?.name,
              color: series?.color || series?.areaStyle?.color,
            };
          });

          const tableData = option?.legend?.data?.map((val) => {
            const series = option?.series?.find(
              (item) => val.name === item.name
            );
            return {
              name: series?.name,
              data: series?.data,
            };
          });
          const allYearsTableData = [
            ["", ..._data.year],
            ...tableData.map((item) => {
              if (Array.isArray(item?.data?.[0])) {
                return [
                  item?.name,
                  ..._data.year.map((year) => {
                    const entry = item?.data?.find(
                      (entry) => entry && entry[0] === year.toString()
                    );
                    return entry
                      ? entry[1] === null ||
                        entry[1] === undefined ||
                        Number.isNaN(entry[1])
                        ? "N/A"
                        : entry[1]
                      : "N/A";
                  }),
                ];
              } else {
                return [
                  item?.name,
                  ...(item?.data?.map((value) =>
                    value === null || value === undefined || Number.isNaN(value)
                      ? "N/A"
                      : value
                  ) || []),
                ];
              }
            }),
          ];
          if (
            !yearData?.length ||
            yearData[0]?.year !== selectedYear ||
            JSON.stringify(yearData) !== JSON.stringify(data)
          ) {
            if (setYearData) {
              setYearData(data);
            }
            if (setAllYearsData) {
              setAllYearsData(allYearsTableData);
            }
          }
        });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    data,
    projections,
    scopeTwo,
    collapsed,
    showAllProjects,
    showDecarbLevers,
    sbtiConfig,
    max,
    scopeOneData,
    emission,
    scopeTwoData,
    setSelectedLegends,
    selectedLegends,
    userDefinedValues,
    onChartRendered,
    userDefinedNames,
    decarbLever,
  ]);

  return (
    <div className="EmissionProjectionsChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default EmissionProjectionsChart;
