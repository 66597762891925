import React, { useEffect, useRef, useState } from "react";
import "./SBTiOverviewChart.scss";
import * as echarts from "echarts";
import { convertNegativeToZero, formatNumber } from "../../helper";
import { isArray } from "lodash";

const SBTiOverviewChart = ({
  data = [],
  sbtiConfig,
  newData = {},
  selectedScope,
  selectedTarget,
  emission,
  onChartRendered,
}) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);

  const userDefinedColors = [
    "#436D5D",
    "#82A78D",
    "#B7B2AA",
    "#82887E",
    "#19FFBC",
    "#4682B4",
    "#87CEEB",
    "#132B15",
  ];

  const currentPreferences = JSON.parse(emission?.sbtiConfig)?.filter(
    (data) => data?.show
  );

  const targets = currentPreferences?.map((data) =>
    data?.type === "sbti" ? data?.label || data?.title : data?.type
  );

  const filteredScope =
    selectedScope === "Scope 1+2+3"
      ? "allScopes"
      : selectedScope === "Scope 1+2"
      ? "scopeOneAndTwo"
      : selectedScope === "Scope 1"
      ? "scopeOne"
      : selectedScope === "Scope 2"
      ? "scopeTwo"
      : "scopeThree";

  let firstLineData;
  let secondLineData;

  if (selectedScope === "Scope 3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_three_no_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "scope_three_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else if (selectedScope === "Scope 1+2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_and_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1+2+3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "all_scope_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "all_scope_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else {
    firstLineData = "";
    secondLineData = "";
  }

  const filteredTarget =
    selectedTarget === "Near-Term Targets" ? "nearTerm" : "netZero";

  const filteredSbtiData = emission?.sbtiData?.[filteredTarget]?.calculations;

  const filterNewDataScopeOne = filteredSbtiData?.filter(
    (item) => item?.[firstLineData]
  );

  const filterNewDataScopeTwo = filteredSbtiData?.filter(
    (item) => item?.[secondLineData]
  );

  const scopeOneData = filterNewDataScopeOne?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[firstLineData]),
  ]);
  const scopeTwoData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[secondLineData]),
  ]);

  const filteredUserDefinedData = emission?.settings?.filter((data) =>
    targets?.includes(data?.type)
  );

  const filteredUserDefinedDataMap = filteredUserDefinedData?.map(
    (data) =>
      data?.calculations?.[filteredScope]?.[
        filteredTarget === "nearTerm" ? "nearTeam" : "netZero"
      ]
  );

  const userDefinedNames =
    filteredUserDefinedData?.map((data) => data?.type) || [];

  const userDefinedValues =
    filteredUserDefinedDataMap?.map(
      (data) => data?.map((inData) => inData?.emission_reduction) || []
    ) || [];

  const years = Array.from(
    {
      length:
        (filteredTarget === "nearTerm"
          ? Number(emission?.nearTermTargetYear)
          : Number(emission?.netzeroTargetYear)) -
        Number(emission?.baselineYear) +
        1,
    },
    (_, index) => Number(emission?.baselineYear) + index
  );

  useEffect(() => {
    const option = {
      title: {
        show:
          scopeOneData?.length === 0 &&
          scopeTwoData?.length === 0 &&
          userDefinedValues?.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: "15%",
        right: "10%",
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => formatNumber(value, 1),
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {},

      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: years,
      },

      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        min: 0,
        ...((selectedScope === "Scope 3" && {
          max: Number(emission?.emissionsScopeThree || 0) + 500,
        }) ||
          (selectedScope === "Scope 1+2+3" && {
            max:
              Number(emission?.emissionsScopeOne || 0) +
              Number(emission?.emissionsScopeTwo || 0) +
              Number(emission?.emissionsScopeThree || 0) +
              500,
          })),
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",

        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        ...(scopeOneData && scopeOneData?.length !== 0
          ? [
              {
                name: currentPreferences?.[0]?.title || "SBTi 1.5C Aligned",
                data: scopeOneData,
                type: "line",
                color: "#32CD32",
              },
            ]
          : []),

        ...(scopeTwoData && scopeTwoData?.length !== 0
          ? [
              {
                name:
                  currentPreferences?.[1]?.title || "SBTi 1.5C S1+2 & WB2C S3",
                data: scopeTwoData,
                type: "line",
                color: "#90EE90",
              },
            ]
          : []),

        ...(userDefinedValues.length !== 0
          ? userDefinedValues
              .filter((filteredData) => filteredData)
              .map((data, i) => ({
                name: userDefinedNames[i],
                data: data,
                type: "line",
                color: userDefinedColors[i],
              }))
          : []),
      ],
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });
    const maxValue = Math.max(
      ...[]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => (isArray(item) ? !isNaN(item[1]) : !isNaN(item)))
        .map((item) => (isArray(item) ? item[1] : item))
    );

    if (
      !max ||
      max < 1 ||
      max < maxValue ||
      ![]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => (isArray(item) ? !isNaN(item[1]) : !isNaN(item)))
        .map((item) => (isArray(item) ? item[1] : item))
        .includes(max)
    ) {
      setMax(maxValue);
    }
    option.yAxis.max = max;
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    onChartRendered &&
      chart.on("finished", (params) => {
        let src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });

        onChartRendered(src);
      });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    data,
    sbtiConfig,
    max,
    newData,
    scopeOneData,
    scopeTwoData,
    selectedScope,
    selectedTarget,
    filteredSbtiData,
    years,
    emission,
  ]);

  return (
    <div className="SBTinitiativeChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default SBTiOverviewChart;
