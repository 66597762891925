import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageReductions from "./ManageReductions/ManageReductions";
import EconomicGrowthChart from "./EconomicGrowthChart/EconomicGrowthChart";
import "./EconomicGrowth.scss";
import { formatNumber, formatPercentage, invertSymbol } from "../helper";
import { getEconomicGrowth } from "../forecastingModels";
import { TableSortArrows } from "components/Shared/TableComponents";

import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";

const EconomicGrowth = ({
  organization,
  breadcrumbs,
  baselineYear,
  scopeOne,
  scopeTwo,
  scopeThree,
  isForecastingUpdate,
}) => {
  const [emission, setEmission] = useState();
  const [economicGrowthData, setEconomicGrowthData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [scopeThreeReduction, setScopeThreeReduction] = useState(100);

  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();
  const userCategory = UserCategories();

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "year",
    updatedAt: true,
    sortAsc: false,
  });

  const sortingTable = (column, objValue) => {
    emission?.data?.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];

      if (
        macA === null ||
        macA === undefined ||
        macA === "n/a" ||
        macA === ""
      ) {
        return tableSortState[column] ? 1 : -1;
      }

      if (
        macB === null ||
        macB === undefined ||
        macB === "n/a" ||
        macA === ""
      ) {
        return tableSortState[column] ? -1 : 1;
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setEmission([...emission]);
  };

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const organizationPromise = get(
        `organizations/${organizationId}/economic-growth`
      );
      organizationPromise.promise
        .then((response) => {
          setIsLoading(false);
          setScopeThreeReduction(
            response?.data[0]?.scopeThreeEmissionPercentage || 0
          );
          setEmission({
            ...response.data[0],
            data: response?.data[0]?.growthProductionCalculation,
          });
          setEconomicGrowthData(response?.data[0]?.growthProductionCalculation);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    fetchEmission(organizationId);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId, scopeOne, scopeTwo, scopeThree, isForecastingUpdate]);

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: "Growth in Production/Turnover",
              link: `/organizations/${organization.id}/economic-growth`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">Growth in Production/Turnover</h2>
      <div className="my-3 p-3 border">
        <h3>
          Emissions Forecast
          <ManageReductions
            onManageReductionsUpdated={(data) => {
              setEconomicGrowthData(data?.growthProductionCalculation || []);
              setEmission({
                ...data,
                data: data?.growthProductionCalculation || [],
              });
            }}
            emissions={emission}
            baselineYear={baselineYear}
            isForecastingUpdate={isForecastingUpdate}
          />
        </h3>
        {!isLoading && !error && (
          <EconomicGrowthChart data={economicGrowthData} />
        )}
        {error && !isLoading && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
      </div>
      <div className="EconomicGrowthTable">
        <Table
          size="sm"
          responsive
          striped
          className="first-column-fixed my-3 mt-5"
        >
          <thead>
            <tr>
              <th
                className="px-3 text-end text-nowrap year-column table-sorter"
                onClick={() => sortingTable("year")}
              >
                Year{" "}
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"year"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="px-3 text-end text-nowrap table-sorter"
                onClick={() => sortingTable("revenue_increase_percent")}
              >
                % Change for Scope 1&2 due to Business Growth
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"revenue_increase_percent"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="px-3 text-end text-nowrap table-sorter"
                onClick={() => sortingTable("s1_reduction")}
              >
                Cumulative Change to Scope 1
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s1_reduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="px-3 text-end text-nowrap table-sorter"
                onClick={() => sortingTable("s2_reduction")}
              >
                Cumulative Change to Scope 2
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s2_reduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="px-3 text-end text-nowrap table-sorter"
                onClick={() => sortingTable("s3_reduction")}
              >
                Cumulative Change to Scope 3
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s3_reduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {emission?.data?.map(
              (
                {
                  year,
                  revenue_increase_percent,
                  s1_reduction_percent,
                  s2_reduction_percent,
                  s1_reduction,
                  s2_reduction,
                  s3_reduction,
                },
                index
              ) => (
                <tr key={index}>
                  <td className="px-3 text-end text-nowrap year-column">
                    {year}
                  </td>
                  <td className="px-3 text-end text-nowrap">
                    {formatPercentage(revenue_increase_percent)}
                  </td>
                  <td className="px-3 text-end text-nowrap">
                    {formatNumber(invertSymbol(s1_reduction), 2)}
                  </td>
                  <td className="px-3 text-end text-nowrap">
                    {formatNumber(invertSymbol(s2_reduction), 2)}
                  </td>
                  <td className="px-3 text-end text-nowrap">
                    {formatNumber(invertSymbol(s3_reduction), 2)}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </div>

      {!error && !isLoading && !emission?.id && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"62eaca7272397"} view={"full"} />
      </div>
    </div>
  );
};

EconomicGrowth.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  baselineYear: PropTypes.number,
  scopeOne: PropTypes.number,
  scopeTwo: PropTypes.number,
};

export default EconomicGrowth;
