import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import "./ResetPassword.css";
import { put } from "utils/BeeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import CopyRight from "../Copyright/Copyright";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";

import Logo from "../Logo/Logo";

import { isValidEmail } from "utils/StringUtils";
import { isValidPassword } from "utils/StringUtils";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState();
  const [passwordValid, setPasswordValid] = useState();
  const [confirmPasswordValid, setConfirmPasswordValid] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const subscribedPromises = useRef([]);
  const [validationToken, setValidationToken] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => promises.forEach((promise) => promise.cancel());
  }, []);

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search || "");
    setValidationToken(searchParams.get("verification"));
  }, [location]);

  function handleEmailChange(event) {
    let _email = event.target.value;
    setEmail(_email);
    setEmailValid(isValidEmail(_email));
  }

  function handlePasswordChange(event) {
    let _password = event.target.value;
    setPassword(_password);
    setPasswordValid(isValidPassword(_password));
  }

  function handleConfirmPasswordChange(event) {
    let _confirmPassword = event.target.value;
    setConfirmPassword(_confirmPassword);
    setConfirmPasswordValid(
      isValidPassword(_confirmPassword) && _confirmPassword === password
    );
  }

  function toggleVisiblePassword() {
    setShowPassword(!showPassword);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setFormSubmitted(true);

    if (emailValid && passwordValid && confirmPasswordValid) {
      setError("");
      setIsLoading(true);

      const createPromise = put("/user/anon/password", {
        userId: "anon",
        email: email,
        token: validationToken,
        password: password,
        password_confirmation: confirmPassword,
      });
      createPromise.promise
        .then((response) => {
          setIsLoading(false);

          //On success redirect to login.
          navigate("/login", { state: { from: location } });
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(createPromise);
    }
  }

  function renderResetPasswordForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="mt-sm mb-md text-center">
          <h2 className="pre-title">Set Password</h2>
          <p>Enter your email and a new password for your account.</p>
        </div>
        <hr />
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
            data-testid="email-input"
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              isInvalid={!passwordValid && formSubmitted}
              placeholder="Password"
              data-testid="password-input"
            />
            <Button
              variant="light"
              className="border-top border-end border-start border-bottom"
              onClick={toggleVisiblePassword}
            >
              {showPassword ? (
                <span className="material-icons-outlined">visibility_off</span>
              ) : (
                <span className="material-icons-outlined">visibility</span>
              )}
            </Button>
            <Form.Control.Feedback type="invalid">
              Password must contain at least 8 but not more than 64 characters,
              including UPPER/lowercase and numbers.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3 text-input">
          <Form.Label>Confirm Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              isInvalid={!confirmPasswordValid && formSubmitted}
              placeholder="Confirm Password"
              data-testid="confirm-input"
            />
            <Button
              variant="light"
              className="border-top border-end border-start border-bottom"
              onClick={toggleVisiblePassword}
            >
              {showPassword ? (
                <span className="material-icons-outlined">visibility_off</span>
              ) : (
                <span className="material-icons-outlined">visibility</span>
              )}
            </Button>
            <Form.Control.Feedback type="invalid">
              Confirm password must match with password
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        {error &&
          ((error) => {
            if (error.status === 403) {
              return (
                <Alert variant="warning" className="mt-3 mb-3">
                  <p>
                    Sorry, your link to set a new password has expired or is
                    invalid. Please request for another link{" "}
                    <Link to="/forgot-password">here</Link>.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        <div className="d-grid gap-2 mb-3">
          <Button
            className="mb-4"
            type="submit"
            color="primary"
            disabled={isLoading}
            data-testid="continue-button"
          >
            {isLoading && (
              <Spinner
                className="mr-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Continue
          </Button>
        </div>
        <p className="text-center">
          <Link to="/login">Cancel</Link>
        </p>
      </Form>
    );
  }

  return (
    <Container id="reset-password" className="ResetPassword mt-4 mb-4">
      <RedirectIfAuthenticated />
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <Logo />
          <Card className="border shadow-sm">
            <Card.Body>{renderResetPasswordForm()}</Card.Body>
          </Card>
        </Col>
      </Row>
      <CopyRight />
    </Container>
  );
};

export default ResetPassword;
