import React, { useEffect, useRef, useState } from "react";
import "./EconomicGrowthChart.scss";
import * as echarts from "echarts";
import { convertNegativeToZero, formatNumber } from "../../helper";

const EconomicGrowthChart = ({ data = [] }) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);
  useEffect(() => {
    const _data = data.reduce(
      (
        prev,
        {
          year,
          s1_emission,
          s2_emission,
          s3_emission,
          s1_s2_emissions,
          total_emission,
        }
      ) => {
        return {
          year: [...prev.year, year],
          scopeOneEmission: [
            ...prev.scopeOneEmission,
            convertNegativeToZero(s1_emission),
          ],
          scopeTwoEmission: [
            ...prev.scopeTwoEmission,
            convertNegativeToZero(s2_emission),
          ],
          scopeThreeEmission: [
            ...prev.scopeThreeEmission,
            convertNegativeToZero(s3_emission),
          ],
          s1s2Emissions: [
            ...prev.s1s2Emissions,
            convertNegativeToZero(s1_s2_emissions),
          ],
          totalEmission: [
            ...prev.totalEmission,
            convertNegativeToZero(total_emission),
          ],
        };
      },
      {
        year: [],
        scopeOneEmission: [],
        scopeTwoEmission: [],
        scopeThreeEmission: [],
        s1s2Emissions: [],
        totalEmission: [],
      }
    );

    const option = {
      title: {
        show: data.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: "15%",
        right: "10%",
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        valueFormatter: (value) => formatNumber(value, 2),
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },

      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        data: _data.year,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        min: 0,
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        {
          data: _data.totalEmission,
          type: "line",
          name: "Scopes 1+2+3",
        },
        {
          data: _data.s1s2Emissions,
          type: "line",
          name: "Scopes 1+2",
        },
        {
          data: _data.scopeOneEmission,
          type: "line",
          name: "Scope 1",
        },
        {
          data: _data.scopeTwoEmission,
          type: "line",
          name: "Scope 2",
        },
        {
          data: _data.scopeThreeEmission,
          type: "line",
          name: "Scope 3",
        },
      ],
      legend: {
        selected: {
          "Scopes 1+2+3": true,
          "Scopes 1+2": false,
          "Scope 1": false,
          "Scope 2": false,
          "Scope 3": false,
        },
      },
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });
    const maxValue = Math.max(
      ...[].concat(...option.series.map((val) => val.data))
    );
    if (
      !max ||
      max < 1 ||
      max < maxValue ||
      ![].concat(...option.series.map((val) => val.data)).includes(max)
    ) {
      setMax(maxValue);
    }
    option.yAxis.max = max;

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data, max]);

  return (
    <div className="EconomicGrowthChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default EconomicGrowthChart;
