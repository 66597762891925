import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { useState, useCallback, useRef, useEffect } from "react";
import { Table, Alert } from "react-bootstrap";
import { get } from "utils/DeApi";
import NzifUpdate from "./NzifUpdate/NzifUpdate";
import { groupBy } from "lodash";
import NzifEdit from "./NzifEdit/NzifEdit";
import NzifDelete from "./NzifDelete/NzifDelete";
import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";
import { TableSortArrows } from "components/Shared/TableComponents";

const NzifDetail = ({ organization }) => {
  const subscribedPromises = useRef([]);

  const [onNzifDetailUpdated, setOnNzifDetailUpdated] = useState(false);
  const [nzifDetail, setNzifDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [tableData, setTableData] = useState([]);
  const [tableSortState, setTableSortState] = useState({
    sortColumn: "yearEnded",
    sortAsc: false,
  });

  const userCategory = UserCategories();

  const fetchNzifDetails = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const nzifDetailPromise = get(
      `organizations/${organization.id}/nzif-details`
    );

    nzifDetailPromise.promise
      .then((response) => {
        setNzifDetail(response?.data);
        setOnNzifDetailUpdated(false);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(nzifDetailPromise);
  }, [onNzifDetailUpdated]);

  useEffect(() => {
    fetchNzifDetails();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchNzifDetails]);

  useEffect(() => {
    const nzifData = groupBy(nzifDetail, "yearEnded");
    const groupedNzif = Object.entries(nzifData).map(([key, value]) => ({
      yearEnded: key,
      assetType: value?.[0]?.assetType,
      nzifDetails: value,
    }));
    groupedNzif?.sort((a, b) => (a["yearEnded"] <= b["yearEnded"] ? 1 : -1));

    setTableData(groupedNzif);
  }, [nzifDetail]);

  const sortTable = (column) => {
    tableData.sort((a, b) => {
      a = a[column];
      b = b[column];
      if (!tableSortState[column]) return a <= b ? 1 : -1;
      else return a >= b ? 1 : -1;
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  return (
    <div id={userCategory}>
      <DataLayer />
      {isLoading && <Loader />}
      {error && <ErrorHandler error={error} />}
      {nzifDetail && !error && !isLoading && (
        <>
          <div className="my-3">
            <BreadCrumbs
              breadcrumbs={[
                { name: "Dashboard", link: "/" },
                {
                  name: organization.name,
                  link: `/organizations/${organization.id}`,
                },
                {
                  name: "NZIF",
                  link: `/organizations/${organization.id}/nzif`,
                  active: true,
                },
              ]}
            />
          </div>
          <h2>NZIF Details</h2>
          <hr />

          <div className="my-4">
            <span className="float-end">
              <NzifUpdate
                organization={organization}
                setOnNzifDetailUpdated={setOnNzifDetailUpdated}
                nzifDetail={nzifDetail}
              />
            </span>
            <h4 className="mb-3">NZIF Category Assignment & Asset Breakdown</h4>
          </div>
          <br />
          <div>
            {!!nzifDetail?.length ? (
              <div className="table-scroll-end">
                <Table
                  size="sm"
                  striped
                  responsive
                  className="first-column-fixed"
                >
                  <thead>
                    <tr>
                      <th
                        className="table-sorter"
                        onClick={() => sortTable("yearEnded")}
                      >
                        Reporting Year
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"yearEnded"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th
                        className="table-sorter"
                        onClick={() => sortTable("assetType")}
                      >
                        Asset Type
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={"assetType"}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                      <th>Asset Breakdown</th>
                      <th>% of AUM</th>
                      <th>NZIF Category</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((data) => (
                      <tr key={data?.yearEnded}>
                        <td className="align-middle">
                          {data?.yearEnded?.slice(0, 4)}
                        </td>
                        <td className="align-middle">
                          {data?.assetType === 1
                            ? "Infrastructure"
                            : "Non-Infrastructure"}
                        </td>
                        <td className="align-middle">
                          {data?.nzifDetails?.length > 1 ? (
                            <>
                              <span>Asset in Operation</span>
                              <hr className="m-0" />
                              <span>Asset Under Construction</span>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td className="align-middle">
                          {data?.nzifDetails?.length > 1 ? (
                            <>
                              {data?.nzifDetails?.sort(
                                (a, b) => a?.assetStatus - b?.assetStatus
                              ) && (
                                <>
                                  <span>
                                    {data?.nzifDetails?.[0]?.assetPercentage}%
                                  </span>
                                  <hr className="m-0" />
                                  <span>
                                    {data?.nzifDetails?.[1]?.assetPercentage}%
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <span>
                              {data?.nzifDetails?.[0]?.assetPercentage}%
                            </span>
                          )}
                        </td>
                        <td className="align-middle">
                          {data?.nzifDetails?.length > 1 ? (
                            <>
                              {data?.nzifDetails?.sort(
                                (a, b) => a?.assetStatus - b?.assetStatus
                              ) && (
                                <>
                                  <span>
                                    {data?.nzifDetails?.[0]?.nzifCategory?.name}
                                  </span>
                                  <hr className="m-0" />
                                  <span>
                                    {data?.nzifDetails?.[1]?.nzifCategory?.name}
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <span>
                              {data?.nzifDetails?.[0]?.nzifCategory?.name}
                            </span>
                          )}
                        </td>
                        <td className="text-end align-middle">
                          <NzifEdit
                            organization={organization}
                            setOnNzifDetailUpdated={setOnNzifDetailUpdated}
                            nzifYear={data?.yearEnded}
                            nzifInfo={data?.nzifDetails?.sort(
                              (a, b) => a?.assetStatus - b?.assetStatus
                            )}
                          />
                          <NzifDelete
                            setOnNzifDetailUpdated={setOnNzifDetailUpdated}
                            nzifYear={data?.yearEnded}
                            nzifInfo={data?.nzifDetails?.sort(
                              (a, b) => a?.assetStatus - b?.assetStatus
                            )}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <Alert variant="info">
                There is currently nothing to show here.
              </Alert>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NzifDetail;
